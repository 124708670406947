import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get all task list
  GET_ALL_TASKS(state) {
    REQUEST(state)
  },
  GET_ALL_TASKS_SUCCESS(state, data) {
    SUCCESS(state)
    state.allTask = data
  },
  GET_ALL_TASKS_FAILURE(state, error) {
    FAILURE(state, error)
  },
  SET_SEARCH_TERM(state, searchTerm) {
    state.searchTask = searchTerm
  },
}
