<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Create ads account"
    width="532px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2 pr-1">
      <validation-observer
        ref="formValidation"
        v-slot="{ invalid }"
      >
        <b-form>
          <div>
            <div class="position-relative">
              <div class="mb-1">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="timezone"
                    rules="required"
                  >
                    <label>Timezone</label>
                    <b-input-group
                      data-testid="dropdown-timezone"
                      class="timezone-select"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <v-select
                        v-model="timezone"
                        style="width: 100%"
                        label="timezone"
                        placeholder="-- Select an option --"
                        class="input-height"
                        :clearable="false"
                        :options="listTimeZone"
                        :state="errors.length > 0 ? false:null"
                      >
                        <template
                          #open-indicator="{ attributes }"
                        >
                          <span v-bind="attributes">
                            <feather-icon
                              icon="ChevronDownIcon"
                              size="20"
                            />
                          </span>
                        </template>
                      </v-select>
                    </b-input-group>
                    <small
                      data-testid="timezone-message-required"
                      class="text-danger position-absolute"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <label>Name</label>
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model="name"
                        id="name"
                        data-testid="name-ads-account"
                        name="name"
                        tabindex="1"
                        class="input-height"
                        placeholder="Enter name"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small
                      data-testid="name-message-required"
                      class="text-danger position-absolute"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="PartnerBCId"
                    rules="required"
                  >
                    <label>Partner BC Id</label>
                    <b-input-group
                      v-if="hasConnectedTikTok"
                      class="input-group-merge mr-1"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-select
                        v-model="partnerBCId"
                        id="partner-bc-id"
                        placeholder="Select business ID"
                        name="PartnerBCId"
                        :options="optionsListPartnerBC"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Select an option --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group>
                    <b-input-group
                      v-else
                      class="input-group-merge mr-1"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model.trim="partnerBCId"
                        id="partner-bc-id"
                        tabindex="1"
                        class="input-height"
                        name="PartnerBCId"
                        type="number"
                        placeholder="Ex: 432342423423"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small
                      data-testid="partner-message-required"
                      class="text-danger position-absolute"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <label>Business Name</label>
                    <b-input-group
                      data-testid="input_busniness_name"
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model="businessName"
                        id="name"
                        data-testid="input-business-name"
                        name="name"
                        tabindex="1"
                        class="input-height"
                        placeholder="Enter business name"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger position-absolute">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="url|required"
                  >
                    <label>Business Website</label>
                    <b-input-group
                      data-testid="input-busniness-website"
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model="businessWebsite"
                        id="name"
                        name="name"
                        tabindex="1"
                        class="input-height"
                        placeholder="Enter business website"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger position-absolute">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <!-- submit -->
            <div
              data-testid="button-submit"
              class="btn-submit"
            >
              <btn-loading
                type="submit"
                variant="primary"
                block
                :loading="loading"
                :disabled="invalid"
                @click.prevent="handleSubmit"
              >
                Create new ads account
              </btn-loading>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-plusplus */

import {
  BSidebar,
  BForm,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { createNamespacedHelpers } from 'vuex'
import vSelect from 'vue-select'

const { mapActions, mapGetters } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BForm,
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    vSelect,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
  },

  mixins: [toastification, generalConfigsMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      isLoading: false,
      timezone: null,
      name: '',
      partnerBCId: null,
      businessWebsite: '',
      businessName: '',
    }
  },

  computed: {
    ...mapGetters(['status', 'loading', 'message', 'hasConnectedTikTok', 'listPartnerBC', 'userDetail']),

    optionsListPartnerBC() {
      let options = []
      if (this.listPartnerBC.length > 0) {
        options = this.listPartnerBC.map(item => ({
          text: `${item?.bc_info?.bc_id} - ${item?.bc_info?.name}`,
          value: item?.bc_info?.bc_id,
        }))
      }
      return options
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.businessWebsite = this.userDetail?.businessInfo?.website || ''
      this.businessName = this.userDetail?.businessInfo?.name || ''
    },

    listPartnerBC: {
      handler(value) {
        if (value.length > 0) {
          const partnerBCDefault = this.listPartnerBC.filter(item => item.isDefault)
          for (let i = 0; i < partnerBCDefault.length; i++) {
            this.partnerBCId = partnerBCDefault[i].bc_info?.bc_id
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['createAdsAccount']),

    resetForm() {
      this.timezone = null
      this.name = ''
      this.partnerBCId = null
      this.businessWebsite = null
      this.businessName = null
      this.$refs.formValidation.reset()
    },

    onHidden() {
      this.resetForm()
      this.$emit('close-side-bar')
    },

    fetchAdsAccountUser() {
      this.$emit('fetch-ads-account-user')
    },

    async handleSubmit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          const { params } = this.$route
          const id = Number(params.id)
          const data = {
            timezone: this.timezone,
            name: this.name,
            companyName: this.businessName,
            companyWebsite: this.businessWebsite,
            businessId: this.partnerBCId,
            localUserId: id,
          }
          await this.createAdsAccount(data)
          if (this.status) {
            this.toastSuccess('Created ads account successfully!')
            this.fetchAdsAccountUser()
            this.onHidden()
          } else {
            this.toastFailure(this.message)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.input-height {
  .vs__dropdown-toggle {
    height: 43px;

    .vs__selected-options {
      .vs__search {
        margin: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;

  strong {
    flex: 1;
  }
}

.button-action {
  width: 48px;
  height: 45px;
  padding: 0;
  margin-top: 22px;
  margin-left: 12px;

  &.remove {
    background-color: white !important;

    svg {
      color: #000;
    }
  }
}

::v-deep .b-sidebar-body {
  overflow-y: hidden;

  & > div {
    height: 100%;

    & > span {
      display: block;
      height: 100%;

      & > form {
        height: 100%;

        & > div {
          height: calc(100% - 50px);
          overflow-y: auto;
        }
      }
    }
  }
}

.btn-submit {
  position: absolute;
  bottom: 16px;
  width: 91%;
}

.input-height {
  height: 43px;

  .vs__dropdown-toggle {
    height: 43px;

    .vs__selected-options {
      .vs__search {
        margin: 0;
      }
    }
  }
}
</style>
