import axios from '@axios'

export default {
  importAdCreative(params) {
    return axios.post('/api/admin/ad-creatives/set-reviewing-ads-account', params)
  },

  getAdCreatives(params) {
    return axios.get('/api/admin/ad-creatives', { params })
  },

  getAdAccountImport(params) {
    return axios.get('/api/admin/ad-creatives/reviewing-ads-accounts', { params })
  },

  reviewAdCreative(params) {
    return axios.post(`/api/admin/ad-creatives/${params.adId}/review`, params)
  },
}
