<template>
  <div class="bc-management-container">
    <b-card
      class="filter-card"
    >
      <b-row class="w-100 m-0">
        <b-col
          md="2"
          class="p-0"
        >
          <h3 class="m-0">
            Filters
          </h3>
        </b-col>
        <b-col
          offset-md="7"
          md="3"
          class="p-0"
        >
          <b-button
            variant="outline-primary"
            class="w-100"
            @click="showAddPartnerSidebar"
          >
            Add Permissions
          </b-button>
        </b-col>
      </b-row>
      <!-- advance search input -->
      <b-row>
        <b-col
          md="4"
          class="
              country-tag
              d-flex
              justify-content-between
              align-items-center
            "
        >
          <b-form-group class="w-100">
            <label>Partner BC ID</label>
            <b-form-input
              v-model.trim="BCId"
              placeholder="Search by Partner BC ID"
              class="d-inline-block input-height"
              type="number"
              @wheel="handleMouseWheelInput"
              @input="handleFilterBCId"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          class="
              country-tag
              d-flex
              justify-content-between
              align-items-center
            "
        >
          <b-form-group class="w-100">
            <label>Ad Account ID</label>
            <b-form-input
              v-model.trim="adAccountId"
              placeholder="Search by ID"
              class="d-inline-block input-height"
              type="number"
              @wheel="handleMouseWheelInput"
              @input="handleFilterAdsAccountId"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          class="
              country-tag
              d-flex
              justify-content-between
              align-items-center
            "
        >
          <b-form-group class="w-100">
            <label> Role </label>
            <v-select
              v-model="roleSelected"
              class="input-height"
              placeholder="Select role"
              :options="roleOptions"
              @input="handleFilterRole"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <!-- table -->
    <vue-good-table
      mode="remote"
      class="table-user-info"
      :columns="columns"
      :rows="listPartnerPermission"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: totalPartnerPermission > 0
      }"
      :sort-options="{
        enabled: totalPartnerPermission > 0
      }"
      :is-loading="loading"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- plans.type -->
        <div v-if="props.column.field === 'BCIds'">
          <div class="column-list-BCId">
            <div
              v-for="(BCId, index) in getListBCId(props.row)"
              :key="index"
              class="list-BCId"
            >
              <span>
                {{ BCId }}
              </span>
            </div>
          </div>
        </div>

        <div v-else-if="props.column.field === 'adsAccountId'">
          <span>
            {{ getAdsAccountId(props.row) }}
          </span>
        </div>

        <div v-else-if="props.column.field === 'role'">
          <span>
            {{ getRoleBC(props.row) }}
          </span>
        </div>

        <div v-else-if="props.column.field === 'createdAt'">
          <span>
            {{ getCreatedDate(props.row) }}
          </span>
        </div>

        <div v-else-if="props.column.field === 'approvedBy'">
          <span>
            {{ getApproveBy(props.row) }}
          </span>
        </div>

        <!-- actions -->
        <div v-else-if="props.column.field === 'actions'">
          <!--          <b-dropdown-->
          <!--            variant="link"-->
          <!--            toggle-class="text-decoration-none"-->
          <!--            no-caret-->
          <!--          >-->
          <!--            <template v-slot:button-content>-->
          <!--              <feather-icon-->
          <!--                icon="MoreVerticalIcon"-->
          <!--                size="16"-->
          <!--                class="text-body align-middle mr-25"-->
          <!--              />-->
          <!--            </template>-->
          <!--            <b-dropdown-item-->
          <!--              @click="handleRevokePermission(props.row)"-->
          <!--            >-->
          <!--              <span>Revoke</span>-->
          <!--            </b-dropdown-item>-->
          <!--          </b-dropdown>-->
          <b-button
            variant="outline-primary"
            @click.prevent="handleRevokePermission(props.row)"
          >
            Revoke Permissions
          </b-button>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalPartnerPermission > 0"
        slot="pagination-bottom"
      >
        <div class="d-flex flex-wrap mt-1 justify-content-between">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              of {{ totalPartnerPermission }} account(s)
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalPartnerPermission"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>

      <div
        slot="emptystate"
        class="text-center"
      >
        <no-data-table-image class="my-5" />
      </div>
    </vue-good-table>

    <add-partner-permission
      :show-side-bar.sync="showSidebar"
      @fetch-list-partner-permissions="fetchListPartnerPermissions"
    />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
import { createNamespacedHelpers } from 'vuex'
import {
  BPagination,
  BSpinner,
  // BDropdown,
  // BDropdownItem,
  BFormSelect,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

// validation
import {
  required,
} from '@validations'
import _get from 'lodash/get'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import AddPartnerPermission from '@/views/partner-permission/components/AddPartnerPermission.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import axiosLib from 'axios'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('partnerPermission')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  name: 'PartnerPermission',

  components: {
    AddPartnerPermission,
    NoDataTableImage,
    BPagination,
    BSpinner,
    VueGoodTable,
    // BDropdown,
    // BDropdownItem,
    BFormSelect,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    vSelect,
  },

  mixins: [toastification, numberFormatMixin, dateTimeFormatMixin],

  data() {
    return {
      required,

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      columns: [
        {
          label: 'Partner BC ID',
          field: 'BCIds',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Ad account ID',
          field: 'adsAccountId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Role',
          field: 'role',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Created at',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Approved by',
          field: 'approvedBy',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],

      showSidebar: false,
      BCId: null,

      roleOptions: [
        {
          label: 'All Status',
          value: null,
        },
        {
          label: 'ADMIN',
          value: 'ADMIN',
        },
        {
          label: 'OPERATOR',
          value: 'OPERATOR',
        },
        {
          label: 'ANALYST',
          value: 'ANALYST',
        },
      ],
      roleSelected: null,

      adAccountId: '',
    }
  },

  computed: {
    ...mapGetters(['listPartnerPermissions', 'status', 'loading', 'message']),

    isActiveBtn() {
      return this.BCId?.length > 3
    },

    listPartnerPermission() {
      return _get(this.listPartnerPermissions, ['content'])
    },

    totalPartnerPermission() {
      return _get(this.listPartnerPermissions, ['paging', 'total'])
    },

    getListBCId() {
      return bc => bc?.BCIds || 'N/A'
    },

    getCreatedDate() {
      return bc => (bc.createdAt
        ? this.formatDate(bc.createdAt)
        : 'N/A')
    },

    getApproveBy() {
      return bc => bc?.approvedBy || 'N/A'
    },

    getAdsAccountId() {
      return bc => bc?.adsAccountId || 'N/A'
    },

    getRoleBC() {
      return bc => bc?.role || 'N/A'
    },
  },

  created() {
    this.fetchListPartnerPermissions()
  },

  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions(['getListPartnerPermission', 'approvePartnerPermission', 'revokePartnerPermission']),

    async handleFilterBCId(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchListPartnerPermissions()
      } else if (searching.length > 3) {
        this.debounce = setTimeout(() => {
          this.fetchListPartnerPermissions()
        }, 600)
      }
    },

    handleFilterRole() {
      this.currentPage = 1
      this.fetchListPartnerPermissions()
    },

    async handleFilterAdsAccountId(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchListPartnerPermissions()
      } else if (searching.length > 3) {
        this.debounce = setTimeout(() => {
          this.fetchListPartnerPermissions()
        }, 600)
      }
    },

    showAddPartnerSidebar() {
      this.showSidebar = true
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListPartnerPermissions()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListPartnerPermissions()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListPartnerPermissions()
    },

    async handleRevokePermission(data) {
      const { _id } = data
      await this.revokePartnerPermission({ _id })
      if (this.status) {
        this.fetchListPartnerPermissions()
        this.toastSuccess('Revoke partner permission successfully!')
      } else {
        this.toastFailure(this.message)
      }
    },

    async fetchListPartnerPermissions() {
      abort()
      let BCIdString = ''
      if (this.BCId) {
        BCIdString = this.BCId.toString()
      }
      const params = {
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        page: this.currentPage,
        size: this.pageLength,
        ...(this.BCId && BCIdString && { BCIds: BCIdString }),
        ...(this.adAccountId && { adsAccountId: this.adAccountId }),
        ...(this.roleSelected && { role: this.roleSelected.value }),
      }

      await this.getListPartnerPermission({ params, cancelToken: cancelToken.token })

      if (!this.status && this.message) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.input-height{
  .vs__dropdown-toggle{
    height: 43px !important;
  }
}
</style>

<style scoped lang="scss">
.btn-content {
  padding-top: calc(19.88px + 4.5712px);
}

.btn-approve {
  height: 43px;
}

.column-list-BCId {
  .list-BCId {
    padding: 1rem 0;

    &:not(:last-child) {
      border-bottom: 1px solid #ebe9f1;
    }
  }
}

.input-height{
  min-height: 43px !important;

  .vs__dropdown-toggle{
    height: 43px !important;
  }
}

.input-search-bc-id {
  border-radius: 0.357rem 0 0 0.357rem;
}
</style>
