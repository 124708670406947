<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Limit ads account change"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="wrapper">
      <validation-observer
        ref="informationValidation"
      >
        <b-form-group
          label="Limit ads account"
          label-cols="12"
        >
          <validation-provider
            #default="{ errors }"
            id="limitAdAccount"
            name="Limit ads account"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model.number="limitNumber"
                id="limit-ad-account"
                name="limit-ad-account"
                type="number"
                class="input-height"
                placeholder="Enter Limit ads account"
              />
            </b-input-group>
            <small
              v-if="errors"
              class="text-danger"
            >{{ errors[0] }}</small>
            <small
              v-if="limitNumber && limitSmallerThanOriginalValue"
              class="text-danger"
            >Quantity limit must be greater than or equal to {{ limitAdsAccount }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
      <btn-loading
        type="submit"
        variant="primary"
        block
        :loading="loading"
        :disabled="!limitNumber || limitSmallerThanOriginalValue"
        @click.prevent="submit"
      >
        Update
      </btn-loading>
    </div>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { required } from '@validations'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BtnLoading,
    BFormGroup,
    BFormInput,
    BInputGroup,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, numberFormatMixin, paymentMixin, envMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    limitAdsAccount: {
      type: Number,
      default: 1,
      required: true,
    },

    userData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      limitNumber: 0,
      show: false,

      required,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),

    limitSmallerThanOriginalValue() {
      return this.hasSubscription && this.limitNumber < this.limitAdsAccount
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },

    limitAdsAccount: {
      handler(val) {
        this.limitNumber = val
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['updateUser']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.limitNumber = this.limitAdsAccount
    },

    async submit() {
      const params = {
        id: this.userData.localUserId,
        adsAccountLimit: this.limitNumber,
      }

      await this.updateUser(params)
      if (this.status) {
        this.toastSuccess('Update limit ads account successfully!')
        this.$emit('update-user-selected')
        this.show = false
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}
</style>
