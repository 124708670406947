<template>
  <b-card
    no-body
    class="card-statistics card-top-payment"
  >
    <b-card-body>
      <div>
        <b-row class="align-items-center">
          <b-col
            md="8"
            sm="12"
            class="title-card"
          >
            <b-card-text class="font-20 font-weight-700">
              Payment method frequently
            </b-card-text>
            <calendar
              v-model="rangeDate"
              class="calendar-date"
              @input="onSelect"
            />
          </b-col>
        </b-row>
      </div>
      <div class="table-payment-method">
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="topPaymentData"
          :group-options="{
            enabled: false,
          }"
          class="table-payment"
        >
          <template
            slot-scope="props"
            slot="table-row"
          >
            <!-- total Uses -->
            <div
              v-if="props.column.field === 'totalUses'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ localStringFormat(props.row.totalUsers) }}
                </p>
              </div>
            </div>

            <!-- payment Method -->
            <div
              v-if="props.column.field === 'paymentMethod'"
              class="d-flex align-items-center"
            >
              <div>
                <p
                  v-if="getPaymentName(props.row) === 'creditCard'"
                  class="mb-0 text-capitalize d-flex align-items-center"
                >
                  <b-img
                    :src="require('@/assets/images/pages/payment/ic-creditcard.svg')"
                    fluid
                    alt="icon payment"
                    class="ic-payment"
                  />
                  Credit Card
                </p>
                <p
                  v-else
                  class="mb-0 text-capitalize"
                >
                  <span>
                    <img
                      :src="getBrandIcon(getPaymentName(props.row) === 'Wise' ? 'transferWise' : getPaymentName(props.row))"
                      width="20px"
                      alt="payment"
                      style="margin-right: 5px"
                    >
                  </span>
                  {{ namePaymentConvert(getPaymentName(props.row)) }}
                </p>
              </div>
            </div>

            <!-- total Amount -->
            <div
              v-if="props.column.field === 'totalAmount'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ currencyFormat(props.row.totalToBalanceAmount) }}
                </p>
              </div>
            </div>

            <!-- total Service Charge -->
            <div
              v-if="props.column.field === 'totalServiceCharge'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ currencyFormat(props.row.totalServiceCharge) }}
                </p>
              </div>
            </div>

            <!-- total Service Charge -->
            <div
              v-if="props.column.field === 'totalPaymentGateCharge'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ currencyFormat(props.row.totalPaymentGateCharge) }}
                </p>
              </div>
            </div>

            <!-- total tax -->
            <div
              v-if="props.column.field === 'totalTaxCharge'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ currencyFormat(props.row.totalTaxCharge) }}
                </p>
              </div>
            </div>
          </template>
          <div
            v-if="errorPaymentMethod"
            slot="emptystate"
          >
            <p class="text-error">
              {{ errorPaymentMethod }}
            </p>
          </div>
        </vue-good-table>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BRow, BCol, BImg,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import _get from 'lodash/get'
import { StatsService } from '@/services'
import { VueGoodTable } from 'vue-good-table'
import { PAYMENT_METHOD } from '@/constants'
// import icPayoneer from '@/assets/images/pages/payment/ic-payoneer.svg'
// import icUSDT from '@/assets/images/pages/payment/ic-usdt.svg'
// import icBUSD from '@/assets/images/pages/payment/ic-busd.svg'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import Calendar from './Calendar.vue'

const momentDate = extendMoment(moment)

const today = moment()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BImg,
    Calendar,
    VueGoodTable,
  },

  mixins: [numberFormatMixin, paymentMixin],

  data() {
    return {
      topPaymentData: [],
      errorPaymentMethod: '',
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      date: null,

      columns: [
        {
          label: 'Total uses',
          field: 'totalUses',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Payment method',
          field: 'paymentMethod',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-midle',
        },
        {
          label: 'Total amount',
          field: 'totalAmount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Total Service charge',
          field: 'totalServiceCharge',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Total Method fee',
          field: 'totalPaymentGateCharge',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Total tax',
          field: 'totalTaxCharge',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],
    }
  },

  computed: {
    getPaymentName() {
      return data => {
        const namePayment = _get(data, ['_id', 'paymentMethod'])
        return namePayment === PAYMENT_METHOD.TRANSFERWISE ? 'Wise' : namePayment
      }
    },

  },

  created() {
    const dayRange = momentDate.range(
      today.clone().subtract(6, 'days'),
      today.clone(),
    )

    // eslint-disable-next-line no-underscore-dangle
    const fromDate = momentDate(dayRange?.start?._d).format('YYYY-MM-DD')
    // eslint-disable-next-line no-underscore-dangle
    const toDate = momentDate(dayRange?.end?._d).format('YYYY-MM-DD')

    this.rangeDate = {
      startDate: fromDate,
      endDate: toDate,
    }
    this.fetchTopPaymentStats(this.rangeDate)
  },

  methods: {
    async fetchTopPaymentStats(daySelect) {
      if (daySelect.endDate === null) {
        // eslint-disable-next-line no-param-reassign
        daySelect.endDate = daySelect.startDate
      }
      try {
        await StatsService.getTopPaymentStats({
          fromDate: daySelect.startDate,
          toDate: daySelect.endDate,
        })
          .then(response => {
            this.topPaymentData = response.data.result
          })
      } catch (error) {
        this.errorPaymentMethod = error?.data?.message
      }
    },

    onSelect(val) {
      if (val) {
        this.fetchTopPaymentStats(val)
      }
    },
  },
}
</script>

<style lang="scss">
.table-payment {
  .vgt-table {
    width: 100%;
  }
  .vgt-table.bordered td {
    border: none;
  }

  .header-table-mf {
    padding-top: 0 !important;
  }

  .vgt-responsive {
    width: 100%;
    overflow-x: auto;
    position: relative;
    height: 100%;
  }
}
</style>

<style lang="scss" scoped>
.ic-payment {
  margin-right: 12px;
}

.title-card {
  .calendar-date {
    margin-top: 10px;
  }
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}

.table-payment-method {
  margin-top: 1rem;
}

.text-error {
  margin-top: 1rem;
  color: #f02649;
}
</style>
