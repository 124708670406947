<template>
  <div>
    <b-card-code class="filter-card">
      <!-- advance search input -->
      <b-row>
        <b-col md="5">
          <b-form-group>
            <label>Search user by field</label>
            <b-input-group class="input-filter">
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>

              <b-form-input
                v-model.trim="searchTerm"
                :placeholder="handleSearchPlaceHolder"
                :type="(searchSelected === 'localUserId' || searchSelected === 'adsAccount') ? 'number' : 'text'"
                class="d-inline-block"
                @wheel="handleMouseWheelInput"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>
              Transaction type
            </label>

            <b-form-select
              v-model="transactionType"
              :options="transactionOptions"
              @input="handleSelectTransactionType"
            >
              <template #first>
                <b-form-select-option
                  :value="null"
                  disabled
                >
                  -- Select an option --
                </b-form-select-option>
              </template>
            </b-form-select>

          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>Create At</label>

            <b-input-group
              class="input-group-merge"
            >
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Select date"
                :config="configDate"
                @on-close="handleDateChange"
              />
              <b-input-group-append
                v-if="startDate && endDate"
                is-text
                class="close-icon"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearDate"
                />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="CalendarIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="tickets"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: totalTickets > 0,
      }"
      :sort-options="{
        enabled: totalTickets > 0,
      }"
      :is-loading="adsAccountHistoryLoading"
      :group-options="{
        enabled: false
      }"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >

        <!-- Column: Ads Account -->
        <div
          v-if="props.column.field === 'userId'"
          class="d-flex align-items-center"
        >
          <b-img
            :id="`avt-user-${props.row.userId._id}`"
            rounded
            class="ads-avt-img"
            :src="getAvatar(props.row.userId.registerFromService)"
          />
          <b-tooltip
            v-if="isEcomdyPlatform && props.row.userId.registerFromService"
            placement="top"
            triggers="hover"
            :target="`avt-user-${props.row.userId._id}`"
          >
            {{ getUserPlatform(props.row.userId.registerFromService) }}
          </b-tooltip>
          <div>
            <p class="mb-0">
              {{ props.row.userId.fullName && capitalise(props.row.userId.fullName) }}
            </p>
            <span class="text-placeholder">{{ props.row.userId.email }}</span>
          </div>
        </div>

        <span v-else-if="props.column.field === 'transactionId'">
          <div v-if="props.row.ticketId && props.row.ticketId.details">
            <span
              v-if="props.row.ticketId.details.note && props.row.ticketId.details.note.length < 10"
              class="font-weight-bold"
            >
              {{ props.row.ticketId.details.note }}
            </span>
            <span v-else>
              <b-dropdown
                :ref="`dropdown-${props.row.originalIndex}`"
                no-caret
                variant="link"
                class="transaction-area"
              >
                <template v-slot:button-content>
                  {{ getBriefTransactionId(props.row.ticketId.details.note) }}
                </template>

                <p class="mb-0">Transaction ID: {{ props.row.ticketId.details.note }}</p>
              </b-dropdown>
            </span>
            <br>
            <span v-if="props.row.ticketId.details.billingAttachment">
              <b-link
                class="underline theme-text-primary font-weight-600"
                :href="props.row.ticketId.details.billingAttachment"
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ $t('payment.attachmentLink') }}
              </b-link>
            </span>
          </div>

          <span v-else>N/A</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="adsStatusVariant(props.row.status)">
            {{ capitalise(props.row.status) }}
          </b-badge>
        </span>

        <span
          v-else-if="props.column.field === 'adsAccount'"
          class="d-flex align-items-center"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="accountInfo()"
          />
          <div>
            <p class="mb-0">
              {{ props.row.adsAccountId.name }}
            </p>
            <span class="text-placeholder">{{ props.row.adsAccountId._id }}</span>
          </div>
        </span>

        <span v-else-if="props.column.field === 'transactionType'">
          {{ formatAccountHistoryType(props.row.type) }}
        </span>
        <span v-else-if="props.column.field === 'transactionStatus'">
          <b-badge :variant="adsStatusVariant(props.row.status)">
            {{ capitalise(props.row.status) }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'balanceChange'">
          <p
            class="text-bold font-weight-bold"
            :class="paymentTextVariant(props.row.operator)"
          >
            {{ props.row.operator === 'none' ? '' : props.row.operator }}
            {{ currencyFormat(props.row.amount) }}
          </p>
        </span>

        <span v-else-if="props.column.field === 'transactionDate'">
          <p class="text-secondary font-weight-bold mb-0">
            {{ getFormatDate(props.row.createdAt) || 'N/A' }}
          </p>
          <span class="font-14 text-placeholder">{{ getFormatTime(props.row.createdAt) }}</span>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalTickets > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[{'justify-content-center':$device.mobile}, {'justify-content-between':!$device.mobile}]"
        >

          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Ticket per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap ">
              Showing
              {{ pageLength * (currentPage-1) + 1 }}
              to
              {{ pageLength * currentPage }}
              of {{ totalTickets }} ticket(s)
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalTickets"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <div
        v-if="errorAdsAccountHistory"
        slot="emptystate"
      >
        <p class="text-error">
          {{ errorAdsAccountHistory }}
        </p>
      </div>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'
import paymentMixin from '@/mixins/paymentMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code'
import get from 'lodash/get'
import {
  BPagination,
  BFormSelect,
  BImg,
  BSpinner,
  BBadge,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormSelectOption,
  BRow,
  BCol, BTooltip,
} from 'bootstrap-vue'

import { REGISTER_FROM_SERVICE, TRANSACTIONS_STATUS } from '@/constants'
import { toastification } from '@core/mixins/toast'
import { debounce } from 'lodash'
import axiosLib from 'axios'
import usersMixin from '@/mixins/usersMixin'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BImg,
    BSpinner,
    BBadge,
    flatPickr,
    BCardCode,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BFormSelectOption,
    BRow,
    BCol,
  },

  mixins: [paymentMixin, numberFormatMixin, toastification, usersMixin, envMixin],

  data() {
    return {
      columns: [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Transaction Date',
          field: 'transactionDate',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-midle',
        },
        {
          label: this.$t('payment.userInfo'),
          field: 'userId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Ads Account',
          field: 'adsAccount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Transaction Type',
          field: 'transactionType',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Transaction Status',
          field: 'transactionStatus',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Balance Change',
          field: 'balanceChange',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      // sort
      sortTerm: {
        field: '',
        type: '',
      },
      currentPage: 1,
      searchTerm: '',

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User email',
          value: 'email',
        },
        {
          text: 'Ads Account ID',
          value: 'adsAccount',
        },
        {
          text: 'User ID',
          value: 'localUserId',
        },
      ],

      // status filter
      statusOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: 'Pending',
          value: TRANSACTIONS_STATUS.PENDING,
        },
        {
          text: 'Done',
          value: TRANSACTIONS_STATUS.DONE,
        },
        {
          text: 'Rejected',
          value: TRANSACTIONS_STATUS.REJECTED,
        },
      ],
      statusSelected: '',

      transactionType: '',
      transactionOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: this.formatAccountHistoryType('recharge_ads_account_balance'),
          value: 'recharge_ads_account_balance',
        },
        {
          text: this.formatAccountHistoryType('refund_ads_account_balance'),
          value: 'refund_ads_account_balance',
        },
        {
          text: this.formatAccountHistoryType('system_withdraw_due_to_inactive'),
          value: 'system_withdraw_due_to_inactive',
        },
      ],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },

      errorAdsAccountHistory: '',
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['adsAccountHistories', 'message', 'status', 'loading', 'adsAccountHistoryLoading']),

    totalTickets() {
      return get(this.adsAccountHistories, ['paging', 'total'], 0)
    },

    tickets() {
      return get(this.adsAccountHistories, ['content'], [])
    },

    accountInfo() {
      return img => img || require('@/assets/images/common/ic-ad-info.svg')
    },

    adsStatusVariant() {
      const statusColor = {
        [TRANSACTIONS_STATUS.DONE]: 'light-success',
        [TRANSACTIONS_STATUS.PENDING]: 'light-warning',
        [TRANSACTIONS_STATUS.REJECTED]: 'light-danger',
      }

      return status => statusColor[status]
    },

    paymentTextVariant() {
      const statusColor = {
        '+': 'text-success',
        '-': 'text-danger',
      }

      return status => statusColor[status]
    },

    handleSearchPlaceHolder() {
      switch (this.searchSelected) {
        case 'localUserId': return 'Search by user ID'
        case 'email': return 'Search by user email'
        case 'adsAccount': return 'Search Ads Account ID'
        default: return ''
      }
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },
  },

  async created() {
    const params = {
      page: this.currentPage,
      size: this.pageLength,
    }

    abort()

    await this.getAdsAccountHistories({
      params,
      cancelToken: cancelToken.token,
    })
    if (!this.status && this.message) {
      this.errorAdsAccountHistory = this.message
    }
  },

  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions(['getAdsAccountHistories']),

    getTransactionType(type) {
      if (!type) {
        return ''
      }

      return {
        'types[]': type,
      }
    },

    async fetchAdsAccountHistory() {
      if (this.searchTerm && !this.validateEmail(this.searchTerm) && this.searchSelected === 'email') {
        return
      }

      abort()

      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.statusSelected && { status: this.statusSelected }),
        ...this.getTransactionType(this.transactionType),
      }

      await this.getAdsAccountHistories({ params, cancelToken: cancelToken.token })
      if (!this.status && this.message) {
        this.errorAdsAccountHistory = this.message
        this.toastFailure(this.message)
      }
    },

    capitalise(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchAdsAccountHistory()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchAdsAccountHistory()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchAdsAccountHistory()
    },

    async handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchAdsAccountHistory()
    },

    handleSearch: debounce(async function (searching) {
      if (searching === '' || ((searching.length > 3 && this.searchSelected !== 'email') || this.validateEmail(this.searchTerm))) {
        await this.fetchAdsAccountHistory()
      }
    }, 1000),

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchAdsAccountHistory()
      }
    },
    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) { // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0])).format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
        await this.fetchAdsAccountHistory()
      }
    },

    async handleSelectTransactionType() {
      this.currentPage = 1
      await this.fetchAdsAccountHistory()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchAdsAccountHistory()
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchAdsAccountHistory()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .ads-avt-img {
    width: 44px;
    height: 44px;
    margin-right: 10px;
  }
  .close-icon {
    .input-group-text {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .select-search-custom{
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .filter-card {
    padding: 24px;
    margin-bottom: 0px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border: 1px solid #ebe9f1;
    border-bottom: 0px;

    .card-header {
        display: none;
    }

    .card-body {
        padding: 0;
    }

    .form-group {
        margin: 0;
    }
  }
</style>
<style lang="scss" scoped>
.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}
</style>
