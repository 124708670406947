import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {

  // get list gift code
  GET_LIST_MARKET_PACKAGE_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_MARKET_PACKAGE_SUCCESS(state, data) {
    SUCCESS(state)
    state.listMarketPackage = data
  },

  GET_LIST_MARKET_PACKAGE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list gift code
  UPDATE_A_PACKAGE_REQUEST(state) {
    REQUEST(state)
  },

  UPDATE_A_PACKAGE_SUCCESS(state) {
    SUCCESS(state)
  },

  UPDATE_A_PACKAGE_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
