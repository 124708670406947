import { ref, watch } from '@vue/composition-api'
import { TICKET_ACTIONS } from '@/constants'

export default function useTaskHandler(
  props,
  emit,
  BCId,
  adsAccountId,
  description,
) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const taskLocal = ref(
    JSON.parse(JSON.stringify(props.task.value)),
  )
  const actionTypeLocal = ref(
    JSON.parse(JSON.stringify(props.actionType.value)),
  )

  const resetTaskLocal = () => {
    taskLocal.value = JSON.parse(
      JSON.stringify(props.task.value),
    )
  }
  watch(props.actionType, () => {
    actionTypeLocal.value = props.actionType.value
  })

  watch(props.task, () => {
    resetTaskLocal()
  })

  const onSubmit = () => {
    const taskData = JSON.parse(JSON.stringify(taskLocal))
    // * If event has id => Edit Event
    // Emit event for add/update event
    if (actionTypeLocal.value === TICKET_ACTIONS.APPROVE) {
      emit('approve-ticket', taskData.value, BCId.value, adsAccountId.value)
    } else emit('reject-ticket', taskData.value, description.value)

    // Close sidebar
    emit('update:is-task-handler-sidebar-active', false)
  }

  return {
    taskLocal,
    actionTypeLocal,
    resetTaskLocal,
    onSubmit,
  }
}
