<template>
  <b-row>
    <b-col
      class="navbar"
      md="9"
      lg="3"
    >
      <div
        class="brand-logo navbar-brand"
      >
        <b-img
          alt="logo"
          name="logo"
          :src="appLogoImage"
          style="max-width: 50px"
        />
      </div>
    </b-col>
  </b-row>
</template>

<script>

import { $themeConfig } from '@themeConfig'
import { BImg, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BRow,
    BCol,
  },

  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss" scoped>
  .navbar-brand {
    top: 1rem !important;
    .name-logo {
      margin-left: 0.5rem;
      width: 60px;
      line-height: 1.2rem;
      white-space: normal;
      color: #3D405B;
    }
  }
  @media (max-width: 767px) {
    .navbar {
      left: -7px;
    }
  }

  .dark-layout {
    .name-logo {
      color: #d0d2d6;
    }
  }
</style>
