<template>
  <div>
    <product-links-tickets />
  </div>
</template>
<script>
import ProductLinksTickets from './components/ProductLinksTickets.vue'

export default {
  components: { ProductLinksTickets },

  methods: {},
}
</script>
<style lang="scss" scoped></style>
