export default [
  {
    path: '/partner-permission',
    name: 'partner-permission',
    component: () => import('@/views/partner-permission/PartnerPermission.vue'),
    meta: {
      pageTitle: 'Partner BC permission',
      requireAuth: true,
    },
  },
]
