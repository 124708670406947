<template>
  <div class="verify-information-container">
    <verify-information-tickets />
  </div>
</template>

<script>
import VerifyInformationTickets from './components/VerifyInformationTickets.vue'

export default {
  components: { VerifyInformationTickets },

}
</script>

<style lang="scss" scoped>

</style>
