import { orderBy } from 'lodash'
import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,

  blockedUsers: state => {
    const blockedUsers = state.user.statisticsUser.filter(user => user.status === 'blocked')
    const blockedUsersSort = orderBy(blockedUsers, [state.typeSort], ['asc'])
    const customUser = []
    if (state.typeSort === 'day') {
      for (let index = 1; index <= state.limitDay; index += 1) {
        const element = blockedUsersSort.find(item => item.day === index)
        if (!element) {
          customUser.push({
            status: 'blocked',
            day: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            day: element.day,
            total: element.total,
          })
        }
      }
    } else {
      for (let index = 1; index <= state.limitMonth; index += 1) {
        const element = blockedUsersSort.find(item => item.month === index)
        if (!element) {
          customUser.push({
            status: 'blocked',
            month: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            month: element.month,
            total: element.total,
          })
        }
      }
    }
    return customUser.map(item => item.total || 0)
  },
  activedUsers: state => {
    const activedUsers = state.user.statisticsUser.filter(user => user.status === 'active')
    const activedUsersSort = orderBy(activedUsers, [state.typeSort], ['asc'])
    const customUser = []
    if (state.typeSort === 'day') {
      for (let index = 1; index <= state.limitDay; index += 1) {
        const element = activedUsersSort.find(item => item.day === index)
        if (!element) {
          customUser.push({
            status: 'active',
            day: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            day: element.day,
            total: element.total,
          })
        }
      }
    } else {
      for (let index = 1; index <= state.limitMonth; index += 1) {
        const element = activedUsersSort.find(item => item.month === index)
        if (!element) {
          customUser.push({
            status: 'active',
            month: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            month: element.month,
            total: element.total,
          })
        }
      }
    }
    return customUser.map(item => item.total || 0)
  },

  typeSort: state => state.typeSort,
  typeSortDeposit: state => state.typeSortDeposit,
  typeSortSpending: state => state.typeSortSpending,

  depositData: state => {
    const deposit = orderBy(state.deposit.statisticsDeposit, [state.typeSortDeposit], ['asc'])
    const customDeposit = []
    if (state.typeSortDeposit === 'day') {
      for (let index = 1; index <= state.limitDayDeposit; index += 1) {
        const element = deposit.find(item => item.day === index)
        if (!element) {
          customDeposit.push({
            day: index,
            deposit: 0,
          })
        } else {
          customDeposit.push({
            day: element.day,
            deposit: element.deposit,
          })
        }
      }
    } else {
      for (let index = 1; index <= state.limitMonthDeposit; index += 1) {
        const element = deposit.find(item => item.month === index)
        if (!element) {
          customDeposit.push({
            month: index,
            deposit: 0,
          })
        } else {
          customDeposit.push({
            month: element.month,
            deposit: element.deposit,
          })
        }
      }
    }
    return customDeposit.map(item => {
      if (item.deposit < 0) {
        return {
          value: item.deposit,
          itemStyle: {
            color: '#F02548',
          },
        }
      }
      return item.deposit || 0
    })
  },
  spendingData: state => {
    const spend = orderBy(state.spending.statisticsSpending, [state.typeSortSpending], ['asc'])
    const customSpending = []
    if (state.typeSortSpending === 'day') {
      for (let index = 1; index <= state.limitDaySpending; index += 1) {
        const element = spend.find(item => item.day === index) || { day: index, spending: 0 }
        customSpending.push({
          day: element.day,
          spending: element.spending,
        })
      }
    } else {
      for (let index = 1; index <= state.limitMonthSpending; index += 1) {
        const element = spend.find(item => item.month === index) || {
          month: index,
          spending: 0,
        }
        customSpending.push({
          month: element.month,
          spending: element.spending,
        })
      }
    }

    return customSpending.map(item => {
      if (item.spending < 0) {
        return {
          value: item.spending,
          itemStyle: {
            color: '#F02548',
          },
        }
      }
      return item.spending || 0
    })
  },
  monthlyAnalytics: state => ([
    {
      id: 'totalUser',
      label: 'Total User',
      value: state.users.totalUsers !== null ? state.users.totalUsers : 'N/A',
      /* eslint-disable global-require */
      icon: require('@/assets/images/common/ic-total-user.svg'),
    },
    {
      id: 'monthlyDeposit',
      label: 'Monthly Deposit',
      value: state.deposits.monthlyDeposit !== null ? `$${parseFloat(state.deposits.monthlyDeposit).toLocaleString('en-US')}` : 'N/A',
      /* eslint-disable global-require */
      icon: require('@/assets/images/common/ic-dollar.svg'),
    },
    {
      id: 'totalAdsAccount',
      label: 'Total Ads Account',
      value: state.adsAccounts.totalAdsAccounts !== null ? state.adsAccounts.totalAdsAccounts : 'N/A',
      /* eslint-disable global-require */
      icon: require('@/assets/images/common/ic-tiktok-user.svg'),
    },
    {
      id: 'totalBonus',
      label: 'Total Bonus',
      value: state.bonus.totalBonus !== null ? `$${state.bonus.totalBonus}` : 'N/A',
      /* eslint-disable global-require */
      icon: require('@/assets/images/common/ic-green-dollar.svg'),
    },
    {
      id: 'totalUserBalance',
      label: 'Total User’s balance',
      value: state.balances.totalBalance >= 0 ? `$${parseFloat(Math.floor(state.balances.totalBalance * 100) / 100).toLocaleString('en-US')}` : 'N/A',
      /* eslint-disable global-require */
      icon: require('@/assets/images/common/ic-black-dollar.svg'),
    },
  ]),
}
