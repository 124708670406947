export default [
  {
    path: '/partners',
    name: 'partners',
    component: () => import('@/views/partner/Partner.vue'),
    meta: {
      pageTitle: 'Partner management',
      requireAuth: true,
    },
  },

  {
    path: '/partner/:id',
    name: 'partner',
    component: () => import('@/views/partner/partnerDetail/PartnerDetail.vue'),
    meta: {
      pageTitle: 'Partner detail',
      requireAuth: true,
    },
  },
]
