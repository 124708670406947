<template>
  <b-modal
    ref="modal-close-ads-account"
    id="block-child"
    class="modal-child"
    title="Are you make sure to close this ads account ?"
    centered
  >
    <div class="ads-remove-items">
      <div
        v-for="(item, index) in adsAccountsSelected"
        :key="index"
        class="d-flex align-items-center wrapper-item"
      >
        <b-form-checkbox
          :data-testid="`checkbox-choose-ad-acc-closed-${item._id}`"
          :checked="true"
          @change="(checked) => onChangeSelect(item, checked)"
        />

        <b-img
          rounded
          class="ads-avt-img"
          :src="adsAccountImg()"
        />
        <div class="d-flex flex-column">
          <b-link
            class="theme-text-primary font-weight-600"
            :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${item._id}`"
            target="_blank"
            rel="noreferrer noopener"
          >
            {{ item.name }}
          </b-link>
          <span class="text-placeholder">ID: {{ item._id }}</span>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100 text-center">
        <b-button
          class="mr-1"
          data-testid="button-cancel-popup-close"
          variant="flat-dark"
          @click="hideModal"
        >
          Cancel
        </b-button>
        <btn-loading
          variant="primary"
          data-testid="button-confirm-popup-close"
          class=""
          :loading="loading"
          :disabled="!adsAccountsSelected.length"
          @click="handleConfirmClose"
        >
          Confirm
        </btn-loading>
      </div>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable no-underscore-dangle,global-require */

import {
  BModal,
  BButton,
  BFormCheckbox,
  BImg,
  BLink,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('user')

export default {
  components: {
    BtnLoading,
    BModal,
    BButton,
    BFormCheckbox,
    BImg,
    BLink,
  },
  mixins: [toastification],

  props: {
    adsAccountsSelected: {
      type: Array,
      default: () => [],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters(['status', 'message']),

    adsAccountImg() {
      return img => img || require('@/assets/images/common/ic-ad-info.svg')
    },
  },

  methods: {
    ...mapActions(['closeAdsAccount', 'getAdsAccountsUser']),

    onChangeSelect(data, checked) {
      if (!checked) {
        this.adsAccountsSelected = this.adsAccountsSelected.filter(
          item => item._id !== data._id,
        )
      } else {
        this.adsAccountsSelected.push(data)
      }
    },

    hideModal() {
      this.$refs['modal-close-ads-account'].hide()
    },

    showModal() {
      this.$refs['modal-close-ads-account'].show()
    },

    async handleConfirmClose() {
      this.loading = true
      const adsAccountIds = this.adsAccountsSelected.map(item => item._id)
      await this.closeAdsAccount({
        adsAccounts: adsAccountIds,
        userId: this.$route.params.id,
      })
      if (this.status) {
        this.toastSuccess('Ads account closed successfully!')
      } else {
        this.toastFailure(this.message)
      }
      this.loading = false
      this.hideModal()
      const { params } = this.$route
      this.getAdsAccountsUser(params)
    },
  },
}
</script>

<style scoped lang="scss">

</style>
