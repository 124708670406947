import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@auth'

// routes
import partnerPermission from '@/router/routes/partnerPermission'
import authRoute from './routes/auth'
import dashboardRoute from './routes/dashboard'
import pages from './routes/pages'
import errorRoute from './routes/error'
import adsAccountRoute from './routes/adsAccount'
import paymentsRoute from './routes/payments'
import usersRoute from './routes/users'
// import myTask from './routes/myTask'
import subscriptionsRoute from './routes/subscription'
import productLinks from './routes/productLinks'
import giftCode from './routes/giftCode'
import partner from './routes/partner'
import marketPartner from './routes/marketPartner'
import verifyInfo from './routes/verifyInfo'
import inactiveAdAccount from './routes/inactiveAdAccount'
import acl from './routes/acl'
import adCreative from './routes/adCreative'
import autoRefund from './routes/autoRefund'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...authRoute,
    ...dashboardRoute,
    ...adsAccountRoute,
    ...pages,
    ...errorRoute,
    ...usersRoute,
    // ...myTask,
    ...paymentsRoute,
    ...subscriptionsRoute,
    ...productLinks,
    ...giftCode,
    ...partner,
    ...marketPartner,
    ...verifyInfo,
    ...inactiveAdAccount,
    ...acl,
    ...partnerPermission,
    ...adCreative,
    ...autoRefund,
  ],
})

// ? For splash screen

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const requireAuth = to.matched.some(record => record.meta.requireAuth)

  // this page required not logged in to access, ex: login, register, reset, forgot password, v..v
  const requireNotAuth = to.matched.some(record => record.meta.requireNotAuth)

  // this page require has siteId to access
  const loggedIn = !!auth.getToken()

  if (loggedIn) {
    if (requireNotAuth) {
      next({ name: 'dashboard' })
    }
  } else if (requireAuth) {
    next({ name: 'login', query: { redirect: to.fullPath } })
  }

  return next()
})

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
