import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
  },
  getters,
  mutations,
  actions,
}
