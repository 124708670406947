import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get acl list
  GET_LIST_ACL_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_ACL_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAcl = data
  },
  GET_LIST_ACL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // create role
  CREATE_ROLE_REQUEST(state) {
    REQUEST(state)
  },

  CREATE_ROLE_SUCCESS(state) {
    SUCCESS(state)
  },

  CREATE_ROLE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update role
  UPDATE_ROLE_REQUEST(state) {
    REQUEST(state)
  },

  UPDATE_ROLE_SUCCESS(state) {
    SUCCESS(state)
  },

  UPDATE_ROLE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // assign role
  ASSIGN_ROLE_REQUEST(state) {
    REQUEST(state)
  },
  ASSIGN_ROLE_SUCCESS(state) {
    SUCCESS(state)
  },
  ASSIGN_ROLE_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
