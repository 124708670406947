<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    width="420px"
    right
    backdrop
    shadow
    title="Assign Partner"
    @hidden="onHidden"
  >
    <div class="p-2">
      <!-- v-slot="{ invalid }" -->
      <b-form>
        <div class="status-input input-height">
          <label
            class="form-label"
            for="postal-code"
          > Email partner </label>
          <v-select
            v-model="emailPartner"
            class="input-height"
            placeholder="Search by email or Id"
            :options="partnerOptions"
            :clearable="false"
            :selectable="(option) => option.value !== partnerLocalId"
            :filter-by="handleSearchPartner"
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <feather-icon
                  icon="ChevronDownIcon"
                  size="20"
                />
              </span>
            </template>
          </v-select>
        </div>

        <div v-if="listAdAccount.length > 0">
          <div
            v-for="(adAccount, index) in listAdAccount"
            :key="index"
            class="mt-1"
          >
            <span class="font-weight-600">Ad account ID: </span><span>{{ adAccount._id }}</span>
            <p class="mb-0">
              Timezone: {{ adAccount.timezone }}
            </p>
            <p>Daily Spending: {{ adAccount.spend }} </p>
          </div>

          <b-form-checkbox
            v-model="skipCheckSpendingAdAccount"
            class="mt-2"
          >
            Avoid spending number error
            <span
              v-if="skipCheckSpendingAdAccount"
              class="font-weight-600 text-error"
            >: {{ totalSpending }}</span>
          </b-form-checkbox>
        </div>

        <div class="mt-2">
          <!-- edit buttons -->
          <btn-loading
            type="submit"
            variant="primary"
            block
            :loading="loading"
            @click.prevent="submitAssignToPartner"
          >
            Assign
          </btn-loading>
        </div>
      </b-form>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import { BSidebar, BForm, BFormCheckbox } from 'bootstrap-vue'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { required, url } from '@validations'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'

const { mapGetters, mapActions } = createNamespacedHelpers('partner')
const { mapGetters: mapGettersGiftCode, mapActions: mapActionsGiftCode } = createNamespacedHelpers('giftCode')

export default {
  components: {
    BSidebar,
    BForm,
    BtnLoading,
    vSelect,
    BFormCheckbox,
  },
  mixins: [toastification, numberFormatMixin],

  props: {
    localUserId: {
      type: Number,
      default: 0,
    },

    partnerLocalId: {
      type: Number,
      default: 0,
    },

    listAdAccount: {
      type: Array,
      default: () => [],
    },

    totalSpending: {
      type: Number,
      default: 0,
    },

    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      show: false,

      emailPartner: null,

      // validate
      required,
      url,

      skipCheckSpendingAdAccount: false,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersGiftCode([
      'listPartnerNoPaging',
    ]),

    partnerOptions() {
      return this.listPartnerNoPaging.content.map(item => ({
        value: item.localUserId,
        label: item.email,
        localId: item.localUserId,
      }))
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.fetchAllPartner()
    },
  },

  methods: {
    ...mapActions(['assignToAnotherPartner']),
    ...mapActionsGiftCode(['getListPartnerNoPaging']),

    async fetchAllPartner() {
      await this.getListPartnerNoPaging()
    },

    async submitAssignToPartner() {
      const { params } = this.$route
      const data = {
        id: this.localUserId || params.id,
        partnerLocalId: this.emailPartner.value,
        skipCheckSpendingAdAccount: this.skipCheckSpendingAdAccount,
      }
      await this.assignToAnotherPartner(data)
      if (this.status) {
        this.toastSuccess('Assign this partner success.')
        this.onHidden()
        this.$emit('fetch-user-info')
      } else {
        this.toastFailure(this.message)
      }
    },

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    resetForm() {
      this.emailPartner = null
      this.skipCheckSpendingAdAccount = false
    },

    handleSearchPartner(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase().indexOf(searchTxt) > -1 || option?.localId?.toString().toLocaleLowerCase().indexOf(searchTxt) > -1
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}

.text-error {
  color: #f02649;
}
</style>
