import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    listUser: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listUserLoading: false,

    userDetail: {},
    userAdsAccounts: {},
    listAM: [],

    listAdminNote: [],

    hasConnectedTikTok: false,
    listPartnerBC: [],

    detailUserRelated: {},
  },
  getters,
  mutations,
  actions,
}
