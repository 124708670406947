import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get list user info
  GET_LIST_USER_INFO_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_USER_INFO_SUCCESS(state, data) {
    SUCCESS(state)
    state.listUserInfo = data
  },
  GET_LIST_USER_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // HANDLE USER INFO
  HANDLE_USER_INFO_REQUEST(state) {
    REQUEST(state)
  },
  HANDLE_USER_INFO_SUCCESS(state) {
    SUCCESS(state)
  },
  HANDLE_USER_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
