<template>
  <div>
    <account-tickets />
  </div>
</template>
<script>
import {
// BCard,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import { toastification } from '@core/mixins/toast'
import AccountTickets from './components/AccountTickets.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    AccountTickets,
  },
  mixins: [toastification],

  computed: {
    ...mapGetters(['accountTickets', 'status', 'message']),
    totalAccount() {
      return get(this.accountTickets, ['paging', 'total'], 0)
    },
  },

  created() {
    this.fetchAccounts()
  },

  methods: {
    ...mapActions(['getAccountTickets']),

    async fetchAccounts() {
      const params = {
        page: 1,
        size: 10, // default size
      }
      await this.getAccountTickets(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
