<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Review Ad creative"
    width="70%"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <validation-observer ref="formValidation">
      <b-form class="h-100">
        <div class="wrapper">
          <div>
            <p
              v-if="errorVideo"
              class="text-danger"
            >
              Please go directly to the
              <a
                class="underline"
                target="_blank"
                :href="`https://ads.tiktok.com/i18n/perf/creative?aadvid=${adCreativeData.advertiser_id}&keyword=${adCreativeData.ad_id}&search_type=3`"
              >
                TikTok page </a>
              to check this ad creative
            </p>
            <div class="text-center">
              <video
                ref="ad-creative-video"
                :key="adCreativeData.ad_id"
                class="video-review"
                :poster="adCreativeData.thumbnail"
                controls
                @error="onVideoError(adCreativeData)"
              >
                <source
                  :src="adCreativeData.preview"
                  type="video/mp4"
                >
              </video>
            </div>

            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="status"
                  rules="required"
                >
                  <b-form-group class="w-100 mt-2">
                    <label> AM review status </label>
                    <v-select
                      v-model="adCreativeStatusSelected"
                      class="input-height"
                      placeholder="Select status"
                      :options="statusOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group class="w-100 mt-2">
                  <label> Ads name: </label>
                  <p>{{ adCreativeData.ad_name }}</p>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group class="w-100 mt-2">
                  <label> Create At: </label>
                  <p>{{ formatDate(adCreativeData.create_time) }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <validation-provider
              #default="{ errors }"
              name="reason"
              rules="required"
            >
              <b-form-group>
                <quill-editor
                  v-model.trim="description"
                  ref="QuillEditor"
                  id="quil-content"
                  size="20"
                  :options="editorOption"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>

          <div class="pb-2">
            <btn-loading
              type="submit"
              variant="primary"
              block
              :loading="loading"
              @click.prevent="submit"
            >
              Submit
            </btn-loading>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </b-sidebar>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BSidebar,
  BFormGroup,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { required } from '@validations'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import { AM_REVIEW_ADS_STATUS, TOOLBAR_EDITOR_OPTIONS } from '@/constants'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('adCreative')

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BSidebar,
    BtnLoading,
    BFormGroup,
    quillEditor,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  mixins: [toastification, numberFormatMixin, paymentMixin, envMixin, dateTimeFormatMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    adCreativeData: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      required,
      errorVideo: false,
      show: false,
      description: '',

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },

      adCreativeStatusSelected: null,
      statusOptions: [
        {
          label: 'Rejected',
          value: AM_REVIEW_ADS_STATUS.REJECTED,
        },
        {
          label: 'Reviewing',
          value: AM_REVIEW_ADS_STATUS.IN_REVIEW,
        },
        {
          label: 'Approved',
          value: AM_REVIEW_ADS_STATUS.APPROVED,
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),
    adId() {
      return this.adCreativeData?.ad_id
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val

      this.adCreativeStatusSelected = this.statusOptions.find(item => item.value === this.adCreativeData.reviewProcess.status)
      this.description = this.adCreativeData.reviewProcess.description
    },
  },

  methods: {
    ...mapActions(['reviewAdCreative']),

    onHidden() {
      this.adCreativeStatusSelected = null
      this.description = ''
      this.errorVideo = false
      this.$refs['ad-creative-video'].load()
      this.$emit('update:show-side-bar', false)
    },

    async submit() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        const params = {
          adId: this.adId,
          ...(this.adCreativeStatusSelected && this.adCreativeStatusSelected.value && { status: this.adCreativeStatusSelected.value }),
          ...(this.description && { description: this.description }),
        }
        await this.reviewAdCreative(params)
        if (this.status) {
          this.toastSuccess('Update status success')
          this.$emit('fetch-list-ad-creative')
          this.onHidden()
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    onVideoError(adCreativeData) {
      fetch(`${adCreativeData.preview}`)
        .then(res => {
          if (res.status >= 400 && !res.result) {
            this.errorVideo = true
          }
        })
        .catch(() => {
          this.errorVideo = true
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
.wrapper {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.video-review {
  width: 100%;
  height: 420px;

  @media (min-width: 768px) and (max-width: 1024px) {
    width: 400px;
  }
  @media (min-width: 1025px) {
    width: 600px;
  }
}
</style>
