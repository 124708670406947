<template>
  <div>
    <partner-tickets />
  </div>
</template>
<script>
import PartnerTickets from './components/PartnerTickets.vue'

export default {
  components: {
    PartnerTickets,
  },
}
</script>
<style lang="scss" scoped>

</style>
