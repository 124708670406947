<template>
  <b-modal
    ref="block-modal"
    id="block-child"
    data-testid="modal-submit-block-user"
    class="modal-child"
    hide-footer
    title="Are you make sure to block this user ?"
  >
    <validation-observer ref="formValidation">
      <b-form
        @submit.prevent="submit"
      >
        <!--Content-->
        <p data-testid="value-user">
          User: <code>{{ userData.fullName ? `${userData.fullName} - ` :'' }}{{ userData.email }}</code>
        </p>
        <!-- <p class="my-2">
          If you have decided to block this user. Please choose a date to recovery this user and enter some of reason block.
        </p> -->
        <div>
          <!-- <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="date"
              :rules="checked ? '' : 'required'"
            >
              <b-input-group
                class="input-group-merge"
              >
                <flat-pickr
                  v-model="dateTime"
                  class="form-control"
                  placeholder="Choose date"
                  :config="{ enableTime: true, dateFormat: 'd-m-Y H:i'}"
                  :disabled="checked"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group> -->
          <!-- <b-form-checkbox
            v-model="checked"
            checked="true"
            name="check-button"
            class="mb-2"
            switch
            inline
          >
            No, I want block permanently
          </b-form-checkbox> -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="reason"
              rules="required"
            >
              <b-input-group>
                <b-form-textarea
                  v-model="blockedReason"
                  data-testid="input-reason-block-user"
                  placeholder="Typing reason ..."
                />
              </b-input-group>
              <small
                data-testid="err-reason-block-user"
                class="text-danger"
              >{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <!--/ Content-->

        <!--modal footer-->
        <div class="d-flex justify-content-end mt-2">
          <b-button
            data-testid="btn-cancel-block-user-modal"
            variant="flat-secondary"
            @click="hideModal"
          >
            Cancel
          </b-button>
          <btn-loading
            data-testid="btn-submit-block-user"
            class="btn-submit ml-1"
            variant="primary"
            type="submit"
            :loading="loading"
            @click.prevent="submit"
          >
            Submit
          </btn-loading>
        </div>
        <!--/modal footer-->
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>

import {
  BModal,
  // BRow,
  // BCol,
  // BFormCheckbox,
  BForm,
  BFormGroup,
  BButton,
  BInputGroup,
  // BInputGroupAppend,
  BFormTextarea,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { USER_STATUS } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
// import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toastification } from '@core/mixins/toast'
import { required } from '@validations'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BModal,
    // BRow,
    // BCol,
    BForm,
    BFormGroup,
    BButton,
    BInputGroup,
    // BInputGroupAppend,
    // BFormCheckbox,
    BFormTextarea,
    // component
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    // flatPickr,
  },
  mixins: [toastification],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      checked: true,
      dateTime: null,
      blockedReason: '',

      // validation
      required,
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  methods: {

    ...mapActions(['blockUser']),

    hideModal() {
      this.$refs['block-modal'].hide()
    },

    showModal() {
      this.$refs['block-modal'].show()
    },

    submit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line no-return-assign

          const dateBlockForever = '30-12-2521 00:00'

          const params = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.userData.localUserId,
            status: USER_STATUS.BLOCKED,
            blockedReason: this.blockedReason,
            blockedExpiredAt: this.checked ? this.timeStampConvert(dateBlockForever) : this.timeStampConvert(this.dateTime),
          }

          await this.blockUser(params)
          if (this.status) {
            this.toastSuccess('Block user success!')
            this.$emit('update-user-selected')
            this.hideModal()
          } else {
            this.toastFailure(this.message)
          }
        }
      })
    },

    timeStampConvert(data) {
      if (data) {
        const dateTimeParts = data.split(' ')
        const timeParts = dateTimeParts[1].split(':')
        const dateParts = dateTimeParts[0].split('-')

        const date = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1])

        return date.getTime()
      }

      return ''
    },
  },
}
</script>
<style lang="scss">
   @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
