<template>
  <b-row>
    <b-col
      v-for="(item, index) in currentMonthlyAnalytics"
      :key="index"
      class="card-content"
    >
      <b-card
        no-body
      >
        <b-card-body class="statistics-body">
          <div class="d-flex">
            <b-img
              :src="item.icon"
              fluid
              alt="icon payment"
              class="icon"
            />
            <div>
              <div class="d-flex">
                {{ item.value }}
                <b-img
                  v-ripple.400="'rgba(61, 64, 91, 0.3)'"
                  v-b-tooltip.hover.v-secondary.top="``"
                  src="@/assets/images/pages/overview/ic-detail.svg"
                  alt="logo"
                />
              </div>
              <span class="small-text">{{ item.label }}</span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BImg, VBTooltip, BCard, BCardBody,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import envMixin from '@/mixins/envMixin'

const { mapGetters } = createNamespacedHelpers('home')

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BCardBody,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [envMixin],

  computed: {
    ...mapGetters(['monthlyAnalytics']),

    currentMonthlyAnalytics() {
      if (!this.hasAM) {
        return this.monthlyAnalytics.filter(item => item.id !== 'totalBonus')
      }
      return this.monthlyAnalytics
    },
  },
}
</script>

<style lang="scss" scoped>
.card-content {
  padding: 10px;
  .card {
    margin-bottom: 0px;
    height: 100%;
  }
}
.statistics-body {
  display: flex;
  align-items: center;
  padding: 15px 10px;
}
.small-text {
  font-size: 12px;
}
.icon {
  margin-right: 12px;
  width: 35px;
}
</style>
