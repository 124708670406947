<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-body>
      <b-row>
        <b-col sm="6">
          <b-card-text class="font-20 font-weight-700">
            {{ hasExclusivePartnerRole() ? 'Exclusive ' : '' }}Plan charged
          </b-card-text>
          <div>
            <calendar
              v-model="rangeDate"
              class="calendar-date"
              @input="onSelect"
            />
          </div>
          <div v-if="errorPlanCharged">
            <p class="text-error">
              {{ errorPlanCharged }}
            </p>
          </div>
          <div class="total-plan-changed">
            <b-row>
              <b-col
                md="5"
                sm="12"
                class="total-user"
              >
                <p>
                  Total User:
                  <span class="total">{{ planChanged.totalUser }}</span>
                </p>
              </b-col>
              <b-col
                md="7"
                sm="12"
                class="total-amount"
              >
                <p>
                  Total Amount:
                  <span class="total">{{
                    currencyFormat(planChanged.totalAmount)
                  }}</span>
                </p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div>
        <app-echart-doughnut :options="options" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable no-underscore-dangle,no-plusplus,no-param-reassign */

import {
  BCard, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import _get from 'lodash/get'
import _some from 'lodash/some'
import { StatsService } from '@/services'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

import moment from 'moment'
import { extendMoment } from 'moment-range'
import Calendar from './Calendar.vue'

const momentDate = extendMoment(moment)
const today = moment()
const initOptionsData = {
  legend: {},
  series: [
    {
      name: 'Plan Change',
      type: 'pie',
      radius: ['50%', '70%'],
      center: ['30%', '50%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
      },
      emphasis: {
        label: {
          show: true,
          fontSize: '18',
          fontWeight: 'bold',
        },
      },
      labelLine: {
        show: false,
      },
      data: [],
    }],
}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    Calendar,
    AppEchartDoughnut,
    BCardText,
  },
  mixins: [numberFormatMixin],
  props: {
    roles: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
  },

  data() {
    return {
      planChanged: {},
      errorPlanCharged: '',
      configDate: {
        mode: 'range',
      },
      rangeDate: {
        startDate: null,
        endDate: null,
      },
      styleChart: [],
      options: JSON.parse(JSON.stringify(initOptionsData)),
    }
  },

  computed: {
    getNamePlan() {
      return data => _get(data, ['_id', 'planType'])
    },
  },

  created() {
    const dayRange = momentDate.range(
      today.clone()
        .subtract(6, 'days'),
      today.clone(),
    )
    // eslint-disable-next-line no-underscore-dangle
    const fromDate = momentDate(dayRange?.start?._d)
      .format('YYYY-MM-DD')
    // eslint-disable-next-line no-underscore-dangle
    const toDate = momentDate(dayRange?.end?._d)
      .format('YYYY-MM-DD')

    this.rangeDate = {
      startDate: fromDate,
      endDate: toDate,
    }

    this.fetchTopPaymentStats(this.rangeDate, this.hasExclusivePartnerRole())
  },

  methods: {
    hasExclusivePartnerRole() {
      return _some(this.roles, role => role === 'EXCLUSIVE_PARTNER')
    },

    onSelect(val) {
      if (val) {
        this.fetchTopPaymentStats(val, this.hasExclusivePartnerRole())
      }
    },

    async fetchTopPaymentStats(dateSelected, excludeExclusiveSub) {
      if (dateSelected.endDate === null) {
        dateSelected.endDate = dateSelected.startDate
      }
      try {
        const params = {
          fromDate: dateSelected.startDate,
          toDate: dateSelected.endDate,
        }
        if (excludeExclusiveSub) {
          params.excludeExclusiveSub = excludeExclusiveSub
        }

        await StatsService.getPlanCharged(params)
          .then(response => {
            const packageName = response.data.result.planCharged.map(item => item._id.planType)

            const o = Math.round
            const r = Math.random
            const s = 255

            this.styleChart = response.data.result.planCharged.map(item => ({
              name: item._id.planType,
              style: `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)}, 1)`,
            }))

            this.planChanged = response.data.result
            this.options.legend.data = packageName
            this.options.series[0].data = []

            response.data.result.planCharged.map(result => {
              if (result?._id?.planType !== null) {
                for (let i = 0; i < this.options.legend.data.length; i++) {
                  for (let f = 0; f < this.styleChart.length; f++) {
                    if (
                      this.styleChart[f].name === result._id.planType
                      && result._id.planType === this.options.legend.data[i]
                    ) {
                      this.options.legend.data[i] = `${this.options.legend.data[i]} - ${result.totalUsers} users`
                      const { totalAmount } = result
                      const dataCharged = {
                        value: totalAmount,
                        name: this.options.legend.data[i],
                        lineStyle: {
                          color: this.styleChart[f].style,
                        },
                        itemStyle: {
                          color: this.styleChart[f].style,
                        },
                      }
                      this.options.series[0].data.push(dataCharged)
                    }
                  }
                }
              }
              return this.options
            })
          })
      } catch (e) {
        this.errorPlanCharged = e?.data?.message
      }
    },
  },
}
</script>
<style lang="scss">
.card-body {
  position: relative;

  .pie-text {
    width: 105px;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 44%;
    bottom: 0;
  }
}
</style>

<style lang="scss" scoped>
.card-statistics {
  .echarts {
    height: 450px;
  }
}

.calendar-date {
  margin-top: 10px;
}

.total-plan-changed {
  margin-top: 2rem;

  .total {
    font-weight: 600;
    font-size: 20px;
  }
}

.text-error {
  margin-top: 1rem;
  font-size: 14px;
  color: #f02649;
}
</style>
