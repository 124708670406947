<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-white">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <brand-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="4"
        class="d-none d-lg-flex align-items-center p-5"
        :style="{ backgroundImage: `url(${imgUrl})` }"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          &nbsp;
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- login Verify -->
      <b-col
        v-if="isShowVerificationPage"
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-1"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="mx-auto max-width form-login"
        >
          <b-card-title
            title-tag="h1"
            class="font-weight-bold mb-2 mt-4 d-flex justify-content-center"
          >
            {{ $t('login.textVerifyAccount') }}
          </b-card-title>
          <p class="text-center">
            Please verify that you are instructor by entering your email verify code below:
          </p>
          <!-- form -->
          <validation-observer ref="verifyLoginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- verification code -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="code"
                  rules="required|verificationCodeLength"
                >
                  <b-form-input
                    v-model="verifyCode"
                    type="number"
                    maxlength="6"
                    :state="errors.length > 0 ? false:null"
                    placeholder="6 - digits"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <div class="d-flex align-items-center justify-content-around">
                <p
                  class="resend-code m-0"
                  :class="isResendCode ? 'isActive' : 'notActive'"
                  @click="resendCodeVerify"
                >
                  Resend Code {{ isResendCode ? null : `(${timeResend}s)` }}
                </p>
                <btn-loading
                  class="btn-verify-code input-height d-flex justify-content-center align-items-center m-0"
                  tabindex="4"
                  type="submit"
                  variant="primary"
                  block
                  :loading="loading"
                  @click="validationFormVerify"
                >
                  {{ $t('login.btnVerifyCode') }}
                </btn-loading>
              </div>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- login Verify -->

      <!-- Login-->
      <b-col
        v-else
        lg="8"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 pt-1"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="mx-auto max-width form-login"
        >
          <b-card-title
            title-tag="h1"
            class="font-weight-bold mb-2 mt-4 d-flex justify-content-center"
          >
            {{ $t('login.textSignIn') }}
          </b-card-title>
          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                class="my-2 form-email"
                label-for="login-email"
                :label="$t('common.labelEmail')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model.trim="userEmail"
                      id="login-email"
                      tabindex="1"
                      class="input-height"
                      name="login-email"
                      :placeholder="$t('common.placeholderEmail')"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group class="form-password">
                <div class="d-flex justify-content-between">
                  <label for="login-password">
                    {{ $t('common.labelPassword') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      v-model.trim="password"
                      id="login-password"
                      tabindex="2"
                      class="form-control-merge input-height"
                      name="login-password"
                      :placeholder="$t('common.placeholderPassword')"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--              <div-->
              <!--                v-if="isEcomdyPlatform"-->
              <!--                class="d-flex justify-content-end mb-2"-->
              <!--              >-->
              <!--                <b-link-->
              <!--                  class="cover"-->
              <!--                  :to="{name:'forgot-password'}"-->
              <!--                >-->
              <!--                  <span class="nav-link">-->
              <!--                    {{ $t('login.linkForgotPassword') }}-->
              <!--                  </span>-->
              <!--                </b-link>-->
              <!--              </div>-->

              <!-- submit buttons -->
              <btn-loading
                class="input-height d-flex justify-content-center align-items-center"
                tabindex="4"
                type="submit"
                variant="primary"
                block
                :loading="loading"
                @click="validationForm"
              >
                {{ $t('login.btnSignIn') }}
              </btn-loading>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BIconGoogle,
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardTitle, BForm,
  // BButton,
} from 'bootstrap-vue'
import { required, email, verificationCodeLength } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { toastification } from '@core/mixins/toast'
import { $themeConfig } from '@themeConfig'
import { createNamespacedHelpers } from 'vuex'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    // BIconGoogle,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    // BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BForm,

    // components
    BrandLogo,
    BtnLoading,

    // validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility, toastification, envMixin],
  data() {
    return {
      password: '',
      userEmail: '',
      isShowVerificationPage: false,
      verifyCode: '',
      timeResend: 0,
      counter: null,
      imgUrl: require('@/assets/images/pages/auth/login-admin.svg'),

      // validation rulesimport store from '@/store/index'
      verificationCodeLength,
      required,
      email,

    }
  },

  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },

  computed: {
    ...mapGetters(['status', 'loading', 'message', 'verificationCode']),

    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    isResendCode() {
      return this.timeResend === 0
    },
  },

  beforeDestroy() {
    clearInterval(this.counter)
  },

  methods: {
    ...mapActions(['login', 'sendLoginVerificationCode', 'getLoginVerificationCode']),

    async validationForm() {
      const success = await this.$refs.loginValidation.validate()
      if (success) {
        const params = {
          email: this.userEmail,
          password: this.password,
          ...(this.status && { rememberMe: this.status }),
        }

        const config = { ...(process.env.VUE_APP_PLATFORM === 'TTM' && { params: { loginFromPlatform: process.env.VUE_APP_PLATFORM } }) }

        await this.getLoginVerificationCode({ params, config })
        if (this.status) {
          this.isShowVerificationPage = true
          this.countdown()
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    async validationFormVerify() {
      const success = await this.$refs.verifyLoginValidation.validate()
      if (success) {
        const params = {
          email: this.userEmail,
          password: this.password,
          verifyCode: this.verifyCode,
        }

        const config = { ...(process.env.VUE_APP_PLATFORM === 'TTM' && { params: { loginFromPlatform: process.env.VUE_APP_PLATFORM } }) }

        await this.sendLoginVerificationCode({ params, config })
        if (this.status) {
          this.$router.push({ name: 'dashboard' })
          clearInterval(this.counter)
          this.toastSuccess(this.$t('login.textLoginSuccess'))
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    async resendCodeVerify() {
      if (!this.loading) {
        const params = {
          email: this.userEmail,
          password: this.password,
          ...(this.status && { rememberMe: this.status }),
        }
        await this.getLoginVerificationCode(params)
        if (this.status) {
          this.toastSuccess('Resend code success')
          this.timeResend = this.verificationCode.expiresIn
          this.countdown()
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    countdown() {
      this.timeResend = this.verificationCode.expiresIn
      this.counter = setInterval(() => {
        this.timeResend -= 1
        if (this.timeResend === 0) {
          clearInterval(this.counter)
        }
      }, 1000)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .max-width{
    max-width: 600px;
  }
</style>
<style lang="scss" scoped>
  .form-email {
    margin-bottom: 1.5rem;
  }
  .form-password {
    margin-bottom: 2.5rem;
  }
  .divider {
    margin: 2.875rem 0;
  }
  .btn-gg {
    margin-bottom: 1.5rem;
  }
  .btn-fb {
    margin-bottom: 2.85rem;
  }
  .form-login {
    padding: 0 2.2rem;
  }
  .description {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .btn-block {
    border: 1px solid #E0E0E5 !important;
  }
  .input-height {
    height: 50px;
  }

  .backgroundImage{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .resend-code{
    width: max-content;
    height: 30px;
    font-weight: bold;
    user-select: none;
  }

  .isActive{
    cursor: pointer;
  }

  .isActive:hover{
    color: #ea1b48;
    text-decoration: underline;
  }

  .notActive{
    cursor: default;
    color: #ccc;
    user-select: none;
    pointer-events: none;
    text-decoration: none;
  }

  .btn-verify-code{
    width: 172px;
    height: 56px;
  }
  @media (max-width: 767px) {
    .form-login {
      padding-left: 0;
      padding-right: 0;
      .description {
        line-height: 24px;
        margin-bottom: 2rem !important;
      }
    }
    .navbar-auth-custom {
      width: calc(100%);
    }
    .card-title {
      padding-top: 2.5rem;
    }
    .card-text:last-child {
      margin-bottom: 2.2rem;
    }
  }
</style>
