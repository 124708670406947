/* eslint-disable global-require */
import get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  computed: {
    ...mapGetters(['generalConfigs']),
    listTargetMarket() {
      return get(this.generalConfigs, 'targetMarkets', [])
    },

    isOnlyEcomdyPlatform() {
      return this.isEcomdyPlatform && !this.isExclusivePartner
    },

    isExclusivePartner() {
      return this.generalConfigs?.system === 'ADREACH'
    },

    timezone() {
      return this.generalConfigs?.providerTimezone
    },

    listTimeZone() {
      return get(this.generalConfigs, 'timezones', [])
    },

    listBussinessCenter() {
      return get(this.generalConfigs, ['businessCenter'], []).map(item => ({
        text: `${item.BCId} (${item.BCName})`,
        value: item.BCId,
      }))
    },

    listOriginBC() {
      return get(this.generalConfigs, ['businessCenter'], [])
    },
  },
}
