<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Plan change"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="wrapper">
      <b-form-group
        label="Plan type"
        label-cols="12"
      >
        <b-form-select
          v-model="planType"
          :options="handlePlanTypes"
        />
        <b-form-checkbox
          v-if="isEcomdyPlatform"
          v-model="shouldForceEndTrial"
          class="mt-2"
        >
          Should Force End Trial
        </b-form-checkbox>

      </b-form-group>

      <btn-loading
        type="submit"
        variant="primary"
        block
        :loading="loading"
        @click.prevent="submit"
      >
        Update
      </btn-loading>
    </div>
  </b-sidebar>
</template>
<script>

import {
  BSidebar,
  BFormGroup,
  BFormSelect,
  BFormCheckbox,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
// import { required } from '@validations'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BtnLoading,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
  },
  mixins: [toastification, numberFormatMixin, paymentMixin, envMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    userData: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      show: false,
      shouldForceEndTrial: false,
      planType: 'white_agency',
      planTypes: [
        { text: 'White Agency', value: 'white_agency' },
        { text: 'Black Agency', value: 'black_agency' },
      ],
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),

    handlePlanTypes() {
      if (this.isEcomdyPlatform) {
        return this.planTypes
      }
      return this.planTypes.filter(item => item.value !== 'black_agency')
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },
  },

  methods: {
    ...mapActions(['changePlanType']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
    },

    async submit() {
      const params = {
        id: this.userData.localUserId,
        planType: this.planType,
        shouldForceEndTrial: this.isEcomdyPlatform ? this.shouldForceEndTrial : true,
      }

      await this.changePlanType(params)
      if (this.status) {
        this.toastSuccess('Update plan type success !')
        this.$emit('update-user-selected')
        this.show = false
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}
</style>
