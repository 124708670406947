<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    data-testid="modal-assign-to-am-verify-info"
    bg-variant="white"
    title="Assign To AM"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2">
      <b-form-group class="w-100">
        <label>
          Email AM
        </label>
        <v-select
          v-model="AMSelected"
          data-testid="textbox-email-am"
          class="input-height"
          placeholder="-- Select an option --"
          :options="listAMOptions"
        />
      </b-form-group>
      <div class="mt-1">
        <!-- submit buttons -->
        <btn-loading
          data-testid="btn-assign-to-am"
          type="submit"
          variant="primary"
          block
          :loading="loading"
          @click.prevent="submit"
        >
          Assign To AM
        </btn-loading>
      </div>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import {
  BSidebar,
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BFormGroup,
    vSelect,
    BtnLoading,
  },
  mixins: [toastification, numberFormatMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    listUserInfoSelected: {
      type: Array,
      required: true,
      default: () => [],
    },
  },

  data() {
    return {
      show: false,
      AMSelected: null,
    }
  },

  computed: {
    ...mapGetters(['listAM', 'loading', 'status', 'message']),

    listAMOptions() {
      const optionNone = { label: 'None', localId: 0, value: 0 }
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          localId: item.localUserId,
          value: item.localUserId,
        }
        return options
      })
      return [optionNone, ...listAMMap]
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.getListAM()
    },
  },

  methods: {
    ...mapActions(['getListAM', 'resignMultiUserToAM', 'assignMultiUserToAM']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    resetForm() {
      this.AMSelected = null
    },

    async submit() {
      let params = {}
      if (this.listUserInfoSelected?.length > 0) {
        const listIdUser = this.listUserInfoSelected?.map(item => item._id)
        params = {
          userIds: listIdUser,
          amId: this.AMSelected.value,
        }
      }
      if (this.AMSelected.value) {
        await this.assignMultiUserToAM(params)
        if (this.status) {
          this.toastSuccess('Assign to AM success!')
          this.onHidden()
          this.$emit('fetch-user')
        } else {
          this.toastFailure(this.message)
        }
      } else {
        await this.resignMultiUserToAM(params)
        if (this.status) {
          this.toastSuccess('Resign AM success!')
          this.onHidden()
          this.$emit('fetch-user')
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep button.close {
    display: block;
    margin-left: auto;
    margin-right: 0;
    font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
    flex-direction: row-reverse;
    strong {
        flex: 1;
    }
}

.recharge{
  color: #7AB34C;
}

.withdraw{
  color: #F02548;
}
</style>
