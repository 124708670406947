import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    user: {
      statisticsUser: [],
      totalUsers: 0,
    },
    deposit: {
      statisticsDeposit: [],
    },
    spending: {
      statisticsSpending: [],
    },
    typeSort: 'day',
    limitDay: 0,
    limitMonth: 0,

    typeSortDeposit: 'day',
    typeSortSpending: 'day',
    limitDayDeposit: 0,
    limitDaySpending: 0,
    limitMonthDeposit: 0,
    limitMonthSpending: 0,
    users: {
      statisticsUser: [],
      totalUsers: 0,
    },
    deposits: {
      statisticsDeposit: [],
      monthlyDeposit: 0,
    },
    adsAccounts: {
      totalAdsAccounts: 0,
      monthlyTotalAdsAccount: 0,
      monthlyStatisticsAdsAccount: [],
    },
    bonus: {
      totalBonus: 0,
    },
    balances: {
      totalBalance: 0,
    },
  },
  getters,
  mutations,
  actions,
}
