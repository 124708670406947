import { AdsAccountService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getAccountTickets({ commit }, params) {
    commit('GET_ACCOUNTS_REQUEST')
    try {
      const { data } = await AdsAccountService.getAccountTickets(params)
      commit('GET_ACCOUNTS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ACCOUNTS_FAILURE', error)
    }
  },

  async getAccountNoPagingList({ commit }) {
    commit('GET_ACCOUNTS_NO_PAGING_REQUEST')
    try {
      const { data } = await AdsAccountService.getListAccountNoPaging()
      commit('GET_ACCOUNTS_NO_PAGING_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ACCOUNTS_NO_PAGING_FAILURE', error)
    }
  },

  async approveTicket({ commit }, params) {
    commit('APPROVE_TICKET_STATUS_REQUEST')
    try {
      const { data } = await AdsAccountService.approveTicket(params)
      commit('APPROVE_TICKET_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('APPROVE_TICKET_STATUS_FAILURE', error)
    }
  },

  async rejectTicket({ commit }, params) {
    commit('REJECT_TICKET_STATUS_REQUEST')
    try {
      const { data } = await AdsAccountService.rejectTicket(params)
      commit('REJECT_TICKET_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REJECT_TICKET_STATUS_FAILURE', error)
    }
  },

  async commentTicket({ commit }, params) {
    commit('COMMENT_TICKET_STATUS_REQUEST')
    try {
      const { data } = await AdsAccountService.commentTicket(params)
      commit('COMMENT_TICKET_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('COMMENT_TICKET_STATUS_FAILURE', error)
    }
  },
}
