<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Edit Roles"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="wrapper">
      <b-form-group class="w-100">
        <label>
          Roles
        </label>
        <v-select
          v-model="roles"
          multiple
          placeholder="Select role"
          :options="roleOptions"
        />
      </b-form-group>

      <btn-loading
        type="submit"
        variant="primary"
        block
        :disabled="roles.length===0"
        :loading="loading"
        @click.prevent="submitUpdateRole"
      >
        Update
      </btn-loading>
    </div>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
} from 'bootstrap-vue'
// import { required } from '@validations'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import _get from 'lodash/get'
import vSelect from 'vue-select'

const { mapGetters, mapActions } = createNamespacedHelpers('acl')

export default {
  components: {
    BSidebar,
    BtnLoading,
    BFormGroup,
    vSelect,
  },
  mixins: [toastification],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    roleSelected: {
      type: Array,
      default: () => [],
      required: true,
    },

    userId: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      roles: [],
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading', 'listAcl']),
    roleOptions() {
      return _get(this.listAcl, ['roles'])
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },

    roleSelected(val) {
      this.roles = val
    },
  },

  created() {
    this.getListAcl()
  },

  methods: {
    ...mapActions(['getListAcl', 'assignRole']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    async submitUpdateRole() {
      const params = {
        id: this.userId,
        roles: this.roles,
      }
      await this.assignRole(params)
      if (this.status) {
        this.toastSuccess('Assign Role successfully.')
        this.resetForm()
        this.show = false
        this.$emit('fetch-user-info')
      } else {
        this.toastFailure(this.message)
      }
    },

    resetForm() {
      this.roles = this.roleSelected
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}
</style>
