<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Product links"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2">
      <ul
        v-if="listProductUrl"
        class="product-links"
      >
        <li
          v-for="(item, index) in listProductUrl"
          :key="index"
          class="item-product-link"
        >
          <b-card-text>Product link</b-card-text>
          <span
            class="text-blue underline cursor-pointer tag-content"
            @click="goToUrl(item.productUrl)"
          >
            {{ item.productUrl }}
          </span>

          <b-card-text
            v-if="item.targetMarkets"
            class="mt-2"
          >
            Target market
          </b-card-text>
          <ul
            v-if="listTargetMarkets"
            class="target-market p-0"
          >
            <li
              v-for="(itemTarget, indexTarget) in item.targetMarkets"
              :key="indexTarget"
              style="list-style-type: none"
              class="item-target-market"
            >
              {{ itemTarget }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </b-sidebar>
</template>
<script>
import { BSidebar, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
    BCardText,
  },

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
    accountTickets: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      show: false,
      listProductUrl: {},
    }
  },

  computed: {
    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    listTargetMarkets() {
      const item = []
      return item
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.listProductLink()
    },
  },

  methods: {
    onHidden() {
      this.$emit('update:show-side-bar', false)
    },

    listProductLink() {
      const listProductUrl = this.accountTickets?.userId?.products?.productURLs || {}

      const arrUrlConverted = Object.keys(listProductUrl).map(value => ({
        productUrl: value,
        ...listProductUrl[value],
      }))

      this.listProductUrl = arrUrlConverted
    },

  },
}
</script>

<style lang="scss">
.ql-editor {
  min-height: 200px;
}

.add-page {
  .markdown {
    border: 1px solid #ccc;
    border-radius: 0.357rem;
  }
}
</style>

<style lang="scss" scoped>
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}

.line-center {
  width: 100%;
  background-color: #eeeeee;
  height: 2px;
  margin: 1rem 0;
}

.product-links {
  list-style-type: none;
  .item-product-link {
    overflow-wrap: break-word;
    margin-bottom: 1rem;
  }
}

.target-market {
  display: flex;
  .item-target-market {
    padding: 6px 8px;
    background-color: #3d405b1a;
    width: max-content;
    border-radius: 4px;
    margin-right: 1rem;
  }
}
</style>
