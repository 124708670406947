import { AclService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getListAcl({ commit }, params) {
    commit('GET_LIST_ACL_REQUEST')
    try {
      const { data } = await AclService.getListAcl(params)
      commit('GET_LIST_ACL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_LIST_ACL_FAILURE', error)
    }
  },

  async createRole({ commit }, params) {
    commit('CREATE_ROLE_REQUEST')
    try {
      const { data } = await AclService.createRole(params)
      commit('CREATE_ROLE_SUCCESS', data)
    } catch (error) {
      commit('CREATE_ROLE_FAILURE', error)
    }
  },

  async updateRole({ commit }, params) {
    commit('UPDATE_ROLE_REQUEST')
    try {
      const { data } = await AclService.updateRole(params)
      commit('UPDATE_ROLE_SUCCESS', data)
    } catch (error) {
      commit('UPDATE_ROLE_FAILURE', error)
    }
  },

  async assignRole({ commit }, params) {
    commit('ASSIGN_ROLE_REQUEST')
    try {
      const { data } = await AclService.assignRole(params)
      commit('ASSIGN_ROLE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ASSIGN_ROLE_FAILURE', error)
    }
  },
}
