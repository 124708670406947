<template>
  <div>
    <b-row class="align-items-end justify-content-between">
      <b-col
        cols="12"
        md="5"
      >
        <b-form-group class="w-100">
          <label>
            Search ACL by field
          </label>
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model.trim="roleSearch"
              placeholder="Enter ACL"
              @input="handleSearch"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <div class="role-tag d-flex align-items-end">
          <b-form-group class="w-100">
            <label>
              Show Roles
            </label>
            <v-select
              v-model.trim="roleSelected"
              multiple
              placeholder="Select roles"
              :options="roleOptions"
              @input="handleFilterRoles"
            />
          </b-form-group>
        </div>
      </b-col>
      <b-col class="text-align-end">
        <b-button
          variant="primary"
          class="btn-add-role mb-1"
          @click="addNewRole"
        >
          Add new role
        </b-button>
      </b-col>
    </b-row>
    <vue-good-table
      ref="table-acl"
      class="table-acl"
      :columns="roles"
      :rows="acls"
      :group-options="{
        enabled: true,
        collapsable: true,
      }"
    >
      <template
        slot-scope="props"
        slot="table-column"
      >
        <span
          v-if="props.column.field === 'aclRoles'"
          class="d-flex justify-content-between align-items-center"
        >
          {{ props.column.label }}

          <span
            class="expand-content"
            @click="ExpandHandle"
          >
            {{ isExpand ? 'Collapse All' : 'Expand All' }}
          </span>
        </span>

        <span v-else-if="props.column.field === 'ROOT' || props.column.field === 'ADMIN' || props.column.field === 'USER' || props.column.field === 'PARTNERSHIP' || props.column.field === 'AM' || props.column.field === 'VERIFY_USER_INFORMATION'">
          {{ props.column.label }}
        </span>

        <div
          v-else
          class="d-flex align-items-center justify-content-center"
        >
          <span>{{ props.column.label }}</span>
          <feather-icon
            class="icon-edit cursor-pointer"
            icon="Trash2Icon"
            size="12"
            @click="openDeleteRoleModal(props.column.label)"
          />
        </div>
      </template>
      <template
        slot-scope="props"
        slot="table-header-row"
      >
        <div
          v-if="props.column.field === 'aclRoles'"
          :ref="props.row.originName"
          class="d-flex align-items-center justify-content-between cursor-pointer column-acl"
        >
          <span class="text-acl">{{ props.row.name }}</span>
          <feather-icon
            class="icon-child cursor-pointer"
            icon="ChevronDownIcon"
            size="16"
          />
        </div>

        <span
          v-else-if="props.column.field === 'ROOT'"
          class="input-checkbox"
        >
          <input
            class="custom-control-primary cursor-not-allowed"
            type="checkbox"
            disabled
            :checked="props.row.role.find(item=>item === props.column.field)"
          >
        </span>

        <span
          v-else
          class="input-checkbox"
        >
          <input
            class="custom-control-primary cursor-pointer"
            type="checkbox"
            :checked="props.row.role.find(item=>item === props.column.field)"
            @change="onItemClick($event,props.row.originName,props.column.field)"
          >
        </span>
      </template>
      <template
        slot-scope="props"
        slot="table-row"
      >
        <div
          v-if="props.column.field === 'aclRoles'"
          class="d-flex align-items-center column-acl"
        >
          <feather-icon
            class="icon-child cursor-pointer"
            icon="CornerDownRightIcon"
            size="16"
          />
          <span class="text-acl">{{ props.row.name }}</span>
        </div>

        <span
          v-else-if="props.column.field === 'ROOT'"
          class="input-checkbox"
        >
          <input
            class="custom-control-primary cursor-not-allowed"
            type="checkbox"
            disabled
            :checked="props.row.role.find(item=>item === props.column.field)"
          >
        </span>

        <span
          v-else
          class="input-checkbox"
        >
          <input
            class="custom-control-primary cursor-pointer"
            type="checkbox"
            :checked="props.row.role.find(item=>item === props.column.field)"
            @change="onItemClick($event,props.row.originName,props.column.field, props.row.parentName)"
          >
        </span>
      </template>
    </vue-good-table>

    <div class="d-flex justify-content-end mt-1">
      <btn-loading
        class="button-update-acl"
        type="submit"
        variant="primary"
        block
        :loading="loading"
        @click="handleUpdateRole"
      >
        Update
      </btn-loading>
    </div>

    <add-new-role-side-bar
      :show-side-bar.sync="showAddNewRoleSideBar"
      @fetchListAcl="fetchListAcl"
    />

    <edit-acl-modal
      ref="open-modal-delete-role"
      :role-edit="roleEdit"
      @fetchListAcl="fetchListAcl"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
// import _flatten from 'lodash/flatten'
// import _uniq from 'lodash/uniq'
import { VueGoodTable } from 'vue-good-table'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import {
  // BFormCheckbox,
  BFormGroup,
  BButton,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import AddNewRoleSideBar from './AddNewRoleSideBar.vue'
import EditAclModal from './EditAclModal.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('acl')
export default {
  components: {
    VueGoodTable,
    // BFormCheckbox,
    BFormGroup,
    vSelect,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,

    AddNewRoleSideBar,
    EditAclModal,
    BtnLoading,
  },

  directives: {
    Ripple,
  },

  mixins: [toastification],

  data() {
    return {
      roleAcl: {
        label: 'ACL Roles',
        field: 'aclRoles',
        thClass: 'p-1 border-left-0 border-right-0 header-table-mf column-acl',
        tdClass: 'p-1 border-left-0 border-right-0 align-middle column-acl',
      },

      roles: [],
      acls: [],

      roleSearch: '',
      roleSelected: [],
      roleOptions: [],

      showAddNewRoleSideBar: false,
      roleEdit: '',

      isExpand: false,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading', 'listAcl']),
  },

  watch: {
    listAcl: {
      handler(val) {
        if (this.roles.length === 0 || (val && !(this.roles.length > 1 && this.roles.length <= this.listAcl.roles.length))) {
          this.handlerColumns()
          this.rows()
        }
      },
      deep: true,
    },
  },

  created() {
    this.fetchListAcl()
  },

  methods: {
    ...mapActions(['getListAcl', 'updateRole']),

    async fetchListAcl() {
      await this.getListAcl()
      if (this.status) {
        this.handlerColumns()
        this.rows()
      } else {
        this.toastFailure(this.message)
      }
    },

    handlerColumns() {
      const listRole = this.listAcl?.roles.map(item => {
        const options = {
          label: item,
          field: item,
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf text-center',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle text-center',
        }
        return options
      })
      this.roleOptions = listRole.filter(item => item.label !== 'USER')
      this.roles = [this.roleAcl, ...this.roleOptions]
    },

    rows() {
      this.acls = Object.entries(this.listAcl.acl).map(item => {
        const childrenLength = Object.keys(item[1]).length
        const { role, children } = Object.entries(item[1]).reduce((rs, child) => {
          child[1].roles.forEach(childRole => {
            // eslint-disable-next-line no-param-reassign
            rs.countBy[childRole] = rs.countBy[childRole] ? rs.countBy[childRole] + 1 : 1
            if (rs.countBy[childRole] === childrenLength) {
              rs.role.push(childRole)
            }
          })

          rs.children.push({
            originName: child[0],
            parentName: item[0],
            name: this.handleAclName(child[0]),
            role: child[1].roles,
          })
          return rs
        }, { countBy: {}, role: [], children: [] })

        return {
          originName: item[0],
          name: this.handleAclName(item[0]),
          children,
          role,
        }
      })
    },

    handleAclName(originName) {
      let result = originName.replace(/([A-Z]+)/g, ' $1')
      const consecutiveUppers = result.match(/([A-Z]{2,}[a-z])/g)
      if (consecutiveUppers) {
        consecutiveUppers.forEach(char => {
          const rg = new RegExp(char)
          result = result.replace(rg, `${char.slice(0, -2)} ${char.slice(-2)}`)
        })
        return result.charAt(0).toUpperCase() + result.slice(1)
      }
      const tiktokExceptionRg = new RegExp('Tiktok')
      result = result.replace(tiktokExceptionRg, 'TikTok')
      return result
    },

    handleFilterRoles() {
      if (this.roleSelected.length === 0) {
        this.roles = [this.roleAcl, ...this.roleOptions]
      } else {
        this.roles = [this.roleAcl, ...this.roleSelected]
      }
    },

    handleSearch() {
      const searchText = this.roleSearch.toLowerCase()
      if (this.roleSearch.length > 3 && this.acls.length <= Object.keys(this.listAcl.acl).length) {
        this.acls = this.acls.filter(item => item.name.toLowerCase().includes(searchText) || item.children.some(child => child.name.toLowerCase().includes(searchText)))
      } else {
        this.rows()
      }
      return this.acls
    },

    handleACLRole(parentACL, roleAcl, isAddRole) {
      const roleIndex = parentACL.roles.findIndex(role => role === roleAcl)
      if (roleIndex !== -1 && !isAddRole) {
        parentACL.roles.splice(
          roleIndex,
          1,
        )
      }
      if (roleIndex === -1 && isAddRole) {
        parentACL.roles.push(roleAcl)
      }
    },

    async onItemClick(e, originName, column, parentName = '') {
      const isAddRole = e.target.checked
      if (parentName) {
        const parentACL = this.listAcl.acl[parentName][originName]
        this.handleACLRole(parentACL, column, isAddRole)
      } else {
        const parentACL = Object.values(this.listAcl.acl[originName])
        const ACLKeys = Object.keys(parentACL)
        ACLKeys.map(ACLKey => this.handleACLRole(parentACL[ACLKey], column, isAddRole))
      }
      await this.rows()
      this.handleSearch()
    },

    async handleUpdateRole() {
      const params = {
        acl: this.listAcl.acl,
      }
      await this.updateRole(params)
      if (this.status) {
        this.toastSuccess('Update Role successfully.')
      } else {
        this.toastFailure(this.message)
      }
    },

    addNewRole() {
      this.showAddNewRoleSideBar = true
    },

    openDeleteRoleModal(data) {
      this.roleEdit = data
      this.$refs['open-modal-delete-role'].showDeleteModal()
    },

    ExpandHandle() {
      this.isExpand = !this.isExpand
      if (this.isExpand) {
        this.$refs['table-acl'].expandAll()
      } else {
        this.$refs['table-acl'].collapseAll()
      }
    },
  },
}
</script>
<style lang="scss">
  .role-tag {
    .vs__selected {
      background: rgba(22, 33, 62, 0.1);
      color: rgba(22, 33, 62, 1);
    }

    .vs__dropdown-option--highlight {
      background: rgba(22, 33, 62, 0.1);
      color: rgba(22, 33, 62, 1) !important;
    }
  }

  .table-acl {
    .vgt-table {
      width: 100%;
      overflow-y: auto;

      thead {
        position: sticky;
        top: 0;
        z-index: 100;
      }
    }

    .vgt-table th.sortable:before, .vgt-table th.sortable:after {
      display: none;
    }

    .vgt-table.bordered td, .vgt-table.bordered th {
      border: 0px;
    }

    .vgt-table.bordered th.vgt-row-header {
      border-bottom: 0px;
    }

    .triangle {
      display: none;
    }

    .column-acl {
      width: 350px;
    }

    .vgt-responsive {
      width: 100%;
      overflow-x: auto;
      position: relative;
      height: 60vh;
    }
  }

  .custom-control-primary:checked {
    border-color: #ea5455;
    background-color: #ea5455;
  }
</style>
<style lang="scss" scoped>
.btn-search {
  margin-bottom: 1rem;
  margin-left: 1rem;
  height: 43px;
}

.button-update-acl {
  width: max-content;
}

.icon-edit {
  margin-left: 4px;
}

.icon-child {
  margin-right: 4px;
  min-width: 8%;
}

.input-checkbox {
  input[type=checkbox] {
    position: relative;
  }
  input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    border:1px solid #DBDBDB;
    background-color:#fff;
    border-radius: 2px;
  }
  input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    left: 0;
    background-color:#7AB34C;
    border-color:#7AB34C;
    border-radius: 2px;
  }
  input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1.5px;
    left: 5px;
    border-radius: 2px;
  }
}

.text-acl {
  text-transform: capitalize;
}

.expand-content {
  margin-left: 12px;
  color: red;
  font-size: 10px;
  font-weight: 100;
  cursor: pointer;
}
</style>
