import axios from '@axios'

export default {
  listInactiveAdAccount(params, cancelToken) {
    return axios.get('/api/admin/ads-account-inactive-report', { params, cancelToken })
  },

  deleteAdsAccounts(params) {
    return axios.delete('/api/admin/ads-accounts/disable', { data: params })
  },

  reactiveAdAccounts(params) {
    return axios.post('/api/admin/ads-accounts/reactive', params)
  },
}
