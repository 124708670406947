<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    data-testid="title-assign-ads-account"
    title="Assign ads Account"
    width="532px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2 pr-1">
      <validation-observer ref="formValidation">
        <b-form>
          <div>
            <div
              v-for="(account, index) in accounts"
              :key="index"
              class="position-relative"
            >
              <div class="d-flex justify-content-between mb-1">
                <validation-provider
                  #default="{ errors }"
                  name="Agency Business"
                  rules="required"
                >
                  <b-form-group
                    label="Agency Business ID"
                    label-for="Agency Business"
                    style="max-width: 406px"
                  >
                    <b-form-select
                      v-model="account.BCId"
                      :options="listBussinessCenter"
                      placeholder="Select business ID"
                      data-testid="select-agency-business-id"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          -- Select an option --
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      {{ errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
                <b-button
                  v-if="index === 0"
                  :disabled="disableAdd || isGetAdAccount"
                  data-testid="add-ads-account-button"
                  class="button-action"
                  @click="addAccount"
                >
                  <b-img
                    alt="Add new"
                    :src="require('@/assets/images/pages/adsAccount/ic-plus-white.svg')"
                  />
                </b-button>
                <b-button
                  v-else
                  data-testid="remove-ad-account"
                  class="button-action remove"
                  @click="removeAccount(index)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="20"
                  />
                </b-button>
              </div>
              <div
                :style="{marginRight: $device.mobile ? '0' : '77px'}"
              >
                <b-form-group
                  data-testid="input-ads-account-id"
                  class="mr-1"
                  :label-for="`account-${index + 1}`"
                  label="Ads account ID"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Account"
                    :vid="`account-${index + 1}`"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model.trim="account.adsAccountId"
                        :id="`account-${index + 1}`"
                        :disabled="isGetAdAccount"
                        tabindex="1"
                        class="input-height"
                        name="Account Id"
                        placeholder="Ex: 432342423423"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger position-absolute">{{ errors[0] || errorAdAccountId }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  data-testid="input-partner-bc-id"
                  class="mr-1 pt-1"
                  :label-for="`partner-${index + 1}`"
                  label="Partner BCID"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="PartnerBCID"
                    :vid="`partner-${index + 1}`"
                    rules="required"
                  >
                    <b-input-group
                      v-if="hasConnectedTikTok"
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-select
                        v-model="account.partnerBCId"
                        :options="optionsListPartnerBC"
                        placeholder="Select business ID"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Select an option --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-input-group>
                    <b-input-group
                      v-else
                      class="input-group-merge mr-1"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        v-model.trim="account.partnerBCId"
                        :id="`partner-${index + 1}`"
                        tabindex="1"
                        class="input-height"
                        name="Partner BCID"
                        type="number"
                        placeholder="Ex: 432342423423"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger position-absolute">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
            </div>

            <div v-if="isGetAdAccount">
              <div
                v-for="(adAccount, index) in listAdAccount"
                :key="index"
                class="mt-1"
              >
                <span class="font-weight-600">Ad account ID: </span><span>{{ adAccount._id }}</span>
                <p class="mb-0">
                  Timezone: {{ adAccount.timezone }}
                </p>
                <p>Daily Spending: {{ adAccount.spend }} </p>
              </div>

              <b-form-checkbox
                v-if="listAdAccount.length > 0"
                v-model="skipCheckSpendingAdAccount"
                data-testid="skip-spending-ads-account"
                class="mt-2"
              >
                Avoid spending number error
                <span
                  v-if="skipCheckSpendingAdAccount"
                  class="font-weight-600 text-error"
                >: {{ totalSpending }}</span>
              </b-form-checkbox>

              <div v-else>
                <span class="font-weight-600">Ad account ID: </span><span>N/A</span>
                <p class="mb-0">
                  Timezone: N/A
                </p>
                <p>Daily Spending: N/A</p>
              </div>
            </div>

            <!-- submit -->
            <div
              data-testid="button-submit-assign-ads-account"
              class="btn-submit"
            >
              <btn-loading
                v-if="isGetAdAccount"
                type="submit"
                variant="primary"
                block
                :loading="isLoading"
                @click.prevent="submit"
              >
                Save
              </btn-loading>
              <btn-loading
                v-else
                type="submit"
                variant="primary"
                block
                :loading="isLoading"
                @click.prevent="getAdAccount"
              >
                Search
              </btn-loading>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-plusplus */

import {
  BSidebar,
  BForm,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BButton,
  BImg,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { createNamespacedHelpers } from 'vuex'
import { AdsAccountService } from '@/services'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('user')
const { mapGetters: mapGettersAdAccountUser, mapActions: mapActionsAdAccountUser } = createNamespacedHelpers('partner')

export default {
  components: {
    BSidebar,
    BForm,
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BButton,
    BImg,
    BFormSelect,
    BFormSelectOption,
    BFormCheckbox,
  },

  mixins: [toastification, generalConfigsMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      isLoading: false,
      accounts: [
        {
          adsAccountId: '',
          partnerBCId: null,
          BCId: null,
        },
      ],

      isGetAdAccount: false,
      errorAdAccountId: '',
      listAdAccount: [],
      totalSpending: 0,
      skipCheckSpendingAdAccount: false,
      infoAdsAccount: null,
    }
  },

  computed: {
    ...mapGettersUser(['userDetail', 'hasConnectedTikTok', 'listPartnerBC', 'userAdsAccounts']),
    ...mapGetters(['addAdsAccount']),
    ...mapGettersAdAccountUser(['listAdAccountUser']),

    optionsListPartnerBC() {
      let options = []
      if (this.listPartnerBC.length > 0) {
        options = this.listPartnerBC.map(item => ({
          text: `${item?.bc_info?.bc_id} - ${item?.bc_info?.name}`,
          value: item?.bc_info?.bc_id,
        }))
      }
      return options
    },

    disableAdd() {
      return this.accounts.length >= 10
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      if (val) {
        this.infoAdsAccount = JSON.parse(localStorage.getItem('infoAdsAccount'))
      }
    },

    listPartnerBC: {
      handler(value) {
        if (value.length > 0) {
          const partnerBCDefault = this.listPartnerBC.filter(item => item.isDefault)
          for (let i = 0; i < partnerBCDefault.length; i++) {
            for (let f = 0; f < this.accounts.length; f++) {
              this.accounts[f].partnerBCId = partnerBCDefault[i].bc_info?.bc_id
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['createAdsAccount']),
    ...mapActionsAdAccountUser(['getListAdAccountUser']),

    onHidden() {
      this.$refs.formValidation.reset()
      this.accounts = [
        {
          adsAccountId: '',
          partnerBCId: null,
          BCId: null,
        },
      ]
      this.isGetAdAccount = false
      this.listAdAccount = []
      this.skipCheckSpendingAdAccount = false
      this.$emit('close-side-bar')
    },

    handleCopyButton() {
      this.accounts[0].partnerBCId = this.infoAdsAccount.BCId
      this.accounts[0].adsAccountId = this.infoAdsAccount.adsAccountId
    },

    addAccount() {
      if (this.disableAdd) {
        return
      }

      this.accounts.push({
        adsAccountId: '',
        partnerBCId: null,
        BCId: null,
      })
    },

    removeAccount(index) {
      this.accounts.splice(index, 1)
    },

    fetchAdsAccountUser() {
      this.$emit('fetch-ads-account-user')
    },

    async submit() {
      const validated = await this.$refs.formValidation.validate()
      if (!validated || !this.accounts.length) {
        return
      }
      this.isLoading = true

      try {
        await AdsAccountService.createAdsAccount({
          id: this.userDetail.localUserId,
          adsAccounts: this.accounts,
          skipCheckSpendingAdAccount: this.skipCheckSpendingAdAccount,
        })
        this.toastSuccess('Assign ads account successfully!')
        this.fetchAdsAccountUser()
        this.onHidden()
      } catch (error) {
        const { message } = error?.response?.data || 'Assign ads account failed!'
        this.toastFailure(message)
      }
      this.isLoading = false
    },

    async getAdAccount() {
      const isErrorAdAccount = this.accounts.find(adAccount => adAccount.adsAccountId === '')
      if (!isErrorAdAccount) {
        await this.getListAdAccountUser({
          adsAccounts: this.accounts.map(adsAccount => adsAccount.adsAccountId).join(','),
        })
        this.isGetAdAccount = true
        this.listAdAccount = this.listAdAccountUser
        this.totalSpending = this.listAdAccountUser.reduce((spend, item) => spend + item.spend, 0)
        this.errorAdAccountId = ''
      } else {
        this.errorAdAccountId = 'The Account field is required'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;

  strong {
    flex: 1;
  }
}

.button-action {
  width: 48px;
  height: 45px;
  padding: 0;
  margin-top: 22px;
  margin-left: 12px;

  &.remove {
    background-color: white !important;

    svg {
      color: #000;
    }
  }
}

::v-deep .b-sidebar-body {
  overflow-y: hidden;

  & > div {
    height: 100%;

    & > span {
      display: block;
      height: 100%;

      & > form {
        height: 100%;

        & > div {
          height: calc(100% - 50px);
          overflow-y: auto;
        }
      }
    }
  }
}

.btn-submit {
  position: absolute;
  bottom: 16px;
  width: 91%;
}
</style>
