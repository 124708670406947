<template>
  <b-modal
    ref="reject-partner-modal"
    id="reject-partner-modal"
    class="modal-child"
    hide-footer
    hide-header
    centered
    title=""
  >
    <div class="d-flex justify-content-between top-modal">
      <h4 class="font-medium text-primary">
        Are you sure to reject this partner?
      </h4>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <validation-observer ref="formValidation">
      <b-form
        class="mt-1"
        @submit.prevent="submit"
      >
        <!--Content-->
        <p>Partner: <code>{{ userData.fullName ? `${userData.fullName} - ` :'' }}{{ userData.email }}</code></p>

        <!--modal footer-->
        <div class="d-flex justify-content-end mt-2">
          <b-button
            variant="flat-secondary"
            @click="hideModal"
          >
            Cancel
          </b-button>
          <btn-loading
            class="btn-submit ml-1"
            variant="primary"
            type="submit"
            :loading="loading"
            @click.prevent="submit"
          >
            Submit
          </btn-loading>
        </div>
        <!--/modal footer-->
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>

import {
  BModal,
  BForm,
  BButton,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { toastification } from '@core/mixins/toast'
import { required } from '@validations'

const { mapGetters, mapActions } = createNamespacedHelpers('partner')

export default {
  components: {
    BModal,
    BForm,
    BButton,
    // component
    BtnLoading,
    ValidationObserver,
    // flatPickr,
  },
  mixins: [toastification],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      // validation
      required,
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  methods: {

    ...mapActions(['rejectPartner']),

    hideModal() {
      this.$refs['reject-partner-modal'].hide()
    },

    showModal() {
      this.$refs['reject-partner-modal'].show()
    },

    submit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          const params = {
            id: this.userData.localUserId,
          }

          await this.rejectPartner(params)
          if (this.status) {
            this.toastSuccess('Reject partner success')
            this.$emit('update-user-selected')
            this.hideModal()
          } else {
            this.toastFailure(this.message)
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
</style>
