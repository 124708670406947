import { PaymentService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async getPaymentTickets({ commit }, { params, cancelToken }) {
    commit('GET_PAYMENT_TICKET_REQUEST')
    try {
      const { data } = await PaymentService.getPaymentTickets(params, cancelToken)
      commit('GET_PAYMENT_TICKET_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_PAYMENT_TICKET_FAILURE', error)
    }
  },

  async approveTicket({ commit }, params) {
    commit('APPROVE_TICKET_STATUS_REQUEST')
    try {
      const { data } = await PaymentService.approveTicket(params)
      commit('APPROVE_TICKET_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('APPROVE_TICKET_STATUS_FAILURE', error)
    }
  },

  async rejectTicket({ commit }, params) {
    commit('REJECT_TICKET_STATUS_REQUEST')
    try {
      const { data } = await PaymentService.rejectTicket(params)
      commit('REJECT_TICKET_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REJECT_TICKET_STATUS_FAILURE', error)
    }
  },

  async commentTicket({ commit }, params) {
    commit('COMMENT_TICKET_STATUS_REQUEST')
    try {
      const { data } = await PaymentService.commentTicket(params)
      commit('COMMENT_TICKET_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('COMMENT_TICKET_STATUS_FAILURE', error)
    }
  },

  async getTransactions({ commit }, { params, cancelToken }) {
    commit('GET_TRANSACTION_REQUEST')
    try {
      const { data } = await PaymentService.getTransactions(params, cancelToken)
      commit('GET_TRANSACTION_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_TRANSACTION_FAILURE', error)
    }
  },

  async getAdsAccountHistories({ commit }, { params, cancelToken }) {
    commit('GET_ADSHISTORIES_REQUEST')
    try {
      const { data } = await PaymentService.getAdsAccountHistories(params, cancelToken)
      commit('GET_ADSHISTORIES_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_ADSHISTORIES_FAILURE', error)
    }
  },

  async getCashbackTransaction({ commit }, params) {
    commit('GET_CASHBACK_TRANSACTION_REQUEST')
    try {
      const { data } = await PaymentService.getCashbackTransaction(params)
      commit('GET_CASHBACK_TRANSACTION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_CASHBACK_TRANSACTION_FAILURE', error)
    }
  },

  async handleCashback({ commit }, params) {
    commit('UPDATE_CASHBACK_REQUEST')
    try {
      await PaymentService.handleCashback(params)
      commit('UPDATE_CASHBACK_SUCCESS')
    } catch (error) {
      commit('UPDATE_CASHBACK_FAILURE', error)
    }
  },

  async getRefundTransaction({ commit }, params) {
    commit('GET_REFUND_TRANSACTION_REQUEST')
    try {
      const { data } = await PaymentService.getRefundTransaction(params)
      commit('GET_REFUND_TRANSACTION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_REFUND_TRANSACTION_FAILURE', error)
    }
  },

  // AUTO REFUND

  async getListRequestAutoRefund({ commit }, { params, cancelToken }) {
    commit('GET_LIST_REQUEST_AUTO_REFUND_REQUEST')
    try {
      const { data } = await PaymentService.getListRequestAutoRefund(params, cancelToken)
      commit('GET_LIST_REQUEST_AUTO_REFUND_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_LIST_REQUEST_AUTO_REFUND_FAILURE', error)
    }
  },

  async AMReviewRequestRefund({ commit }, params) {
    commit('AM_REVIEW_REQUEST_REFUND_REQUEST')
    try {
      const { data } = await PaymentService.AMReviewRequestRefund(params)
      commit('AM_REVIEW_REQUEST_REFUND_SUCCESS', getResponse(data))
    } catch (error) {
      commit('AM_REVIEW_REQUEST_REFUND_FAILURE', error)
    }
  },

  async reviewRequestRefund({ commit }, params) {
    commit('FM_REVIEW_REQUEST_REFUND_REQUEST')
    try {
      const { data } = await PaymentService.reviewRequestRefund(params)
      commit('FM_REVIEW_REQUEST_REFUND_SUCCESS', getResponse(data))
    } catch (error) {
      commit('FM_REVIEW_REQUEST_REFUND_FAILURE', error)
    }
  },

  // END AUTO REFUND
}
