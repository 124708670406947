// eslint-disable-next-line import/prefer-default-export
export const PAYMENT_METHOD = {
  PAYPAL: 'paypal',
  PAYONEER: 'payoneer',
  PAYONEER_TICKET: 'payoneerTicket',
  USDT: 'USDT',
  BUSD: 'BUSD',
  TPBANK: 'TPBank',
  CREDITCARD: 'creditCard',
  AIRWALLEX: 'airWallex',
  AIRWALLEXAUTO: 'airWallexAuto',
  TRANSFERWISE: 'transferWise',
  TAZAPAY: 'tazapay',
  LIANLIAN: 'lianlian',
  LIANLIAN_TICKET: 'lianLianTicket',
  LOCALBANK: 'localBank',
  LOCALBANK_TICKET: 'localBankTicket',
  PAYFAST: 'payfast',
  PINGPONG_TICKET: 'pingPongTicket',
  IDEAL: 'ideal',
  BANCONTACT: 'bancontact',
}

export const CREDIT_CARD_TYPE = {
  electron: 'electron',
  maestro: 'maestro',
  dankort: 'dankort',
  interpayment: 'interpayment',
  unionpay: 'unionpay',
  visa: 'visa',
  mastercard: 'mastercard',
  amex: 'amex',
  diners: 'diners',
  discover: 'discover',
  jcb: 'jcb',
}
