import Vue from 'vue'

// axios
import axios from 'axios'
import auth from './auth'

const baseURL = process.env.VUE_APP_NODE_ENV === 'production' ? window.location.origin : process.env.VUE_APP_SERVER_API
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.getToken()}`,
    timezone: auth.getTimezone(),
  },
})

axiosIns.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = auth.getToken()

    // If token is present add it to request's Authorization Header
    if (accessToken) {
    // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => Promise.reject(error),
)

// throw error when call aixos error
axiosIns.interceptors.response.use(null, error => {
  const token = auth.getToken()
  if (error.response?.status === 401 && !!token) {
    auth.clearLocalStorage()
    window.location.href = '/login'
  }

  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
