import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // login
  LOGIN_REQUEST(state) {
    REQUEST(state)
  },
  LOGIN_SUCCESS(state, user, idToken) {
    SUCCESS(state)
    state.idToken = idToken
    state.isLoggedIn = true
    state.user = user
  },
  LOGIN_FAILURE(state, error) {
    FAILURE(state, error)
    state.isLoggedIn = false
  },

  // register
  REGISTER_REQUEST(state) {
    REQUEST(state)
  },
  REGISTER_SUCCESS(state) {
    SUCCESS(state)
  },
  REGISTER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // activate
  // ACTIVATE_REQUEST(state) {
  //   REQUEST(state)
  // },
  // ACTIVATE_SUCCESS(state) {
  //   SUCCESS(state)
  // },
  // ACTIVATE_FAILURE(state, error) {
  //   FAILURE(state, error)
  // },

  // get account info
  GET_ACCOUNT_INFO_REQUEST(state) {
    REQUEST(state)
  },
  GET_ACCOUNT_INFO_SUCCESS(state, user) {
    SUCCESS(state)
    state.user = user
  },
  GET_ACCOUNT_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // send link to reset
  RESET_INIT_REQUEST(state) {
    REQUEST(state)
  },
  RESET_INIT_SUCCESS(state) {
    SUCCESS(state)
  },
  RESET_INIT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // reset password
  RESET_FINISH_REQUEST(state) {
    REQUEST(state)
  },
  RESET_FINISH_SUCCESS(state) {
    SUCCESS(state)
  },
  RESET_FINISH_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get general configs
  GET_GENERAL_CONFIG_REQUEST(state) {
    REQUEST(state)
  },
  GET_GENERAL_CONFIG_SUCCESS(state, configs) {
    SUCCESS(state)
    state.generalConfigs = configs
  },
  GET_GENERAL_CONFIG_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update account
  // UPDATE_ACCOUNT_REQUEST(state) {
  //   REQUEST(state)
  // },
  // UPDATE_ACCOUNT_SUCCESS(state) {
  //   SUCCESS(state)
  // },
  // UPDATE_ACCOUNT_FAILURE(state, error) {
  //   FAILURE(state, error)
  // },

  // update password
  UPDATE_PASSWORD_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_PASSWORD_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_PASSWORD_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // logout
  LOGOUT(state) {
    state.isLoggedIn = false
    state.idToken = ''
  },

  // login verification
  LOGIN_VERIFICATION_REQUEST(state) {
    REQUEST(state)
  },

  LOGIN_VERIFICATION_SUCCESS(state, data) {
    SUCCESS(state)
    state.verificationCode.expiresIn = data.expiresIn
  },

  LOGIN_VERIFICATION_FAILURE(state, error) {
    FAILURE(state, error)
    state.isLoggedIn = false
  },
}
