<template>
  <b-col
    lg="7"
    class="d-none d-lg-flex align-items-center px-0 banner"
  >
    <div class="w-100 d-lg-flex align-items-center justify-content-center flex-column left-image p-0">
      <b-img
        fluid
        alt="Forgot password"
        class="img"
        :src="src"
      />
      <slot name="register-text-content" />
    </div>
  </b-col>
</template>

<script>

import { BImg, BCol } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCol,
  },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
}
</script>
<style lang="scss" scoped>
  .banner {
    padding: 26px 0 22px 0;
    .left-image {
      height: 100%;
      margin: 0 24px 0 27px;
      border-radius: 32px;
      background-color: rgba(61, 64, 91, 1);
    }
  }
  .img{
    max-width: 476px;
    max-height: 400px;
  }
</style>
