<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-white">

      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12">
        <b-col
          lg="3"
          cols="8"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
        <b-col
          cols="4"
          class="navbar-title px-0 d-flex align-items-center justify-content-end"
        >
          <div class="d-flex justify-content-between">
            <b-link
              class="cover"
              :to="{name:'forgot-password'}"
            >
              <span class="nav-link">
                {{ $t('login.linkForgotPassword') }}
              </span>
            </b-link>
          </div>
        </b-col>
      </b-row>

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="mx-auto max-width form-signup"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-2 mt-3 d-flex justify-content-center"
          >
            {{ $t('register.textWelcome') }}
          </b-card-title>
          <b-card-text class="mb-2 text-center signup-title">
            {{ $t('register.textGuide') }}
          </b-card-text>
          <!-- form -->
          <validation-observer ref="registerValidation">
            <b-form class="auth-register-form mt-2">

              <!-- username -->
              <b-form-group class="my-2">
                <div class="d-flex justify-content-between">
                  <label for="username">
                    {{ $t('common.labelName') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="username"
                  name="Username"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="UserIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="username"
                      id="register-username"
                      name="register-username"
                      class="input-height"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.placeholderName')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label-for="register-email"
              >
                <div class="d-flex justify-content-between">
                  <label for="email">
                    {{ $t('common.labelEmail') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="email"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.trim="userEmail"
                      id="register-email"
                      name="register-email"
                      class="input-height"
                      :placeholder="$t('common.placeholderEmail')"
                      :state="errors.length > 0 ? false:null"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- reference -->
              <b-form-group class="my-2">
                <div class="d-flex justify-content-between">
                  <label for="reference">
                    {{ $t('common.labelReferralCode') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="refCode"
                  name="refCode"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="TrendingUpIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="refCode"
                      id="register-refCode"
                      name="register-refCode"
                      class="input-height"
                      :placeholder="$t('common.placeholderReferralCode')"
                      disabled
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
              >
                <div class="d-flex justify-content-between">
                  <label for="password">
                    {{ $t('common.labelPassword') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="password"
                  name="Password"
                  rules="required|password"
                >

                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend
                      is-text
                    >
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.trim="password"
                      id="register-password"
                      class="form-control-merge input-height"
                      name="register-password"
                      :placeholder="$t('common.placeholderNewPassword')"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Password confirm  -->
              <b-form-group class="my-2">
                <div class="d-flex justify-content-between">
                  <label for="passwordConfirm">
                    {{ $t('common.labelRepeatPassword') }}
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="passwordConfirm"
                  name="Password Confirm"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-input-group-prepend is-text>
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="passwordConfirm"
                      id="register-password-confirm"
                      name="register-password-confirm"
                      class="form-control-merge input-height"
                      :type="password2FieldType"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('common.placeholderRepeatNewPassword')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="password2ToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <btn-loading
                class="mt-3 mb-4 btn-submit input-height"
                variant="primary"
                block
                type="submit"
                :loading="loading"
                @click.prevent="validationForm"
              >
                {{ $t('register.btnSignUp') }}
              </btn-loading>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <p class="text-center mt-2">
              <span>{{ $t('register.textAlreadyAccount') }}</span>
              <b-link :to="{name:'login'}">
                <span>&nbsp;{{ $t('register.linkLogin') }}</span>
              </b-link>
            </p>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Register-->

      <!-- Left Text-->
      <left-image :src="imgUrl">
        <template #register-text-content>
          <div
            class="sign-up-text"
          >
            <ul class="text-left description mb-2 p-0">
              {{ $t('register.textBenefits') }}
            </ul>
            <b-row class="d-flex">
              <b-col cols="6">
                <ul class="p-0">
                  <li
                    v-for="(benefit, index) in benefits.slice(0, 4)"
                    :key="index"
                    class="text-description"
                  >
                    <ul class="p-0 d-flex align-items-baseline">
                      <b-img
                        class="icons-tick"
                        fluid
                        :src="require('@/assets/images/icons/icon-tick.svg')"
                        alt="guide"
                      />
                      <li class="title">
                        {{ benefit.title }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </b-col>
              <b-col cols="6">
                <ul class="p-0">
                  <li
                    v-for="(benefit, index) in benefits.slice(4, 8)"
                    :key="index"
                    class="text-description"
                  >
                    <ul class="p-0 d-flex align-items-baseline">
                      <b-img
                        class="icons-tick"
                        fluid
                        :src="require('@/assets/images/icons/icon-tick.svg')"
                        alt="guide"
                      />
                      <li class="title">
                        {{ benefit.title }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>
        </template>
      </left-image>
      <!-- /Left Text-->

    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  // BIconGoogle, BButton,
  BImg, BRow, BCol, BLink, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email, checked } from '@validations'
import { toastification } from '@core/mixins/toast'
import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  titleTemplate: '%s ← Ecomdy Media',
  // Define meta tags here.
  metaInfo: {
    title: 'Registration',
    meta: [
      { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'description', content: 'Advertisting management by Ecomdy Media' },

      // OpenGraph data (Most widely used)
      { property: 'og:title', content: 'Advertising Management ← Ecomdy Media' },
      { property: 'og:site_name', content: 'Ecomdy Media' },
      // The list of types is available here: http://ogp.me/#types
      { property: 'og:type', content: 'website' },
      // Should the the same as your canonical link, see below.
      { property: 'og:url', content: `${process.env.VUE_STOREFRONT_URL}/register` },
      { property: 'og:image', content: `${process.env.VUE_STOREFRONT_URL}/share_rect.png` },
      // Often the same as your meta description, but not always.
      { property: 'og:description', content: 'I have things here on Ecomdy Media.' },

      // Twitter card
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: `${process.env.VUE_STOREFRONT_URL}/register` },
      { name: 'twitter:title', content: 'Advertising Management ← Ecomdy Media' },
      { name: 'twitter:description', content: 'I have things here on Ecomdy Media.' },
      // Your twitter handle, if you have one.
      { name: 'twitter:creator', content: '@ecomdymedia' },
      { name: 'twitter:image:src', content: `${process.env.VUE_STOREFRONT_URL}/share_square.png` },

      // Google / Schema.org markup:
      { itemprop: 'name', content: 'Advertising Management ← Ecomdy Media' },
      { itemprop: 'description', content: 'I have things here on Ecomdy Media.' },
      { itemprop: 'image', content: `${process.env.VUE_STOREFRONT_URL}/share_square.png` },
    ],
  },

  components: {
    // BIconGoogle,
    BRow,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BImg,

    // components
    BtnLoading,
    BrandLogo,
    LeftImage,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification],

  data() {
    return {
      term: false,
      username: '',
      userEmail: '',
      password: '',
      passwordConfirm: '',
      refCode: this.$route.query.refcode || '',
      imgUrl: require('@/assets/images/pages/auth/signup-illustrator.svg'),
      benefits: [
        { title: this.$t('register.textGetGlobal') },
        { title: this.$t('register.textFreeDayService') },
        { title: this.$t('register.textUnlimitedSpending') },
        { title: this.$t('register.textGlobalDeposit') },
        { title: this.$t('register.textEarnMoney') },
        { title: this.$t('register.textJoinWithCommunity') },
        { title: this.$t('register.textLiveChat') },
        { title: this.$t('register.textSupport') },
      ],

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',

      // validation
      required,
      email,
      checked,
    }
  },

  setup() {
    // App Name
    const { appName } = $themeConfig.app
    return {
      appName,
    }
  },

  computed: {
    ...mapGetters({
      status: 'auth/status',
      error: 'auth/error',
      loading: 'auth/loading',
      message: 'auth/message',
    }),

    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  mounted() {
    if (this.refCode) {
      const params = {
        refCode: this.refCode,
      }
      this.$store.dispatch('referral/track', { params })
    }
  },

  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },

    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },

    async validationForm() {
      const success = await this.$refs.registerValidation.validate()
      if (success) {
        const params = {
          fullName: this.username,
          email: this.userEmail,
          password: this.password,
          refCode: this.refCode,
        }

        await this.$store.dispatch('auth/register', { params })

        if (this.status) {
          this.toastSuccess(this.$t('register.textRegisterSuccess'))
          this.$router.push({ name: 'login' })
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .max-width{
    max-width: 600px;
  }
</style>
<style lang="scss" scoped>
  .form-signup {
    padding: 0 2.2rem;
  }
  .sign-up-text {
    padding: 0 75px;
    .description {
      color: #fff;
      font-size: 20px;
      line-height: 30px;
      font-weight: 800;
    }
  }
  .text-description {
    list-style-type: none;
    .icons-tick {
      position: relative;
      top: 5px;
    }
    .title {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      list-style-type: none;
      text-align: left;
      margin-bottom: 1.375rem;
      margin-left: 1rem;
    }
  }
  .input-height {
    height: 50px;
  }
  @media (max-width: 767px) {
    .form-signup {
      top: 3rem;
      padding-left: 0;
      padding-right: 0;
    }
    .navbar-auth-custom {
      width: calc(100%);
    }
    .btn-submit {
      margin-bottom: 2rem !important;
    }
  }
</style>
