import axios from '@axios'

export default {
  getListPackage(params) {
    return axios.get('/api/admin/partnership/packages', { params })
  },

  updateAPackage(params) {
    return axios.patch(`/api/admin/partnership/packages/${params.id}/price/${params.priceId}`, params)
  },
}
