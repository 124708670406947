import { PartnerService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async createPartner({ commit }, params) {
    commit('CREATE_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.createPartner(params)
      commit('CREATE_PARTNER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CREATE_PARTNER_FAILURE', error)
    }
  },

  async getListPartner({ commit }, { params, cancelToken }) {
    commit('GET_LIST_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.getListPartner(params, cancelToken)
      commit('GET_LIST_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_LIST_PARTNER_FAILURE', error)
    }
  },

  async getTotalMemberPartner({ commit }, params) {
    commit('GET_TOTAL_MEMBER_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.getTotalMemberPartner(params)
      commit('GET_TOTAL_MEMBER_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_TOTAL_MEMBER_PARTNER_FAILURE', error)
    }
  },

  async getListMemberPartner({ commit }, params) {
    commit('GET_MEMBER_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.getListMemberPartner(params)
      commit('GET_MEMBER_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_MEMBER_PARTNER_FAILURE', error)
    }
  },

  async rejectPartner({ commit }, params) {
    commit('REJECT_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.rejectPartner(params)
      commit('REJECT_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('REJECT_PARTNER_FAILURE', error)
    }
  },

  async updateRolePartner({ commit }, params) {
    commit('UPDATE_ROLE_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.updateRolePartner(params)
      commit('UPDATE_ROLE_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('UPDATE_ROLE_PARTNER_FAILURE', error)
    }
  },

  async deleteRolePartner({ commit }, params) {
    commit('DELETE_ROLE_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.deleteRolePartner(params)
      commit('DELETE_ROLE_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('DELETE_ROLE_PARTNER_FAILURE', error)
    }
  },

  async upgradeToPartner({ commit }, params) {
    commit('UPGRADE_TO_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.upgradeToPartner(params)
      commit('UPGRADE_TO_PARTNER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPGRADE_TO_PARTNER_FAILURE', error)
    }
  },

  async assignToAnotherPartner({ commit }, params) {
    commit('ASSIGN_TO_ANOTHER_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.assignToAnotherPartner(params)
      commit('ASSIGN_TO_ANOTHER_PARTNER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ASSIGN_TO_ANOTHER_PARTNER_FAILURE', error)
    }
  },

  async getSpendingDepositPartnerDetail({ commit }, params) {
    commit('GET_SPENDING_DEPOSIT_PARTNER_DETAIL_REQUEST')
    try {
      const { data } = await PartnerService.getSpendingDepositPartnerDetail(params)
      commit('GET_SPENDING_DEPOSIT_PARTNER_DETAIL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_SPENDING_DEPOSIT_PARTNER_DETAIL_FAILURE', error)
    }
  },

  async getTotalMemberLifeTimePartnerDetail({ commit }, params) {
    commit('GET_TOTAL_MEMBER_LIFE_TIME_PARTNER_DETAIL_REQUEST')
    try {
      const { data } = await PartnerService.getTotalMemberMonthPartnerDetail(params)
      commit('GET_TOTAL_MEMBER_LIFE_TIME_PARTNER_DETAIL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_TOTAL_MEMBER_LIFE_TIME_PARTNER_DETAIL_FAILURE', error)
    }
  },

  async getTotalMemberMonthPartnerDetail({ commit }, params) {
    commit('GET_TOTAL_MEMBER_MONTH_PARTNER_DETAIL_REQUEST')
    try {
      const { data } = await PartnerService.getTotalMemberMonthPartnerDetail(params)
      commit('GET_TOTAL_MEMBER_MONTH_PARTNER_DETAIL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_TOTAL_MEMBER_MONTH_PARTNER_DETAIL_FAILURE', error)
    }
  },

  async getTotalAdsAccountMonthPartnerDetail({ commit }, params) {
    commit('GET_TOTAL_ADS_ACCOUNT_MONTH_PARTNER_DETAIL_REQUEST')
    try {
      const { data } = await PartnerService.getTotalAdsAccountMonthPartnerDetail(params)
      commit('GET_TOTAL_ADS_ACCOUNT_MONTH_PARTNER_DETAIL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_TOTAL_ADS_ACCOUNT_MONTH_PARTNER_DETAIL_FAILURE', error)
    }
  },

  async getAdsAccountsPartner({ commit }, params) {
    commit('GET_ADS_ACCOUNTS_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.getAdsAccountsPartner(params)
      commit('GET_ADS_ACCOUNTS_PARTNER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ADS_ACCOUNTS_PARTNER_FAILURE', error)
    }
  },

  async removeMember({ commit }, params) {
    commit('REMOVE_MEMBER_OF_PARTNERSHIP_REQUEST')
    try {
      const { data } = await PartnerService.removeMember(params)
      commit('REMOVE_MEMBER_OF_PARTNERSHIP_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REMOVE_MEMBER_OF_PARTNERSHIP_FAILURE', error)
    }
  },

  async getListAdAccountUser({ commit }, params) {
    commit('GET_LIST_AD_ACCOUNT_USER_REQUEST')
    try {
      const { data } = await PartnerService.getListAdAccountUser(params)
      commit('GET_LIST_AD_ACCOUNT_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_LIST_AD_ACCOUNT_USER_FAILURE', error)
    }
  },

  async changeStatusFormWhitelistPartner({ commit }, params) {
    commit('CHANGE_STATUS_FORM_WHITELIST_PARTNER_REQUEST')
    try {
      const { data } = await PartnerService.changeStatusFormWhitelistPartner(params)
      commit('CHANGE_STATUS_FORM_WHITELIST_PARTNER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CHANGE_STATUS_FORM_WHITELIST_PARTNER_FAILURE', error)
    }
  },
}
