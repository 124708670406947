<template>
  <div class="card-plan">
    <b-row>
      <b-col class="mt-1">
        <b-card
          v-if="hasSubscription"
          class="plan-subscription"
        >
          <b-card-title class="group-input">
            Plan subscription
          </b-card-title>

          <div class="form-information">
            <b-row>
              <b-col>
                <b-form>
                  <b-row>
                    <!-- User ID -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <!-- Plan name -->
                      <b-form-group>
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="postal-code"
                            >
                              Plan name
                            </label>
                          </div>
                          <v-select
                            v-model="planName"
                            placeholder="Select your answer"
                            class="currency input-height"
                            disabled
                            :clearable="false"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon
                                  icon="ChevronDownIcon"
                                  size="20"
                                />
                              </span>
                            </template>
                          </v-select>
                        </div>
                      </b-form-group>
                      <!-- Plan name -->
                    </b-col>
                    <!-- User ID -->

                    <!-- Package for member -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group>
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="postal-code"
                            >
                              Package for member
                            </label>
                          </div>
                          <v-select
                            v-model="packetForMember"
                            placeholder="Select your answer"
                            class="currency input-height"
                            disabled
                            :clearable="false"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon
                                  icon="ChevronDownIcon"
                                  size="20"
                                />
                              </span>
                            </template>
                          </v-select>
                        </div>
                      </b-form-group>
                      <!-- Plan name -->
                    </b-col>
                    <!-- Package for member -->
                  </b-row>

                  <b-row>
                    <!-- Create At -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="subscriptionStatus"
                            >
                              Subscription Status
                            </label>
                          </div>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              v-model="subscriptionStatus"
                              id="subscriptionStatus"
                              name="subscriptionStatus"
                              class="input-height"
                              placeholder="Subscription status"
                              disabled
                            />
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- Create At -->

                    <!-- Join date -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="Join-date"
                          >
                            Join date
                          </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="joinDate"
                            id="Join-date"
                            name="Join-date"
                            class="input-height"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Join date -->
                  </b-row>

                  <b-row>
                    <!-- Admin review -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="admin-review"
                          >
                            Admin review
                          </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="adminReview"
                            id="admin-review"
                            name="admin-review"
                            class="input-height"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Admin review -->
                    <!-- Cancel reason -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="cancel-reason"
                          >
                            Cancel reason
                          </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="cancelReason"
                            id="cancel-reason"
                            name="cancel-reason"
                            class="input-height"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Cancel reason -->
                  </b-row>
                  <b-row>
                    <!-- Admin change plan -->
                    <!-- <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="admin-change-plan"
                          >
                            Admin change plan
                          </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="adminChangePlan"
                            id="admin-change-plan"
                            name="admin-change-plan"
                            class="input-height"
                            disabled
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col> -->
                    <!-- Admin change plan -->
                  </b-row>

                  <div
                    v-if="hasPartnership"
                    class="btn-control"
                  >
                    <b-button
                      class="
                          btn-submit btn-register
                          input-height
                          font-medium
                          txt-color
                          btn-continue
                        "
                      variant="primary"
                      block
                      type="submit"
                      @click.prevent="onChangePlan"
                    >
                      Edit
                    </b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <b-card class="plan-subscription">
          <b-card-title class="group-input">
            Service fees
          </b-card-title>

          <div class="form-information">
            <b-row>
              <b-col>
                <b-form>
                  <b-row>
                    <!-- Payoneer -->
                    <b-col
                      v-for="payment in listTransferFee"
                      :key="payment.key"
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="text-capitalize"
                              :for="keyPayment(payment.key)"
                            >
                              {{ namePaymentConvert(keyPayment(payment.key)) }}
                            </label>
                          </div>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              :id="keyPayment(payment.key)"
                              :name="keyPayment(payment.key)"
                              class="input-height"
                              placeholder="Subscription status"
                              disabled
                              :value="`${payment.value} %`"
                            />
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- Payoneer -->
                    <!-- Create At -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="subscriptionStatus"
                            >
                              TAX
                            </label>
                          </div>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="subscriptionStatus"
                              name="subscriptionStatus"
                              class="input-height"
                              placeholder="Subscription status"
                              disabled
                              :value="`${tax} %`"
                            />
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- Create At -->
                  </b-row>
                  <div class="btn-control">
                    <b-button
                      class="
                          btn-submit btn-register
                          input-height
                          font-medium
                          txt-color
                          btn-continue
                        "
                      variant="primary"
                      block
                      type="submit"
                      @click.prevent="openSideBar"
                    >
                      Edit
                    </b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </b-card>

        <b-card
          v-if="hasCreditCard"
          class="plan-subscription"
        >
          <b-card-title class="group-input">
            Available credit card
          </b-card-title>

          <div class="btn-load-data mb-1">
            <btn-loading
              class="btn-block-user mr-1"
              variant="flat-dark"
              size="sm"
              :loading="isLoading"
              @click.prevent="loadAvailableCreditCard"
            >
              <feather-icon
                size="20"
                icon="RefreshCwIcon"
                class="cursor-pointer icon-btn"
              />
              Load data
            </btn-loading>
          </div>

          <div v-if="isNotFound">
            <div class="text-center my-2">
              <b-img
                :src="
                  require('@/assets/images/common/no-credit-card-found.png')
                "
                alt="image"
                class="img-not-found mb-1"
              />
              <p>No credit card found!</p>
            </div>
          </div>
          <div
            v-else
            class="payment-method"
          >
            <div
              v-for="(card, index) in listCreditCard"
              :key="index"
              class="item-payment"
            >
              <div class="name-method">
                <b-img
                  class="icon-method"
                  :src="getCreditCardLogo(card.brand)"
                />
                <strong
                  v-if="!$device.mobile"
                  class="font-14 text-primary"
                >
                  {{ getCreditCardName(card.brand) }}
                </strong>
              </div>
              <div class="number-card">
                {{
                  card.last4
                    ? `**** **** **** ${card.last4}`
                    : '**** **** **** ****'
                }}
              </div>
              <div
                class="status-card"
                :class="card.verifiedAt ? 'active' : ''"
              >
                <p>{{ card.verifiedAt ? 'Active' : 'Pending' }}</p>
              </div>
            </div>
          </div>
        </b-card>

        <b-card class="plan-subscription mb-0">
          <b-card-title class="group-input">
            Balance change
          </b-card-title>

          <div class="form-information">
            <b-row>
              <b-col>
                <b-form>
                  <b-row>
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="text-capitalize"
                              for="list-ads-account"
                            >
                              List Ads Account
                            </label>
                          </div>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="list-ads-account"
                              name="list-ads-account"
                              class="input-height"
                              placeholder="Subscription status"
                              disabled
                              :value="listAdsAccounts"
                            />
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="text-capitalize"
                              for="amount-balance"
                            >
                              Amount balance
                            </label>
                          </div>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="amount-balance"
                              name="amount-balance"
                              class="input-height"
                              placeholder="Subscription status"
                              disabled
                              :value="`${currencyFormat(userBalance)}`"
                            />
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="btn-control">
                    <b-button
                      class="
                          btn-submit btn-register
                          input-height
                          font-medium
                          txt-color
                          btn-continue
                        "
                      variant="primary"
                      block
                      type="submit"
                      @click.prevent="showBalanceSideBar = true"
                    >
                      Balance change
                    </b-button>
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <!--Edit user side bar-->
    <edit-user-side-bar
      :show-side-bar.sync="showSideBar"
      :user-data="userDetail"
      @update-user-selected="updateUserSelected"
    />

    <!--Change plan side bar-->
    <change-plan-side-bar
      :show-side-bar.sync="changePlanSidebar"
      :user-data="userDetail"
      @update-user-selected="updateUserSelected"
    />

    <!-- Balance sidebar -->
    <partner-balance-side-bar
      :show-side-bar.sync="showBalanceSideBar"
      :user-data="userDetail"
      @update-user-selected="updateUserSelected"
    />
  </div>
</template>

<script>
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import {
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BButton,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
import pick from 'lodash/pick'
import { UserService } from '@/services'
import envMixin from '@/mixins/envMixin'
import { PAYMENT_METHOD } from '@/constants'
import ChangePlanSideBar from './ChangePlanSideBar.vue'
import EditUserSideBar from './EditUserSideBar.vue'
import PartnerBalanceSideBar from './PartnerBalanceSideBar.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('user')

export default {
  components: {
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    vSelect,
    BButton,
    BImg,
    BtnLoading,
    ChangePlanSideBar,
    EditUserSideBar,
    PartnerBalanceSideBar,

    // validation
    // ValidationProvider,
    // ValidationObserver,
  },

  mixins: [numberFormatMixin, toastification, paymentMixin, envMixin],

  // props: {
  //   userData: {
  //     type: Object,
  //     default: () => ({}),
  //     required: true,
  //   },
  // },

  data() {
    return {
      isNotFound: false,
      listCreditCard: [],
      showSideBar: false,
      isUpdateUser: false,
      changePlanSidebar: false,
      showBalanceSideBar: false,
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters(['userDetail', 'userAdsAccounts', 'status', 'loading']),

    keyPayment() {
      return data => (data === PAYMENT_METHOD.TRANSFERWISE ? 'Wise' : data)
    },

    planName() {
      return this.userDetail.plans
        ? this.capitalise(this.userDetail.plans.type)
        : 'N/A'
    },

    packetForMember() {
      return this.userDetail?.partnership?.packageIdForMember?.name
    },

    subscriptionStatus() {
      return this.userDetail.plans
        ? this.capitalise(this.userDetail.plans.subscription.status)
        : 'N/A'
    },

    joinDate() {
      return this.userDetail.plans && this.userDetail.plans.createdAt
        ? this.getFormatDate(
          this.userDetail.plans && this.userDetail.plans.createdAt,
        )
        : 'N/A'
    },

    adminChangePlan() {
      return this.userDetail.plans && this.userDetail.plans.updatedPlanBy
        ? this.userDetail.plans.updatedPlanBy
        : 'N/A'
    },

    adminReview() {
      return this.userDetail.plans && this.userDetail.plans.reviewedBy
        ? this.userDetail.plans.reviewedBy
        : 'N/A'
    },

    listAdsAccounts() {
      return this.userDetail?.adsAccounts?.length >= 0 ? this.userDetail?.adsAccounts?.length : 'N/A'
    },

    cancelReason() {
      return this.userDetail?.plans?.subscription?.failedChargeReason || 'null'
    },

    listTransferFee() {
      const { plans, transferFee } = this.userDetail
      if (plans?.transferFee) {
        return Object.entries(
          pick(plans.transferFee, this.listPaymentMethodGeneral),
        ).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
          isShow: this.defaultTransferFee.find(item => item.key === key)?.isShow,
        })).filter(item => item.isShow)
      }

      if (transferFee) {
        return Object.entries(
          pick(transferFee, this.listPaymentMethodGeneral),
        ).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
          isShow: this.defaultTransferFee.find(item => item.key === key)?.isShow,
        })).filter(item => item.isShow)
      }

      return this.defaultTransferFee.filter(item => item.isShow)
    },

    tax() {
      let tax = 0
      const { plans, transferFee } = this.userDetail
      if (
        plans?.transferFee?.tax !== undefined
        && plans?.transferFee?.tax !== null
      ) {
        tax = plans.transferFee.tax
      } else if (transferFee?.tax !== undefined && transferFee?.tax !== null) {
        tax = transferFee.tax
      }

      return parseFloat((tax * 100).toLocaleString('en-US'))
    },

    userBalance() {
      const amount = _get(this.userDetail, ['balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },
  },

  watch: {
    isUpdateUser(value) {
      if (value) {
        const { params } = this.$route
        this.fetchUserInfo(params)
        this.isUpdateUser = false
      }
    },
  },

  methods: {
    ...mapActions([
      'getUser',
      'getAdsAccountsUser',
      'activeUser',
      'getAvailableCreditCard',
    ]),

    openSideBar() {
      this.showSideBar = true
    },

    async fetchUserInfo(params) {
      await this.getUser(params)
      await this.getAdsAccountsUser(params)
    },

    capitalise(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    updateUserSelected() {
      this.isUpdateUser = true
    },

    onChangePlan() {
      this.changePlanSidebar = true
    },

    async loadAvailableCreditCard() {
      const { params } = this.$route
      this.isLoading = true
      try {
        await UserService.getAvailableCreditCard(params).then(response => {
          if (response.data.result.length > 0) {
            this.listCreditCard = response.data.result
          } else {
            this.isNotFound = true
          }
          this.isLoading = false
        })
      } catch (e) {
        this.toastFailure(e.data.message)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.img-not-found {
  width: 70px;
}

.text-primary {
  color: #3d405b !important;
}

.plan-subscription {
  background: #ffffff;
  padding: 24px;
  margin-bottom: 20px;

  .card-body {
    padding: 0;

    .card-title {
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.btn-control {
  display: flex;
  justify-content: end;
  align-items: center;

  .btn-continue {
    width: max-content;
    font-size: 14px;
    margin: 0 6px;
  }
}

.group-input {
  margin-bottom: 26px;
}

.btn-block-user {
  padding: 10px 18.4px;
  border: 1px solid #e0e0e5;
  border-radius: 6px;
  transform: rotate(0.01deg);
  display: flex;
  align-items: center;

  .icon-btn {
    margin-right: 0.5rem;
  }
}

.payment-method {
  .item-payment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eeeeee;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 12px;

    p {
      margin: 0;
      color: rgba(130, 130, 135, 1);
      font-weight: 600;
    }

    .name-method {
      display: flex;
      align-items: center;

      .icon-method {
        margin-right: 10px;
      }

      p {
        color: rgba(61, 64, 91, 1);
      }
    }

    .status-card {
      background: #faf2e6;
      padding: 8px 24.5px;
      border-radius: 100px;
      p {
        color: rgba(250, 179, 69, 1);
        text-transform: capitalize;
        font-weight: 700;
      }
    }

    .active {
      background: #effae6;

      p {
        color: #7ab34c;
      }
    }
  }
}
</style>
