<template>

  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template
        #title
      >
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />

        <span class="font-weight-bold">{{ $t('profile.textGeneral') }}</span>
      </template>

      <b-card><general /></b-card>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('profile.textChangePassword') }}</span>
      </template>

      <b-card><change-password /></b-card>
    </b-tab>
    <!--/ change password tab -->

  </b-tabs>
</template>
<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import ChangePassword from './components/ChangePassword.vue'
import General from './components/General.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,

    // components
    General,
    ChangePassword,
  },
}
</script>
