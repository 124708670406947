<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Edit user profile"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2">
      <validation-observer ref="formValidation">
        <b-form>
          <b-form-group
            label="List service fees and Tax"
            class="form-label mb-2"
          >
            <validation-provider
              v-for="payment in listTransferFee"
              :key="payment.key"
              #default="{ errors }"
              :name="payment.name + ' fee'"
              rules="required"
              class="mb-2 d-flex justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center"
              >
                <img
                  :src="getBrandIcon(payment.key)"
                  width="20px"
                  alt="payment"
                  style="margin-right: 5px"
                >
                <div class="d-flex flex-column">
                  <span class="font-14 text-capitalize">
                    {{ namePaymentConvert(keyPayment(payment.key)) }}
                  </span>
                  <small
                    :data-testid="`error-edit-${keyPayment(payment.key)}`"
                    class="text-danger"
                  >{{ errors[0] }}
                  </small>
                </div>
              </div>
              <b-input-group
                style="max-width: 110px"
                class="text-right input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model.number="payment.value"
                  type="number"
                  :data-testid="`input-${keyPayment(payment.key)}`"
                  :state="errors.length > 0 ? false:null"
                  :disabled="isInTrial"
                />
                <b-input-group-append
                  is-text
                >
                  %
                </b-input-group-append>
              </b-input-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="tax"
              rules="required|valTaxFee"
              class="mb-2 d-flex justify-content-between w-100"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <span class="font-14 font-weight-bold">TAX</span>
                  <small
                    data-testid="error-edit-tax"
                    class="text-danger"
                  >{{ errors[0] }}
                  </small>
                </div>
              </div>
              <b-input-group
                style="max-width: 110px"
                class="text-right input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model.number="tax"
                  type="number"
                  data-testid="input-tax"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append
                  is-text
                >
                  %
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </b-form-group>

          <div class="mt-1">
            <btn-loading
              type="submit"
              variant="primary"
              data-testid="btn-update-service-fees"
              block
              :loading="loading"
              @click.prevent="submit"
            >
              Update
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>

import {
  BSidebar,
  // BRow,
  // BCol,
  // BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  // BButton,
  BInputGroup,
  // BInputGroupPrepend,
  BInputGroupAppend,
  // BFormTextarea,
} from 'bootstrap-vue'

import pick from 'lodash/pick'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { required, valTaxFee } from '@validations'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { PAYMENT_METHOD } from '@/constants'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    // BFormSelect,
    BInputGroup,
    BFormInput,
    // BInputGroupPrepend,
    BInputGroupAppend,
    // BFormCheckbox,
    // BFormTextarea,
    // component
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, numberFormatMixin, paymentMixin, envMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      show: false,
      tax: 0,
      // validation
      required,
      valTaxFee,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),

    keyPayment() {
      return data => (data === PAYMENT_METHOD.TRANSFERWISE ? 'Wise' : data)
    },

    isInTrial() {
      return this.userData?.isInTrial
    },

    listTransferFee() {
      const { plans, transferFee } = this.userData

      if (plans?.transferFee) {
        return Object.entries(
          pick(plans.transferFee, this.listPaymentMethodGeneral),
        ).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
          name: this.defaultTransferFee.find(item => item.key === key)?.name,
          isShow: this.defaultTransferFee.find(item => item.key === key)?.isShow,
        })).filter(item => item.isShow)
      }

      if (transferFee) {
        return Object.entries(
          pick(transferFee, this.listPaymentMethodGeneral),
        ).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
          name: this.defaultTransferFee.find(item => item.key === key)?.name,
          isShow: this.defaultTransferFee.find(item => item.key === key)?.isShow,
        })).filter(item => item.isShow)
      }

      return this.defaultTransferFee.filter(item => item.isShow)
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },

    userData: {
      deep: true,
      handler(value) {
        let tax = 0
        const { plans, transferFee } = value
        if (plans?.transferFee?.tax !== undefined && plans?.transferFee?.tax !== null) {
          tax = plans.transferFee.tax
        } else if (transferFee?.tax !== undefined && transferFee?.tax !== null) {
          tax = transferFee.tax
        }

        this.tax = parseFloat((tax * 100).toLocaleString('en-US'))
      },
    },
  },

  methods: {
    ...mapActions(['updateUser', 'updateBalanceUser']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
    },

    arrToObj(arr) {
      const result = {}
      for (let i = 0; i < arr.length; i += 1) {
        result[arr[i].key] = parseFloat((arr[i].value / 100).toLocaleString('en-US'))
      }

      return result
    },

    async submit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          const taxConverted = parseFloat((this.tax / 100).toLocaleString('en-US'))
          // eslint-disable-next-line no-return-assign
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.userData.localUserId,
            transferFee: { ...this.arrToObj(this.listTransferFee), tax: taxConverted },
          }
          await this.updateUser(params)
          if (this.status) {
            this.toastSuccess('Update user success!')
            this.$emit('update-user-selected')
            this.onHidden()
          } else {
            this.toastFailure(this.message)
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep button.close {
    display: block;
    margin-left: auto;
    margin-right: 0;
    font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
    flex-direction: row-reverse;
    strong {
        flex: 1;
    }
}
</style>
