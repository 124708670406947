<template>
  <div class="tabs-mf mt-1">
    <b-tabs>
      <b-tab v-if="hasRefundTransaction">
        <template #title>
          <span class="tab-title">Refund</span>
        </template>
        <refund-transaction />
      </b-tab>
      <b-tab v-if="hasCashbackTransaction">
        <template #title>
          <span class="tab-title">Cashback</span>
        </template>
        <cashback-transaction />
      </b-tab>
      <b-tab v-if="!isSMBAgencyPlatform && !isTakiPlatform">
        <template #title>
          <span
            data-testid="tab-title-ticket-history"
            class="tab-title"
          >
            Ticket History
          </span>
        </template>

        <user-transaction />
      </b-tab>
      <b-tab>
        <template #title>
          <span
            data-testid="title-tab-balance-history"
            class="tab-title"
          >Balance History</span>
        </template>
        <balance-history />
      </b-tab>
      <b-tab>
        <template #title>
          <span class="tab-title">Ads Account History</span>
        </template>
        <ads-account-history />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import envMixin from '@/mixins/envMixin'
import CashbackTransaction from '@/views/payments/components/CashbackTransaction.vue'
import RefundTransaction from '@/views/payments/components/RefundTransaction.vue'
import UserTransaction from './components/UserTransaction.vue'
import BalanceHistory from './components/BalanceHistory.vue'
import AdsAccountHistory from './components/AdsAccountHistory.vue'

export default {
  components: {
    RefundTransaction,
    UserTransaction,
    BalanceHistory,
    AdsAccountHistory,
    CashbackTransaction,
    BTabs,
    BTab,

    // components
  },
  mixins: [envMixin],

  data() {
    return {
    }
  },
}
</script>
<style lang="scss">
  .max-width-card-form{
    max-width: 600px;
  }

</style>
