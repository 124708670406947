<template>
  <b-modal
    ref="export-data-modal"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
    @hidden="hideModal"
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary">
        Export data
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class=" p-1">
      <b-card-text class="mb-1">
        Choose the spend ad date which you want to export data?
      </b-card-text>
      <b-row>
        <b-col>

          <b-form-group>
            <label>Created At</label>

            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Select date"
                :config="configDate"
                @on-close="handleDateChange"
              />
              <b-input-group-append
                v-if="startDate && endDate"
                is-text
                class="close-icon"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearDate"
                />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="CalendarIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
      <btn-loading
        class="btn-text-color ml-1"
        type="submit"
        variant="primary"
        :loading="loadingExport"
        :disabled="!startDate && !endDate"
        @click="exportExcel"
      >
        Confirm
      </btn-loading>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
import {
  BModal,
  BButton,
  BCardText,
  BFormGroup,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
} from 'bootstrap-vue'
import axios from '@axios'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import { toastification } from '@core/mixins/toast'

export default {
  components: {
    BModal,
    BButton,
    BtnLoading,
    BCardText,
    BFormGroup,
    BRow,
    BCol,
    flatPickr,
    BInputGroup,
    BInputGroupAppend,
  },

  mixins: [toastification],

  props: {
    paramsFilter: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      monthSelected: new Date().getMonth() + 1,
      yearSelected: new Date().getFullYear(),
      loadingExport: false,

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },
    }
  },

  methods: {
    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.startDate = moment(new Date(selectedDates[0])).format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
    },

    hideModal() {
      this.clearDate()
      this.$refs['export-data-modal'].hide()
    },

    showModal() {
      this.$refs['export-data-modal'].show()
    },

    async exportExcel() {
      this.loadingExport = true
      try {
        const params = {
          ...this.paramsFilter,
          ...(this.startDate && { startDate: this.startDate }),
          ...(this.endDate && { endDate: this.endDate }),
        }

        const { data } = await axios.get(
          '/api/admin/users/partnership/export',
          {
            responseType: 'blob',
            params,
          },
        )
        const url = URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `list-partner-${this.monthSelected}/${this.yearSelected}.xlsx`,
        )
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) // Clean up after download
        this.hideModal()
      } catch (error) {
        const errorMessage = await new Response(error.data).json() || 'Server Error'
        this.toastFailure(errorMessage.message)
        this.hideModal()
      } finally {
        this.loadingExport = false
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.reason-reject {
  max-width: max-content;
  width: 100%;
}

.input-height {
  height: 40px;
  width: 100%;
}
</style>
