export default [
  {
    path: '/ads-account',
    name: 'ads-account',
    component: () => import('@/views/ads-account/AdsAccountTickets.vue'),
    meta: {
      pageTitle: 'Ads Account Tickets',
      breadcrumb: [
        {
          text: 'Listing tickets',
          active: true,
        },
      ],
      requireAuth: true,
    },
  },
]
