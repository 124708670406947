import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import partnerPermission from '@/store/partner-permision'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import notification from './notification'
import auth from './auth'
import stats from './stats'
import adsAccount from './ads-account'
import payment from './payment'
import user from './user'
import myTask from './my-task'
import subscription from './subscription'
import home from './home'
import productLinks from './product-links'
import kyc from './kyc'
import giftCode from './gift-code'
import partner from './partner'
import marketPackage from './market-partner'
import verifyInfo from './verify-information'
import inactiveAdAccount from './inactive-ad-account'
import acl from './acl'
import adCreative from './ad-creative'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    notification,
    auth,
    stats,
    adsAccount,
    payment,
    user,
    myTask,
    subscription,
    home,
    productLinks,
    kyc,
    giftCode,
    partner,
    marketPackage,
    verifyInfo,
    inactiveAdAccount,
    acl,
    partnerPermission,
    adCreative,
  },
  strict: process.env.DEV,
})
