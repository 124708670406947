import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get user detail
  GET_USER_DETAIL_REQUEST(state) {
    REQUEST(state)
  },
  GET_USER_DETAIL_SUCCESS(state, data) {
    SUCCESS(state)
    state.userDetail = data
  },
  GET_USER_DETAIL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get accounts detail
  GET_ADS_ACCOUNTS_USER_REQUEST(state) {
    REQUEST(state)
  },
  GET_ADS_ACCOUNTS_USER_SUCCESS(state, data) {
    SUCCESS(state)
    state.userAdsAccounts = data
  },
  GET_ADS_ACCOUNTS_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get account list
  GET_USER_REQUEST(state) {
    REQUEST(state)
    state.listUserLoading = true
  },
  GET_USER_SUCCESS(state, data) {
    SUCCESS(state)
    state.listUser = data
    state.listUserLoading = false
  },
  GET_USER_FAILURE(state, error) {
    FAILURE(state, error)
    state.listUserLoading = false
  },

  // active user
  ACTIVE_USER_REQUEST(state) {
    REQUEST(state)
  },
  ACTIVE_USER_SUCCESS(state) {
    SUCCESS(state)
  },
  ACTIVE_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // block user
  BLOCK_USER_REQUEST(state) {
    REQUEST(state)
  },
  BLOCK_USER_SUCCESS(state) {
    SUCCESS(state)
  },
  BLOCK_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // add white list block
  ADD_WHITE_LIST_BLOCK_REQUEST(state) {
    REQUEST(state)
  },
  ADD_WHITE_LIST_BLOCK_SUCCESS(state) {
    SUCCESS(state)
  },
  ADD_WHITE_LIST_BLOCK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update user
  UPDATE_USER_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_USER_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // ADD PRODUCT LINKS
  ADD_PRODUCT_LINKS_REQUEST(state) {
    REQUEST(state)
  },
  ADD_PRODUCT_LINKS_SUCCESS(state) {
    SUCCESS(state)
  },
  ADD_PRODUCT_LINKS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // UPDATE STATUS PRODUCT LINKS
  UPDATE_STATUS_PRODUCT_LINKS_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_STATUS_PRODUCT_LINKS_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_STATUS_PRODUCT_LINKS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update balance user
  UPDATE_BALANCE_USER_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_BALANCE_USER_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_BALANCE_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update balance user
  CHANGE_PLAN_TYPE_REQUEST(state) {
    REQUEST(state)
  },
  CHANGE_PLAN_TYPE_SUCCESS(state) {
    SUCCESS(state)
  },
  CHANGE_PLAN_TYPE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update balance user
  DELETE_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  DELETE_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  DELETE_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Cancel Subscription
  CANCEL_SUB_REQUEST(state) {
    REQUEST(state)
  },
  CANCEL_SUB_SUCCESS(state) {
    SUCCESS(state)
  },
  CANCEL_SUB_FAILURE(state, error) {
    FAILURE(state, error)
  },

  ADD_USER_REQUEST(state) {
    REQUEST(state)
  },

  ADD_USER_SUCCESS(state) {
    SUCCESS(state)
  },

  ADD_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  PROFILE_STATUS_UPDATE_REQUEST(state) {
    REQUEST(state)
  },

  PROFILE_STATUS_UPDATE_SUCCESS(state) {
    SUCCESS(state)
  },

  PROFILE_STATUS_UPDATE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  MARK_KYC_USER_REQUEST(state) {
    REQUEST(state)
  },
  MARK_KYC_USER_SUCCESS(state) {
    SUCCESS(state)
  },
  MARK_KYC_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list am
  GET_LIST_AM_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_AM_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAM = data
  },

  GET_LIST_AM_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // assign to am
  ASSIGN_TO_AM_REQUEST_REQUEST(state) {
    REQUEST(state)
  },

  ASSIGN_TO_AM_REQUEST_SUCCESS(state) {
    SUCCESS(state)
  },

  ASSIGN_TO_AM_REQUEST_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_LIST_ADMIN_NOTE_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_ADMIN_NOTE_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAdminNote = data
  },
  GET_LIST_ADMIN_NOTE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  ADD_NOTE_REQUEST(state) {
    REQUEST(state)
  },
  ADD_NOTE_SUCCESS(state) {
    SUCCESS(state)
  },
  ADD_NOTE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  UPDATE_NOTE_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_NOTE_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_NOTE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  DELETE_NOTE_REQUEST(state) {
    REQUEST(state)
  },
  DELETE_NOTE_SUCCESS(state) {
    SUCCESS(state)
  },
  DELETE_NOTE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  ASSIGN_MULTI_TO_AM_REQUEST(state) {
    REQUEST(state)
  },
  ASSIGN_MULTI_TO_AM_SUCCESS(state) {
    SUCCESS(state)
  },
  ASSIGN_MULTI_TO_AM_FAILURE(state, error) {
    FAILURE(state, error)
  },

  RESIGN_MULTI_TO_AM_REQUEST(state) {
    REQUEST(state)
  },
  RESIGN_MULTI_TO_AM_SUCCESS(state) {
    SUCCESS(state)
  },
  RESIGN_MULTI_TO_AM_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list partner bcs
  GET_LIST_PARTNER_BCS_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_PARTNER_BCS_SUCCESS(state, data) {
    SUCCESS(state)
    const { hasConnectedTikTok, list } = data
    state.hasConnectedTikTok = hasConnectedTikTok
    state.listPartnerBC = list || []
  },
  GET_LIST_PARTNER_BCS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update tier
  UPDATE_TIER_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_TIER_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_TIER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // create ads account
  CREATE_ADS_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_ADS_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_ADS_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // close ads account
  CLOSE_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  CLOSE_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  CLOSE_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // disconnect tiktok account
  DISCONNECT_TIKTOK_REQUEST(state) {
    REQUEST(state)
  },
  DISCONNECT_TIKTOK_SUCCESS(state) {
    SUCCESS(state)
  },
  DISCONNECT_TIKTOK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get detail user related
  GET_DETAIL_USER_RELATED_REQUEST(state) {
    REQUEST(state)
  },
  GET_DETAIL_USER_RELATED_SUCCESS(state, data) {
    SUCCESS(state)
    state.detailUserRelated = data
  },
  GET_DETAIL_USER_RELATED_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
