<template>
  <b-modal
    v-model="showModal"
    centered
    title="Are you make sure to Approve this ad account ?"
    hide-footer
    header-class="business-header"
    @close="$emit('close-modal')"
  >
    <div
      v-if="userInfo"
      class="user-info"
    >
      <b-img
        class="mr-1 ml-1"
        rounded
        :src="require('@/assets/images/common/ic-user-tiktok.svg')"
      />

      {{ `${userInfo.localUserId}_${userInfo.details.name}_${userInfo.details.timezone}` }}
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <b-button
        @click="$emit('close-modal')"
      >
        Cancel
      </b-button>

      <btn-loading
        class="btn-text-color ml-1"
        type="submit"
        variant="primary"
        :loading="loading"
        @click="$emit('on-confirm')"
      >
        Confirm
      </btn-loading>
    </div>
  </b-modal>
</template>
<script>
import {
  BModal,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { toastification } from '@core/mixins/toast'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')

export default {
  components: {
    BModal,
    BtnLoading,
    BButton,
    BImg,
  },

  mixins: [generalConfigsMixin, toastification],

  props: {
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    userInfo: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      showModal: this.show,
    }
  },

  computed: {
    ...mapGetters(['loading', 'message', 'status']),
  },

  watch: {
    show(value) {
      this.showModal = value
    },

    showModal(value) {
      if (!value) {
        this.$emit('close-modal')
      }
    },
  },

  created() {
  },

  methods: {
    ...mapActions(['createBusinessAccount']),

    handleSelectTimeZone(value) {
      this.timeZone = value
    },

    resetForm() {
      this.businessName = ''
      this.timeZone = ''
      this.$refs.businessFormValidation.reset()
    },

    async submit() {
      this.$refs.businessFormValidation.validate().then(async success => {
        if (success) {
          await this.createBusinessAccount({ BCName: this.businessName, timezone: this.timeZone })
          if (this.status) {
            this.toastSuccess('Create new account success')
            this.resetForm()
          } else {
            this.toastFailure(this.message)
          }

          this.$emit('close-modal')
          this.$emit('refresh-page')
        }
      })
    },
  },
}
</script>

<style lang="scss">
.business-header {
  padding: 32px;
  padding-bottom: 0px;
  border-radius: 24px;

  .modal-title {
    font-size: 20px;
  }
  &.modal-header .close {
    padding: 0;
    background-color: transparent;
    top: 25px;
    font-size: 40px;
    position: absolute;
    right: 50px;
    transform: none;
    box-shadow: none;
    border-radius: 0px;
    &:hover {
      transform: none;
    }
  }
}

.business-body {
  padding: 32px 32px;
}

.business-content {
  border-radius: 24px;
}

.timezone-select {
  .custom-select {
    &:focus {
      border-color: #16213e;
    }
  }
}

.user-info {
  border: 1px solid #e0e0e5;
  font-weight: bold;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
}
</style>
