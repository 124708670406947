import axios from '@axios'

export default {
  getPaymentTickets(_params, cancelToken) {
    const params = {
      ..._params,
      type: _params.type,
    }
    return axios.get('/api/admin/tickets', { params, cancelToken })
  },

  approveTicket(params) {
    return axios.patch(`/api/admin/tickets/approve/${params.id}`, {
      adsAccountId: params.adsAccountId,
      BCId: params.BCId,
    })
  },

  rejectTicket(params) {
    return axios.patch(`/api/admin/tickets/reject/${params.id}`, {
      message: params.message,
    })
  },

  commentTicket(params) {
    return axios.post(`/api/admin/tickets/${params.id}/comments`, {
      message: params.message,
    })
  },

  getTransactions(_params, cancelToken) {
    const params = {
      ..._params,
    }

    const hasTypes = Object.keys(params).find(item => item === 'types[]')
    if (!hasTypes) {
      return axios.get('/api/admin/transactions?types[]=add_fund_user_balance&types[]=admin_fix_recharge_money&types[]=admin_fix_withdraw_money&types[]=referral_payout&types[]=recharge_gift_code&types[]=stripe_subscription&types[]=user_tip_for_am&types[]=setup_cost', { params, cancelToken })
    }

    return axios.get('/api/admin/transactions', { params, cancelToken })
  },

  getAdsAccountHistories(_params, cancelToken) {
    const params = {
      ..._params,
    }

    const hasTypes = Object.keys(params).find(item => item === 'types[]')
    if (!hasTypes) {
      return axios.get('/api/admin/transactions?types[]=refund_ads_account_balance&types[]=recharge_ads_account_balance&types=system_withdraw_due_to_inactive', { params, cancelToken })
    }

    return axios.get('/api/admin/transactions', { params, cancelToken })
  },

  getCashbackTransaction(params) {
    return axios.get('/api/admin/transactions', { params })
  },

  handleCashback(params) {
    return axios.post('/api/admin/transactions/cashback', params)
  },

  getRefundTransaction(params) {
    return axios.get('/api/admin/tickets', params)
  },

  // AUTO REFUND

  getListRequestAutoRefund(params, cancelToken) {
    return axios.get('/api/admin/transactions', { params, cancelToken })
  },

  reviewRequestRefund(params) {
    const { admin, ...realParams } = params
    return axios.post(`/api/admin/transactions/refund/${admin === 'AM' ? 'am-review' : 'fm-review'}`, realParams)
  },

  // END AUTO REFUND
}
