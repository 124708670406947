<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex" />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <!--      <notification-dropdown />-->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ fullName }}
            </p>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            class="badge-minimal"
            badge-variant="success"
            :src="avatarURL"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          :to="{name: 'profile'}"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textProfile') }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onBilling"
        >
          <feather-icon
            size="16"
            icon="DollarSignIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textBilling') }}</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <!-- <b-dropdown-item
          v-if="storeUrl"
          link-class="d-flex align-items-center"
          @click="onGoToStore"
        >
          <feather-icon
            size="16"
            icon="GlobeIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textGoToYourStore') }}</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onChooseAnotherStore"
        >
          <feather-icon
            size="16"
            icon="RefreshCwIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textChooseAnotherStore') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider /> -->

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="onLogout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ $t('layout.textLogOut') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */

import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import defaultAvavar from '@/assets/images/common/no-image-square.png'
// import NotificationDropdown from './NotificationDropdown.vue'
import DarkToggler from './DarkToggler.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // NotificationDropdown,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters(['user', 'generalConfigs']),
    fullName() {
      return this.user?.fullName ? this.user.fullName : `${this.user?.email || ''}`
    },
    avatarURL() {
      return this.user?.imageUrl ? this.user?.imageUrl : defaultAvavar
    },
  },

  async created() {
    // const userData = JSON.parse(localStorage.getItem('userData'))
    // if (!userData) {
    await this.getAccountInfo()
    this.handleGetGeneralConfig()
    // }
  },

  methods: {
    ...mapActions(['logout', 'getAccountInfo', 'getGeneralConfig']),
    handleGetGeneralConfig() {
      const userId = this.$route.params?.id
      if (userId) {
        this.getGeneralConfig(userId)
      } else {
        this.getGeneralConfig('')
      }
    },

    onLogout() {
      this.logout()
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
<style lang="scss">
  .dropdown-user .dropdown-menu {
    width: 16rem !important;
  }
</style>
