<template>
  <div class="auto-refund-container">
    <auto-refund-ticket />
  </div>
</template>

<script>
import AutoRefundTicket from './components/AutoRefundTicket.vue'

export default {
  components: { AutoRefundTicket },

}
</script>

<style>

</style>
