import axios from '@axios'

export default {
  getListProductLinks(params, cancelToken) {
    return axios.get('/api/admin/users', { params, cancelToken })
  },

  handleProductVideo(params) {
    const { id, ...realParams } = params
    return axios.put(`/api/admin/users/${id}/product-video`, realParams)
  },
}
