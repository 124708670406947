import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  GET_USER_ANALYTICS_REQUEST(state) {
    REQUEST(state)
  },
  GET_USER_ANALYTICS_SUCCESS(state, data) {
    SUCCESS(state)
    state.user = data
  },
  GET_USER_ANALYTICS_FAILURE(state, error) {
    FAILURE(state, error)
  },
  GET_DEPOSIT_ANALYTICS_REQUEST(state) {
    REQUEST(state)
  },
  GET_DEPOSIT_ANALYTICS_SUCCESS(state, data) {
    SUCCESS(state)
    state.deposit = data
  },
  GET_DEPOSIT_ANALYTICS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_SPENDING_ANALYTICS_REQUEST(state) {
    REQUEST(state)
  },
  GET_SPENDING_ANALYTICS_SUCCESS(state, data) {
    SUCCESS(state)
    state.spending = data
  },
  GET_SPENDING_ANALYTICS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  SET_TYPE_USER_SORT(state, type) {
    state.typeSort = type
  },
  SET_LIMIT_DAY_USER(state, limit) {
    state.limitDay = limit
  },
  SET_LIMIT_MONTH_USER(state, limit) {
    state.limitMonth = limit
  },

  SET_TYPE_DEPOSIT_SORT(state, type) {
    state.typeSortDeposit = type
  },
  SET_LIMIT_DAY_DEPOSIT(state, limit) {
    state.limitDayDeposit = limit
  },
  SET_LIMIT_MONTH_DEPOSIT(state, limit) {
    state.limitMonthDeposit = limit
  },

  SET_TYPE_SPENDING_SORT(state, type) {
    state.typeSortSpending = type
  },
  SET_LIMIT_DAY_SPENDING(state, limit) {
    state.limitDaySpending = limit
  },
  SET_LIMIT_MONTH_SPENDING(state, limit) {
    state.limitMonthSpending = limit
  },
  GET_USERS_REQUEST(state) {
    REQUEST(state)
  },
  GET_USERS_SUCCESS(state, data) {
    SUCCESS(state)
    state.users = data
  },
  GET_USERS_FAILURE(state, error) {
    FAILURE(state, error)
    state.users.totalUsers = null
  },
  GET_DEPOSITS_REQUEST(state) {
    REQUEST(state)
  },
  GET_DEPOSITS_SUCCESS(state, data) {
    SUCCESS(state)
    state.deposits = data
  },
  GET_DEPOSITS_FAILURE(state, error) {
    FAILURE(state, error)
    state.deposits.monthlyDeposit = null
  },
  GET_ADS_ACCOUNTS_REQUEST(state) {
    REQUEST(state)
  },
  GET_ADS_ACCOUNTS_SUCCESS(state, data) {
    SUCCESS(state)
    state.adsAccounts = data
  },
  GET_ADS_ACCOUNTS_FAILURE(state, error) {
    FAILURE(state, error)
    state.adsAccounts.totalAdsAccounts = null
  },
  GET_BONUS_REQUEST(state) {
    REQUEST(state)
  },
  GET_BONUS_SUCCESS(state, data) {
    SUCCESS(state)
    state.bonus = data
  },
  GET_BONUS_FAILURE(state, error) {
    FAILURE(state, error)
    state.bonus.totalBonus = null
  },
  GET_BALANCES_REQUEST(state) {
    REQUEST(state)
  },
  GET_BALANCES_SUCCESS(state, data) {
    SUCCESS(state)
    state.balances = data
  },
  GET_BALANCES_FAILURE(state, error) {
    FAILURE(state, error)
    state.balances.totalBalance = null
  },

  UPDATE_LIST_BC_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_LIST_BC_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_LIST_BC_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
