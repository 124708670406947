<template>
  <b-card
    no-body
    class="card-statistics card-top-payment"
  >
    <b-card-body>
      <div>
        <b-row class="align-items-center">
          <b-col
            md="8"
            sm="12"
            class="title-card"
          >
            <b-card-text class="font-20 font-weight-700">
              List BC
            </b-card-text>
          </b-col>
        </b-row>
      </div>
      <div class="table-payment-method">
        <vue-good-table
          :columns="columns"
          :rows="listBC"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :is-loading="loading"
          class="table-bc"
        >
          <template
            slot-scope="props"
            slot="table-row"
          >
            <div
              v-if="props.column.field === 'bcId'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ props.row.id }}
                </p>
              </div>
            </div>

            <div
              v-if="props.column.field === 'bcName'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ props.row.name }}
                </p>
              </div>
            </div>

            <div
              v-if="props.column.field === 'balance'"
              class="d-flex align-items-center"
            >
              <div>
                <p class="mb-0">
                  {{ new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: props.row.currency,
                  }).format(props.row.balance) }}
                </p>
              </div>
            </div>

            <div
              v-if="props.column.field === 'status'"
              class="d-flex align-items-center"
            >
              <div
                class="status-bc"
                :class="statusListBC(props.row.status)"
              >
                <p class="mb-0 status-content">
                  {{ statusListBC(props.row.status) }}
                </p>
              </div>
            </div>

            <span v-else-if="props.column.field === 'action'">
              <span v-if="props.row.status !== BC_STATUS.INTERNAL_BC">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    @click="openUpdateStatusModal(props.row)"
                  >
                    <span> Update </span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot-scope="props"
            slot="pagination-bottom"
          >
            <div class="d-flex flex-wrap mt-1 justify-content-end">
              <!-- page length -->
              <!--              <div class="d-flex align-items-center mb-0">-->
              <!--                <span class="text-nowrap ">-->
              <!--                  Showing 1 to-->
              <!--                </span>-->
              <!--                <b-form-select-->
              <!--                  v-model="pageLength"-->
              <!--                  :options="pages"-->
              <!--                  class="mx-1"-->
              <!--                  @input="(value)=>props.perPageChanged({currentPerPage:value})"-->
              <!--                />-->
              <!--                <span class="text-nowrap"> of {{ props.total }} BC(s) </span>-->
              <!--              </div>-->
              <div>
                <b-pagination
                  :total-rows="props.total"
                  :value="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>

          <div
            v-if="errorListBC"
            slot="emptystate"
          >
            <p class="text-error">
              {{ errorListBC }}
            </p>
          </div>
        </vue-good-table>
      </div>
    </b-card-body>
    <update-bc-modal
      ref="open-modal-update"
      :bc-selected="bcSelected"
      @fetchListBC="fetchListBC"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BRow, BCol, BPagination, BDropdown, BDropdownItem,
  // BFormSelect,
} from 'bootstrap-vue'
import { StatsService } from '@/services'
import { VueGoodTable } from 'vue-good-table'
import { BC_STATUS } from '@/constants'
import UpdateBcModal from './UpdateBcModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    VueGoodTable,
    BPagination,
    // BFormSelect,
    BDropdown,
    BDropdownItem,
    UpdateBcModal,
  },
  data() {
    return {
      listBC: [],
      bcSelected: {},
      BC_STATUS,
      columns: [
        {
          label: 'BC ID',
          field: 'bcId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'BC Name',
          field: 'bcName',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-midle',
        },
        {
          label: 'Balance',
          field: 'balance',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],

      currentPage: 1,
      // per page
      pageLength: 10,
      pages: ['10', '20'],
      loading: false,

      errorListBC: '',
    }
  },

  computed: {
    statusListBC() {
      const statusColor = {
        [BC_STATUS.LIMITED]: 'limited',
        [BC_STATUS.ACTIVATED]: 'available',
        [BC_STATUS.SUSPENDED]: 'suspended',
        [BC_STATUS.INTERNAL_BC]: 'Internal BC',
      }

      return status => statusColor[status]
    },
  },

  created() {
    this.fetchListBC()
  },

  methods: {
    openUpdateStatusModal(data) {
      this.bcSelected = data
      this.$refs['open-modal-update'].showUpdateModal()
    },

    async fetchListBC() {
      this.loading = true
      try {
        await StatsService.getListBC().then(response => {
          this.loading = false
          this.listBC = response?.data?.result
        })
      } catch (error) {
        this.loading = false
        this.errorListBC = error?.data?.message
      }
    },
  },
}
</script>

  <style lang="scss">
  .table-bc {
    .vgt-table {
      width: 100%;
    }
    .vgt-table.bordered td {
      border: none;
    }

    .header-table-mf {
      padding-top: 0 !important;
    }

    .vgt-responsive {
      width: 100%;
      overflow-x: auto;
      position: relative;
      height: 100%;
    }
  }
  </style>

  <style lang="scss" scoped>
  .table-payment-method {
    margin-top: 1rem;
  }

  .status-bc {
    color: rgba(29, 185, 195, 1);
    background-color: rgba(29, 185, 195, 0.1);
    padding: 0 14px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    width: 81px;
    justify-content: center;
  }

  .limited {
    color: rgba(240, 37, 72, 1);
    background-color: rgba(240, 37, 72, 0.1);
  }

  .available {
    color: rgba(122, 179, 76, 1);
    background-color: rgba(122, 179, 76, 0.1);
  }

  .suspended {
    color: rgba(255, 159, 67, 1);
    background-color: rgba(255, 159, 67, 0.1);
  }

  .text-error {
    margin-top: 1rem;
    color: #f02649;
  }

  .status-content{
    text-transform: capitalize;
  }
</style>
