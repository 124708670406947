/* eslint-disable no-underscore-dangle */
import axios from '@axios'

export default {
  getListGiftCode(params) {
    return axios.get('/api/admin/gift-codes', { params })
  },

  getListPartnerNoPaging(params) {
    return axios.get('/api/admin/partners/all', { params })
  },

  getUserInfo(params) {
    return axios.get('/api/admin/users', { params })
  },

  deactivateGiftCode(params) {
    return axios.put(`/api/admin/gift-codes/${params.id}`, params)
  },

  updateGiftCode(params) {
    return axios.put(`/api/admin/gift-codes/${params.id}`, params)
  },

  createGiftCode(params) {
    return axios.post('/api/admin/gift-codes', params)
  },
}
