<template>
  <div class="partner-detail-container">
    <b-card>
      <!-- Information -->
      <b-row class="mb-1">
        <b-col
          sm="12"
          class="d-flex align-items-center"
        >
          <feather-icon
            size="23"
            icon="ChevronLeftIcon"
            class="cursor-pointer mr-1"
            @click="$router.push('/partners')"
          />
          <div class="d-flex align-items-center">
            <b-img
              :id="`avt-user-${userDetail._id}`"
              rounded
              class="ads-avt-img mr-1"
              :src="getAvatar(userDetail.registerFromService)"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && userDetail.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${userDetail._id}`"
            >
              {{ getUserPlatform(userDetail.registerFromService) }}
            </b-tooltip>
            <div class="ml-1">
              <p
                class="name-user"
                style="margin-bottom: 6px"
              >
                {{
                  `${
                    userDetail.fullName ? userDetail.fullName : userDetail.email
                  } - ID: ${
                    userDetail.localUserId || ""
                  } - IP: ${userIp} - Country: ${userCountry}`
                }}
              </p>
              <div class="d-flex">
                <small class="">User Agent: {{ userAgent }}</small>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-col class="d-flex align-items-center">
          <b-button
            v-if="!isFormWhitelist"
            class="btn-text-color"
            variant="primary"
            :disabled="loadingPartner || loading"
            @click="onChangeWhiteListPartner({whitelist: true})"
          >
            <feather-icon
              size="20"
              icon="PlusSquareIcon"
              class="cursor-pointer icon-btn"
            />
            Form Embed URL whitelist
          </b-button>

          <b-button
            v-else
            class="btn-text-color"
            variant="primary"
            :disabled="loadingPartner || loading"
            @click="onChangeWhiteListPartner({whitelist: false})"
          >
            <feather-icon
              size="20"
              icon="MinusSquareIcon"
              class="cursor-pointer icon-btn"
            />
            Remove Form Embed URL whitelist
          </b-button>
        </b-col>
        <b-col class="d-flex align-items-center">
          <div
            v-if="false"
            class="d-flex align-items-center"
          >
            <div class="d-flex  mr-1">
              <b-card-text class="mb-0">
                Member verification
              </b-card-text>
              <b-img
                id="tooltip-verification"
                variant="outline-primary"
                src="@/assets/images/pages/overview/ic-detail.svg"
                alt="logo"
              />
              <b-tooltip
                target="tooltip-verification"
                placement="top"
                triggers="hover"
              >
                <div class="content-tooltip">
                  <ul>
                    <li>Verify member product link</li>
                    <li>Add member product link</li>
                    <li>Verify member contact information</li>
                  </ul>
                </div>
              </b-tooltip>
            </div>
            <b-form-checkbox
              class="custom-control-success"
              name="check-button"
              switch
              inline
              :checked="hasAcl"
              @change="onChange"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
          <b-button
            v-if="false"
            class="btn-block-user"
            variant="flat-dark"
            size="sm"
            @click.prevent="handlePartner"
          >
            <feather-icon
              size="20"
              icon="UserXIcon"
              class="cursor-pointer icon-btn"
            />
            Reject partner
          </b-button>
        </b-col>
      </b-row>

      <!-- Tab content -->
      <b-row>
        <b-col>
          <b-tabs
            v-model="tabIndex"
            class="tabs-info"
          >
            <b-tab title="Analysis">
              <b-card class="mb-0">
                <analysis />
              </b-card>
            </b-tab>

            <b-tab title="Partner Information">
              <b-card class="mb-0">
                <partner-information />
              </b-card>
            </b-tab>

            <b-tab title="Plan & Service Fees">
              <plan-charged />
            </b-tab>

            <b-tab title="List Ads Account">
              <list-ads-account
                :error-get-list-ad-account-partner="errorGetListAdAccountPartner"
              />
            </b-tab>

            <b-tab
              title="List member"
              :active="tab === 'list-member'"
            >
              <list-member-user
                :user-data="userDetail"
                :error-get-list-member-partner="errorGetListMemberPartner"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <!-- reject modal -->
    <reject-partner-modal
      ref="open-modal-reject-partner"
      :user-data="userDetail || {}"
      @update-user-selected="updateUserSelected"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */

import {
  BRow,
  BCol,
  BCard,
  BTabs,
  BTab,
  BImg,
  BButton,
  BFormCheckbox,
  BCardText,
  VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { USER_STATUS } from '@/constants'
import _get from 'lodash/get'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import usersMixin from '@/mixins/usersMixin'
import envMixin from '@/mixins/envMixin'
import PartnerInformation from './PartnerInformation.vue'
import PlanCharged from './PlanAndMethodFees.vue'
import ListAdsAccount from './ListAdsAccount.vue'
import ListMemberUser from './ListMemberUser.vue'
import RejectPartnerModal from './RejectPartnerModal.vue'
import Analysis from './Analysis.vue'

const { mapActions } = createNamespacedHelpers('user')
const { mapActions: mapActionsPartnership } = createNamespacedHelpers('partner')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BImg,
    BButton,
    BFormCheckbox,
    BCardText,
    BTooltip,
    PartnerInformation,
    PlanCharged,
    ListAdsAccount,
    ListMemberUser,
    RejectPartnerModal,
    Analysis,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, paymentMixin, usersMixin, envMixin],

  metaInfo() {
    return {
      title: `${this.$route.params.id}-${this.userDetail.fullName}` || process.env.VUE_APP_OG_TITLE,
    }
  },

  data() {
    return {
      USER_STATUS,
      showSideBar: false,
      showBalanceSideBar: false,
      isUpdateUser: false,
      changePlanSidebar: false,
      tabIndex: 0,
      isTabProductLinks: false,
      monthSelected: null,
      yearSelected: null,

      tab: this.$route.query.tab || 'list-member',

      errorGetListAdAccountPartner: '',
      errorGetListMemberPartner: '',
    }
  },

  computed: {
    ...mapGetters({
      userDetail: 'user/userDetail',
      userAdsAccounts: 'user/userAdsAccounts',
      status: 'user/status',
      message: 'user/message',
      loading: 'user/loading',
      statusPartner: 'partner/status',
      messagePartner: 'partner/message',
      loadingPartner: 'partner/loading',
    }),

    isFormWhitelist() {
      return this.userDetail?.partnership?.formWhitelist
    },

    userInfoImg() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    partnerIsReject() {
      return this.userDetail?.partnership?.status === 'rejected'
    },

    userIp() {
      return this.userDetail?.signupTracking?.ip
    },

    userCountry() {
      return _get(this.userDetail, ['signupTracking', 'countryName'], '')
    },

    userAgent() {
      return this.userDetail?.signupTracking?.userAgent
    },

    hasAcl() {
      return !!this.userDetail?.acl
    },
  },

  watch: {
    isUpdateUser(value) {
      if (value) {
        const { params } = this.$route
        this.fetchUserInfo(params)
        this.isUpdateUser = false
      }
    },

    $route: {
      immediate: true,
      handler(val) {
        if (val.query.type === 'productLinks') {
          this.isTabProductLinks = true
        }
      },
    },
  },

  created() {
    const { params, query } = this.$route
    this.fetchUserInfo(params)
    if (query.tab && query.tab === 'list-ads-account') {
      this.tabIndex = 1
    }

    this.getSpendingDeposit()

    this.tab = this.$route.query.tab || 'list-member'
  },

  methods: {
    ...mapActions([
      'blockUser',
      'getUser',
      'getAdsAccountsUser',
      'activeUser',
      'updateUser',
    ]),
    ...mapActionsPartnership([
      'updateRolePartner',
      'getListMemberPartner',
      'getTotalMemberPartner',
      'deleteRolePartner',
      'getSpendingDepositPartnerDetail',
      'getAdsAccountsPartner',
      'getTotalMemberLifeTimePartnerDetail',
      'getTotalMemberMonthPartnerDetail',
      'getTotalAdsAccountMonthPartnerDetail',
      'changeStatusFormWhitelistPartner',
    ]),

    async onChangeWhiteListPartner({ whitelist }) {
      const localUserId = this.userDetail.localUserId || this.$route.params?.id
      const params = {
        localUserId,
        whitelist,
      }
      await this.changeStatusFormWhitelistPartner(params)
      if (this.statusPartner) {
        this.toastSuccess(`${whitelist ? 'Add' : 'Remove'} partner URL whitelist success!`)
        this.getUser({ id: localUserId })
      } else {
        this.toastFailure(this.messagePartner)
      }
    },

    async fetchUserInfo(params) {
      this.getTotalMemberPartner({
        ...params,
        year: 2022, // this params is default
        month: 8, // this params is default
        type: 'daily', // this params is default
      })
      await this.getUser(params)
      await this.getTotalMemberLifeTimePartnerDetail(params)
      await this.getAdsAccountsPartner(params)
      await this.getAdsAccountsUser({
        id: params.id,
        page: 1,
        size: 10,
      }).then(() => {
        this.errorGetListAdAccountPartner = this.messagePartner
      })
      const paramsListMember = {
        ...params,
        sort: '-belongToPartner.requestedAt',
      }
      await this.getListMemberPartner(paramsListMember).then(() => {
        this.errorGetListMemberPartner = this.messagePartner
      })

      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    openBlockModal() {
      this.$refs['open-block-modal'].showModal()
    },

    capitalise(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    subStatusVariant(status) {
      if (status === 'canceled') {
        return 'light-warning'
      }
      return 'light-success'
    },

    async onActiveUser(id) {
      const params = {
        status: USER_STATUS.ACTIVE,
        id,
      }
      await this.activeUser(params)
      if (this.status) {
        this.toastSuccess('Active user success')
        // eslint-disable-next-line no-underscore-dangle
        this.$emit('update-user-selected')
        this.updateUserSelected()
      } else {
        this.toastFailure(this.message)
      }
    },

    onChangePlan() {
      this.changePlanSidebar = true
    },

    openSideBar() {
      this.showSideBar = true
    },

    updateUserSelected() {
      this.isUpdateUser = true
    },

    onChangeListAdsAccount({ page = 1, size = 10, keyword = '' }) {
      const params = {
        id: this.$route.params.id,
        page,
        size,
        keyword,
      }
      this.getAdsAccountsPartner(params)
    },

    showPhoneNumber(userDetail) {
      let result = ''
      const { contactInfo } = userDetail
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      }

      return result
    },

    async handleWhiteListUser(status) {
      const { params } = this.$route
      const payload = {
        id: this.userDetail.localUserId,
        whitelistProductVerify: status.whitelistProductVerify,
      }
      await this.updateUser(payload)
      if (this.status) {
        this.toastSuccess(
          status.whitelistProductVerify
            ? 'Add white list user success'
            : 'Remove white list user success',
        )
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },

    async activeUser() {
      const payload = {
        id: this.userDetail.localUserId,
        status: USER_STATUS.ACTIVE,
      }

      await this.blockUser(payload)
      if (this.status) {
        this.toastSuccess('Active user success!')
        const { params } = this.$route
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },

    async onChange(val) {
      if (val) {
        const payload = {
          id: this.userDetail.localUserId,
          landingPage: this.userDetail.partnership.landingPage,
          communityGroup: this.userDetail.partnership.communityGroup,
          acl: 'TODO',
        }
        await this.updateRolePartner(payload)
        if (this.statusPartner) {
          this.toastSuccess('Apply member verification permission success!')
          const { params } = this.$route
          this.getUser(params)
        } else {
          this.toastFailure(this.messagePartner)
        }
      } else {
        const payload = {
          id: this.userDetail.localUserId,
          landingPage: this.userDetail.partnership.landingPage,
          communityGroup: this.userDetail.partnership.communityGroup,
        }
        await this.deleteRolePartner(payload)
        if (this.statusPartner) {
          this.toastSuccess('Remove member verification permission success!')
          const { params } = this.$route
          this.getUser(params)
        } else {
          this.toastFailure(this.messagePartner)
        }
      }
    },

    handlePartner() {
      this.$refs['open-modal-reject-partner'].showModal()
    },

    async getSpendingDeposit() {
      const { params } = this.$route

      this.monthSelected = moment().month() + 1
      this.yearSelected = moment().year()

      const payload = {
        id: params.id,
        year: this.yearSelected,
        month: this.monthSelected,
        totalSpending: 1,
        totalDeposit: 1,
        monthlySpending: 1,
        monthlyDeposit: 1,
      }
      const fromDate = moment(`${this.yearSelected}/${this.monthSelected}/01`, 'YYYY/MM/DD').startOf('month').format('YYYY-MM-DD')
      const toDate = moment(fromDate).endOf('month').format('YYYY-MM-DD')
      const data = {
        id: params.id,
        fromDate,
        toDate,
      }
      await this.getSpendingDepositPartnerDetail(payload)
      await this.getTotalMemberMonthPartnerDetail(data)
      await this.getTotalAdsAccountMonthPartnerDetail(data)
    },
  },
}
</script>

<style lang="scss" scoped>
.partner-detail-container {
  .card {
    border-radius: 10px;

    .card-body {
      padding: 32px 24px;
      border: 1px solid #eeeeee;
      border-radius: 10px;

      .btn-block-user {
        padding: 10px 18.4px;
        border: 1px solid #e0e0e5;
        border-radius: 6px;
        transform: rotate(0.01deg);
        display: flex;
        align-items: center;
        transition: 0.2s ease-in-out;
        margin-right: 12px;

        .icon-btn {
          margin-right: 0.5rem;
        }
      }

      .tabs-info {
        .card-body {
          padding: 0;
          border: none;
        }
      }
    }
  }
}

.name-user {
  font-weight: 700;
}

.percent-input {
  width: 83px;
}

.change-plan-text {
  padding: 0;
  border-radius: 0;
  display: inline-block;
  color: #f02548;
  line-height: 1;
  border-bottom: 1px solid #f02548;
  cursor: pointer;
}

.content-tooltip {
  text-align: left !important;

  ul{
    padding-left: 1rem;
  }
}
</style>
