<template>
  <div class="partner-info-container">
    <div class="status-partner d-flex align-items-center">
      <p class="title-status">
        Account Status
      </p>
      <p class="content-status">
        {{ userDetail.status }}
      </p>
    </div>
    <div class="form-information-partner">
      <b-row class="align-items-end">
        <b-col
          cols="6"
          class="role-tag"
        >
          <b-form-group class="w-100">
            <label>
              Roles
            </label>
            <v-select
              v-model="roleSelected"
              multiple
              placeholder="Select role"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-button
            variant="outline-primary"
            class="btn-edit"
            @click.prevent="EditRole"
          >
            Edit Roles
          </b-button>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <!-- v-slot="{ invalid }" -->
          <b-form>
            <b-row>
              <!-- User ID -->
              <b-col
                sm="12"
                md="12"
                lg="4"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="userId"
                    >
                      User ID
                    </label>
                  </div>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="userId"
                      name="userId"
                      class="input-height"
                      placeholder="User Id"
                      disabled
                      :value="userDetail.localUserId || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- User ID -->
              <!-- Create At -->
              <b-col
                sm="12"
                md="12"
                lg="4"
              >
                <b-form-group class="group-input">
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="createAt"
                      >
                        Create At
                      </label>
                    </div>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="createAt"
                        name="createAt"
                        class="input-height"
                        placeholder="Create At"
                        disabled
                        :value="createAt || 'N/A'"
                      />
                    </b-input-group>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Create At -->

              <!-- Create At -->
              <b-col
                sm="12"
                md="12"
                lg="4"
              >
                <b-form-group class="group-input">
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="createAt"
                      >
                        Refcode
                      </label>
                    </div>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="createAt"
                        name="createAt"
                        class="input-height"
                        placeholder="Create At"
                        disabled
                        :value="refCode || 'N/A'"
                      />
                    </b-input-group>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Create At -->
            </b-row>
            <b-row>
              <!-- Landing page -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="landingPage"
                    >
                      Landing page
                    </label>
                  </div>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="landingPage"
                      name="landingPage"
                      class="input-height"
                      placeholder="Landing page"
                      disabled
                      :value="userDetail.partnership && userDetail.partnership.landingPage
                        ? userDetail.partnership.landingPage
                        : 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Landing page -->
              <!-- Community group -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="createAt"
                      >
                        Community group
                      </label>
                    </div>
                    <b-input-group class="input-group-merge">
                      <b-form-input
                        id="createAt"
                        name="createAt"
                        class="input-height"
                        placeholder="Create At"
                        disabled
                        :value="userDetail.partnership && userDetail.partnership.communityGroup ? userDetail.partnership.communityGroup : 'N/A'"
                      />
                    </b-input-group>
                  </div>
                </b-form-group>
              </b-col>
              <!-- Community group -->
            </b-row>

            <b-row>
              <!-- Full Name -->
              <b-col
                sm="12"
                md="12"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="street-address"
                    >
                      Full Name
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="business-name"
                      name="business-name"
                      class="input-height"
                      placeholder="Enter Full name"
                      disabled
                      :value="userDetail.fullName || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Full Name -->
            </b-row>

            <b-row>
              <!-- email -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="email"
                    >
                      Email
                    </label>
                  </div>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="email"
                      name="email"
                      class="input-height"
                      placeholder="Enter Full name"
                      disabled
                      :value="userDetail.email || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Email -->
              <!-- Phone number -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="phone-number"
                    >
                      Phone number
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="phone-number"
                      name="phone-number"
                      class="input-height"
                      placeholder="Enter Business Name"
                      disabled
                      :value="showPhoneNumber || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Phone number -->
            </b-row>

            <b-row>
              <!-- URL partner -->
              <b-col
                sm="12"
                md="12"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="street-address"
                    >
                      Embed URL
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="business-name"
                      name="business-name"
                      class="input-height"
                      placeholder="Enter Full name"
                      disabled
                      :value="formEmbedURL"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Full Name -->
            </b-row>

            <b-row>
              <b-col
                md="12"
                lg="4"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="contact-facebook"
                    >
                      Facebook
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="contact-facebook"
                      name="contact-facebook"
                      class="input-height"
                      placeholder="Enter Business Name"
                      disabled
                      :value="facebookContact || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                lg="4"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="contact-WhatsApp"
                    >
                      What'sApp
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="contact-WhatsApp"
                      name="contact-WhatsApp"
                      class="input-height"
                      placeholder="Enter Business Name"
                      disabled
                      :value="whatsAppContact || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col
                md="12"
                lg="4"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="contact-Telegram"
                    >
                      Telegram
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="contact-Telegram"
                      name="contact-Telegram"
                      class="input-height"
                      placeholder="Enter Business Name"
                      disabled
                      :value="telegramContact || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- business type -->
              <b-col
                sm="12"
                md="12"
              >
                <b-form-group class="input-group-merge">
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="postal-code"
                      >
                        Business Type
                      </label>
                    </div>
                    <v-select
                      placeholder="Select your answer"
                      class="currency input-height"
                      disabled
                      :value="userDetail.businessModel || 'N/A'"
                      :clearable="false"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                          />
                        </span>
                      </template>
                    </v-select>
                  </div>
                </b-form-group>
              </b-col>
              <!-- business type -->
            </b-row>

            <b-row>
              <!-- Country -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="country"
                    >
                      Country
                    </label>
                  </div>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="country"
                      name="country"
                      class="input-height"
                      placeholder="Enter Full name"
                      disabled
                      :value="userDetail.country || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- County -->
              <!-- City/Region -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="region"
                    >
                      City/Region
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="region"
                      name="region"
                      class="input-height"
                      placeholder="Enter Business Name"
                      disabled
                      :value="userDetail.city || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- City/Region -->
            </b-row>

            <b-row>
              <!-- Street Address -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="form-label"
                      for="street-address"
                    >
                      Street Address
                    </label>
                  </div>
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="street-address"
                      name="street-address"
                      class="input-height"
                      placeholder="Enter street address"
                      disabled
                      :value="userDetail.streetAddress || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Street Address -->
              <!-- Postal Code -->
              <b-col
                sm="12"
                md="12"
                lg="6"
              >
                <b-form-group class="group-input">
                  <div class="d-flex justify-content-between">
                    <label
                      class="postal-code"
                      for="region"
                    >
                      Postal Code
                    </label>
                  </div>

                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="postal-code"
                      name="postal-code"
                      class="input-height"
                      placeholder="Enter Business Name"
                      disabled
                      :value="userDetail.postalCode || 'N/A'"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- Postal Code -->
            </b-row>

            <b-row class="group-input">
              <b-col
                sm="12"
                md="12"
                lg="4"
              >
                <!-- What platform do you use for your store ? -->

                <b-form-group>
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="postal-code"
                      >
                        Platform store
                      </label>
                    </div>
                    <v-select
                      placeholder="Select your answer"
                      class="currency input-height"
                      disabled
                      :value="userDetail.storingPlatform || 'N/A'"
                      :clearable="false"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                          />
                        </span>
                      </template>
                    </v-select>
                  </div>
                </b-form-group>
                <!-- What platform do you use for your store ? -->
              </b-col>
              <b-col
                sm="12"
                md="12"
                lg="4"
              >
                <!-- What is your current monthly -->

                <b-form-group>
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="postal-code"
                      >
                        Current monthly
                      </label>
                    </div>
                    <v-select
                      placeholder="Select your answer"
                      class="currency input-height"
                      disabled
                      :value="userDetail.monthlyRevenue || 'N/A'"
                      :clearable="false"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                          />
                        </span>
                      </template>
                    </v-select>
                  </div>
                </b-form-group>
                <!-- What is your current monthly -->
              </b-col>
              <b-col
                sm="12"
                md="12"
                lg="4"
              >
                <!-- Have you run TikTok Ads before -->

                <b-form-group>
                  <div>
                    <div class="d-flex justify-content-between">
                      <label
                        class="form-label"
                        for="postal-code"
                      >
                        Has run tikTok ads
                      </label>
                    </div>
                    <v-select
                      placeholder="Select your answer"
                      class="currency input-height"
                      disabled
                      :value="hasRunAds(userDetail.hasRunTikTokAds)"
                      :clearable="false"
                    >
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon
                            icon="ChevronDownIcon"
                            size="20"
                          />
                        </span>
                      </template>
                    </v-select>
                  </div>
                </b-form-group>
                <!-- Have you run TikTok Ads before -->
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>

    <edit-role-partner-side-bar
      :show-side-bar.sync="showEditRoleSideBar"
      :role-selected.sync="roleSelected"
      :user-id.sync="userDetail.localUserId"
      @fetch-user-info="fetchUserInfo"
    />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import vSelect from 'vue-select'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import EditRolePartnerSideBar from './EditRolePartnerSideBar.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('user')
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,

    vSelect,
    EditRolePartnerSideBar,
  },

  mixins: [dateTimeFormatMixin],

  data() {
    return {
      showEditRoleSideBar: false,
    }
  },

  computed: {
    ...mapGetters(['userDetail']),

    formEmbedURL() {
      return this.userDetail?.partnership?.formEmbedURL || 'N/A'
    },

    refCode() {
      return _get(this.userDetail, ['referralTracking', 'code'])
    },

    createAt() {
      return this.formatDate(this.userDetail?.createdAt || '')
    },

    showPhoneNumber() {
      let result = ''
      const { contactInfo } = this.userDetail
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      }
      return result
    },

    facebookContact() {
      return _get(this.userDetail, ['contactInfo', 'facebook'])
    },

    whatsAppContact() {
      return _get(this.userDetail, ['contactInfo', 'whatsApp'])
    },

    telegramContact() {
      return _get(this.userDetail, ['contactInfo', 'telegram'])
    },

    roleSelected() {
      return _get(this.userDetail, ['roles'])
    },

    hasRunAds() {
      return val => {
        if (val === false) {
          return 'No'
        }
        if (val === true) {
          return 'Yes'
        }
        return 'N/A'
      }
    },
  },

  methods: {
    ...mapActions(['getUser']),
    fetchUserInfo() {
      const { params } = this.$route
      this.getUser(params)
    },

    EditRole() {
      this.showEditRoleSideBar = true
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.role-tag {
  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }
  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1) !important;
  }
}
</style>

<style lang="scss" scoped>
.partner-info-container {
  padding-top: 24px;

  .status-partner {
    padding-bottom: 24px;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 24px;

.title-status {
      margin-right: 45px;
      margin-bottom: 0;
    }

    .content-status {
      padding: 6px 26.5px;
      background: #effae6;
      color: #7ab34c;
      border-radius: 100px;
      margin-bottom: 0;
    }
  }
}

.btn-edit {
  margin-bottom: 1rem;
  height: 43px;
}
</style>
