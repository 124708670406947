<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in itemsCustom"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import { ADMIN_ROLES, EXCLUSIVE_PARTNER_EXCLUDE_ROUTES } from '@/constants'
import envMixin from '@/mixins/envMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  mixins: [envMixin, generalConfigsMixin],
  props: {
    items: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
  },

  computed: {
    itemsCustom() {
      const itemConverted = this.handleShowNav(this.items)

      if (this.roles.some(role => role === ADMIN_ROLES.EXCLUSIVE_PARTNER) && !this.roles.some(role => [ADMIN_ROLES.ROOT, ADMIN_ROLES.ADMIN].includes(role))) {
        return itemConverted.filter(item => !EXCLUSIVE_PARTNER_EXCLUDE_ROUTES.includes(item.route))
      }
      if (process.env.VUE_APP_PLATFORM_NAME !== 'Ecomdy') {
        return itemConverted.filter(item => !item.route.startsWith('myTask') && !item.route.startsWith('ads-account'))
      }
      return itemConverted
    },
  },

  methods: {
    handleShowNav(router) {
      let listRouterSelect = router
      if (!this.hasSubscription) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'subscriptions' && item.route !== 'market-partner')
      }

      if (!this.hasRefundAutoTransaction || this.isExclusivePartner) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'auto-refund')
      }

      if (!this.hasPartnership) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'partner-permission' && item.route !== 'partners' && item.route !== 'gift-code' && item.route !== 'market-partner')
      }

      if (!this.isEcomdyPlatform) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'ads-account')
      }

      if (!this.hasProductLink) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'productLinks')
      }

      if (!this.hasInactiveAdAccount) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'inactive-ad-account')
      }

      if (!this.hasReviewAdCreative || this.isExclusivePartner) {
        listRouterSelect = listRouterSelect.filter(item => item.route !== 'ad-creative')
      }

      return listRouterSelect
    },
  },

  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
