<template>
  <div>
    <div>
      <b-card-code>
        <!-- advance search input -->
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="title-card">
            <h3 class="m-0">
              Filters
            </h3>
          </div>
          <div
            v-if="false"
            class="btn-add-user"
          >
            <btn-loading
              v-if="isEcomdyPlatform"
              class="btn-addNewUser d-flex justify-content-center align-items-center m-0"
              type="submit"
              variant="primary"
              block
              :loading="listUserLoading"
              @click="addNewUser"
            >
              {{ $t('user.btnAddNewUser') }}
            </btn-loading>
          </div>
        </div>
        <b-row class="form-product-links">
          <b-col
            md="6"
            class="country-tag d-flex justify-content-between align-items-center"
          >
            <b-form-group class="w-100">
              <label>{{ $t('common.textSearchUserByField', { nameSearch: 'user' }) }}</label>
              <b-input-group class="input-filter">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    data-testid="search-option"
                    class="select-search-custom"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  data-testid="search-by"
                  :placeholder="
                    searchSelected === 'email'
                      ? 'Search by email'
                      : 'Search by ID'
                  "
                  :type="
                    searchSelected === 'localUserId' ||
                      searchSelected === 'adsAccountId'
                      ? 'number'
                      : 'text'
                  "
                  class="d-inline-block"
                  @wheel="handleMouseWheelInput"
                  @input="handleSearch"
                  @keyup.enter="handleSubmitSearch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="country-tag d-flex justify-content-between align-items-center"
          >
            <b-form-group class="w-100">
              <label>
                Country
              </label>
              <v-select
                v-model="countrySelected"
                multiple
                placeholder="Select country"
                :options="availableOptions"
              />
            </b-form-group>
            <b-button
              id="btn-search-user"
              data-testid="btn-search-user"
              variant="primary"
              class="btn-search"
              @click.prevent="handleCountryChange"
            >
              Search
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>
                Filter by spending
              </label>
              <v-select
                v-model="spendSelected"
                class="input-height"
                placeholder="Select spending"
                :options="listSpendOptions"
                @input="handleSpendingChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>
                Account status
              </label>
              <div v-if="hasAutoBlock">
                <b-form-select
                  v-model="statusSelected"
                  @input="handleStatusChange"
                >
                  <b-form-select-option
                    v-for="status in statusOptions"
                    :key="status.value"
                    :value="status.value"
                  >
                    {{ status.text }}
                  </b-form-select-option>
                  <b-form-select-option-group label="Blocked">
                    <b-form-select-option
                      v-for="(statusBlock, index) in statusBlockConvert"
                      :key="index"
                      :value="statusBlock.value"
                    >
                      {{ statusBlock.text }}
                    </b-form-select-option>
                  </b-form-select-option-group>
                </b-form-select>
              </div>

              <div v-else>
                <b-form-select
                  v-model="statusSelected"
                  :options="accountStatusOptions"
                  @input="handleStatusChange"
                />
              </div>
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group>
              <label>Created At</label>

              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Select date"
                  :config="configDate"
                  @on-close="handleDateChange"
                />
                <b-input-group-append
                  v-if="startDate && endDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col
            v-if="hasAM"
            md="3"
          >
            <b-form-group>
              <label>
                Filter by AM
              </label>
              <v-select
                v-model="amSelected"
                class="input-height"
                placeholder="Select AM"
                :options="listAMOptions"
                :filter-by="handleSearchAm"
                @input="handleAmChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="hasSubscription"
            md="2"
          >
            <b-form-group>
              <label>
                Current plan
              </label>

              <b-form-select
                v-model="planSelected"
                :options="planOptions"
                @input="handlePlanChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isOnlyEcomdyPlatform"
            md="2"
          >
            <b-form-group>
              <label>
                Filter by Platform
              </label>

              <b-form-select
                v-model="registerFromServiceSelected"
                :options="registerFromServiceOptions"
                @input="handleServiceChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <label>
                Filter by Tier
              </label>
              <v-select
                v-model="tierSelected"
                class="input-height"
                placeholder="Select tier"
                :options="listTierOptions"
                @input="handleTierChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <label>Spending At</label>

              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeSpendingDate"
                  class="form-control"
                  placeholder="Select date"
                  :config="configDate"
                  @on-close="handleSpendingDateChange"
                />
                <b-input-group-append
                  v-if="spendingFromDate && spendingToDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearSpendingDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <btn-loading
              data-testid="btn-export-data-user"
              class="btn-export btn-partner"
              type="submit"
              block
              variant="outline-primary"
              :loading="loadingDown"
              @click="submitExportData"
            >
              Export data
              <feather-icon
                icon="UploadIcon"
                size="16"
              />
            </btn-loading>
          </b-col>
        </b-row>
      </b-card-code>

      <!-- table -->
      <vue-good-table
        class="table-user-ticket"
        mode="remote"
        :columns="columns"
        :rows="users"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalUser > 0,
        }"
        :sort-options="{
          enabled: totalUser > 0,
        }"
        :is-loading="listUserLoading"
        @on-sort-change="onSortChange"
      >

        <template
          slot-scope="props"
          slot="table-column"
        >
          <span :data-testid="`cell-${props.column.field}`">
            {{ props.column.label }}
          </span>
        </template>

        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <a
            v-if="props.column.field === 'email'"
            data-testid="ads-account-belong-to-user"
            class="d-flex align-items-center cursor-pointer"
            target="_blank"
            :href="`/user/${props.row.localUserId}`"
          >
            <b-img
              :id="`avt-user-${props.row._id}`"
              rounded
              class="ads-avt-img"
              :src="getAvatar(props.row.registerFromService)"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && props.row.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${props.row._id}`"
            >
              {{ getUserPlatform(props.row.registerFromService) }}
            </b-tooltip>
            <div>
              <p class="mb-0 text-secondary font-weight-bold mb-0 underline position-relative">
                {{ props.row.fullName || '' }}
                <span
                  v-if="isPartner(props.row)"
                >
                  <b-img
                    v-b-tooltip.hover.top="'Partner'"
                    rounded
                    class="partner-icon"
                    :src="require('@/assets/images/icons/icon-tick.svg')"
                  />
                </span>
              </p>
              <span class="text-placeholder">{{ props.row.email || '' }}</span>
              <b-img
                v-if="(props.row.onboardingStep > 5)"
                :id="`user-${props.row._id}`"
                size="24"
                class="color-icon-shield position-absolute"
                :src="require('@/assets/images/icons/shield-check.png')"
              />
              <b-tooltip
                v-if="(props.row.onboardingStep > 5)"
                placement="top"
                triggers="hover"
                :target="`user-${props.row._id}`"
              >
                Done onboarding steps
              </b-tooltip>
            </div>
          </a>

          <!-- admin note -->
          <div
            v-else-if="props.column.field === 'adminNote'"
            class="admin-note cursor-pointer"
          >
            <span
              v-if="props.row.hasUserBeenNoted"
              @click="openListNoteSideBar(props.row)"
            >
              <b-img
                :src="require('@/assets/images/icons/admin_note.svg')"
              />
              View note
            </span>
            <span
              v-else
              @click="openAddNoteModal(props.row)"
            >
              <b-img
                :src="require('@/assets/images/icons/admin_note.svg')"
              />
              Add note
            </span>
          </div>

          <!-- country -->
          <div v-else-if="props.column.field === 'country'">
            <span v-if="props.row.country">
              {{ props.row.country }}
            </span>
            <span v-else>
              N/A
            </span>
          </div>

          <!-- Column: Reff -->
          <span
            v-else-if="props.column.field === 'referralTracking.receivedMoney'"
          >
            {{ currencyFormat(receivedMoney(props.row)) }}
          </span>

          <!-- Column: Balance -->
          <span
            v-else-if="props.column.field === 'balance'"
            data-testid="balance-user-management"
          >
            {{ currencyFormat(props.row.balance) }}
          </span>
          <!-- Column: adsAccounts -->
          <span
            v-else-if="props.column.field === 'adsAccounts'"
            :data-testid="`ads-account-column-${props.row.localUserId}`"
          >
            {{
              props.row.adsAccounts && props.row.adsAccounts.length
                ? props.row.adsAccounts.length
                : 0
            }}
          </span>
          <!-- Column: currentPlan -->
          <span v-else-if="props.column.field === 'currentPlan'">
            {{
              props.row.plans && props.row.plans.type
                ? props.row.plans.type
                : 'N/A'
            }}
          </span>

          <!-- Column: Tier -->
          <span v-else-if="props.column.field === 'tier'">
            {{ props.row.tier && props.row.tier.level ? convertToRoman(props.row.tier.level) : 0 }}
          </span>

          <!-- Column: createdDate -->
          <div
            v-else-if="props.column.field === 'createdAt'"
            class="d-flex flex-column"
          >
            <span>{{ formatDate(props.row.createdAt) }}</span>
          </div>

          <!-- Column: verifyEmailAt -->
          <div
            v-else-if="props.column.field === 'verifyEmailAt'"
            class="d-flex flex-column"
          >
            <span>{{ formatDate(props.row.verifyEmailAt) }}</span>
          </div>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'status'"
            data-testid="status-user"
            class="position-relative"
          >
            <feather-icon
              v-if="props.row.status === 'blocked'"
              icon="EyeIcon"
              size="16"
              data-testid="icon-reason-block"
              class="text-body align-middle cursor-pointer icon-reason-block"
              @click="openReasonBlock(props.row)"
            />
            <b-badge
              :variant="statusVariant(props.row.status)"
              data-testid="text-status-user"
              class="text-capitalize"
            >
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: product status -->
          <span
            v-else-if="props.column.field === 'products.status'"
            class="position-relative"
          >
            <div
              v-if="props.row.products && props.row.products.status"
              class="status-product-links"
              :class="statusVariantRejected(props.row.products.status)"
            >
              <span class="content-status">{{ props.row.products.status }}</span>
            </div>
          </span>

          <!-- Column: kyc.by -->
          <!-- <div
            v-else-if="props.column.field === 'kyc.by'"
          >
            <span
              v-if="props.row.kyc && props.row.kyc.by"
              class="content-status"
            >
              {{ props.row.kyc.by }}
            </span>
            <span v-else>
              N/A
            </span>
          </div> -->

          <!-- Column: amId -->
          <div
            v-else-if="props.column.field === 'amId'"
          >
            <span
              v-if="props.row.amId"
              class="content-status"
            >
              {{ props.row.amId }}
            </span>
            <span v-else>
              N/A
            </span>
          </div>

          <span v-else-if="props.column.field === 'roles'">
            <span v-if="props.row.roles && props.row.roles.length === 0">
              N/A
            </span>
            <b-dropdown
              v-else
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-for="(role, index) in props.row.roles"
                :key="index"
              >
                <span>{{ role }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  data-testid="btn-actions-user"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="isUserActive(props.row.status)"
                data-testid="item-change-status-user"
                @click="openBlockModal(props.row)"
              >
                <span>Block</span>
              </b-dropdown-item>

              <div v-else>
                <b-dropdown-item
                  data-testid="item-change-status-user"
                  @click="onActiveUser(props.row.localUserId)"
                >
                  <span>Active</span>
                </b-dropdown-item>
                <b-dropdown-item
                  data-testid="item-add-reason-block"
                  @click="openBlockReasonModal(props.row)"
                >
                  <span>Add reason block</span>
                </b-dropdown-item>
              </div>

              <b-dropdown-item
                data-testid="item-balance-change"
                @click="openSideBar(props.row)"
              >
                <span>Balance Change</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="hasAM"
                data-testid="item-assign-to-AM"
                @click="openAssignToAMSideBar(props.row)"
              >
                <span>Assign to AM</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item
                v-if="!props.row.kyc && isUserActive(props.row.status)"
                @click="markKycUser(props.row)"
              >
                <span>Mark KYC user</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalUser > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalUser }} user(s) </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalUser"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </vue-good-table>
    </div>

    <!-- block modal -->
    <block-modal
      ref="open-block-modal"
      :user-data="userSelected === null ? {} : userSelected"
      @update-user-selected="updateUserSelected"
    />

    <!-- block modal for user blocked by system -->
    <block-reason-modal
      ref="open-reason-modal"
      :user-data="userSelected === null ? {} : userSelected"
      @update-user-selected="updateUserSelected"
    />

    <!-- Balance sidebar -->
    <balance-side-bar
      :show-side-bar.sync="showSideBar"
      :user-data="userSelected === null ? {} : userSelected"
      @update-user-selected="updateUserSelected"
    />
    <add-new-user-side-bar
      :show-side-bar.sync="showAddNewUerSideBar"
      @fetch-user="fetchUser"
    />

    <detail-blocked-user-modal
      ref="open-modal-reason-block"
      :user-data="userSelected === null ? {} : userSelected"
    />

    <reject-reason-modal
      ref="open-modal-reason-reject"
      :user-data="userSelected === null ? {} : userSelected"
    />

    <assign-to-AM-side-bar
      :show-side-bar.sync="showAssignAMSideBar"
      :list-user-info-selected="listUserInfoSelected === null ? [] : listUserInfoSelected"
      @fetch-user="fetchUser"
    />

    <admin-note-side-bar
      :user-data="userSelected === null ? {} : userSelected"
      :show-side-bar.sync="showListNote"
    />

    <!-- Modal note -->
    <add-note-modal
      ref="open-modal-add-note"
      :user-data="userSelected === null ? {} : userSelected"
      @fetchList="fetchUser"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import moment from 'moment'
import {
  DATE_RANGE, USER_STATUS, PROFILE_STATUS, COUNTRIES, LIST_TIER, REGISTER_FROM_SERVICE, REASON_BLOCK,
} from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BImg,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  VBModal,
  BButton,
  VBTooltip,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import vSelect from 'vue-select'
import axios from '@axios'
import envMixin from '@/mixins/envMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { debounce } from 'lodash'
import axiosLib from 'axios'
import usersMixin from '@/mixins/usersMixin'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import AddNoteModal from './components/AddNoteModal.vue'
import BlockModal from './components/BlockModal.vue'
import BalanceSideBar from './components/BalanceSideBar.vue'
import addNewUserSideBar from './components/addNewUserSideBar.vue'
import BlockReasonModal from './components/BlockReasonModal.vue'
import DetailBlockedUserModal from './components/DetailBlockedUserModal.vue'
import RejectReasonModal from './components/RejectReasonModal.vue'
import AssignToAMSideBar from './components/AssignToAMSideBar.vue'
import AdminNoteSideBar from './components/AdminNoteSideBar.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('user')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BlockReasonModal,
    addNewUserSideBar,
    BalanceSideBar,
    BlockModal,
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BtnLoading,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
    flatPickr,
    BCardCode,
    DetailBlockedUserModal,
    RejectReasonModal,
    vSelect,
    BButton,
    AssignToAMSideBar,
    AdminNoteSideBar,
    AddNoteModal,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BTooltip,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, envMixin, paymentMixin, usersMixin, dateTimeFormatMixin],
  data() {
    return {
      loadingDown: false,
      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: 'localUserId',
        type: 'desc',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      // date picker
      rangeDate: null,
      rangeSpendingDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },
      spendingFromDate: null,
      spendingToDate: null,

      // status filter
      statusSelected: null,
      statusOptions: [
        {
          text: 'All status',
          value: null,
        },
        {
          text: 'Activated',
          value: USER_STATUS.ACTIVE,
        },
        // {
        //   text: 'Blocked',
        //   value: USER_STATUS.BLOCKED,
        // },
      ],

      accountStatusOptions: [
        {
          text: 'All status',
          value: null,
        },
        {
          text: 'Activated',
          value: USER_STATUS.ACTIVE,
        },
        {
          text: 'Blocked',
          value: USER_STATUS.BLOCKED,
        },
      ],

      statusBlockSelected: null,

      // plan filter need add more
      planSelected: null,
      countrySelected: [],

      countryOptions: COUNTRIES.map(item => item.name),

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
      openAddFundModal: false,
      ticketComment: '',
      currentTicket: '',

      userSelected: null,

      // sidebar
      showSideBar: false,
      showAddNewUerSideBar: false,
      showAssignAMSideBar: false,
      showListNote: false,

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'Ads account ID',
          value: 'adsAccountId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      amSelected: null,
      listUserInfoSelected: null,

      spendSelected: null,
      listSpendOptions: [
        {
          label: 'No Spending within 3 days',
          value: '3',
        },
        {
          label: 'No Spending within 5 days',
          value: '5',
        },
        {
          label: 'No Spending within 7 days',
          value: '7',
        },
      ],

      tierSelected: null,
      listTierOptions: LIST_TIER,

      // platform filter
      registerFromServiceSelected: null,
      registerFromServiceOptions: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'TTM',
          value: REGISTER_FROM_SERVICE.TTM,
        },
        {
          text: 'Pancake',
          value: REGISTER_FROM_SERVICE.PANCAKE,
        },
        {
          text: 'TTAC',
          value: REGISTER_FROM_SERVICE.TTAC,
        },
      ],
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['listUser', 'listAM', 'message', 'status', 'loading', 'listUserLoading']),
    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'User info',
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Admin Note',
          field: 'adminNote',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Country',
          field: 'country',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Referral',
          field: 'referralTracking.receivedMoney',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf ${this.hasReferral',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle ${this.hasReferral',
          hidden: !this.hasReferral,
        },
        {
          label: 'Ads accounts',
          field: 'adsAccounts',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf td-ads-account-column',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Balance',
          field: 'balance',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Current plan',
          field: 'currentPlan',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasSubscription,
        },
        {
          label: 'Tier',
          field: 'tier',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Create At',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Verify Email At',
          field: 'verifyEmailAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.isVerifyEmailLater,
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Product Status',
          field: 'products.status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasProductLink,
        },
        // {
        //   label: 'KYC',
        //   field: 'kyc.by',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        {
          label: 'Belong to AM',
          field: 'amId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasAM,
        },
        {
          label: 'Roles',
          field: 'roles',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.action'),
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    planOptions() {
      const listPlan = [
        {
          text: 'All plan',
          value: null,
        },
        {
          text: 'Regular 1',
          value: 'regular_1',
        },
        {
          text: 'Regular 2',
          value: 'regular_2',
        },
        {
          text: 'Regular 3',
          value: 'regular_3',
        },
        {
          text: 'Regular 4',
          value: 'regular_4',
        },
        {
          text: 'Regular 5',
          value: 'regular_5',
        },
        {
          text: 'Black Agency',
          value: 'black_agency',
          isHide: this.isGoodvibesPlatform || this.isGyxenPlatform || this.isFinalArrowPlatform,
        },
        {
          text: 'White Agency',
          value: 'white_agency',
          isHide: this.isGyxenPlatform || this.isFinalArrowPlatform,
        },
        {
          text: 'Enterprise',
          value: 'enterprise',
          isHide: this.isGoodvibesPlatform,
        },
      ]

      return listPlan.filter(item => item.isHide !== true)
    },

    statusBlockOptions() {
      return [
        {
          text: 'All blocked',
          value: USER_STATUS.BLOCKED,
          isHide: false,
        },
        {
          text: 'Admin blocked',
          value: USER_STATUS.BLOCKED_BY_ADMIN,
          isHide: false,
        },
        {
          text: 'Recharge failed many times',
          value: USER_STATUS.BLOCKED_BY_RECHARGE_FAILED,
          isHide: !this.hasCreditCard || !this.listStatusAutoBlock.find(item => item.trim() === REASON_BLOCK.BLOCKED_BY_RECHARGE_FAILED),
        },
        {
          text: 'IP violation',
          value: USER_STATUS.BLOCKED_BY_IP_VIOLATION,
          isHide: !this.listStatusAutoBlock.find(item => item.trim() === REASON_BLOCK.BLOCKED_BY_IP_VIOLATION),
        },
        {
          text: 'Domain violation',
          value: USER_STATUS.BLOCKED_BY_DOMAIN_VIOLATION,
          isHide: !this.listStatusAutoBlock.find(item => item.trim() === REASON_BLOCK.BLOCKED_BY_DOMAIN_VIOLATION),
        },
        {
          text: 'Contact violation',
          value: USER_STATUS.BLOCKED_BY_CONTACT_VIOLATION,
          isHide: !this.listStatusAutoBlock.find(item => item.trim() === REASON_BLOCK.BLOCKED_BY_CONTACT_VIOLATION),
        },
        {
          text: 'Payment violation',
          value: USER_STATUS.BLOCKED_BY_PAYMENT_VIOLATION,
          isHide: !this.hasCreditCard || !this.listStatusAutoBlock.find(item => item.trim() === REASON_BLOCK.BLOCKED_BY_PAYMENT_VIOLATION),
        },
      ]
    },

    statusBlockConvert() {
      return this.statusBlockOptions.filter(item => item.isHide !== true)
    },

    totalUser() {
      return _get(this.listUser, ['paging', 'total'], 0)
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    users() {
      return _get(this.listUser, ['content'], [])
    },

    statusVariant() {
      const statusColor = {
        [USER_STATUS.ACTIVE]: 'light-success',
        [USER_STATUS.BLOCKED]: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },

    isUserActive() {
      return stt => stt === USER_STATUS.ACTIVE
    },

    receivedMoney() {
      return data => _get(data, ['referralTracking', 'receivedMoney'])
    },

    availableOptions() {
      return this.countryOptions?.filter(
        opt => this.countrySelected?.indexOf(opt) === -1,
      )
    },

    isPartner() {
      return user => user?.partnership?.status === 'active'
    },

    noFilter() {
      return this.searchTerm === '' && this.countrySelected.length === 0 && this.planSelected === null && this.statusSelected === null && (this.rangeDate === null || this.rangeDate === '')
    },

    listAMOptions() {
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          value: item.localUserId,
        }
        return options
      })
      const optionNone = {
        label: 'N/A',
        value: 0,
      }
      return [optionNone, ...listAMMap]
    },
  },

  created() {
    this.fetchUser()
    this.getListAM()
  },
  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions(['getListUser', 'activeUser', 'markKYCUser', 'getListAM']),

    async onActiveUser(localUserId) {
      const params = {
        status: USER_STATUS.ACTIVE,
        id: localUserId,
      }
      await this.activeUser(params)
      if (this.status) {
        this.toastSuccess('Active user success')
        this.updateUserSelected()
      } else {
        this.toastFailure(this.message)
      }
    },

    async fetchUser() {
      // if (
      //   this.searchTerm
      //     && !this.validateEmail(this.searchTerm)
      //     && this.searchSelected === 'email'
      // ) {
      //   return
      // }

      // check localUserId
      if (this.sortTerm.field === 'localUserId') {
        this.sortTerm.field = '_id'
      }

      abort()

      const { countrySelected } = this
      let countryString = ''
      if (countrySelected && Array.isArray(countrySelected) && countrySelected.length > 0) {
        countryString = this.countrySelected.map(item => encodeURIComponent(item))
        countryString = countryString.join(',')
      }

      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.statusSelected === USER_STATUS.BLOCKED
              ? 'authentication.blockedAt'
              : this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.statusSelected && { status: this.statusSelected }),
        ...(this.planSelected && { planType: this.planSelected }),
        ...(countryString && { country: countryString }),
        ...(this.amSelected && { amId: this.amSelected.value }),
        ...(this.spendSelected && { noSpendingInDays: this.spendSelected.value }),
        ...(this.tierSelected && { tier: this.tierSelected.value }),
        ...(this.registerFromServiceSelected && { registerFromService: this.registerFromServiceSelected }),
      }

      await this.getListUser({
        params,
        cancelToken: cancelToken.token,
      })
      if (!this.status && this.message) {
        this.toastFailure(this.message)
      }
    },

    handleMouseWheelInput(e) {
      e.target.blur()
    },

    handleSearch: debounce(async function (searching) {
      if (searching === '' || ((searching.length > 3 && this.searchSelected !== 'email') || this.validateEmail(this.searchTerm))) {
        await this.fetchUser()
      }
    }, 1000),

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchUser()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchUser()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchUser()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchUser()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchUser()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0]))
          .format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1]))
          .format('YYYY-MM-DD')
        await this.fetchUser()
      }
    },

    async handleSpendingDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        const [fromDateStr, toDateStr] = selectedDates
        this.spendingFromDate = moment(fromDateStr)
          .format('YYYY-MM-DD')
        this.spendingToDate = moment(toDateStr)
          .format('YYYY-MM-DD')
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchUser()
    },
    async clearSpendingDate() {
      this.rangeSpendingDate = null
      this.spendingToDate = null
      this.spendingFromDate = null
      this.dateSelected = null
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchUser()
    },
    async handlePlanChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    async handleCountryChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    async handlePickDateChange(date) {
      const { value } = date
      if (value !== 'ALL') {
        const start = value.start.format('YYYY-MM-DD')
        const end = value.end.format('YYYY-MM-DD')
        this.startDate = start
        this.endDate = end
        this.rangeDate = `${start} to ${end}`
        this.fetchUser()
      } else {
        this.clearDate()
      }
    },

    handleSearchAm(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.value?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },

    async handleAmChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    async handleSpendingChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    async handleTierChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    async handleServiceChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    openBlockModal(data) {
      this.userSelected = data
      this.$refs['open-block-modal'].showModal()
    },

    openReasonBlock(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-block'].showModal()
    },

    openBlockReasonModal(data) {
      this.userSelected = data
      this.$refs['open-reason-modal'].showModal()
    },

    openReasonRejected(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-reject'].showModal()
    },

    async updateUserSelected() {
      await this.fetchUser()
      this.userSelected = this.users.find(
        // eslint-disable-next-line no-underscore-dangle
        user => user._id === this.userSelected._id,
      )
    },

    openSideBar(data) {
      this.userSelected = data
      this.showSideBar = true
    },

    openAssignToAMSideBar(data) {
      this.listUserInfoSelected = [data]
      this.showAssignAMSideBar = true
    },

    openListNoteSideBar(data) {
      this.userSelected = data
      this.showListNote = true
    },

    openAddNoteModal(data) {
      this.userSelected = data
      this.$refs['open-modal-add-note'].showAddModal()
    },

    addNewUser() {
      this.showAddNewUerSideBar = true
    },

    userBalance(balance) {
      return this.currencyFormat(balance)
        .toLocaleString('en-US')
    },

    markKycUser(data) {
      this.$bvModal
        .msgBoxConfirm('do you want to mark KYC this user?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Mark KYC user',
          cancelTitle: 'Close',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            const params = {
              id: data.localUserId,
            }
            this.markKYCUser(params)
            if (this.status) {
              this.toastSuccess('Mark KYC this user success!')
              this.fetchUser()
            } else {
              this.toastFailure(this.message)
            }
          }
        })
    },

    async exportExcel() {
      this.loadingDown = true
      try {
        const { countrySelected } = this
        let countryString = ''
        if (countrySelected && Array.isArray(countrySelected) && countrySelected.length > 0) {
          countryString = countrySelected.map(item => encodeURIComponent(item))
            .join(',')
        }

        const params = {
          ...(this.sortTerm.field && {
            sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
              this.statusSelected === USER_STATUS.BLOCKED
                ? 'authentication.blockedAt'
                : this.sortTerm.field
            }`,
          }),
          ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
          ...(this.startDate && { from: this.startDate }),
          ...(this.endDate && { to: this.endDate }),
          ...(this.statusSelected && { status: this.statusSelected }),
          ...(this.planSelected && { planType: this.planSelected }),
          ...(countryString && { country: countryString }),
          ...(this.amSelected && { amId: this.amSelected.value }),
          ...(this.spendSelected && { noSpendingInDays: this.spendSelected.value }),
          ...(this.tierSelected && { tier: this.tierSelected.value }),
          ...(this.spendingFromDate && { spendingFromDate: this.spendingFromDate }),
          ...(this.spendingToDate && { spendingToDate: this.spendingToDate }),
        }
        await axios.get('/api/admin/users/export', {
          params,
        })
        this.toastSuccess('We will send an email to you the result !')
      } catch (error) {
        const errorMessage = error?.response?.data?.message || 'Server Error'
        this.toastFailure(errorMessage)
      } finally {
        this.loadingDown = false
      }
    },

    submitExportData() {
      this.loadingDown = true
      if (this.noFilter) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want export all data?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Export data',
            cancelTitle: 'Close',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.exportExcel()
            } else {
              this.loadingDown = false
            }
          })
      } else {
        this.exportExcel()
      }
    },

    convertToRoman(num) {
      const char1 = 'I'
      const char2 = 'V'
      let result = ''
      if (num === 0) {
        result = '0'
      }
      if (num > 0 && num <= 3) {
        result = char1.repeat(num)
      }
      if (num === 4) {
        result = char1 + char2
      }
      if (num === 5) {
        result = char2
      }
      if (num > 5 && num < 9) {
        result = char2 + char1.repeat(num - 5)
      }
      return result
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

  /* CSS for customizing scrollbar */
/* width */
::-webkit-scrollbar {
      width: 8px !important;
      height: 8px !important;

      .dark-layout & {
        background-color: #161d31 !important;
      }
    }

/* Track */
::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background:#fafafa;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background:#7D7D7D;
  }

.form-product-links {
  .b-form-tags {
    border: none;
    padding: 0;
  }

  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }
}

.country-tag {
  /* .b-form-tags {
    padding: 0;
    border: none;
  } */

  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1) !important;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-height {
  .vs__dropdown-toggle {
    padding: 3.09px 0 3.5px 0;
  }
}
</style>
<style lang="scss" scoped>
.dropdown-custom {
  position: absolute !important;
  top: -30px;
  left: 33px;
}

.icon-reason-block {
  position: absolute;
  right: -10px;
  top: -15px;
}

.btn-partner {
  margin-top: calc(19.88px + 0.2857rem);
  display: flex;
  justify-content: center;
  align-items: center;

  &.btn-export {
    height: 43px;
  }
}
</style>

<style lang="scss" scoped>
.btn-search {
  margin-top: 13px;
  margin-left: 1rem;
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.partner-icon {
  width: 18px;
  //margin-top: -10px;
}

.color-icon-shield {
  margin-left: 4px;
}

.admin-note {
  min-width: 95px;
}
</style>
