<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-white">

      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12">
        <b-col
          lg="3"
          cols="8"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
        <b-col
          cols="4"
          class="navbar-title px-0 d-flex align-items-center justify-content-end"
        >
          <div class="d-flex justify-content-between">
            <b-link
              class="cover"
              :to="{name:'login'}"
            >
              <span class="nav-link">
                {{ $t('forgotPW.textLogin') }}
              </span>
            </b-link>
          </div>
        </b-col>
      </b-row>
      <!-- Forgot password-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="mx-auto max-width form-forgot"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-2 d-flex justify-content-center"
          >
            {{ $t('forgotPW.textWelcome') }}
          </b-card-title>
          <b-card-text class="mb-3 text-center">
            {{ $t('forgotPW.textGuide') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label-for="forgot-password-email"
                :label="$t('common.labelEmail')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend
                      is-text
                    >
                      <feather-icon icon="MailIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.trim="userEmail"
                      id="forgot-password-email"
                      name="forgot-password-email"
                      class="input-height"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('common.placeholderEmail')"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <btn-loading
                class="my-3 input-height"
                type="submit"
                variant="primary"
                block
                :loading="loading"
              >
                {{ $t('forgotPW.btnSendLinkReset') }}
              </btn-loading>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('forgotPW.textNoAccount') }}</span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;
                {{ $t('forgotPW.btnRegisterNow') }}
              </span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Forgot password-->

      <!-- Left Text-->
      <left-image :src="imgUrl" />
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import { mapGetters } from 'vuex'
import { toastification } from '@core/mixins/toast'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BCardTitle,
    BCardText,

    // components
    BtnLoading,
    BrandLogo,
    LeftImage,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification],
  data() {
    return {
      userEmail: '',
      imgUrl: require('@/assets/images/pages/auth/forgot-password-illustrator.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    ...mapGetters({
      status: 'auth/status',
      error: 'auth/error',
      loading: 'auth/loading',
      message: 'auth/message',
    }),
  },
  methods: {
    async validationForm() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        const params = {
          email: this.userEmail,
        }
        await this.$store.dispatch('auth/resetInit', { params })

        if (this.status) {
          this.toastSuccess(this.$t('forgotPW.textForgotPWSuccess'))
          this.$router.push({ name: 'login' })
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
  .max-width{
    max-width: 600px;
  }
</style>
<style lang="scss" scoped>
  .input-height {
    height: 50px;
  }
  @media (max-width: 767px) {
    .form-login {
      padding-left: 0;
      padding-right: 0;
      .description {
        line-height: 24px;
        margin-bottom: 2rem !important;
      }
    }
    .navbar-auth-custom {
      width: calc(100%);
    }
  }
  .form-forgot {
    padding: 0 2rem;
  }
</style>
