import { showLoading, hideLoading } from '@/libs/common'

export const REQUEST = (state, isShowLoading = false) => {
  state.loading = true
  state.error = ''
  if (isShowLoading) {
    showLoading()
  }
}

export const SUCCESS = (state, isShowLoading = false) => {
  state.status = true
  state.loading = false
  state.error = ''
  if (isShowLoading) {
    hideLoading()
  }
}

export const FAILURE = (state, error, isShowLoading = false) => {
  state.loading = false
  state.status = false
  state.error = error

  if (isShowLoading) {
    hideLoading()
  }
}

export const getResponse = data => data.result
