import { MyTaskService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getAllTask({ commit }, params) {
    commit('GET_ALL_TASKS')
    try {
      const { data } = await MyTaskService.getAllTask(params)
      commit('GET_ALL_TASKS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ALL_TASKS_FAILURE', error)
    }
  },
  setSearchTask({ commit }, searchTask) {
    commit('SET_SEARCH_TERM', searchTask)
  },
}
