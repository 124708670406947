<template>
  <b-modal
    ref="confirm-cashback-modal"
    id="confirm-cashback-modal"
    class="confirm-cashback-modal"
    hide-footer
    centered
  >
    <div class="d-flex justify-content-center">
      <b-img src="@/assets/images/pages/payment/bg-confirm-cashback.png" />
    </div>
    <div class="text-center">
      <p
        v-if="!cashbackSelected._id"
        class="my-2 font-20 font-weight-600"
      >
        Are you sure you want cashback all?
      </p>
      <div
        v-else
        class="mb-2"
      >
        <p class="mb-0 font-20 font-weight-600">
          Are you sure you want cashback?
        </p>
        <div class="content-cashback-modal">
          <p class="mb-0">
            Transaction ID: {{ cashbackSelected._id }}
          </p>
          <p>Total cashback: {{ currencyFormat(cashbackSelected.amount) }}</p>
        </div>
      </div>
      <div class="d-flex">
        <b-button
          size="sm"
          variant="outline-primary"
          class="btn-cancel mr-1 w-100"
          @click="hideModal"
        >
          Cancel
        </b-button>
        <btn-loading
          class="btn-cashback w-100"
          type="submit"
          block
          variant="primary"
          :disabled="loading"
          :loading="loading"
          @click="handleConfirmCashback"
        >
          Cashback now
        </btn-loading>
      </div>
    </div></b-modal>
</template>
<script>

import {
  BModal,
  BImg,
  BButton,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import btnLoading from '@/layouts/components/BtnLoading.vue'
import numberFormatMixin from '@/mixins/numberFormatMixin'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('payment')

export default {
  components: {
    BModal,
    BImg,
    BButton,
    btnLoading,
  },
  mixins: [toastification, numberFormatMixin],

  props: {
    cashbackSelected: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  methods: {

    ...mapActions(['handleCashback']),

    hideModal() {
      this.$refs['confirm-cashback-modal'].hide()
    },

    showModal() {
      this.$refs['confirm-cashback-modal'].show()
    },

    async handleConfirmCashback() {
      // eslint-disable-next-line no-underscore-dangle
      const params = this.cashbackSelected._id ? {
        // eslint-disable-next-line no-underscore-dangle
        transactionIds: [this.cashbackSelected._id],
      } : {}
      await this.handleCashback(params)
      if (this.status) {
        this.toastSuccess('Cashback transaction successfully!')
        this.hideModal()
        this.$emit('fetchCashbackTransaction')
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.content-cashback-modal {
  border: 1px solid #ddd;
  border-radius: 12px;
  width: max-content;
  margin: 1rem auto 0;
  text-align: left;
  padding: 1rem 24px 0;
}
</style>
