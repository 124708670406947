import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {

  // get member partner list
  GET_MEMBER_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  GET_MEMBER_PARTNER_SUCCESS(state, data) {
    SUCCESS(state)
    state.listMemberPartner = data
  },
  GET_MEMBER_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get partner list
  GET_LIST_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_PARTNER_SUCCESS(state, data) {
    SUCCESS(state)
    state.listPartner = data
  },
  GET_LIST_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get total member partner
  GET_TOTAL_MEMBER_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  GET_TOTAL_MEMBER_PARTNER_SUCCESS(state, data) {
    SUCCESS(state)
    state.totalMember = data
  },
  GET_TOTAL_MEMBER_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  CREATE_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  REJECT_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  REJECT_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  REJECT_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  UPDATE_ROLE_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_ROLE_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_ROLE_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  DELETE_ROLE_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  DELETE_ROLE_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  DELETE_ROLE_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  UPGRADE_TO_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  UPGRADE_TO_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  UPGRADE_TO_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  ASSIGN_TO_ANOTHER_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  ASSIGN_TO_ANOTHER_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  ASSIGN_TO_ANOTHER_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_SPENDING_DEPOSIT_PARTNER_DETAIL_REQUEST(state) {
    REQUEST(state)
  },
  GET_SPENDING_DEPOSIT_PARTNER_DETAIL_SUCCESS(state, data) {
    SUCCESS(state)
    state.totalSpendingAndDeposit = data
  },
  GET_SPENDING_DEPOSIT_PARTNER_DETAIL_FAILURE(state, error) {
    FAILURE(state, error)
    state.totalSpendingAndDeposit = null
  },

  GET_TOTAL_MEMBER_LIFE_TIME_PARTNER_DETAIL_REQUEST(state) {
    REQUEST(state)
  },
  GET_TOTAL_MEMBER_LIFE_TIME_PARTNER_DETAIL_SUCCESS(state, data) {
    SUCCESS(state)
    state.totalMemberLifeTime = data
  },
  GET_TOTAL_MEMBER_LIFE_TIME_PARTNER_DETAIL_FAILURE(state, error) {
    FAILURE(state, error)
    state.totalMemberLifeTime = null
  },

  GET_TOTAL_MEMBER_MONTH_PARTNER_DETAIL_REQUEST(state) {
    REQUEST(state)
  },
  GET_TOTAL_MEMBER_MONTH_PARTNER_DETAIL_SUCCESS(state, data) {
    SUCCESS(state)
    state.totalMemberMonth = data
  },
  GET_TOTAL_MEMBER_MONTH_PARTNER_DETAIL_FAILURE(state, error) {
    FAILURE(state, error)
    state.totalMemberMonth = null
  },

  GET_TOTAL_ADS_ACCOUNT_MONTH_PARTNER_DETAIL_REQUEST(state) {
    REQUEST(state)
  },
  GET_TOTAL_ADS_ACCOUNT_MONTH_PARTNER_DETAIL_SUCCESS(state, data) {
    SUCCESS(state)
    state.totalAdsAccountMonth = data
  },
  GET_TOTAL_ADS_ACCOUNT_MONTH_PARTNER_DETAIL_FAILURE(state, error) {
    FAILURE(state, error)
    state.totalAdsAccountMonth = null
  },

  GET_ADS_ACCOUNTS_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  GET_ADS_ACCOUNTS_PARTNER_SUCCESS(state, data) {
    SUCCESS(state)
    state.partnerAdsAccounts = data
  },
  GET_ADS_ACCOUNTS_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
    state.partnerAdsAccounts.totalAdsAccounts = null
  },

  REMOVE_MEMBER_OF_PARTNERSHIP_REQUEST(state) {
    REQUEST(state)
  },
  REMOVE_MEMBER_OF_PARTNERSHIP_SUCCESS(state) {
    SUCCESS(state)
  },
  REMOVE_MEMBER_OF_PARTNERSHIP_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_LIST_AD_ACCOUNT_USER_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_AD_ACCOUNT_USER_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAdAccountUser = data
  },
  GET_LIST_AD_ACCOUNT_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  CHANGE_STATUS_FORM_WHITELIST_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  CHANGE_STATUS_FORM_WHITELIST_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  CHANGE_STATUS_FORM_WHITELIST_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
