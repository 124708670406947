import { REGISTER_FROM_SERVICE } from '@/constants'

export default {
  methods: {
    getAvatar(platform) {
      switch (platform) {
        case REGISTER_FROM_SERVICE.TTAC:
          // eslint-disable-next-line global-require
          return require('@/assets/images/common/ic-user-tiktok_2.svg')
        case REGISTER_FROM_SERVICE.PANCAKE:
          // eslint-disable-next-line global-require
          return require('@/assets/images/common/ic-user-pancake.svg')
        default:
          // eslint-disable-next-line global-require
          return require('@/assets/images/common/ic-user-info.svg')
      }
    },
    getUserPlatform(platform) {
      switch (platform) {
        case REGISTER_FROM_SERVICE.TTAC:
          return 'TikTok app center user'
        case REGISTER_FROM_SERVICE.PANCAKE:
          return 'Pancake user'
        default:
          return 'TTM user'
      }
    },
  },
}
