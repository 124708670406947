export default [
  {
    path: '/market-partner',
    name: 'market-partner',
    component: () => import('@/views/market-partner/MarketPartner.vue'),
    meta: {
      pageTitle: 'Market Package',
      requireAuth: true,
    },
  },
]
