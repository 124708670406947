/* eslint-disable no-underscore-dangle */
import axios from '@axios'

export default {
  getUser({ id }) {
    return axios.get(`/api/admin/users/${id}`)
  },

  getAdsAccountsUser(params) {
    const { id, ...realParams } = params
    return axios.get(`/api/admin/users/${id}/ads-accounts`, { params: realParams })
  },

  getListUser(params, cancelToken) {
    return axios.get('/api/admin/users', { params, cancelToken })
  },

  activeUser(params) {
    return axios.patch(`/api/admin/users/${params.id}/status`, params)
  },

  blockUser(params) {
    return axios.patch(`/api/admin/users/${params.id}/status`, params)
  },

  addWhiteListBlock(params) {
    return axios.patch(`/api/admin-users/${params.id}/whitelist-block`, { whitelist: params.whitelist })
  },

  updateUser(params) {
    return axios.patch(`/api/admin/users/${params.id}`, params)
  },

  createProductLinks(params) {
    return axios.put(`/api/admin/users/${params.id}/product-profile`, params.detail)
  },

  updateBalanceUser(params) {
    return axios.patch(`/api/admin/users/${params.id}/balance`, params)
  },

  changePlanType(params) {
    return axios.patch(`/api/admin/users/${params.id}/plans`, params)
  },

  deleteAdsAccount(params) {
    return axios.delete(`/api/admin/users/${params.userId}/ads-accounts`, { data: { adsAccountIds: params.adsAccounts } })
  },

  cancelSubscription(params) {
    return axios.post(`/api/admin/users/${params.userId}/cancel-subscription`)
  },

  addNewUser(params) {
    return axios.post('/api/admin/users/create/black-agency', params)
  },

  profileStatusUpdate(params) {
    return axios.patch(`/api/admin/users/${params.idUser}/profile-status`, params.detail)
  },

  getAvailableCreditCard(params) {
    return axios.get(`/api/admin/users/${params.id}/payment-methods`)
  },

  updateStatusProductLinks(params) {
    return axios.put(`/api/admin/users/${params.id}/product-profile`, params)
  },

  markKYCUser(params) {
    return axios.put(`/api/admin/users/${params.id}/kyc/done`, params)
  },

  getListAM(params) {
    return axios.get('/api/admin/accounts-management', { params })
  },

  listAdminNote(params) {
    return axios.get(`/api/admin/users/${params.id}/notes`, params)
  },

  addNote(params) {
    return axios.post(`/api/admin/users/${params.id}/notes`, params)
  },

  updateNote(params) {
    return axios.patch(`/api/admin/users/${params.id}/notes/${params.noteId}`, params)
  },

  deleteNote(params) {
    return axios.delete(`/api/admin/users/${params.id}/notes`, { data: { noteIds: params.noteIds } })
  },

  assignMultiUserToAM(params) {
    return axios.put('/api/admin/accounts-management/multi-users/assign', params)
  },

  resignMultiUserToAM(params) {
    return axios.put('/api/admin/accounts-management/multi-users/resign', params)
  },

  getListPartnerBcs(params) {
    return axios.get(`/api/admin/ads-accounts/users/${params.id}/partner-bcs`, params)
  },

  updateTier(params) {
    return axios.patch(`/api/admin/users/${params.id}/tier-level`, {
      tierLevel: params.tier,
    })
  },

  createAdsAccount(params) {
    return axios.post('/api/admin/ads-accounts', params)
  },

  closeAdsAccount(params) {
    return axios.delete(`/api/admin/ads-accounts/users/${params.userId}/close`, { data: { adsAccountIds: params.adsAccounts } })
  },

  disconnectTiktok(params) {
    return axios.patch(`/api/admin-users/${params.id}/disconnect-tiktok`)
  },

  fetchDetailUserRelated({ id }) {
    return axios.get(`/api/admin/ads-accounts/users/${id}/statistics`)
  },
}
