import { MarketPackageService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getListPackage({ commit }, params) {
    commit('GET_LIST_MARKET_PACKAGE_REQUEST')
    try {
      const { data } = await MarketPackageService.getListPackage(params)
      commit('GET_LIST_MARKET_PACKAGE_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_LIST_MARKET_PACKAGE_FAILURE', error)
    }
  },

  // update a package
  async updateAPackage({ commit }, params) {
    commit('UPDATE_A_PACKAGE_REQUEST')
    try {
      const { data } = await MarketPackageService.updateAPackage(params)
      commit('UPDATE_A_PACKAGE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_A_PACKAGE_FAILURE', error)
    }
  },
}
