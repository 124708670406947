<template>
  <div>

    <b-card-code class="filter-card">
      <!-- advance search input -->
      <b-row>
        <b-col
          md="12"
          class="mb-1"
        >
          <b-form-group>
            <label>Search user by field</label>
            <b-input-group class="input-filter">
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>

              <b-form-input
                v-model.trim="searchTerm"
                :placeholder="handleSearchPlaceHolder"
                :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                class="d-inline-block"
                :class="searchSelected === 'email' ? 'input-search' : ''"
                @wheel="handleMouseWheelInput"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>
              Transfer status
            </label>

            <b-form-select
              v-model="statusSelected"
              class="w-100"
              :options="statusOptions"
              placeholder="All status"
              @input="handleStatusChange"
            />

          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Create At</label>

            <b-input-group
              class="input-group-merge"
            >
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Select date"
                :config="configDate"
                @on-close="handleDateChange"
              />
              <b-input-group-append
                v-if="startDate && endDate"
                is-text
                class="close-icon"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearDate"
                />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="CalendarIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="tickets"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: totalTickets > 0,
      }"
      :sort-options="{
        enabled: totalTickets > 0,
      }"
      :is-loading="refundTransactionLoading"
      :group-options="{
        enabled: false
      }"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >

        <!-- Column: User Info -->
        <a
          v-if="props.column.field === 'userId'"
          class="d-flex align-items-center"
          target="_blank"
          :href="`/user/${props.row.localUserId}`"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="userInfo()"
          />
          <div>
            <p class="mb-0 text-secondary font-weight-bold mb-0 underline position-relative">
              {{ props.row.userId && props.row.userId.fullName && capitalise(props.row.userId.fullName) }}
            </p>
            <span
              v-if="props.row.userId && props.row.userId.email"
              class="text-placeholder"
            >
              {{ props.row.userId.email }}
            </span>
          </div>
        </a>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="adsStatusVariant(props.row.status)">
            {{ capitalise(props.row.status) }}
          </b-badge>
        </span>

        <span v-else-if="props.column.field === 'type'">
          <p class="text-secondary font-weight-bold">
            {{ formatTransType(props.row.type) }}
          </p>
        </span>

        <span v-else-if="props.column.field === 'createdAt'">
          <p class="text-secondary font-weight-bold mb-0">
            {{ getFormatDate(props.row.createdAt) || 'N/A' }}
          </p>
          <span class="font-14 text-placeholder">{{ getFormatTime(props.row.createdAt) }}</span>
        </span>

        <span v-else-if="props.column.field === 'total'">
          <p class="text-secondary font-weight-bold">
            {{ currencyFormat(props.row.details.amount) }}
          </p>
        </span>

        <span v-else-if="props.column.field === 'paymentInfo'">
          <ul style="padding-left: 0; list-style: circle">
            <li v-if="props.row.details.bankType">{{ props.row.details.bankType }}</li>
            <li v-if="props.row.details.bankAccountName">Recipient name: {{ props.row.details.bankAccountName }}</li>
            <li v-if="props.row.details.bankAccountNumber">Account number: {{ props.row.details.bankAccountNumber }}</li>
          </ul>
        </span>

        <!-- Column: Actions -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="props.row.status === ADS_ACCOUNT_TICKETS_STATUS.OPEN">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="approveAccountTicket(props.row)"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckCircleIcon"
                />
                <span>
                  Approve
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="rejectAccountTicket(props.row)"
              >
                <feather-icon
                  icon="ThumbsDownIcon"
                  class="mr-50"
                />
                <span>Reject</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <span v-else>
            <b-dropdown
              :ref="`dropdown-${props.row.originalIndex}`"
              no-caret
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <div class="position-relative pl-1 pr-1 reason-reject">
                <p class="text-capitalize font-weight-bolder">{{ props.row.status }}</p>
                <a @click="$refs[`dropdown-${props.row.originalIndex}`].hide(true)">
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    class="position-absolute position-top-0 position-right-0 mr-1"
                  />
                </a>

                <b-dropdown-divider v-if="props.row.by && props.row.by.localUserId" />
                <div v-if="props.row.by && props.row.by.localUserId">
                  <label>Admin ID</label>
                  <p>{{ props.row.by && props.row.by.localUserId }}</p>
                </div>

                <b-badge
                  v-if="props.row.rejectedReason"
                  variant="light-danger"
                  class="reason-reject-message"
                >
                  <p class="mb-0">{{ props.row.rejectedReason }}</p>
                </b-badge>
              </div>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalTickets > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[{'justify-content-center':$device.mobile}, {'justify-content-between':!$device.mobile}]"
        >

          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Ticket per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap ">
              Showing
              {{ pageLength * (currentPage-1) + 1 }}
              to
              {{ pageLength * currentPage }}
              of {{ totalTickets }} ticket(s)
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalTickets"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <div
        v-if="errorUserTransaction"
        slot="emptystate"
      >
        <p class="text-error">
          {{ errorUserTransaction }}
        </p>
      </div>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>

    <!-- ticket handler -->
    <payment-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :action-type="actionType"
      :clear-task-data="clearTaskData"
      @reject-ticket="onRejectAccountTicket"
    />

  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import moment from 'moment'
import {
  ADS_ACCOUNT_TICKETS_STATUS,
  DATE_RANGE,
  TICKET_ACTIONS,
  TICKET_TYPE,
  PAYMENT_METHOD,
} from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BDropdownDivider,
  BImg,
  BFormSelect, BDropdown, BDropdownItem, BBadge, BFormGroup, BFormInput, BInputGroupPrepend, BRow, BCol, BInputGroupAppend,
  BInputGroup, VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'
import { debounce } from 'lodash'
import axiosLib from 'axios'
import PaymentSidebar from './PaymentSidebar.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersMyTask, mapActions: mapActionsMyTask } = createNamespacedHelpers('myTask')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

// clearTaskData
const blankTask = {
  id: null,
  title: '',
  type: '',
  status: '',
  userId: '',
  details: {},
  description: '',
}

export default {
  components: {
    BPagination,
    BFormSelect,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
    BImg,
    // BFormSelectOption,
    flatPickr,
    BCardCode,
    PaymentSidebar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, paymentMixin, envMixin],
  data() {
    return {
      currentPage: 1,
      searchTerm: '',
      ADS_ACCOUNT_TICKETS_STATUS,

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },

      // status filter
      statusOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: 'Approved',
          value: ADS_ACCOUNT_TICKETS_STATUS.APPROVED,
        },
        {
          text: 'Rejected',
          value: ADS_ACCOUNT_TICKETS_STATUS.REJECTED,
        },
        {
          text: 'Open',
          value: ADS_ACCOUNT_TICKETS_STATUS.OPEN,
        },
        {
          text: 'Closed',
          value: ADS_ACCOUNT_TICKETS_STATUS.CLOSED,
        },
      ],
      statusSelected: '',

      // Timzone
      timezoneSelected: {
        name: 'All ',
        value: '',
      },
      timezoneOptions: [
        {
          name: 'All status',
          value: '',
        },
      ],

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
        {
          text: 'Transaction ID',
          value: 'note',
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
      openAddFundModal: false,
      ticketComment: '',
      currentTicket: '',
      isTaskHandlerSidebarActive: false,
      task: (JSON.parse(JSON.stringify(blankTask))),
      actionType: TICKET_ACTIONS.REJECT,

      methodSelected: { label: 'All method', value: '' },

      errorUserTransaction: '',
    }
  },

  computed: {
    ...mapGetters(['refundTransaction', 'message', 'status', 'loading', 'refundTransactionLoading']),
    ...mapGettersMyTask(['searchTask']),
    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.userInfo'),
          field: 'userId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Transfer status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Amount',
          field: 'total',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Payment Info',
          field: 'paymentInfo',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          width: '300px',
        },
        {
          label: 'Transaction type',
          field: 'type',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.action'),
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.createdAt'),
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-midle',
        },
      ]
    },

    methodOptions() {
      const listPaymentMethod = [
        {
          label: 'All method',
          value: '',
          isShow: true,
        },
        {
          label: 'USDT',
          value: PAYMENT_METHOD.USDT,
          isShow: this.hasUSDT,
        },
        {
          label: 'AirWallex',
          value: PAYMENT_METHOD.AIRWALLEX,
          isShow: this.hasAirwallex,
        },
        {
          label: 'Wise',
          value: PAYMENT_METHOD.TRANSFERWISE,
          isShow: this.hasTransferWise,
        },
      ]

      return listPaymentMethod.filter(item => item.isShow === true)
    },

    getPayment() {
      return data => _get(data, ['details', 'paymentMethod'], '')
    },

    getNamePayment() {
      return data => (data === PAYMENT_METHOD.TRANSFERWISE ? 'Wise' : data)
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    tickets() {
      return _get(this.refundTransaction, ['content'], [])
    },

    totalTickets() {
      return _get(this.refundTransaction, ['paging', 'total'], 0)
    },

    adsStatusVariant() {
      const statusColor = {
        [ADS_ACCOUNT_TICKETS_STATUS.APPROVED]: 'light-success',
        [ADS_ACCOUNT_TICKETS_STATUS.CLOSED]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.PENDING]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.REJECTED]: 'light-danger',
        [ADS_ACCOUNT_TICKETS_STATUS.OPEN]: 'light-info',
      }

      return status => statusColor[status]
    },

    paymentVariant() {
      const statusColor = {
        '+': 'light-success',
        '-': 'light-danger',
      }

      return status => statusColor[status]
    },

    adsStatusTxt() {
      const statusColor = {
        [ADS_ACCOUNT_TICKETS_STATUS.APPROVED]: 'light-success',
        [ADS_ACCOUNT_TICKETS_STATUS.CLOSED]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.PENDING]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.REJECTED]: 'light-danger',
        [ADS_ACCOUNT_TICKETS_STATUS.OPEN]: 'light-info',
      }

      return status => statusColor[status]
    },

    alertTextColor() {
      const txtColor = {
        [true]: 'text-danger',
        [false]: 'text-success',
      }

      return txt => txtColor[txt]
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    handleSearchPlaceHolder() {
      switch (this.searchSelected) {
        case 'localUserId': return 'Search by user ID'
        case 'email': return 'Search by user email'
        case 'transactionId': return 'Search by note'
        default: return ''
      }
    },
  },

  async created() {
    if (this.searchTask.id && this.searchTask.status) {
      this.searchTerm = this.searchTask.id
      this.statusSelected = this.searchTask.status
      this.handleSearch(this.searchTask.id)
    }
    await this.fetchRefundTransaction()
  },

  beforeDestroy() {
    abort()
    this.setSearchTask({ id: '', status: '' })
  },

  methods: {
    ...mapActions(['getRefundTransaction', 'approveTicket', 'rejectTicket', 'commentTicket']),
    ...mapActionsMyTask(['setSearchTask']),

    capitalise(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    formatDate(date) {
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a')
      return moment(date).format('MMM Do YY') // Oct 15th 21
    },

    async rejectAccountTicket(data) {
      this.task = data
      this.isTaskHandlerSidebarActive = true
      this.actionType = TICKET_ACTIONS.REJECT
    },

    async onRejectAccountTicket(data, message) {
      const { _id } = data
      const params = {
        id: _id,
        message,
      }
      await this.rejectTicket(params)
      if (this.status) {
        this.toastSuccess(this.$t('account.textRejectSuccess'))
        this.fetchRefundTransaction()
      } else {
        this.toastFailure(this.message)
      }
    },

    clearTaskData() {
      const task = (JSON.parse(JSON.stringify(blankTask)))
      task.value = JSON.parse(JSON.stringify(blankTask))
    },

    async approveAccountTicket(data) {
      const { _id, userId } = data
      const BCId = data.details.businessId

      const params = {
        id: _id,
        adsAccountId: userId,
        BCId,
      }
      await this.approveTicket(params)
      if (this.status) {
        this.toastSuccess(this.$t('account.textApproveSuccess'))
        this.fetchRefundTransaction()
      } else {
        this.toastFailure(this.message)
      }
    },

    showCommentModal(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.currentTicket = data._id
    },

    handleCommentSubmit() {
      if (this.ticketComment) {
        this.commentAccountTicket(this.currentTicket, this.ticketComment)
      }
    },

    async commentAccountTicket(id, message) {
      const params = {
        id,
        message,
      }
      await this.commentTicket(params)
      this.currentTicket = ''
      this.ticketComment = ''
      if (this.status) {
        this.toastSuccess(this.$t('account.textCommentSuccess'))
        this.fetchRefundTransaction()
      } else {
        this.toastFailure(this.message)
      }
    },

    async fetchRefundTransaction() {
      if (this.searchTerm && !this.validateEmail(this.searchTerm) && this.searchSelected === 'email') {
        return
      }

      abort()

      const params = {
        type: TICKET_TYPE.REFUND,
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.statusSelected && { status: [this.statusSelected] }),
        ...(this.methodSelected.value && { paymentMethod: this.methodSelected.value }),
      }
      await this.getRefundTransaction({ params })
      if (!this.status && this.message) {
        this.errorUserTransaction = this.message
        this.toastFailure(this.message)
      }
    },

    handleSearch: debounce(async function (searching) {
      if (searching === '' || ((searching.length > 3 && this.searchSelected !== 'email') || this.validateEmail(this.searchTerm))) {
        await this.fetchRefundTransaction()
      }
    }, 1000),

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchRefundTransaction()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchRefundTransaction()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchRefundTransaction()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchRefundTransaction()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchRefundTransaction()
    },

    async handleMethodChange() {
      this.currentPage = 1
      await this.fetchRefundTransaction()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) { // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0])).format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
        await this.fetchRefundTransaction()
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchRefundTransaction()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchRefundTransaction()
    },

    async handleSelectPaymentMethod() {
      this.currentPage = 1
      await this.fetchRefundTransaction()
    },

    async handlePickDateChange(date) {
      const { value } = date
      if (value !== 'ALL') {
        const start = value.start.format('YYYY-MM-DD')
        const end = value.end.format('YYYY-MM-DD')
        this.startDate = start
        this.endDate = end
        this.rangeDate = `${start} to ${end}`
        this.fetchRefundTransaction()
      } else {
        this.clearDate()
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom{
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.reason-reject {
  width: 250px;
  max-height: 200px;
  overflow: auto;

  &-message {
    display: block;
    white-space: normal;
    text-align: start;
  }
}

.filter-card {
  padding: 24px;
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #ebe9f1;
  border-bottom: 0px;

  .card-header {
    display: none;
  }

  .card-body {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }
}

.dropdown-menu.show {
  .dropdown-item:hover {
    background-color: #F02548;
    color: #fff;
  }
}

.transaction-area {
  button {
    padding: 0;
    color: #3d405b;
    &:hover {
      color: #3d405b;
      opacity: 0.5;
    }
  }

  .dropdown-menu.show {
    padding: 10px;
    max-width: 300px;
    word-break: break-word;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .vgt-responsive {
  min-height: 40vh;
}

.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}

</style>
