import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get user detail
  GET_LIST_INACTIVE_AD_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_INACTIVE_AD_ACCOUNT_SUCCESS(state, data) {
    SUCCESS(state)
    state.listInactiveAdAccount = data
  },
  GET_LIST_INACTIVE_AD_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // disable multi inactive ad account
  DISABLE_MULTI_INACTIVE_AD_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  DISABLE_MULTI_INACTIVE_AD_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  DISABLE_MULTI_INACTIVE_AD_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // reactive ad account
  REACTIVE_AD_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  REACTIVE_AD_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  REACTIVE_AD_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
