<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    width="420px"
    right
    backdrop
    shadow
    :title="editMode ? 'Edit Gift code' : 'Add Gift code'"
    @hidden="onHidden"
  >
    <div class="p-2">
      <validation-observer
        ref="formValidation"
        v-slot="{ invalid }"
      >
        <b-form>
          <!-- Worth gift -->
          <b-form-group
            label="Worth gift"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="worth gift"
              rules="required|greaterThanZero"
            >
              <b-input-group
                prepend="$"
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="worthGift"
                  type="number"
                  name="worthGift"
                  pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                  placeholder="Enter worth gift"
                  :disabled="isSavedGiftCode"
                  :state="errors.length > 0 ? false : null"
                  @keypress="restrictChars($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Quantity -->
          <b-form-group
            label="Quantity"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="quantity"
              rules="required|greaterThanZero"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="quantity"
                  name="quantity"
                  pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                  type="number"
                  placeholder="Enter quantity"
                  :disabled="isSavedGiftCode"
                  :state="errors.length > 0 ? false : null"
                  @keypress="restrictChars($event)"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Start -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="startDate"
              rules="required"
            >
              <label>Start date</label>
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid' : null"
                class="input-group-merge"
              >
                <flat-pickr
                  v-model="startedAt"
                  name="startDate"
                  class="form-control"
                  placeholder="Select date start"
                  :disabled="isSavedGiftCode"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i' }"
                  @on-close="startDateChange"
                />
                <b-input-group-append
                  v-if="startedAt && !isSavedGiftCode"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearStartDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Expired -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="endDate"
              rules="required"
            >
              <label>End date</label>
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid' : null"
                class="input-group-merge"
              >
                <flat-pickr
                  v-model="endedAt"
                  class="form-control"
                  name="endDate"
                  placeholder="Select date end"
                  :disabled="isSavedGiftCode"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i' }"
                  @on-close="expiredDateChange"
                />
                <b-input-group-append
                  v-if="endedAt && !isSavedGiftCode"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearExpiredDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Min total spend -->
          <b-form-group
            label="Min total spend"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="minTotalSpend"
              rules="required"
            >
              <b-input-group
                prepend="$"
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="minSpend"
                  type="number"
                  name="minTotalSpend"
                  :disabled="isSavedGiftCode"
                  placeholder="Enter min total spend"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Max total spend -->
          <b-form-group
            label="Max total spend"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="maxTotalSpend"
              rules="required"
            >
              <b-input-group
                prepend="$"
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="maxSpend"
                  type="number"
                  name="email"
                  placeholder="Enter max total spend"
                  :disabled="isSavedGiftCode"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Time of use within -->
          <b-form-group
            label="Time of use within"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="time of use within"
              rules="required|greaterThanZero"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="timeOfUseWithin"
                  name="time of use within"
                  pattern=" 0+\.[0-9]*[1-9][0-9]*$"
                  type="number"
                  placeholder="Enter time of use within"
                  :disabled="isSavedGiftCode"
                  :state="errors.length > 0 ? false : null"
                  @keypress="restrictChars($event)"
                />
                <b-input-group-append
                  v-if="timeOfUseWithin"
                  is-text
                >
                  <p class="m-0">
                    days
                  </p>
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Minimum Required Spending -->
          <b-form-group
            label="Minimum Required Spending"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="minimum Required Spending"
              rules="required|greaterThanZero"
            >
              <b-input-group
                prepend="$"
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="minimumSpending"
                  type="number"
                  name="minimum Required Spending"
                  placeholder="Enter minimum Required Spending"
                  :disabled="isSavedGiftCode"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Assign for partner -->
          <b-form-group
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="email partner"
              rules="required"
            >
              <label
                class="form-label"
                for="postal-code"
              >
                Email partner
              </label>
              <v-select
                v-model="emailPartner"
                name="email partner"
                class="input-height"
                placeholder="Search by email or Id"
                :class="errors.length > 0 ? 'is-invalid' : null"
                :options="partnerOptions"
                :clearable="false"
                :disabled="editMode"
                :filter-by="handleSearchPartner"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>

          <div class="mt-2">
            <!-- edit buttons -->
            <btn-loading
              v-if="editMode"
              type="submit"
              variant="primary"
              block
              :loading="loading"
              @click.prevent="submitUpdateGiftCode"
            >
              Save
            </btn-loading>
            <!-- create buttons -->
            <btn-loading
              v-else
              type="submit"
              variant="primary"
              block
              :disabled="invalid"
              :loading="loading"
              @click.prevent="submitCreateGiftCode"
            >
              Create
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import {
  BSidebar,
  // BRow,
  // BCol,
  BInputGroupAppend,
  BForm,
  BFormGroup,
  BFormInput,
  // BButton,
  BInputGroup,
  // BInputGroupAppend,
  // BFormTextarea,
} from 'bootstrap-vue'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, greaterThanZero } from '@validations'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('giftCode')

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    flatPickr,
    BInputGroupAppend,
    // BFormTextarea,
    // component
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, numberFormatMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
      required: true,
    },
    giftCodeSelected: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      required,
      greaterThanZero,
      show: false,

      currentPage: 1,
      pageLength: 10,

      worthGift: '',
      quantity: '',
      startedAt: null,
      endedAt: null,
      minSpend: '',
      maxSpend: '',
      timeOfUseWithin: '',
      minimumSpending: '',
      emailPartner: '',
      savedQuantity: 0,
    }
  },

  computed: {
    ...mapGetters([
      'listPartnerNoPaging',
      'message',
      'status',
      'loading',
    ]),

    partnerOptions() {
      return this.listPartnerNoPaging.content.map(item => ({
        value: item._id,
        label: item.email,
        localId: item.localUserId,
      }))
    },

    isSavedGiftCode() {
      return this.savedQuantity > 0
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },

    editMode(val) {
      if (val) {
        const {
          value,
          quantity,
          startedAt,
          endedAt,
          minSpend,
          maxSpend,
          timeOfUse,
          minimumSpending,
          forPartnerId,
          savedQuantity,
        } = this.giftCodeSelected

        this.worthGift = value
        this.quantity = quantity
        this.startedAt = startedAt
        this.endedAt = endedAt
        this.minSpend = minSpend
        this.maxSpend = maxSpend
        this.timeOfUseWithin = timeOfUse
        this.minimumSpending = minimumSpending
        this.emailPartner = forPartnerId.email
        this.savedQuantity = savedQuantity
      } else {
        this.worthGift = ''
        this.quantity = ''
        this.startedAt = null
        this.endedAt = null
        this.minSpend = ''
        this.maxSpend = ''
        this.timeOfUseWithin = ''
        this.minimumSpending = ''
        this.emailPartner = ''
        this.savedQuantity = 0
      }
    },
  },

  methods: {
    ...mapActions([
      'createGiftCode',
      'updateGiftCode',
      'getListPartnerNoPaging',
    ]),

    restrictChars($event) {
      if ($event.charCode >= 48 && $event.charCode <= 57) {
        return true
      }
      return $event.preventDefault()
    },

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.$emit('update:edit-mode', false)
      this.resetForm()
    },

    async startDateChange(selectedDates) {
      this.startedAt = moment(new Date(selectedDates[0]))
        .format('YYYY-MM-DD')

      // if (this.startedAt && this.endedAt) {
      //   console.log(this.startedAt)
      //   console.log(this.endedAt)
      //   const convertStartedAt = moment(this.startedAt, moment.defaultFormat)
      //     .toDate()
      //   const convertEndedAt = moment(this.endedAt, moment.defaultFormat)
      //     .toDate()
      //
      //   if (convertStartedAt > convertEndedAt) {
      //     this.$refs.formValidation.errors.endDate.push('The end date must be greater than the start date')
      //     this.$refs.formValidation.errors.startDate.push('The start date must be less than the end date')
      //     console.log(this.$refs.formValidation)
      //   }
      // }
    },
    async expiredDateChange(selectedDates) {
      this.endedAt = moment(new Date(selectedDates[0]))
        .format('YYYY-MM-DD')

      // if (this.startedAt && this.endedAt) {
      //   const convertStartedAt = moment(this.startedAt, moment.defaultFormat).toDate()
      //   const convertEndedAt = moment(this.endedAt, moment.defaultFormat).toDate()
      //   console.log(convertStartedAt)
      //   console.log(convertEndedAt)
      //
      //   if (convertStartedAt > convertEndedAt) {
      //     this.$refs.formValidation.errors.endDate.push('The end date must be greater than the start date')
      //     this.$refs.formValidation.errors.startDate.push('The start date must be less than the end date')
      //     console.log(this.$refs.formValidation.errors.endDate)
      //     console.log(this.$refs.formValidation.errors.startDate)
      //   }
      // }
    },

    async clearStartDate() {
      this.startedAt = null
    },

    async clearExpiredDate() {
      this.endedAt = null
    },

    async submitCreateGiftCode() {
      this.$refs.formValidation.validate()
        .then(async success => {
          const convertStartedAt = moment(this.startedAt, moment.defaultFormat)
            .toDate()
          const convertEndedAt = moment(this.endedAt, moment.defaultFormat)
            .toDate()

          if (this.minSpend > this.maxSpend || convertStartedAt > convertEndedAt) {
            if (this.minSpend > this.maxSpend) {
              this.$refs.formValidation.errors.minTotalSpend.push('The min total spend must be less than the max total spend')
              this.$refs.formValidation.errors.maxTotalSpend.push('The max total spend must be greater than the min total spend')
            }

            if (convertStartedAt > convertEndedAt) {
              this.$refs.formValidation.errors.endDate.push('The end date must be greater than the start date')
              this.$refs.formValidation.errors.startDate.push('The start date must be less than the end date')
            }
          } else if (success && this.startedAt && this.endedAt && this.emailPartner.value) {
            const params = {
              value: this.worthGift,
              quantity: this.quantity,
              startedAt: convertStartedAt,
              endedAt: convertEndedAt,
              minSpend: this.minSpend,
              maxSpend: this.maxSpend,
              timeOfUse: this.timeOfUseWithin,
              minimumSpending: this.minimumSpending,
              forPartnerId: this.emailPartner.value,
            }
            await this.createGiftCode(params)
            if (this.status) {
              this.toastSuccess('Create gift code successfully.')
              this.resetForm()
              this.show = false
              this.$emit('fetch-gift-code')
            } else {
              this.toastFailure(this.message)
            }
          }
        })
    },

    submitUpdateGiftCode() {
      this.$refs.formValidation.validate()
        .then(async success => {
          const convertStartedAt = moment(this.startedAt, moment.defaultFormat).toDate()
          const convertEndedAt = moment(this.endedAt, moment.defaultFormat).toDate()

          if (this.minSpend > this.maxSpend || convertStartedAt > convertEndedAt) {
            if (this.minSpend > this.maxSpend) {
              this.$refs.formValidation.errors.minTotalSpend.push('The min total spend must be less than the max total spend')
              this.$refs.formValidation.errors.maxTotalSpend.push('The max total spend must be greater than the min total spend')
            }

            if (convertStartedAt > convertEndedAt) {
              this.$refs.formValidation.errors.endDate.push('The end date must be greater than the start date')
              this.$refs.formValidation.errors.startDate.push('The start date must be less than the end date')
            }
          } else if (success && this.startedAt && this.endedAt && this.emailPartner) {
            const params = {
              // eslint-disable-next-line no-underscore-dangle
              id: this.giftCodeSelected._id,
              value: this.worthGift,
              quantity: this.quantity,
              startedAt: convertStartedAt,
              endedAt: convertEndedAt,
              minSpend: this.minSpend,
              maxSpend: this.maxSpend,
              timeOfUse: this.timeOfUseWithin,
              minimumSpending: this.minimumSpending,
              emailPartner: this.emailPartner,
            }

            await this.updateGiftCode(params)
            if (this.status) {
              this.toastSuccess('Update gift code successfully.')
              this.onHidden()
              this.$emit('fetch-gift-code')
            } else {
              this.toastFailure(this.message)
            }
          }
        })
    },

    resetForm() {
      this.worthGift = ''
      this.quantity = ''
      this.startedAt = null
      this.endedAt = null
      this.minSpend = ''
      this.maxSpend = ''
      this.timeOfUseWithin = ''
      this.minimumSpending = ''
      this.emailPartner = ''
      this.$refs.formValidation.reset()
    },

    handleSearchPartner(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.localId?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },
  },
}
</script>

<style lang="scss">
.input-height {
  height: 43px;

  .vs__dropdown-toggle {
    height: 100%;
  }
}

.is-invalid {
  .vs__dropdown-toggle {
    border-color: #ea5455;
  }
}
</style>

<style lang="scss" scoped>
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;

  strong {
    flex: 1;
  }
}
</style>
