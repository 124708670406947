<template>
  <b-modal
    ref="list-product-video"
    id="list-product-video"
    class="modal-child"
    size="lg"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal mb-1">
      <h3 class="font-medium">
        List Product Video
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="24"
          @click="hideModal"
        />
      </div>
    </div>
    <template>
      <p class="font-24">Product URL: <span
        v-b-tooltip="productUrl"
        class="
                text-blue
                underline
                cursor-pointer
                tag-content
                content-product-link
                mb-1
              "
        @click="goToUrl(productUrl)"
      >
        {{ productUrl }}
      </span></p>

      <vue-good-table
        mode="remote"
        :columns="columns"
        style-class="vgt-table table-list-video"
        :rows="listVideo"
        :is-loading="loadingProductLinks || loadingUser"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!--product video-->
          <div
            v-if="props.column.field === 'productVideo'"
          >

            <div class="item-video">
              <div class="h-100 d-flex align-items-center">
                <div
                  class="video-content"
                  @mouseover="handelShowIconPlayVideo(props.row.videoURL)"
                  @mouseleave="showIconPlay = false"
                >
                  <video
                    class="video-list"
                    :src="getVideoUrl(props.row)"
                  />
                  <div
                    class="icon-play-video"
                    :class="videoShowIconPlay === props.row.videoURL && showIconPlay ? 'show-icon' : 'hide-icon'"
                    @click="showModalVideo(props.row)"
                  >
                    <feather-icon
                      class="icon-play"
                      icon="PlayCircleIcon"
                      size="32"
                    />
                  </div>
                </div>

                <div class="name-video">
                  <p>{{ getNameVideo(props.row) }}</p>
                </div>
              </div>
              <div class="dropdown-action">
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item
                    :disabled="props.row.status === 'approved'"
                    @click="approveProductVideo(props.row.videoURL)"
                  >
                    <feather-icon
                      class="mr-50"
                      icon="CheckCircleIcon"
                    />
                    <span>{{ $t('common.approve') }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    :disabled="props.row.status === 'rejected'"
                    @click="openRejectedProductVideo(props.row.videoURL)"
                  >
                    <feather-icon
                      icon="ThumbsDownIcon"
                      class="mr-50"
                    />
                    <span>{{ $t('common.reject') }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>

          <!--status product video-->
          <div
            v-else-if="props.column.field === 'productVideoStatus'"
          >

            <p
              v-if="props.row.status"
              class="content-status status-product-links"
              :class="statusVariantRejected(props.row.status)"
            >
              {{ props.row.status }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>

          <!--reason reject product video-->
          <div
            v-else-if="props.column.field === 'reason'"
          >

            <div
              v-if="props.row.rejectedReason && statusVariantRejected(props.row.status) === 'rejected'"
            >
              <div
                :class="isShowFullReason && (urlVideoSelected === props.row.videoURL) ? 'full-content-reason' : 'shot-content-reason'"
                v-html="contentReasonReject(props.row)"
              />
              <small v-if="isShowBtnShowMore(props.row)">
                <strong>
                  <span
                    class="show-more"
                    @click="handleShowReason(props.row.videoURL)"
                  >{{ isShowFullReason && (urlVideoSelected === props.row.videoURL) ? 'Show less' : 'Show more' }}</span>
                </strong>
              </small>
            </div>
            <p
              v-else
              class="m-0"
            >
              N/A
            </p>
          </div>

        </template>
      </vue-good-table>
    </template>

    <div class="d-flex mt-1 justify-content-end">
      <btn-loading
        class="btn-close"
        type="submit"
        variant="outline-primary"
        block
        :loading="loadingProductLinks || loadingUser"
        @click.prevent="hideModal"
      >
        Close
      </btn-loading>
    </div>

    <reject-product-video
      :product-video-selected="productVideoSelected"
      :show-side-bar.sync="showRejectProductVideoSideBar"
      @fetchProductLinks="fetchProductLinks"
    />

    <video-modal
      ref="modal-play-video"
      :src-video="urlVideo || null"
    />
  </b-modal>
</template>
<script>

import {
  BModal,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { PROFILE_STATUS } from '@/constants'
import RejectProductVideo from '@/views/product-links/components/RejectProductVideo.vue'
import VideoModal from '@/views/product-links/components/VideoModal.vue'

const {
  mapActions,
} = createNamespacedHelpers('productLinks')
const { mapActions: mapActionsUser } = createNamespacedHelpers('user')

export default {
  components: {
    VideoModal,
    RejectProductVideo,
    BtnLoading,
    BModal,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [toastification],

  props: {
    productLinkSelected: {
      type: Array,
      default: () => [],
      required: true,
    },

    localUserId: {
      type: Number,
      default: null,
    },

    productUrl: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      columns: [
        {
          label: 'Product video',
          field: 'productVideo',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-product-video',
        },
        {
          label: 'Status',
          field: 'productVideoStatus',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Reason reject',
          field: 'reason',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],

      showRejectProductVideoSideBar: false,
      videoShowIconPlay: '',
      showIconPlay: false,
      productVideoSelected: {},
      isShowFullReason: false,
      urlVideo: null,
      nameVideo: '',
      listVideo: [],
      urlVideoSelected: '',

      pageLength: 10,
      currentPage: 1,
    }
  },

  computed: {
    ...mapGetters({
      statusProductLinks: 'productLinks/status',
      messageProductLinks: 'productLinks/message',
      loadingProductLinks: 'productLinks/loading',
      userDetail: 'user/userDetail',
      loadingUser: 'user/loading',
    }),

    isShowBtnShowMore() {
      return content => content?.rejectedReason.length > 200
    },

    contentReasonReject() {
      return content => {
        if (content?.rejectedReason.length > 200 && !this.isShowFullReason) {
          this.isShowFullReason = false
          this.urlVideoSelected = content.videoURL
        }
        return content?.rejectedReason
      }
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    getListVideo() {
      return item => item?.summaryVideos?.videos
    },

    getVideoUrl() {
      return valueVideo => valueVideo?.videoURL
    },

    getNameVideo() {
      return valueVideo => {
        const textSplit = 'https://files.ecomdy.com/videos/'
        const arrayNameVideo = valueVideo?.videoURL?.split(textSplit)
        return arrayNameVideo?.[1]
      }
    },

    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },
  },

  watch: {
    // listProductLinks: {
    //   handler(val) {
    //     if (val?.content.length > 0) {
    //       const { content } = val
    //       const productSelected = content.find(item => item.localUserId === this.localUserId)

    //       const productLinks = productSelected?.products?.productURLs
    //       if (productLinks) {
    //         const convertedUrls = Object.keys(productLinks).map(value => ({
    //           productUrl: value,
    //           ...productLinks[value],
    //         }))

    //         const currentProductSelected = convertedUrls?.find(itemUrl => itemUrl.productUrl === this.productUrl)
    //         this.listVideo = currentProductSelected?.summaryVideos?.videos
    //       }
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },

    userDetail: {
      handler(userInfo) {
        if (userInfo?.products) {
          const productLinks = userInfo?.products?.productURLs
          if (productLinks) {
            const convertedUrls = Object.keys(productLinks).map(value => ({
              productUrl: value,
              ...productLinks[value],
            }))

            const currentProductSelected = convertedUrls?.find(itemUrl => itemUrl.productUrl === this.productUrl)
            this.listVideo = currentProductSelected?.summaryVideos?.videos
          }
        }
      },
      deep: true,
      immediate: true,
    },

    productLinkSelected: {
      handler(listProductLink) {
        if (listProductLink.length > 0) {
          this.listVideo = listProductLink
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['handleProductVideo']),
    ...mapActionsUser(['getUser']),

    handleShowReason(urlVideo) {
      if ((this.urlVideoSelected === urlVideo) || (this.urlVideoSelected !== urlVideo && !this.isShowFullReason)) {
        this.isShowFullReason = !this.isShowFullReason
        this.urlVideoSelected = urlVideo
      } else {
        this.urlVideoSelected = urlVideo
      }
    },

    async fetchProductLinks() {
      const params = {
        id: this.localUserId,
      }

      await this.getUser(params)
      this.$emit('fetchProductLinks')
    },

    showModal() {
      this.$refs['list-product-video'].show()
    },

    hideModal() {
      if (!this.loadingProductLinks || !this.loadingUser) {
        this.isShowFullReason = false
        this.$refs['list-product-video'].hide()
      }
    },

    handelShowIconPlayVideo(videoUrl) {
      this.videoShowIconPlay = videoUrl
      this.showIconPlay = true
    },

    async approveProductVideo(productVideo) {
      const params = {
        id: this.localUserId,
        action: 'approve',
        videoURL: productVideo,
        productURL: this.productUrl,
      }
      await this.handleProductVideo(params)
      if (this.statusProductLinks) {
        this.toastSuccess('Approve product video success!')
        this.fetchProductLinks()
      } else {
        this.toastFailure(this.messageProductLinks)
      }
    },

    openRejectedProductVideo(productVideo) {
      this.productVideoSelected = {
        id: this.localUserId,
        videoURL: productVideo,
        productURL: this.productUrl,
      }
      this.showRejectProductVideoSideBar = true
    },

    showModalVideo(productItem) {
      this.urlVideo = productItem.videoURL
      this.$refs['modal-play-video'].showModalPlayVideo()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';

#list-product-video {
  .modal-content {
    border-radius: 20px;
  }

  .modal-body {
    padding: 30px;
  }
}

.dropdown-action {
  .dropdown-toggle {
    padding: 0;
  }
}

.table-list-video {
  .td-product-video {
    min-width: 300px;
    max-width: 350px;
  }
}
</style>

<style lang="scss" scoped>
.shot-content-reason {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.btn-close {
  width: max-content;
  margin-top: 0.5rem;
}

.item-video {
  padding: .5rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .video-content {
    width: 150px;
    height: 90px;
    position: relative;
    display: flex;

    .video-list {
      width: 150px;
      position: relative;
    }

    .icon-play-video {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon-play {
        z-index: 999;
        color: #fff;
      }

      &.show-icon {
        animation: showicon 0.5s;
      }

      &.hide-icon {
        visibility: hidden;
        opacity: 0;
      }

      @keyframes showicon {
        0% {
          visibility: hidden;
          opacity: 0;
        }
        100% {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  .name-video {
    margin-left: .5rem;
    font-size: 12px;
    max-width: 200px;

    p {
      margin: 0;
    }
  }
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.content-reason-reject-video {
  padding: .5rem 1rem;
}

.show-more, .show-less {
  cursor: pointer;
  text-transform: uppercase;
  color: grey;
}
</style>
