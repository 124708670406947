import { PartnerPermissionService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async approvePartnerPermission({ commit }, params) {
    commit('APPROVE_PARTNER_PERMISSION_REQUEST')
    try {
      const { data } = await PartnerPermissionService.approvePartnerPermission(params)
      commit('APPROVE_PARTNER_PERMISSION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('APPROVE_PARTNER_PERMISSION_FAILURE', error)
    }
  },

  async revokePartnerPermission({ commit }, params) {
    commit('REVOKE_PARTNER_PERMISSION_REQUEST')
    try {
      const { data } = await PartnerPermissionService.revokePartnerPermission(params)
      commit('REVOKE_PARTNER_PERMISSION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REVOKE_PARTNER_PERMISSION_FAILURE', error)
    }
  },

  async getListPartnerPermission({ commit }, { params, cancelToken }) {
    commit('GET_LIST_PARTNER_PERMISSION_REQUEST')
    try {
      const { data } = await PartnerPermissionService.getListPartnerPermission(params, cancelToken)
      commit('GET_LIST_PARTNER_PERMISSION_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_LIST_PARTNER_PERMISSION_FAILURE', error)
    }
  },
}
