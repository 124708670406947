<template>
  <!-- form -->
  <validation-observer ref="simpleRules">
    <b-form
      method="POST"
      @submit.prevent="validationForm"
    >
      <b-row>
        <!-- old password -->
        <b-col md="6">
          <b-form-group
            :label="$t('profile.labelOldPassword')"
          >
            <validation-provider
              #default="{ errors }"
              name="Old Password"
              vid="OldPassword"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model.trim="oldPasswordValue"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  :placeholder="$t('profile.placeholderPassword')"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconOld"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      <!--/ old password -->
      </b-row>
      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            :label="$t('profile.labelNewPassword')"
          >
            <validation-provider
              #default="{ errors }"
              name="New Password"
              vid="Password"
              rules="required|password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model.trim="newPasswordValue"
                  id="account-new-password"
                  name="new-password"
                  trim
                  :type="passwordFieldTypeNew"
                  :placeholder="$t('profile.placeholderPassword')"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconNew"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- retype password -->
        <b-col md="6">
          <b-form-group
            :label="$t('profile.labelRetypeNewPassword')"
          >
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required|confirmed:Password"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model.trim="retypePasswordValue"
                  id="account-retype-new-password"
                  name="retype-password"
                  trim
                  :type="passwordFieldTypeRetype"
                  :placeholder="$t('profile.placeholderPassword')"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIconRetype"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ retype password -->

        <!-- buttons -->
        <b-col cols="12">
          <btn-loading
            type="submit"
            variant="primary"
            class="mt-1 mr-1 btn-save"
            :loading="loading"
          >
            {{ $t('common.btnSaveChanges') }}
          </btn-loading>
        </b-col>
      <!--/ buttons -->
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,

    // components
    BtnLoading,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification],

  data() {
    return {
      oldPasswordValue: '',
      newPasswordValue: '',
      retypePasswordValue: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      required,
    }
  },
  computed: {
    ...mapGetters(['status', 'loading', 'message']),
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  watch: {
    newPasswordValue(value) {
      this.newPasswordValue = this.removeSpace(value)
    },

    retypePasswordValue(value) {
      this.retypePasswordValue = this.removeSpace(value)
    },
  },

  methods: {
    ...mapActions(['updatePassword']),
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    removeSpace(str) {
      return str.replace(/\s/g, '')
    },

    async validationForm() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        const params = {
          currentPassword: this.oldPasswordValue,
          newPassword: this.newPasswordValue,
        }

        await this.updatePassword(params)

        if (this.status) {
          this.$store.dispatch('auth/logout')
          this.$router.push({ name: 'login' })
          this.toastSuccess(this.$t('profile.textChangePasswordSuccess'))
        } else if (this.message === 'error.current_password_wrong') {
          this.toastFailure(this.$t('profile.textCurrentPasswordWrong'))
        } else {
          this.toastFailure(this.$t('profile.textChangePasswordFailed'))
        }
      }
    },
  },
}
</script>
