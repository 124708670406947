import i18n from '@/libs/i18n'

export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: i18n.t('page.profile'),
      requireAuth: true,
      requireSiteId: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: () => import('@/views/miscellaneous/Introduction.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
