<template>
  <b-button
    v-bind="$attrs"
    :disabled="loading"
    v-on="$listeners"
  >
    <b-spinner
      v-if="loading"
      small
      type="grow"
    />
    <span v-else><slot /></span>
  </b-button>
</template>
<script>
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BSpinner,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
