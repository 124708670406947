<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Balance change"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2">
      <validation-observer ref="formValidation">
        <b-form>
          <!-- Amount -->
          <b-form-group
            label="Amount ($)"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="amount"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model.number="amount"
                  type="number"
                  name="email"
                  placeholder="Enter amount"
                  :state="errors.length > 0 ? false:null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Method -->
          <b-form-group
            label="Change method"
            class="form-label mb-2"
          >
            <b-form-select
              v-model="type"
              :options="typeOptions"
              :class="type === 'RECHARGE' ? 'recharge' : 'withdraw'"
            />
          </b-form-group>

          <!-- Transfer Bank Description -->

          <b-form-group
            v-if="isSMBAgencyPlatform && type === 'RECHARGE'"
            label="Transfer Bank Description (Optional)"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="transer bank description"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  v-model="transferBankDescription"
                  placeholder="Transfer bank description"
                  :state="errors.length > 0 ? false:null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Comment -->

          <b-form-group
            label="Comment"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="comment"
              rules="required"
            >
              <b-input-group>
                <b-form-textarea
                  v-model="description"
                  placeholder="Typing something ..."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div class="mt-1">
            <!-- submit buttons -->
            <btn-loading
              type="submit"
              variant="primary"
              block
              :loading="loading"
              @click.prevent="submit"
            >
              Apply
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>

import {
  BSidebar,
  // BRow,
  // BCol,
  BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  // BButton,
  BInputGroup,
  // BInputGroupAppend,
  BFormTextarea,
} from 'bootstrap-vue'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    // component
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, numberFormatMixin, envMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      show: false,
      amount: '',
      transferBankDescription: '',
      description: '',
      type: 'RECHARGE',
      typeOptions: [
        {
          text: 'Add fund (+)',
          value: 'RECHARGE',
        },
        {
          text: 'Withdraw (-)',
          value: 'REFUND',
        },
      ],
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },
  },

  methods: {
    ...mapActions(['updateBalanceUser']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    submit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.userData.localUserId,
            amount: this.amount,
            description: this.description,
            type: this.type,
            ...(this.isSMBAgencyPlatform && this.type === 'RECHARGE' && this.transferBankDescription && { transferBankDescription: this.transferBankDescription }),
          }

          await this.updateBalanceUser(params)
          if (this.status) {
            this.toastSuccess(`${this.type === 'RECHARGE' ? 'Add fund' : 'Withdraw'} success!`)
            this.$emit('update-user-selected')
            this.resetForm()
            this.show = false
          } else {
            this.toastFailure(this.message)
          }
        }
      })
    },

    resetForm() {
      this.amount = ''
      this.description = ''
      this.type = 'RECHARGE'
      this.transferBankDescription = ''
      this.$refs.formValidation.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep button.close {
    display: block;
    margin-left: auto;
    margin-right: 0;
    font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
    flex-direction: row-reverse;
    strong {
        flex: 1;
    }
}

.recharge{
  color: #7AB34C;
}

.withdraw{
  color: #F02548;
}
</style>
