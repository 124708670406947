<template>
  <div>
    <b-card-code class="filter-card">
      <!-- advance search input -->
      <b-row>
        <b-col
          md="7"
          class="mb-1"
        >
          <b-form-group>
            <label>Search user by field</label>
            <b-input-group class="input-filter">
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  :disabled="loading"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>

              <b-form-input
                v-model.trim="searchTerm"
                :placeholder="handleSearchPlaceHolder"
                :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                class="d-inline-block"
                :disabled="loading"
                @wheel="handleMouseWheelInput"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="5"
          class="mb-1"
        >
          <b-form-group>
            <label> Status </label>

            <b-form-select
              v-model="statusSelected"
              :options="statusOptions"
              :disabled="loading"
              placeholder="All status"
              @input="handleStatusChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-col md="3">
          <btn-loading
            class="btn-export btn-partner"
            type="submit"
            block
            variant="outline-primary"
            :disabled="false"
            :loading="loading"
            @click="handleCashback({})"
          >
            Cashback All
          </btn-loading>
        </b-col>
      </b-row>
    </b-card-code>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="listCashback"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalcashback > 0,
      }"
      :sort-options="{
        enabled: totalcashback > 0,
      }"
      :is-loading="loading"
      :group-options="{
        enabled: false,
      }"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: User ID -->
        <div
          v-if="props.column.field === 'userId'"
          class="d-flex align-items-center"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="userInfo()"
          />
          <div>
            <p
              v-if="props.row.userId && props.row.userId.fullName"
              class="mb-0"
            >
              {{
                props.row.userId.fullName &&
                  capitalise(props.row.userId.fullName)
              }}
            </p>
            <span
              v-if="props.row.userId && props.row.userId.email"
              class="text-placeholder"
            >{{ props.row.userId.email }}</span>
          </div>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="adsStatusVariant(props.row.status)">
            {{ capitalise(props.row.status) }}
          </b-badge>
        </span>

        <!-- total spending -->
        <span v-else-if="props.column.field === 'payload.totalRechargeAmount'">
          {{ currencyFormat(props.row.payload.totalRechargeAmount) }}
        </span>

        <!-- Amount cashback -->
        <span v-else-if="props.column.field === 'amount'">
          {{ currencyFormat(props.row.amount) }}
        </span>

        <!-- Column: Create At -->
        <span v-else-if="props.column.field === 'createdAt'">
          <p class="text-secondary font-weight-bold mb-0">
            {{ getFormatDate(props.row.createdAt) || 'N/A' }}
          </p>
          <span class="font-14 text-placeholder">{{ getFormatTime(props.row.createdAt) }}</span>
        </span>

        <!-- Column: Actions -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            class="btn-cashback"
            type="submit"
            block
            variant="outline-primary"
            :disabled="props.row.status !== TRANSACTIONS_STATUS.PENDING"
            :loading="loading"
            @click="handleCashback(props.row)"
          >
            <b-img src="@/assets/images/icons/ic-cashback.svg" />
            Cashback
          </b-button>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalcashback > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Ticket per page </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              Showing
              {{ pageLength * (currentPage - 1) + 1 }}
              to
              {{ pageLength * currentPage }}
              of {{ totalcashback }} ticket(s)
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalcashback"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <div
        v-if="errorBalanceHistory"
        slot="emptystate"
      >
        <p class="text-error">
          {{ errorBalanceHistory }}
        </p>
      </div>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>

    <confirm-cashback-modal
      ref="open-modal-confirm-cashback"
      :cashback-selected="cashbackSelected"
      @fetchCashbackTransaction="fetchCashbackTransaction"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { TICKET_TYPE, TRANSACTIONS_STATUS } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import ConfirmCashbackModal from '@/views/payments/components/ConfirmCashbackModal.vue'

import {
  BPagination,
  BSpinner,
  BFormSelect,
  BBadge,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BRow,
  BCol,
  BImg,
  BInputGroup,
  BButton,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('payment')

export default {
  components: {
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroup,
    BRow,
    BCol,
    BImg,
    BButton,
    BCardCode,
    BtnLoading,
    ConfirmCashbackModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, paymentMixin, envMixin],
  data() {
    return {
      currentPage: 1,
      searchTerm: '',
      cashbackSelected: {},

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      // status filter
      statusOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: 'Pending',
          value: TRANSACTIONS_STATUS.PENDING,
        },
        {
          text: 'Done',
          value: TRANSACTIONS_STATUS.DONE,
        },
      ],
      statusSelected: '',

      transactionType: 'cashback',

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      openAddFundModal: false,

      errorBalanceHistory: '',
    }
  },

  computed: {
    TRANSACTIONS_STATUS() {
      return TRANSACTIONS_STATUS
    },
    ...mapGetters(['cashbackTransaction', 'message', 'status', 'loading']),
    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.userInfo'),
          field: 'userId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Transaction ID',
          field: '_id',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Total recharge',
          field: 'payload.totalRechargeAmount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Total cashback',
          field: 'amount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Cashback status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.createdAt'),
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-midle',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    listCashback() {
      return get(this.cashbackTransaction, ['content'], [])
    },

    totalcashback() {
      return get(this.cashbackTransaction, ['paging', 'total'], 0)
    },

    adsStatusVariant() {
      const statusColor = {
        [TRANSACTIONS_STATUS.DONE]: 'light-success',
        [TRANSACTIONS_STATUS.PENDING]: 'light-warning',
        [TRANSACTIONS_STATUS.REJECTED]: 'light-danger',
      }

      return status => statusColor[status]
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    handleSearchPlaceHolder() {
      switch (this.searchSelected) {
        case 'localUserId':
          return 'Search by user ID'
        case 'email':
          return 'Search by user email'
        default:
          return ''
      }
    },
  },

  created() {
    this.fetchCashbackTransaction()
    if (!this.status) {
      this.errorBalanceHistory = this.message
    }
  },

  methods: {
    ...mapActions(['getCashbackTransaction']),

    capitalise(value) {
      return value.charAt(0)
        .toUpperCase() + value.slice(1)
    },

    getAllQueriesParam() {
      return {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.statusSelected && { status: this.statusSelected }),
        types: [TICKET_TYPE.CASHBACK],
      }
    },

    async fetchCashbackTransaction() {
      if (
        this.searchTerm
        && !this.validateEmail(this.searchTerm)
        && this.searchSelected === 'email'
      ) {
        return
      }

      const params = this.getAllQueriesParam()

      await this.getCashbackTransaction(params)
      if (!this.status) {
        this.errorBalanceHistory = this.message
        this.toastFailure(this.message)
      }
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchCashbackTransaction()
      } else if (
        searching.toString().length > 3
        && this.searchSelected !== 'email'
      ) {
        this.fetchCashbackTransaction()
      } else if (this.searchSelected === 'email') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            this.fetchCashbackTransaction()
          }, 1000)
        }
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchCashbackTransaction()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchCashbackTransaction()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchCashbackTransaction()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchCashbackTransaction()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchCashbackTransaction()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchCashbackTransaction()
    },

    handleCashback(data) {
      this.cashbackSelected = data
      this.$refs['open-modal-confirm-cashback'].showModal()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.filter-card {
  padding: 24px;
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #ebe9f1;
  border-bottom: 0px;

  .card-header {
    display: none;
  }

  .card-body {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.btn-partner {
  margin-top: calc(19.88px + 0.2857rem);
  display: flex;
  justify-content: center;
  align-items: center;

  &.btn-export {
    height: 43px;
  }
}

.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}

.btn-cashback {
  width: max-content;
}
</style>
