<template>
  <div>
    <div>
      <!-- <b-card-code title="Filters">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>
                Package name
              </label>

              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>
                Target Market
              </label>

              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>
                Payment method
              </label>

              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-code> -->

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="marketPackage"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalMarketPackage > 0,
        }"
        :sort-options="{
          enabled: totalMarketPackage > 0,
        }"
        :is-loading="loading"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: description -->
          <div
            v-if="props.column.field === 'description'"
            class="d-flex flex-column"
          >
            <span>{{ (props.row.description) }}</span>
          </div>

          <!-- Column: name -->
          <div
            v-else-if="props.column.field === 'name'"
            class="d-flex flex-column"
          >
            <div v-if="props.row.policies">
              <p class="m-0">
                {{ packageName(props.row) }}
              </p>
            </div>
          </div>

          <!-- Column: Payment method -->
          <div
            v-else-if="props.column.field === 'paymentMethods'"
            class="d-flex flex-column"
          >
            <ul
              v-for="(data,index) in props.row.paymentMethods"
              :key="index"
            >
              <li>
                <span>{{ data }}</span>
              </li>
            </ul>
          </div>

          <!-- Column: ads Account Limit -->
          <div
            v-else-if="props.column.field === 'adsAccountLimit'"
            class="d-flex flex-column"
          >
            <div v-if="props.row.policies">
              <div
                v-for="(item, index) in adsAccountLimit(props.row)"
                :key="index"
              >
                <p class="m-0">
                  {{ item }}
                </p>
              </div>
            </div>
          </div>

          <!-- Column: unit_amount -->
          <div
            v-else-if="props.column.field === 'unit_amount'"
            class="d-flex flex-column"
          >
            <div>
              <p>${{ getUnitAmount(props.row) }}</p>
            </div>
          </div>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'status'"
            class="position-relative"
          >
            <b-badge
              class="text-capitalize status-gift-code"
              variant="success"
            >
              <span
                class="content-status"
              >Active</span>
            </b-badge>
          </span>

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'action'">
            <!-- <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="openPackage(props.row)"
              >
                <span>Update</span>
              </b-dropdown-item>
            </b-dropdown> -->
            <p
              class="btn-update m-0"
              @click="openUpdatePackage(props.row)"
            >Update</p>
          </span>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalMarketPackage > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalMarketPackage }} market(s) package </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalMarketPackage"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </vue-good-table>
    </div>

    <edit-package
      :show-side-bar.sync="showEditPackageSideBar"
      :detail-package="detailPackage"
      @fetch-list-package="fetchListPackage"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { USER_STATUS } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
// import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BBadge,
  BFormSelect,
  // BFormGroup,
  // BRow,
  // BCol,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import editPackage from './components/editPackage.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('marketPackage')

export default {
  components: {
    BPagination,
    BFormSelect,
    BSpinner,
    BBadge,
    // BFormGroup,
    VueGoodTable,
    // BRow,
    // BCol,
    // BCardCode,

    // component
    editPackage,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification],
  data() {
    return {
      columns: [
        {
          label: 'Package name',
          field: 'name',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Description',
          field: 'description',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        // {
        //   label: 'Target Market',
        //   field: 'targetMarket',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        {
          label: 'Ads accounts limit',
          field: 'adsAccountLimit',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Monthly fee',
          field: 'unit_amount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        // {
        //   label: 'Payment method',
        //   field: 'paymentMethods',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        // {
        //   label: 'What’s include',
        //   field: 'include',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },

      ],
      currentPage: 1,
      searchTerm: '',

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      // status filter
      statusSelected: null,
      statusOptions: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'Activated',
          value: 'activate',
        },
        {
          text: 'Deactivated',
          value: 'deactivated',
        },
      ],

      showEditPackageSideBar: false,
      detailPackage: {},
    }
  },

  computed: {
    ...mapGetters(['listMarketPackage', 'message', 'status', 'loading']),

    marketPackage() {
      return _get(this.listMarketPackage, ['content'], [])
    },

    totalMarketPackage() {
      return _get(this.listMarketPackage, ['paging', 'total'], 0)
    },

    adsAccountLimit() {
      return val => val?.policies.map(item => item?.plans?.adsAccountLimit)
    },

    packageName() {
      return val => val?.policies && val?.policies[0].plans?.type
    },

    getUnitAmount() {
      return data => `${(data?.policies[0].plans?.unit_amount / 100).toFixed(2)}`
    },
  },

  created() {
    this.fetchListPackage()
  },

  methods: {
    ...mapActions(['getListPackage']),

    async DeactivatedGiftCode(localUserId) {
      const params = {
        status: USER_STATUS.ACTIVE,
        id: localUserId,
      }
      await this.activeUser(params)
      if (this.status) {
        this.toastSuccess('Active user success')
        this.updateGiftCodeSelected()
      } else {
        this.toastFailure(this.message)
      }
    },

    async fetchListPackage() {
      // if (
      //   this.searchTerm
      //   && !this.validateEmail(this.searchTerm)
      //   && this.searchSelected === 'email'
      // ) {
      //   return
      // }
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        // ...(this.sortTerm.field && {
        //   sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
        //     this.statusSelected === USER_STATUS.BLOCKED
        //       ? 'authentication.blockedAt'
        //       : this.sortTerm.field
        //   }`,
        // }),
        // ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        // ...(this.startDate && { from: this.startDate }),
        // ...(this.endDate && { to: this.endDate }),
        // ...(this.statusSelected && { status: this.statusSelected }),
      }
      await this.getListPackage(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListPackage()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListPackage()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListPackage()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchListPackage()
    },

    openUpdatePackage(data) {
      this.detailPackage = data
      this.showEditPackageSideBar = true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-addNewUser {
  margin-top: 24.449px !important;
}
</style>
<style lang="scss" scoped>
.dropdown-custom {
  position: absolute !important;
  top: -30px;
  left: 33px;
}

.icon-reason-block {
  position: absolute;
  right: -10px;
  top: -15px;
}
</style>

<style lang="scss" scoped>
.status-gift-code {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
  &.activate {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.deactivated {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }
}

.btn-update{
  cursor: pointer;
  color: #F02548;
}
</style>
