export default [
  {
    path: '/productLinks',
    name: 'productLinks',
    component: () => import('@/views/product-links/ProductLinks.vue'),
    meta: {
      pageTitle: 'Product Links',
      requireAuth: true,
    },
  },
]
