/* eslint-disable no-underscore-dangle */
import axios from '@axios'

export default {
  // action partner permission
  approvePartnerPermission(params) {
    return axios.post('/api/admin/ads-accounts/partner-bc-permissions/approve', params)
  },

  revokePartnerPermission(params) {
    return axios.post(`/api/admin/ads-accounts/partner-bc-permissions/${params._id}/revoke`)
  },

  getListPartnerPermission(params, cancelToken) {
    return axios.get('/api/admin/ads-accounts/partners-bc-permissions', { params, cancelToken })
  },
}
