<template>
  <div class="monthly-analysis-component">
    <b-row class="align-items-end">
      <b-col md="2">
        <b-form-group>
          <label> Month </label>
          <b-form-select
            v-model="monthSelected"
            :options="monthOptions"
            class="mr-1 input-height"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="3"
        lg="2"
      >
        <b-form-group>
          <label> Year </label>
          <b-form-select
            v-model="yearSelected"
            :options="yearOptions"
            class="input-height"
          />
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-button
          variant="primary"
          type="submit"
          class="mb-md-1 input-height"
          @click="handleSelect"
        >
          Submit
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-for="(item, index) in monthlyMemberPartnerDetail"
        :key="index"
        cols="4"
        class="card-content mt-1"
      >
        <b-card no-body>
          <b-card-body class="statistics-body">
            <div class="d-flex">
              <b-img
                :src="item.icon"
                fluid
                alt="icon payment"
                class="icon"
              />
              <div>
                <div class="d-flex">
                  {{ item.value }}
                  <b-img
                    v-b-tooltip.hover.v-secondary.top="``"
                    src="@/assets/images/pages/overview/ic-detail.svg"
                    alt="logo"
                  />
                </div>
                <span class="small-text">{{ item.label }}</span>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BFormSelect,
  BFormGroup,
  BRow,
  BCol,
  BImg,
  BButton,
  VBTooltip,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'

const { mapGetters, mapActions } = createNamespacedHelpers('partner')

export default {
  components: {
    BFormSelect,
    BFormGroup,
    BRow,
    BCol,
    BImg,
    BButton,
    BCard,
    BCardBody,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      monthSelected: new Date().getMonth() + 1,
      yearSelected: new Date().getFullYear(),
    }
  },

  computed: {
    ...mapGetters(['monthlyMemberPartnerDetail']),

    monthOptions() {
      const data = []
      let i = 1
      while (i <= 12) {
        data.push({
          value: i,
          text: i,
          disabled:
            i > new Date().getMonth() + 1
            && this.yearSelected >= new Date().getFullYear(),
        })

        i += 1
      }

      return data
    },

    yearOptions() {
      const data = []
      let i = 2020
      const yearly = new Date().getFullYear()
      while (i <= yearly) {
        data.push({
          value: i,
          text: i,
        })
        i += 1
      }

      return data
    },
  },

  methods: {
    ...mapActions(['getSpendingDepositPartnerDetail', 'getTotalMemberMonthPartnerDetail', 'getTotalAdsAccountMonthPartnerDetail']),

    handleSelect() {
      if (this.monthSelected > moment().format('MM') && this.yearSelected >= moment().format('YYYY')) {
        this.monthSelected = moment().format('M')
      }
      this.onSelect()
    },

    async onSelect() {
      const { params } = this.$route

      const payload = {
        id: params.id,
        year: this.yearSelected,
        month: this.monthSelected,
        totalSpending: 1,
        totalDeposit: 1,
        monthlySpending: 1,
        monthlyDeposit: 1,
      }
      const fromDate = moment(`${this.yearSelected}/${this.monthSelected}/01`, 'YYYY/MM/DD').startOf('month').format('YYYY-MM-DD')
      const toDate = moment(fromDate).endOf('month').format('YYYY-MM-DD')
      const data = {
        id: params.id,
        fromDate,
        toDate,
      }
      await this.getSpendingDepositPartnerDetail(payload)
      await this.getTotalMemberMonthPartnerDetail(data)
      await this.getTotalAdsAccountMonthPartnerDetail(data)
    },
  },
}
</script>

<style lang="scss" scoped>
.monthly-analysis-component {
  padding: 24px 0;

  .input-height {
    height: 48px;
  }

  .card-content {

    .card {
      margin-bottom: 0px;
      height: 100%;
    }
  }
  .statistics-body {
    display: flex;
    align-items: center;

    padding: 15px 10px;

    border: 1px solid #eeeeee;
    border-radius: 10px;
  }
  .small-text {
    font-size: 12px;
  }
  .icon {
    margin-right: 12px;
    width: 35px;
  }
}
</style>
