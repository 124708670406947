import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get account list
  GET_ACCOUNTS_REQUEST(state) {
    REQUEST(state)
  },
  GET_ACCOUNTS_SUCCESS(state, data) {
    SUCCESS(state)
    state.accountTickets = data
  },
  GET_ACCOUNTS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get account list no paging
  GET_ACCOUNTS_NO_PAGING_REQUEST(state) {
    REQUEST(state)
  },
  GET_ACCOUNTS_NO_PAGING_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAccountNoPaging = data
  },
  GET_ACCOUNTS_NO_PAGING_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Approve ticket
  APPROVE_TICKET_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  APPROVE_TICKET_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  APPROVE_TICKET_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Reject ticket
  REJECT_TICKET_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  REJECT_TICKET_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  REJECT_TICKET_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Comments ticket
  COMMENT_TICKET_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  COMMENT_TICKET_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  COMMENT_TICKET_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Comments ticket
  CREARRE_ADS_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  CREARRE_ADS_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  CREARRE_ADS_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
