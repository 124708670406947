import { UserService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async getUser({ commit }, params) {
    commit('GET_USER_DETAIL_REQUEST')
    try {
      const { data } = await UserService.getUser(params)
      commit('GET_USER_DETAIL_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_USER_DETAIL_FAILURE', error)
    }
  },

  async getAdsAccountsUser({ commit }, params) {
    commit('GET_ADS_ACCOUNTS_USER_REQUEST')
    try {
      const { data } = await UserService.getAdsAccountsUser(params)
      commit('GET_ADS_ACCOUNTS_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ADS_ACCOUNTS_USER_FAILURE', error)
    }
  },

  async getListUser({ commit }, { params, cancelToken }) {
    commit('GET_USER_REQUEST')
    try {
      const { data } = await UserService.getListUser(params, cancelToken)
      commit('GET_USER_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_USER_FAILURE', error)
    }
  },

  async activeUser({ commit }, params) {
    commit('ACTIVE_USER_REQUEST')
    try {
      const { data } = await UserService.activeUser(params)
      commit('ACTIVE_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ACTIVE_USER_FAILURE', error)
    }
  },

  async blockUser({ commit }, params) {
    commit('BLOCK_USER_REQUEST')
    try {
      const { data } = await UserService.blockUser(params)
      commit('BLOCK_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('BLOCK_USER_FAILURE', error)
    }
  },

  async addWhiteListBlock({ commit }, params) {
    commit('ADD_WHITE_LIST_BLOCK_REQUEST')
    try {
      const { data } = await UserService.addWhiteListBlock(params)
      commit('ADD_WHITE_LIST_BLOCK_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ADD_WHITE_LIST_BLOCK_FAILURE', error)
    }
  },

  async updateUser({ commit }, params) {
    commit('UPDATE_USER_REQUEST')
    try {
      const { data } = await UserService.updateUser(params)
      commit('UPDATE_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_USER_FAILURE', error)
    }
  },

  async createProductLinks({ commit }, params) {
    commit('ADD_PRODUCT_LINKS_REQUEST')
    try {
      const { data } = await UserService.createProductLinks(params)
      commit('ADD_PRODUCT_LINKS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ADD_PRODUCT_LINKS_FAILURE', error)
    }
  },

  async updateStatusProductLinks({ commit }, params) {
    commit('UPDATE_STATUS_PRODUCT_LINKS_REQUEST')
    try {
      const { data } = await UserService.updateStatusProductLinks(params)
      commit('UPDATE_STATUS_PRODUCT_LINKS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_STATUS_PRODUCT_LINKS_FAILURE', error)
    }
  },

  async updateBalanceUser({ commit }, params) {
    commit('UPDATE_BALANCE_USER_REQUEST')
    try {
      const { data } = await UserService.updateBalanceUser(params)
      commit('UPDATE_BALANCE_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_BALANCE_USER_FAILURE', error)
    }
  },

  async changePlanType({ commit }, params) {
    commit('CHANGE_PLAN_TYPE_REQUEST')
    try {
      const { data } = await UserService.changePlanType(params)
      commit('CHANGE_PLAN_TYPE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CHANGE_PLAN_TYPE_FAILURE', error)
    }
  },

  async deleteAdsAccount({ commit }, params) {
    commit('DELETE_ACCOUNT_REQUEST')
    try {
      const { data } = await UserService.deleteAdsAccount(params)
      commit('DELETE_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('DELETE_ACCOUNT_FAILURE', error)
    }
  },

  async cancelSubscription({ commit }, params) {
    commit('CANCEL_SUB_REQUEST')
    try {
      const { data } = await UserService.cancelSubscription(params)
      commit('CANCEL_SUB_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CANCEL_SUB_FAILURE', error)
    }
  },

  async addNewUser({ commit }, params) {
    commit('ADD_USER_REQUEST')
    try {
      const { data } = await UserService.addNewUser(params)
      commit('ADD_USER_SUCCESS', data)
    } catch (error) {
      commit('ADD_USER_FAILURE', error)
    }
  },

  async profileStatusUpdate({ commit }, params) {
    commit('PROFILE_STATUS_UPDATE_REQUEST')
    try {
      const { data } = await UserService.profileStatusUpdate(params)
      commit('PROFILE_STATUS_UPDATE_SUCCESS', data)
    } catch (error) {
      commit('PROFILE_STATUS_UPDATE_FAILURE', error)
    }
  },

  async markKYCUser({ commit }, params) {
    commit('MARK_KYC_USER_REQUEST')
    try {
      const { data } = await UserService.markKYCUser(params)
      commit('MARK_KYC_USER_SUCCESS', data)
    } catch (error) {
      commit('MARK_KYC_USER_FAILURE', error)
    }
  },

  // get list AM
  async getListAM({ commit }, params) {
    commit('GET_LIST_AM_REQUEST')
    try {
      const { data } = await UserService.getListAM(params)
      commit('GET_LIST_AM_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_LIST_AM_FAILURE', error)
    }
  },

  async getListAdminNote({ commit }, params) {
    commit('GET_LIST_ADMIN_NOTE_REQUEST')
    try {
      const { data } = await UserService.listAdminNote(params)
      commit('GET_LIST_ADMIN_NOTE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_LIST_ADMIN_NOTE_FAILURE', error)
    }
  },

  async handleAddNote({ commit }, params) {
    commit('ADD_NOTE_REQUEST')
    try {
      const { data } = await UserService.addNote(params)
      commit('ADD_NOTE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ADD_NOTE_FAILURE', error)
    }
  },

  async handleUpdateNote({ commit }, params) {
    commit('UPDATE_NOTE_REQUEST')
    try {
      const { data } = await UserService.updateNote(params)
      commit('UPDATE_NOTE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_NOTE_FAILURE', error)
    }
  },

  async handleDeleteNote({ commit }, params) {
    commit('DELETE_NOTE_REQUEST')
    try {
      const { data } = await UserService.deleteNote(params)
      commit('DELETE_NOTE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('DELETE_NOTE_FAILURE', error)
    }
  },

  async assignMultiUserToAM({ commit }, params) {
    commit('ASSIGN_MULTI_TO_AM_REQUEST')
    try {
      const { data } = await UserService.assignMultiUserToAM(params)
      commit('ASSIGN_MULTI_TO_AM_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ASSIGN_MULTI_TO_AM_FAILURE', error)
    }
  },

  async resignMultiUserToAM({ commit }, params) {
    commit('RESIGN_MULTI_TO_AM_REQUEST')
    try {
      const { data } = await UserService.resignMultiUserToAM(params)
      commit('RESIGN_MULTI_TO_AM_SUCCESS', getResponse(data))
    } catch (error) {
      commit('RESIGN_MULTI_TO_AM_FAILURE', error)
    }
  },

  async getListPartnerBcs({ commit }, params) {
    commit('GET_LIST_PARTNER_BCS_REQUEST')
    try {
      const { data } = await UserService.getListPartnerBcs(params)
      commit('GET_LIST_PARTNER_BCS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_LIST_PARTNER_BCS_FAILURE', error)
    }
  },

  async updateTier({ commit }, params) {
    commit('UPDATE_TIER_REQUEST')
    try {
      const { data } = await UserService.updateTier(params)
      commit('UPDATE_TIER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_TIER_FAILURE', error)
    }
  },

  async createAdsAccount({ commit }, params) {
    commit('CREATE_ADS_ACCOUNT_REQUEST')
    try {
      const { data } = await UserService.createAdsAccount(params)
      commit('CREATE_ADS_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CREATE_ADS_ACCOUNT_FAILURE', error)
    }
  },

  async closeAdsAccount({ commit }, params) {
    commit('CLOSE_ACCOUNT_REQUEST')
    try {
      const { data } = await UserService.closeAdsAccount(params)
      commit('CLOSE_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CLOSE_ACCOUNT_FAILURE', error)
    }
  },

  async disconnectTiktok({ commit }, params) {
    commit('DISCONNECT_TIKTOK_REQUEST')
    try {
      const { data } = await UserService.disconnectTiktok(params)
      commit('DISCONNECT_TIKTOK_SUCCESS', getResponse(data))
    } catch (error) {
      commit('DISCONNECT_TIKTOK_FAILURE', error)
    }
  },

  async fetchDetailUserRelated({ commit }, params) {
    commit('GET_DETAIL_USER_RELATED_REQUEST')
    try {
      const { data } = await UserService.fetchDetailUserRelated(params)
      commit('GET_DETAIL_USER_RELATED_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_DETAIL_USER_RELATED_FAILURE', error)
    }
  },
}
