<template>
  <div>
    <!-- filter -->
    <b-card-code title="Filters">
      <!-- advance search input -->
      <b-row>
        <b-col
          md="6"
          class="d-flex justify-content-between align-items-center"
        >
          <b-form-group class="w-100">
            <label>Search request by field</label>
            <b-input-group class="input-filter">
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model.trim="searchTerm"
                :placeholder="searchSelected === 'email'
                  ? 'Search by user email'
                  : 'Search by user ID'
                "
                :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                class="d-inline-block"
                @wheel="handleMouseWheelInput"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <label>{{ $t('common.requestedAt') }}</label>
            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Select date"
                :config="configDate"
                @on-close="handleDateChange"
              />
              <b-input-group-append
                v-if="startDate && endDate"
                is-text
                class="close-icon"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearDate"
                />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="CalendarIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group>
            <label> Status </label>
            <v-select
              v-model="statusSelected"
              class="input-height"
              placeholder="Select status"
              :options="statusOptions"
              @input="handleStatusChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="hasAM"
          md="5"
          class="country-tag d-flex justify-content-between align-items-center select-filter-am"
        >
          <b-form-group class="w-100">
            <label>
              Filter by belong to AM
            </label>
            <v-select
              v-model="amSelected"
              class="input-height"
              placeholder="Select AM"
              :options="listAMOptions"
              :filter-by="handleSearchAm"
              @input="handleAmChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <!-- table -->
    <vue-good-table
      id="table-request-refund"
      mode="remote"
      :columns="columns"
      style-class="vgt-table table-request-refund"
      :rows="listRequestRefund"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalRequestPayment > 0,
      }"
      :sort-options="{
        enabled: totalRequestPayment > 0,
      }"
      :is-loading="loading"
      :group-options="{
        enabled: false,
      }"
      @on-sort-change="onSortChange"
    >
      <!-- @on-sort-change="onSortChange" @on-row-click="onRowClick" -->
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ads Account -->
        <div
          v-if="props.column.field === 'userId'"
          class="field-user-info"
        >
          <a
            class="d-flex align-items-center"
            target="_blank"
            :href="`/user/${props.row.localUserId}`"
          >
            <b-img
              :id="`avt-user-${props.row.userId._id}`"
              rounded
              class="ads-avt-img"
              :src="getAvatar(props.row.userId.registerFromService)"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && props.row.userId.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${props.row.userId._id}`"
            >
              {{ getUserPlatform(props.row.userId.registerFromService) }}
            </b-tooltip>
            <div class="d-flex flex-column">
              <p class="text-secondary font-weight-bold mb-0 underline">
                {{ getFullName(props.row) && capitalise(getFullName(props.row)) }}
                <br>
                <small class="text-placeholder">
                  {{ getEmailUser(props.row) }}
                </small>
              </p>
            </div>
          </a>
          <div
            v-if="!isUsedService(props.row)"
            class="ic-used-service"
          >
            <feather-icon
              v-b-tooltip.hover.top="'Do not use the service'"
              icon="UserXIcon"
              size="20"
              class="icon-content"
            />
          </div>
        </div>

        <div v-else-if="props.column.field === 'refundReason'">
          <div>
            <span>{{ refundReason(props.row) }}</span>
          </div>
        </div>

        <div v-else-if="props.column.field === 'description'">
          <div>
            <span>{{ descriptionRequest(props.row) }}</span>
          </div>
        </div>

        <div
          v-else-if="props.column.field === 'receivingMethod'"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="m-0 text-capitalize">
            {{ receivingMethod(props.row) }}
          </p>

          <b-dropdown
            v-if="additionalInfo(props.row)"
            :ref="`dropdown-${props.row._id}`"
            no-caret
            right
            variant="link"
          >
            <template v-slot:button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <div class="position-relative pl-1 pr-1 reason-reject">
              <div>
                <div v-if="emailReceivingMethod(props.row)">
                  <label class="text-capitalize font-weight-700">{{ receivingMethod(props.row) }} email</label>
                  <p class="m-0">
                    {{ emailReceivingMethod(props.row) }}
                  </p>
                </div>

                <div
                  v-if="usdtWalletAddress(props.row)"
                  class="mb-1"
                >
                  <label class="font-weight-700">USDT wallet address</label>
                  <div class="wallet-address">
                    <p class="m-0">
                      {{ usdtWalletAddress(props.row) }}
                    </p>

                    <div class="btn-copy">
                      <feather-icon
                        v-b-tooltip.hover.top="'Copy Address'"
                        icon="CopyIcon"
                        size="14"
                        class="cursor-pointer icon-copy"
                        @click="doCopy(usdtWalletAddress(props.row))"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="usdtNetwork(props.row)"
                  class="mb-1"
                >
                  <label class="font-weight-700">USDT network</label>
                  <p class="m-0">
                    {{ usdtNetwork(props.row) }}
                  </p>
                </div>

                <div v-if="usdtQRCode(props.row)">
                  <label class="font-weight-700">USDT QR code</label>

                  <div
                    class="drop-zoon__preview content-addition"
                    @mouseover="showIconPlay = true"
                    @mouseleave="showIconPlay = false"
                  >
                    <img
                      id="previewImage"
                      :src="usdtQRCode(props.row)"
                      alt="Preview Image"
                      class="drop-zoon__preview-image"
                    >

                    <div
                      class="icon-play-video"
                      :class="showIconPlay ? 'show-icon' : 'hide-icon'"
                      @click="showModalVideo(usdtQRCode(props.row))"
                    >
                      <feather-icon
                        class="icon-play"
                        icon="EyeIcon"
                        size="32"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="wiseAccountHolder(props.row)"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Wise account holder</label>
                  <p class="m-0">
                    {{ wiseAccountHolder(props.row) }}
                  </p>
                </div>

                <div
                  v-if="wiseRountingNumber(props.row)"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Wise rounting number</label>
                  <p class="m-0">
                    {{ wiseRountingNumber(props.row) }}
                  </p>
                </div>

                <div
                  v-if="wiseAccountNumber(props.row)"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Wise account number</label>
                  <p class="m-0">
                    {{ wiseAccountNumber(props.row) }}
                  </p>
                </div>

                <div
                  v-if="wiseAccountType(props.row)"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Wise account type</label>
                  <p class="m-0">
                    {{ wiseAccountType(props.row) }}
                  </p>
                </div>

                <div
                  v-if="wiseAddress(props.row)"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Wise address</label>
                  <p class="m-0">
                    {{ wiseAddress(props.row) }}
                  </p>
                </div>

                <div
                  v-if="additionalInfo(props.row).bankName"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Bank</label>
                  <p class="m-0">
                    {{ additionalInfo(props.row).bankName }}
                  </p>
                </div>

                <div
                  v-if="additionalInfo(props.row).customerName"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Account holder name</label>
                  <p class="m-0">
                    {{ additionalInfo(props.row).customerName }}
                  </p>
                </div>

                <div
                  v-if="additionalInfo(props.row).accountNumber"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Account number</label>
                  <div class="wallet-address">
                    <p class="m-0">
                      {{ additionalInfo(props.row).accountNumber }}
                    </p>

                    <div class="btn-copy">
                      <feather-icon
                        v-b-tooltip.hover.top="'Copy Address'"
                        icon="CopyIcon"
                        size="14"
                        class="cursor-pointer icon-copy"
                        @click="doCopy(additionalInfo(props.row).accountNumber)"
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="localbankQRCodeUrl(props.row)"
                  class="mb-1 mt-1"
                >
                  <label class="font-weight-700">Local bank QR code</label>
                  <div
                    class="drop-zoon__preview content-addition"
                    @mouseover="showIconPlay = true"
                    @mouseleave="showIconPlay = false"
                  >
                    <img
                      id="previewImage"
                      :src="localbankQRCodeUrl(props.row)"
                      alt="Preview Image"
                      class="drop-zoon__preview-image"
                    >

                    <div
                      class="icon-play-video"
                      :class="showIconPlay ? 'show-icon' : 'hide-icon'"
                      @click="showModalVideo(localbankQRCodeUrl(props.row))"
                    >
                      <feather-icon
                        class="icon-play"
                        icon="EyeIcon"
                        size="32"
                      />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </b-dropdown>
        </div>

        <div v-else-if="props.column.field === 'requestAmount'">
          <div class="text-center">
            <span>${{ requestAmount(props.row) }}</span>
          </div>
        </div>

        <div v-else-if="props.column.field === 'currentBalance'">
          <div class="text-center">
            <span>${{ currentBalance(props.row) }}</span>
          </div>
        </div>

        <div v-else-if="props.column.field === 'amReview'">
          <!-- info AM review -->
          <b-dropdown
            v-if="amReviewedRefundPayload(props.row)"
            :ref="`dropdown-am-review-${props.row._id}`"
            no-caret
            right
            variant="link"
          >
            <template v-slot:button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <div class="position-relative pl-1 pr-1 reason-reject">
              <div>
                <p class="text-capitalize font-weight-bolder mb-0">
                  {{ reasonRejectByAM(props.row) ? 'Rejected' : 'Approved' }}
                </p>
                <a @click="$refs[`dropdown-am-review-${props.row._id}`].hide(true)">
                  <feather-icon
                    icon="XIcon"
                    size="16"
                    class="position-absolute position-top-0 position-right-0 mr-1"
                  />
                </a>

                <b-dropdown-divider />

                <div>
                  <label class="font-weight-700">Admin ID</label>
                  <p>{{ idAmReviewed(props.row) }}</p>
                </div>

                <div>
                  <label class="font-weight-700">Review at</label>
                  <p>{{ AMReviewedDate(props.row) }}</p>
                </div>

                <b-badge
                  v-if="reasonRejectByAM(props.row)"
                  variant="light-danger"
                  class="reason-reject-message"
                >
                  <div
                    class="reason-reject-by-am"
                    v-html="reasonRejectByAM(props.row)"
                  />
                </b-badge>
              </div>
            </div>
          </b-dropdown>
          <!-- End info AM review -->

          <!-- Action AM review -->
          <b-dropdown
            v-else
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            right
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              @click="handleActionApproveRequest({ infoRequest: props.row, action: { admin: 'AM', type: 'manual' } })"
            >
              <feather-icon
                class="mr-50"
                icon="CheckCircleIcon"
              />
              <span>Approve</span>
            </b-dropdown-item>
            <b-dropdown-item @click="handleRejectRequest({ admin: 'AM', info: props.row })">
              <feather-icon
                icon="ThumbsDownIcon"
                class="mr-50"
              />
              <span>Reject</span>
            </b-dropdown-item>
          </b-dropdown>
          <!-- End Action AM review -->
        </div>

        <div v-else-if="props.column.field === 'financeReview'">
          <!-- INFO FM REVIEW -->
          <b-dropdown
            v-if="fmReviewedRefundPayload(props.row)"
            :ref="`dropdown-${props.row._id}`"
            no-caret
            right
            variant="link"
          >
            <template v-slot:button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <div class="position-relative pl-1 pr-1 reason-reject">
              <div>
                <p class="text-capitalize font-weight-bolder mb-0">
                  {{ reasonRejectByFM(props.row) ? 'Rejected' : `${typeFMRefund(props.row)} Refund` }}
                </p>
                <a @click="$refs[`dropdown-${props.row._id}`].hide(true)">
                  <feather-icon
                    icon="XIcon"
                    size="16"
                    class="position-absolute position-top-0 position-right-0 mr-1"
                  />
                </a>

                <b-dropdown-divider />

                <div>
                  <label class="font-weight-700">Admin ID</label>
                  <p>{{ idFMReviewed(props.row) }}</p>
                </div>

                <div>
                  <label class="font-weight-700">Review at</label>
                  <p>{{ FMReviewedDate(props.row) }}</p>
                </div>

                <b-badge
                  v-if="reasonRejectByFM(props.row)"
                  variant="light-danger"
                  class="reason-reject-message"
                >
                  <div
                    class="reason-reject-by-am"
                    v-html="reasonRejectByFM(props.row)"
                  />
                </b-badge>
              </div>
            </div>
          </b-dropdown>
          <!-- END INFO FM REVIEW -->

          <!-- ACTION FM REVIEW -->
          <!-- v-b-tooltip.hover.top="
            {(!amReviewedRefundPayload(props.row) && statusTxt(props.row) === 'Pending') : 'Please wait for the AM review'},
              {(!!reasonRejectByAM(props.row) && statusTxt(props.row) === 'Rejected') : 'Request has been rejected by AM' }" -->
          <b-dropdown
            v-else
            :disabled="(!amReviewedRefundPayload(props.row) && statusTxt(props.row) === 'Pending') || !!reasonRejectByAM(props.row)"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >

            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item
              @click="handleActionApproveRequest({ infoRequest: props.row, action: { admin: 'FM', type: 'auto' } })"
            >
              <feather-icon
                class="mr-50"
                icon="CheckCircleIcon"
              />
              <span>Auto Refund</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="handleActionApproveRequest({ infoRequest: props.row, action: { admin: 'FM', type: 'manual' } })"
            >
              <feather-icon
                class="mr-50"
                icon="CheckCircleIcon"
              />
              <span>Manual Refund</span>
            </b-dropdown-item>
            <b-dropdown-item @click="handleRejectRequest({ admin: 'FM', info: props.row })">
              <feather-icon
                icon="ThumbsDownIcon"
                class="mr-50"
              />
              <span>Reject</span>
            </b-dropdown-item>
          </b-dropdown>

          <!-- END ACTION FM REVIEW -->
        </div>

        <div
          v-else-if="props.column.field === 'belongToPartnerID'"
          class="text-center"
        >
          <span>{{ belongToPartnerID(props.row) }}</span>
        </div>

        <div
          v-else-if="props.column.field === 'belongToAM'"
          class="text-center"
        >
          <span>{{ belongToAM(props.row) }}</span>
        </div>

        <div
          v-else-if="props.column.field === 'requestStatus'"
          class="d-flex justify-content-center"
        >
          <div
            :class="statusVariant(props.row)"
            class="request-status text-center"
          >
            <span class="status-content">{{ statusTxt(props.row) }}</span>
          </div>
        </div>

        <div
          v-else-if="props.column.field === 'requestAt'"
          class="text-center"
        >
          <span class="status-content">{{ requestDate(props.row) }}</span>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalRequestPayment > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Ticket per page </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{
                $t('pagination.showing', {
                  pageFrom: pageLength * (currentPage - 1) + 1,
                  pageTo: pageLength * currentPage
                })
              }} of {{ totalRequestPayment }} ticket(s)
            </span>
          </div>

          <div>
            <b-pagination
              :total-rows="totalRequestPayment"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>

    <reject-request-refund-side-bar
      :request-refund-info="requestRefundInfo || {}"
      :show-side-bar.sync="isShowSideBarReject"
      :admin-review="adminReview"
      @fetchListRequestAutoRefund="fetchListRequestAutoRefund"
    />

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewScreenshot"
    />
  </div>
</template>
<script>
import {
  BPagination,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
  BCol,
  BRow,
  BFormSelect,
  BInputGroupPrepend,
  BFormInput,
  BSpinner,
  BImg,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge, BTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code'
import { PAYMENT_METHOD, REGISTER_FROM_SERVICE, TRANSACTIONS_STATUS } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import Ripple from 'vue-ripple-directive'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import axiosLib from 'axios'
import moment from 'moment'
import _get from 'lodash/get'
import usersMixin from '@/mixins/usersMixin'
import ShowScreenshotModal from '@/components/ShowScreenshotModal.vue'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import RejectRequestRefundSideBar from './RejectRequestRefundSideBar.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('payment')
const { mapGetters: mapGettersUser, mapActions: mapActionsUser } = createNamespacedHelpers('user')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    BPagination,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BCol,
    BRow,
    BFormSelect,
    BInputGroupPrepend,
    BFormInput,
    BSpinner,
    BImg,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,

    BCardCode,

    flatPickr,

    VueGoodTable,
    RejectRequestRefundSideBar,
    vSelect,
    ShowScreenshotModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [numberFormatMixin, toastification, envMixin, generalConfigsMixin, usersMixin, dateTimeFormatMixin],

  data() {
    return {
      searchTerm: '',
      searchSelected: 'localUserId',
      isShowSideBarReject: false,
      userSelected: null,
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],
      // sort
      sortTerm: {
        field: '',
        type: '',
      },
      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },
      amSelected: null,
      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],
      currentPage: 1,
      // status filter
      statusSelected: {
        label: 'Pending',
        value: TRANSACTIONS_STATUS.PENDING,
      },
      statusOptions: [
        {
          label: 'All status',
          value: null,
        },
        {
          label: 'Pending',
          value: TRANSACTIONS_STATUS.PENDING,
        },
        {
          label: 'Done',
          value: TRANSACTIONS_STATUS.DONE,
        },
        {
          label: 'Rejected',
          value: TRANSACTIONS_STATUS.REJECTED,
        },
      ],
      requestRefundInfo: {},
      adminReview: '',
      showIconPlay: false,
      imgPreviewScreenshot: '',
    }
  },

  computed: {
    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: `p-1 border-left-0 border-right-0 header-table-mf column-user-id ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
          tdClass: `p-1 border-left-0 border-right-0 align-middle column-user-id bg-white ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
        },
        {
          label: 'User Info',
          field: 'userId',
          thClass: `p-1 border-left-0 border-right-0 header-table-mf column-user-info ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
          tdClass: `p-1 border-left-0 border-right-0 align-middle td-user-info column-user-info bg-white ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
        },
        {
          label: 'Refund reason',
          field: 'refundReason',
          thClass: `p-1 border-left-0 border-right-0 header-table-mf column-sticky ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
          tdClass: `  p-1 border-left-0 border-right-0 align-middle td-refund-reason column-sticky bg-white ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
        },
        {
          label: 'Detail reason',
          field: 'description',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-description',
        },
        {
          label: 'Receiving method',
          field: 'receivingMethod',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-receiving-method',
        },
        {
          label: 'Request amount',
          field: 'requestAmount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-request-amount',
        },
        {
          label: 'Current balance',
          field: 'currentBalance',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-request-amount',
        },
        {
          label: 'AM Review',
          field: 'amReview',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Finance review',
          field: 'financeReview',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Belong to partner ID',
          field: 'belongToPartnerID',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-width-150',
          hidden: !this.hasPartnership,
        },
        {
          label: 'Belong to AM',
          field: 'belongToAM',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-width-150',
        },
        {
          label: 'Request status',
          field: 'requestStatus',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-width-150',
        },
        {
          label: 'Request at',
          field: 'requestAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle td-width-150',
        },
      ]
    },

    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['listRequestAutoRefund', 'status', 'loading', 'message']),
    ...mapGettersUser(['listAM']),

    listAMOptions() {
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          value: item.localUserId,
        }
        return options
      })
      const optionNone = {
        label: 'N/A',
        value: 0,
      }
      return [optionNone, ...listAMMap]
    },

    amReviewedRefundPayload() {
      return data => data?.payload?.amReviewedRefundPayload
    },

    fmReviewedRefundPayload() {
      return data => data?.payload?.fmReviewedRefundPayload
    },

    idAmReviewed() {
      return data => data?.payload?.amReviewedRefundPayload?.reviewBy || 'N/A'
    },

    idFMReviewed() {
      return data => data?.payload?.fmReviewedRefundPayload?.reviewBy || 'N/A'
    },

    AMReviewedDate() {
      return value => {
        const reviewAt = value?.payload?.amReviewedRefundPayload?.rejectedAt || value?.payload?.amReviewedRefundPayload?.approvedAt
        return (reviewAt ? this.formatDate(reviewAt) : '')
      }
    },

    FMReviewedDate() {
      return value => {
        const reviewAt = value?.payload?.fmReviewedRefundPayload?.rejectedAt || value?.payload?.fmReviewedRefundPayload?.approvedAt
        return (reviewAt ? this.formatDate(reviewAt) : '')
      }
    },

    reasonRejectByAM() {
      return data => data?.payload?.amReviewedRefundPayload?.rejectedReason
    },

    typeFMRefund() {
      return data => data?.payload?.fmReviewedRefundPayload?.refundAction
    },

    reasonRejectByFM() {
      return data => data?.payload?.fmReviewedRefundPayload?.rejectedReason
    },

    requestDate() {
      return value => (value?.createdAt ? this.formatDate(value?.createdAt) : '')
    },

    belongToPartnerID() {
      return data => data?.payload?.belongToPartnerId || 'N/A'
    },

    belongToAM() {
      return data => data?.payload?.belongToAmId || 'N/A'
    },

    statusVariant() {
      const statusColor = {
        [TRANSACTIONS_STATUS.DONE]: 'light-success',
        [TRANSACTIONS_STATUS.REJECTED]: 'light-danger',
        [TRANSACTIONS_STATUS.PENDING]: 'light-warning',
      }

      return data => statusColor[data.status]
    },

    statusTxt() {
      const statusTxt = {
        [TRANSACTIONS_STATUS.DONE]: 'Done',
        [TRANSACTIONS_STATUS.REJECTED]: 'Rejected',
        [TRANSACTIONS_STATUS.PENDING]: 'Pending',
      }

      return data => statusTxt[data.status]
    },

    getFullName() {
      return data => data?.userId?.fullName
    },

    getEmailUser() {
      return data => data?.userId?.email
    },

    isUsedService() {
      return data => data?.payload?.isUsedService
    },

    refundReason() {
      return data => {
        switch (data?.payload?.reasonType) {
          case 'permanent_suspended':
            return 'Permanent service discontinuation'
          case 'temporary_suspended':
            return 'Temporarily stop using service'
          case 'other':
            return 'Other'
          default:
            return 'N/A'
        }
      }
    },

    descriptionRequest() {
      return data => data?.payload?.description || 'N/A'
    },

    receivingMethod() {
      return data => {
        switch (data.paymentMethod) {
          case PAYMENT_METHOD.PAYPAL:
            return 'PayPal'
          case PAYMENT_METHOD.CREDITCARD:
            return 'Credit card'
          case PAYMENT_METHOD.TRANSFERWISE:
            return 'Wise'
          case PAYMENT_METHOD.LOCALBANK:
            return 'Local bank'
          default:
            return data.paymentMethod
        }
      }
    },

    emailReceivingMethod() {
      return data => data?.payload?.additionalInfo?.email
    },

    usdtWalletAddress() {
      return data => data?.payload?.additionalInfo?.usdtWalletAddress
    },

    usdtNetwork() {
      return data => data?.payload?.additionalInfo?.usdtNetwork
    },

    usdtQRCode() {
      return data => data?.payload?.additionalInfo?.usdtQRCodeUrl
    },

    wiseAccountHolder() {
      return data => data?.payload?.additionalInfo?.wiseAccountHolder
    },

    wiseRountingNumber() {
      return data => data?.payload?.additionalInfo?.wiseRountingNumber
    },

    wiseAccountNumber() {
      return data => data?.payload?.additionalInfo?.wiseAccountNumber
    },

    wiseAccountType() {
      return data => data?.payload?.additionalInfo?.wiseAccountType
    },

    wiseAddress() {
      return data => data?.payload?.additionalInfo?.wiseAddress
    },

    additionalInfo() {
      return data => data?.payload?.additionalInfo || {}
    },

    localbankQRCodeUrl() {
      return data => data?.payload?.additionalInfo?.localbankQRCodeUrl
    },

    requestAmount() {
      return data => data?.amount || 'N/A'
    },

    currentBalance() {
      return data => this.amountFormat(data?.payload?.remainBalance)
    },

    totalRequestPayment() {
      return _get(this.listRequestAutoRefund, ['paging', 'total'], 0)
    },

    listRequestRefund() {
      return this.listRequestAutoRefund.content
    },
  },

  created() {
    this.fetchListRequestAutoRefund()
    this.getListAM()
    this.initScrollVerifyInfoTable()
  },

  methods: {
    ...mapActions(['getListRequestAutoRefund', 'reviewRequestRefund']),
    ...mapActionsUser(['getListAM']),

    doCopy(contentCopy) {
      this.$copyText(contentCopy).then(() => {
        this.toastSuccess('Copy successfully!')
      }, e => {
        this.toastFailure(e)
      })
    },

    showModalVideo(file) {
      this.imgPreviewScreenshot = file
      this.$refs['open-screenshot-modal'].showModal()
    },

    async initScrollVerifyInfoTable() {
      await document.querySelector('#table-request-refund')
      document.querySelector('#table-request-refund .vgt-responsive').addEventListener('scroll', event => this.scrollVerifyInfoTable(event))
    },

    scrollVerifyInfoTable(event) {
      const currentScroll = Math.floor(event.target.scrollLeft)
      if (currentScroll === 0) {
        this.isScrollTable = false
      } else {
        this.isScrollTable = true
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.currentPage = 1
        this.fetchListRequestAutoRefund()
      }
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchListRequestAutoRefund()
    },

    async handleAmChange() {
      this.currentPage = 1
      await this.fetchListRequestAutoRefund()
    },

    handleSearchAm(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.value?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchListRequestAutoRefund()
    },

    async handleActionApproveRequest({ infoRequest, action }) {
      const params = {
        admin: action.admin,
        status: 'approve',
        // eslint-disable-next-line no-underscore-dangle
        transactionId: infoRequest?._id,
        refundAction: action.type,
      }

      await this.reviewRequestRefund(params)
      if (this.status) {
        await this.fetchListRequestAutoRefund()
        this.toastSuccess(`Approved request refund${action.admin === 'FM' ? ` by ${action.type} refund` : ''} successfully!`)
      } else {
        this.toastFailure(this.message)
      }
    },

    handleRejectRequest({ admin, info }) {
      this.requestRefundInfo = info
      this.adminReview = admin
      this.isShowSideBarReject = true
    },

    capitalise(value) {
      return value && value.charAt(0).toUpperCase() + value.slice(1)
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListRequestAutoRefund()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListRequestAutoRefund()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListRequestAutoRefund()
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchListRequestAutoRefund()
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchListRequestAutoRefund()
      } else if (searching.toString().length > 3 && this.searchSelected !== 'email') {
        this.debounce = setTimeout(() => {
          this.currentPage = 1
          this.fetchListRequestAutoRefund()
        }, 600)
      } else if (this.searchSelected === 'email') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            this.currentPage = 1
            this.fetchListRequestAutoRefund()
          }, 1000)
        }
      }
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0]))
          .format(
            'YYYY-MM-DD',
          )
        this.endDate = moment(new Date(selectedDates[1]))
          .format('YYYY-MM-DD')
        await this.fetchListRequestAutoRefund()
      }
    },

    async fetchListRequestAutoRefund() {
      if (
        this.searchTerm
        && !this.validateEmail(this.searchTerm)
        && this.searchSelected === 'email'
      ) {
        return
      }

      const params = {
        page: this.currentPage,
        size: this.pageLength,
        types: ['refund_user_balance'],
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.amSelected && { belongToAmId: this.amSelected.value }),
        ...(this.statusSelected && { status: this.statusSelected.value }),
      }

      await this.getListRequestAutoRefund({ params, cancelToken: cancelToken.token })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.country-tag {
  /* .b-form-tags {
      padding: 0;
      border: none;
    } */

  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1) !important;
  }
}

.table-request-refund {
  .td-user-info {
    min-width: 350px;
    max-width: 400px;
  }

  .td-refund-reason {
    min-width: 250px;
  }

  .td-description {
    min-width: 250px;
    max-width: 350px;
  }

  .td-receiving-method {
    min-width: 200px;
  }

  .td-request-amount {
    min-width: 150px;
  }

  .td-width-150 {
    min-width: 150px;
  }

  .column-user-id {
    left: 0px;
    min-width: 100px !important;
    width: 100%;
    border-bottom: 1px solid #ebe9f1;
  }

  .column-user-id, .vgt-checkbox-col {
    position: sticky !important;
    z-index: 2;
  }

  .column-user-info {
    left: 100px;
    min-width: 350px !important;
    width: 100%;
    border-bottom: 1px solid #ebe9f1;
  }

  .column-user-info, .vgt-checkbox-col {
    position: sticky !important;
    z-index: 2;
  }

  .column-sticky {
    left: 450px;
    border-bottom: 1px solid #ebe9f1;
  }

  .column-sticky, .vgt-checkbox-col {
    position: sticky !important;
    z-index: 1;
  }

  tr:hover {
    .column-user-id, .column-user-info, .column-sticky, .vgt-checkbox-col {
      background-color: #fafafc !important;
    }
  }

  .scroll-user-info-column {
    &:after {
      content: "";
      position: absolute;
      height: 101%;
      top: 0;
      right: -13px;
      width: 13px;
      box-shadow: inset 13px 0px 8px -8px #00000026;
    }
  }
}

.reason-reject-by-am {
  p {
    margin: 0;
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 3.09px 0 3.5px 0;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.reason-reject {
  max-width: 350px;
  width: max-content;
  max-height: 200px;
  overflow: auto;

  &-message {
    display: block;
    white-space: normal;
    text-align: start;
  }
}

.content-reason {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  p {
    margin: 0;
  }
}

.tooltip-inner {
  max-width: 700px !important;
}
</style>

<style lang="scss" scoped>
.field-user-info {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;

  .ic-used-service {
    margin-left: 1rem;

    .icon-content {
      color: #FAB345
    }
  }
}

.btn-search {
  margin-top: 13px;
  margin-left: 1rem;
}

.text-capitalize {
  width: max-content;
}

.none-value {
  padding: .5rem;
  height: 74px;
  display: flex;
  align-items: center;
}

.request-status {
  border-radius: 10px;
  padding: 4px 6px;
  text-align: center;
  width: 70px;

  .status-content {
    font-size: 14px;
  }

  &.light-success {
    background: rgba(122, 179, 76, 0.10);

    .status-content {
      color: #7AB34C;
    }
  }

  &.light-danger {
    background: rgba(242, 37, 72, 0.10);

    .status-content {
      color: #FD4C5C;
    }
  }

  &.light-warning {
    background: rgba(250, 179, 69, 0.10);

    .status-content {
      color: #FAB345;
    }
  }
}

.drop-zoon__preview {
  position: relative;
  margin-bottom: 16px;
  width: max-content;

  .drop-zoon__preview-image {
    width: max-content;
    height: 110px;
    object-fit: contain;
    border-radius: 6px;
  }

  .icon-play-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;

    .icon-play {
      z-index: 999;
      color: #fff;
    }

    &.show-icon {
      animation: showicon 0.5s;
    }

    &.hide-icon {
      visibility: hidden;
      opacity: 0;
    }

    @keyframes showicon {
      0% {
        visibility: hidden;
        opacity: 0;
      }
      100% {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.wallet-address {
  display: flex;

  p {
    overflow-x: auto;
  }

  .icon-copy {
    margin-left: 6px;
    color: #FF003B;
  }
}
</style>
