import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    listPartner: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listMemberPartner: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    totalMember: {},
    totalSpendingAndDeposit: {},
    partnerAdsAccounts: [],
    totalMemberLifeTime: {},
    totalAdsAccountMonth: {},
    totalMemberMonth: {},
    listAdAccountUser: [],
  },
  getters,
  mutations,
  actions,
}
