<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    width="420px"
    right
    backdrop
    shadow
    title="Add Partner BC"
    @hidden="onHidden"
  >
    <div class="wrapper">
      <div>
        <validation-observer
          ref="formValidation"
        >
          <b-form>
            <!-- Worth gift -->
            <b-form-group class="w-100">
              <label> Partner BC ID </label>
              <validation-provider
                #default="{ errors }"
                id="BC Id"
                name="BC Id"
                rules="required"
              >
                <b-form-tags
                  v-model="bcIds"
                  class="group-input-bc-id"
                  no-outer-focus
                >
                  <template
                    v-slot="{tags, inputAttrs, inputHandlers, addTag, removeTag}"
                  >
                    <b-input-group
                      aria-controls="my-custom-tags-list"
                    >
                      <input
                        v-bind="inputAttrs"
                        placeholder="BC ID - Press enter to add"
                        class="form-control"
                        type="number"
                        v-on="inputHandlers"
                      >
                      <b-input-group-append>
                        <b-button
                          variant="primary"
                          @click="addTag()"
                        >
                          Add
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <ul
                      id="my-custom-tags-list"
                      class="list-unstyled mb-0"
                      aria-live="polite"
                      aria-atomic="false"
                      aria-relevant="additions removals"
                    >
                      <!-- Always use the tag value as the :key, not the index! -->
                      <!-- Otherwise screen readers will not read the tag
                   additions and removals correctly -->
                      <b-card
                        v-for="tag in tags"
                        :key="tag"
                        :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                        tag="li"
                        class="bc-id-item"
                        body-class="body-bc-id-input"
                      >
                        <strong>{{ tag }}</strong>
                        <b-button
                          variant="link"
                          size="sm"
                          :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                          class="p-0"
                          @click="removeTag(tag)"
                        >
                          <feather-icon
                            class="icon-delete-id"
                            icon="XIcon"
                            size="16"
                          />
                        </b-button>
                      </b-card>
                    </ul>
                  </template>
                </b-form-tags>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="w-100">
              <validation-provider
                #default="{ errors }"
                id="ads account id"
                name="ads account id"
                rules="required"
              >
                <label>Ads Account ID</label>
                <b-form-input
                  v-model.trim="adsAccountId"
                  placeholder="EX: 7172040789353086977"
                  class="d-inline-block"
                  type="number"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="w-100">
              <validation-provider
                #default="{ errors }"
                id="role"
                name="role"
                rules="required"
              >
                <label> Role </label>
                <v-select
                  v-model="role"
                  class="input-height"
                  placeholder="Select role"
                  :options="roleOptions"
                />
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </div>

      <btn-loading
        class="btn-approve"
        type="submit"
        block
        variant="outline-primary"
        :loading="loading"
        @click="handleSubmit"
      >
        Approve Permissions
      </btn-loading>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTags, BInputGroup, BInputGroupAppend, BCard, BButton,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'

const { mapGetters, mapActions } = createNamespacedHelpers('partnerPermission')

export default {
  components: {
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BButton,
  },
  mixins: [toastification],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      required,
      show: false,

      bcIds: null,
      adsAccountId: '',
      role: null,

      roleOptions: [
        {
          label: 'ADMIN',
          value: 'ADMIN',
        },
        {
          label: 'OPERATOR',
          value: 'OPERATOR',
        },
        {
          label: 'ANALYST',
          value: 'ANALYST',
        },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'listPartnerPermissions',
      'message',
      'status',
      'loading',
    ]),

    partnerOptions() {
      return this.listPartnerNoPaging.content.map(item => ({
        value: item._id,
        label: item.email,
        localId: item.localUserId,
      }))
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.$refs.formValidation.reset()
    },
  },

  methods: {
    ...mapActions([
      'approvePartnerPermission',
    ]),

    resetForm() {
      this.bcIds = null
      this.adsAccountId = ''
      this.role = null
      this.$refs.formValidation.reset()
    },

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    async handleSubmit() {
      const success = await this.$refs.formValidation.validate()
      if (success && Array.isArray(this.bcIds) && this.bcIds.length > 0) {
        const params = {
          BCActions: {
            BCIds: this.bcIds,
            role: this.role.value,
          },
          adsAccountId: this.adsAccountId,
        }
        await this.approvePartnerPermission(params)
        if (this.status) {
          this.$emit('fetch-list-partner-permissions')
          this.onHidden()
          this.toastSuccess('Approve partner permission successfully!')
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}

.group-input-bc-id{
  padding: 0;
  border: none;
}

.body-bc-id-input {
  padding: 5px 10px;
  background: #828287;
  border-radius: 6px;
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.icon-delete-id{
  color: #fff;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.list-unstyled{
  width: calc(100% - 81.09px);
}

.bc-id-item{
  margin: 1rem 0 0 0;
  width: 100%;
}
</style>
