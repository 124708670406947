export const validatorPositive = value => {
  if (value >= 0) {
    return true
  }
  return false
}

export const validatorGreaterThanZero = value => {
  if (value > 0) {
    return true
  }
  return false
}

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.{6,})/
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password)
  return validPassword
}

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum)
  return validCreditCard
}

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
  // /^(?:https?:\/\/(?:www\.)?|www\.)[a-z0-9]+(?:[-.][a-z0-9]+)*\.[a-z]{2,5}(?::[0-9]{1,5})?(?:\/\S*)?$/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorUrlWithProtocolsValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  /* eslint-disable no-useless-escape */
  const re = /^((https):\/\/)/
  /* eslint-enable no-useless-escape */
  return re.test(val)
}

export const validatorAdsAccount = val => {
  const inbadChars = "\t\n\r^&*=~`';<>?[]{}abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ "
  // debugger;
  for (let n = 0; n < val.length; n += 1) {
    if (inbadChars.indexOf(val.charAt(n)) !== -1) return false
  }
  return true
}

export const validatorExcludeUrl = url => {
  if (!url) return false
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
            + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' // domain name
            + '((\\d{1,3}\\.){3}\\d{1,3}))|' // OR ip (v4) address
            + 'localhost' // OR localhost
            + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
            + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
            + '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator
  return !pattern.test(url)
}

export const validatorAdsAccountId = val => !!(val.length < 1000)

export const validatorVerificationCode = value => !!(value.length === 6)

export const validatorChecked = val => val === true

export const validateTopUp = val => val <= 100000 && val >= 100

export const validateVNDTopUp = val => val <= 2320000000 && val >= 2320000

export const validatorTelegramLength = val => val.length <= 100

export const validatorPhoneNumberZalo = phone => {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\+]?[(]?[0-9]{2,3}[)]?[-\s\.]?[0-9]{1,3}[-\s\.]?[0-9]{0,3}[-\s\.]?[0-9]{0,41}$/im
  return re.test(phone)
}

export const validatorTaxFee = val => val <= 3
