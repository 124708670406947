export default [
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('@/views/subscriptions/Subscriptions.vue'),
    meta: {
      pageTitle: 'Review Subscription',
      requireAuth: true,
    },
  },
]
