var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-right","bg-variant":"white","title":"Edit Package","width":"420px","right":"","backdrop":"","shadow":""},on:{"hidden":_vm.onHidden},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"p-2"},[_c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-form-group',{staticClass:"form-label",attrs:{"label":"List service fees and Tax"}},[_vm._l((_vm.listTransferFee),function(payment){return _c('validation-provider',{key:payment.key,staticClass:"mb-2 d-flex justify-content-between w-100",attrs:{"name":payment.name + ' fee',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticStyle:{"margin-right":"5px"},attrs:{"src":_vm.getBrandIcon(payment.key),"width":"20px","alt":"payment"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-14 text-capitalize"},[_vm._v(" "+_vm._s(_vm.namePaymentConvert(_vm.keyPayment(payment.key)))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]),_c('b-input-group',{staticClass:"text-right input-group-merge",class:errors.length > 0 ? 'is-invalid':null,staticStyle:{"max-width":"110px"}},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false:null},model:{value:(payment.value),callback:function ($$v) {_vm.$set(payment, "value", _vm._n($$v))},expression:"payment.value"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" % ")])],1)]}}],null,true)})}),_c('validation-provider',{staticClass:"mb-2 d-flex justify-content-between w-100",attrs:{"name":"tax","rules":"required|valTaxFee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-14 font-weight-bold"},[_vm._v("TAX")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]),_c('b-input-group',{staticClass:"text-right input-group-merge",class:errors.length > 0 ? 'is-invalid':null,staticStyle:{"max-width":"110px"}},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"tax","state":errors.length > 0 ? false : null,"placeholder":"Enter Tax","type":"number"},model:{value:(_vm.tax),callback:function ($$v) {_vm.tax=_vm._n($$v)},expression:"tax"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v(" % ")])],1)]}}])})],2),_c('b-form-group',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"landingPage"}},[_vm._v(" Min top up ")])]),_c('validation-provider',{attrs:{"name":"Min top up","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"limitAdsAccount","placeholder":"Enter limit ads account","state":errors.length > 0 ? false : null},model:{value:(_vm.minTopup),callback:function ($$v) {_vm.minTopup=_vm._n($$v)},expression:"minTopup"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"landingPage"}},[_vm._v(" Limit ads account ")])]),_c('validation-provider',{attrs:{"name":"limitAdsAccount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"input-height",attrs:{"name":"limitAdsAccount","state":errors.length > 0 ? false : null,"placeholder":"Enter limit ads account"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"form-label mb-2"},[_c('div',[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Description")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Enter description","rows":"3"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]),_c('div',{staticClass:"mt-1"},[_c('btn-loading',{attrs:{"type":"submit","variant":"primary","block":"","loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" Update ")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }