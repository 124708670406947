import { VerifyInfoService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async getListUserInfo({ commit }, { params, cancelToken }) {
    commit('GET_LIST_USER_INFO_REQUEST')
    try {
      const { data } = await VerifyInfoService.getListUserInfo(params, cancelToken)
      commit('GET_LIST_USER_INFO_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_LIST_USER_INFO_FAILURE', error)
    }
  },

  async verifyUserInfo({ commit }, params) {
    commit('HANDLE_USER_INFO_REQUEST')
    try {
      const { data } = await VerifyInfoService.verifyUserInfo(params)
      commit('HANDLE_USER_INFO_SUCCESS', getResponse(data))
    } catch (error) {
      commit('HANDLE_USER_INFO_FAILURE', error)
    }
  },
}
