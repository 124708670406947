/* eslint-disable global-require */
import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  listMemberPartner: state => state.listMemberPartner,
  listPartner: state => state.listPartner,
  totalMember: state => state.totalMember,
  totalSpendingAndDeposit: state => state.totalSpendingAndDeposit,
  partnerAdsAccounts: state => state.partnerAdsAccounts,
  listAdAccountUser: state => state.listAdAccountUser,

  listAnalysisPartnerDetail: state => ([
    // {
    //   label: 'Total member',
    //   value: `${parseFloat(Math.floor(state.totalMember?.totalUsers * 100) / 100).toLocaleString('en-US')}`,
    //   icon: require('@/assets/images/common/ic-total-user.svg'),
    // },
    {
      label: 'Total opened ad accounts',
      value: state.partnerAdsAccounts?.totalAdsAccounts !== null ? `${parseFloat(Math.floor(state.partnerAdsAccounts?.totalAdsAccounts * 100) / 100).toLocaleString('en-US')}` : 'N/A',
      icon: require('@/assets/images/common/ic-tiktok-user.svg'),
    },
    {
      label: 'Total Deposit',
      value: state.totalSpendingAndDeposit !== null ? `$${parseFloat(Math.floor(state.totalSpendingAndDeposit?.totalDeposit * 100) / 100).toLocaleString('en-US')}` : 'N/A',
      icon: require('@/assets/images/common/ic-green-dollar.svg'),
    },
    {
      label: 'Total spending',
      value: state.totalSpendingAndDeposit !== null ? `$${parseFloat(Math.floor(state.totalSpendingAndDeposit?.totalSpending * 100) / 100).toLocaleString('en-US')}` : 'N/A',
      icon: require('@/assets/images/common/ic-dollar.svg'),
    },
    {
      label: 'Total members/blocked members',
      value: state.totalMemberLifeTime !== null ? `${state.totalMemberLifeTime?.totalUsers}/${state.totalMemberLifeTime?.totalBlockedUsers}` : 'N/A',
      icon: require('@/assets/images/common/ic-total-user.svg'),
    },
    {
      label: 'Total members spending',
      value: state.totalMemberLifeTime !== null ? `${state.totalMemberLifeTime?.totalSpendUsers}` : 'N/A',
      icon: require('@/assets/images/common/ic-total-user.svg'),
    },
  ]),

  monthlyMemberPartnerDetail: state => ([
    {
      label: 'Monthly Deposit',
      value: state.totalSpendingAndDeposit !== null ? `$${parseFloat(Math.floor(state.totalSpendingAndDeposit?.monthlyDeposit * 100) / 100).toLocaleString('en-US')}` : 'N/A',
      icon: require('@/assets/images/common/ic-green-dollar.svg'),
    },
    {
      label: 'Monthly spending',
      value: state.totalSpendingAndDeposit !== null ? `$${parseFloat(Math.floor(state.totalSpendingAndDeposit?.monthlySpending * 100) / 100).toLocaleString('en-US')}` : 'N/A',
      icon: require('@/assets/images/common/ic-dollar.svg'),
    },
    {
      label: 'Monthly opened ad accounts',
      value: state.totalAdsAccountMonth ? `${state.totalAdsAccountMonth?.totalAdsAccounts}` : 'N/A',
      icon: require('@/assets/images/common/ic-tiktok-user.svg'),
    },
    {
      label: 'Monthly new members/blocked members',
      value: state.totalMemberMonth !== null ? `${state.totalMemberMonth?.totalUsers}/${state.totalMemberMonth?.totalBlockedUsers}` : 'N/A',
      icon: require('@/assets/images/common/ic-total-user.svg'),
    },
    {
      label: 'Monthly members spending',
      value: state.totalMemberMonth !== null ? `${state.totalMemberMonth?.totalSpendUsers}` : 'N/A',
      icon: require('@/assets/images/common/ic-total-user.svg'),
    },
  ]),
}
