import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    paymentTickets: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    transactions: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    adsAccountHistories: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    cashbackTransaction: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    refundTransaction: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    listRequestAutoRefund: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    ticketHistoryLoading: false,
    balanceHistoryLoading: false,
    adsAccountHistoryLoading: false,
    refundTransactionLoading: false,
  },
  getters,
  mutations,
  actions,
}
