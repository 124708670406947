import auth from '@auth'
import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const idTokenData = auth.getToken()

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    idToken: idTokenData,
    isLoggedIn: !!idTokenData,
    user: {
      firstName: '',
      lastName: '',
      imageUrl: '',
      email: '',
      phoneNumber: '',
    },
    generalConfigs: {},
    verificationCode: {},
  },
  getters,
  mutations,
  actions,
}
