<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-body class="p-1">
      <div class="d-flex justify-content-between">
        <b-card-title class="font-20 font-weight-700">
          {{ typeSortSpending === 'day' ? 'Monthly' : 'Yearly' }} spending
        </b-card-title>
        <analytics-selection
          :selection="selection"
          @change="handleSelectionChange"
        />
      </div>
      <div v-if="errorSpending">
        <p class="text-error">
          {{ errorSpending }}
        </p>
      </div>
      <div v-else>
        <app-echart-bar :option-data="option" />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import store from '@/store'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import AnalyticsSelection from './AnalyticsSelection.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('home')

export default {
  components: {
    BCard,
    BCardTitle,
    BCardBody,
    AppEchartBar,
    AnalyticsSelection,
  },

  mixins: [dateTimeFormatMixin],

  data() {
    return {
      option: {
        xAxis: {
          data: [],
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            data: [],
            type: 'bar',
            itemStyle: {
              color: '#7AB34C',
            },
          },
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
      },
      selection: {
        type: 'daily',
        monthSelected: new Date().getMonth() + 1,
        yearSelected: new Date().getFullYear(),
      },

      errorSpending: '',
    }
  },

  computed: {
    ...mapGetters(['spendingData']),
    ...mapGetters(['typeSortSpending', 'message', 'status']),
    params() {
      if (this.selection.type === 'daily') {
        return {
          year: this.selection.yearSelected,
          type: this.selection.type,
          month: this.selection.monthSelected,
        }
      }

      return {
        year: this.selection.yearSelected,
        type: this.selection.type,
      }
    },
  },

  watch: {
    spendingData() {
      this.setData()
    },
  },

  created() {
    this.createdComponent()
  },

  methods: {
    ...mapActions(['getSpendingAnalytics']),

    async createdComponent() {
      const date = new Date()
      store.commit('home/SET_TYPE_SPENDING_SORT', 'day')
      store.commit('home/SET_LIMIT_DAY_SPENDING', date.getDate())
      store.commit('home/SET_LIMIT_MONTH_SPENDING', date.getMonth() + 1)

      if (this.selection.type === 'daily') {
        this.setMonthly(date.getFullYear(), date.getMonth() + 1)
      } else {
        this.setYearly()
      }

      this.setData()
      await this.getSpendingAnalytics(this.params)
      if (!this.status) {
        this.errorSpending = this.message
      }
    },

    setYearly() {
      this.option = {
        ...this.option,
        xAxis: {
          data: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
        },
      }
    },

    setData() {
      this.option = {
        ...this.option,
      }
      // Active item
      this.option.series[0].data = this.spendingData
    },

    setMonthly(year = '2022', month = new Date().getMonth() + 1) {
      const daysInMonth = this.getDaysInMonth(year, month)

      const xAxis = []
      for (let index = 1; index <= daysInMonth; index += 1) {
        xAxis.push(index)
      }

      this.option = {
        ...this.option,
        xAxis: {
          data: [...xAxis],
        },
      }
    },

    handleSelectionChange(data) {
      const date = new Date()
      if (data.type === 'daily') {
        this.setMonthly(data.year, data.month)
        store.commit('home/SET_TYPE_SPENDING_SORT', 'day')
      } else {
        this.setYearly()
        store.commit('home/SET_TYPE_SPENDING_SORT', 'month')
      }

      let limitDay = 0
      if (data.year < date.getFullYear()) {
        limitDay = this.getDaysInMonth(data.year, data.month)
      } else {
        limitDay = (data.month < date.getMonth() + 1) ? this.getDaysInMonth(data.year, data.month) : date.getDate()
      }
      const limitMonth = (data.year < date.getFullYear()) ? 12 : date.getMonth() + 1
      store.commit('home/SET_LIMIT_DAY_SPENDING', limitDay)
      store.commit('home/SET_LIMIT_MONTH_SPENDING', limitMonth)
      setTimeout(() => {
        this.getSpendingAnalytics(data)
      }, 1000)
    },
  },
}

</script>
<style lang="scss" scoped>
.text-error {
  margin-top: 1rem;
  font-size: 14px;
  color: #f02649;
}
</style>
