import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // list
  GET_SUBSCRIPTIONS_REQUEST(state) {
    REQUEST(state)
  },
  GET_SUBSCRIPTIONS_SUCCESS(state, data) {
    SUCCESS(state)
    state.subscriptions = data
  },

  GET_SUBSCRIPTIONS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // approve subscription
  APROVE_SUBSCRIPTION_REQUEST(state) {
    REQUEST(state)
  },
  APROVE_SUBSCRIPTION_SUCCESS(state, data) {
    SUCCESS(state)
    state.subscriptions = data
  },

  APROVE_SUBSCRIPTION_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // reject subscription
  REJECT_SUBSCRIPTION_REQUEST(state) {
    REQUEST(state)
  },
  REJECT_SUBSCRIPTION_SUCCESS(state, data) {
    SUCCESS(state)
    state.subscriptions = data
  },

  REJECT_SUBSCRIPTION_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // delete subscription
  DELETE_SUBSCRIPTION_REQUEST(state) {
    REQUEST(state)
  },
  DELETE_SUBSCRIPTION_SUCCESS(state) {
    SUCCESS(state)
  },

  DELETE_SUBSCRIPTION_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
