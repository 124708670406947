<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Add new user"
    width="420px"
    right
    no-header
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        Add new user
      </h5>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="onHidden"
        />
      </div>
    </div>
    <div class="p-2 form-user-info">

      <validation-observer ref="formValidation">
        <b-form>
          <div class="pb-3">
            <!-- email -->
            <b-form-group
              class="my-2 form-email"
              label-for="login-email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model.trim="emailPartner"
                    id="login-email"
                    class="input-height"
                    name="email"
                    placeholder="Ex: name@gmail.com"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Business ID -->
            <b-form-group
              label="Agency Business ID"
              label-for="Agency Business"
            >
              <validation-provider
                #default="{ errors }"
                name="Agency Business"
                rules="required"
              >
                <b-form-select
                  v-model="BCId"
                  placeholder="Select business ID"
                  :options="listBussinessCenter"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      -- Select an option --
                    </b-form-select-option>
                  </template>
                </b-form-select>
                <small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>

            <!-- Partner ID -->
            <b-form-group
              class="my-2 form-partnerBCId"
              label-for="partnerBCId"
              label="Partner BC Id"
            >
              <validation-provider
                #default="{ errors }"
                name="partnerBCId"
                vid="partnerBCId"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    v-model.trim="partnerBCId"
                    id="partnerBCId"
                    type="number"
                    class="input-height"
                    name="partnerBCId"
                    placeholder="Ex: 6846301192088109999"
                    :state="errors.length > 0 ? false:null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Balance -->
            <b-form-group
              label="Ads Account ID(exists)"
              class="form-label mb-1"
            >
              <validation-provider
                #default="{ errors }"
                name="Ads account id"
                rules="required|valAdsAccountId|valAdsAccountIdFormat"
              >
                <b-input-group>
                  <b-form-textarea
                    v-model.trim="idAccount"
                    type="number"
                    placeholder="Ex:  6981314059819,698131405984..."
                    @keydown.space.prevent
                    @keydown.enter.prevent
                    @keyup="restrictChars()"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <div
              v-if="!isWhiteList"
              class="mb-1"
            >
              <b-form-group
                class="product-tag"
                label-for="tags-validation"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    Product Url
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="fullName"
                  name="product link"
                  rules="required|url"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="productsData.productURL"
                      name="product link"
                      class="input-height"
                      placeholder="Enter product link"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group class="m-0 country-tag">
                <validation-provider
                  #default="{}"
                  name="country"
                  rules="required"
                >
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      Target Market
                    </label>
                  </div>
                  <v-select
                    v-model="productsData.targetMarkets"
                    class="input-height"
                    multiple
                    placeholder="Select your target Market"
                    :options="availableOptions"
                  />
                </validation-provider>
              </b-form-group>
            </div>

            <b-form-group class="group-input">
              <phone-input
                :phone-data.sync="phoneData"
                :phone-error="phoneError"
              />
            </b-form-group>

            <b-card-text :class="isVaidContact ? 'text-danger': ''">
              <small class="text-danger">
                *
              </small>
              Please add at least one contact URL to help us contact you easily.
            </b-card-text>

            <b-form-group class="group-input">
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="connectTelegram"
                >
                  Connect via Telegram
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="connectTelegram"
                name="telegram"
                rules="telegramLength|valExcludeUrl"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model.trim="telegram"
                    id="connect-telegram"
                    name="connectTelegram"
                    class="input-height"
                    placeholder="@username"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="group-input">
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="connectTelegram"
                >
                  Connect via Facebook
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="connectFacebook"
                name="facebook"
              >
                <!-- rules="fbUrl" -->
                <b-input-group
                  class="input-group-merge input-fbUrl"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  prepend="https://www.facebook.com/"
                >
                  <b-form-input
                    v-model.trim="facebook"
                    id="connect-facebook"
                    name="connectFacebook"
                    class="input-height input-facebook"
                    placeholder="Enter your facebook url"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group class="group-input">
              <div class="d-flex justify-content-between">
                <label
                  class="form-label"
                  for="connectWhatsapp"
                >
                  Connect via What'sApp
                </label>
              </div>
              <validation-provider
                #default="{ errors }"
                id="connectWhatsapp"
                name="What'sApp"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model.trim="whatsApp"
                    id="connect-whatsApp"
                    name="connectWhatsapp"
                    class="input-height"
                    type="number"
                    placeholder="Enter your what's app number"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-checkbox
              v-model="isWhiteList"
              class="custom-control-danger"
            >
              Allow white list user
            </b-form-checkbox>

          </div>
          <div class="mt-1 btn-submit">
            <!-- submit buttons -->
            <btn-loading
              class="btn-addNew"
              type="submit"
              variant="primary"
              block
              :loading="loading"
              @click.prevent="submitAddNewUser"
            >
              Add
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BCardText,
  BFormCheckbox,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toastification } from '@core/mixins/toast'
import {
  required, email, valAdsAccountId, valAdsAccountIdFormat, telegramLength, valExcludeUrl,
} from '@validations'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import vSelect from 'vue-select'
import PhoneInput from './PhoneInput.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    PhoneInput,
    BCardText,
    BFormCheckbox,
    vSelect,
  },
  mixins: [toastification, generalConfigsMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      emailPartner: '',
      partnerBCId: '',
      idAccount: '',
      BCId: null,
      required,
      email,
      valAdsAccountId,
      valAdsAccountIdFormat,
      isWhiteList: false,
      phoneData: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
      },
      telegram: '',
      facebook: '',
      whatsApp: '',
      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      // validate
      telegramLength,
      valExcludeUrl,
      phoneError: false,
      isVaidContact: false,
    }
  },

  setup() {
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),

    validPhoneInput() {
      return invalid => invalid || !this.phoneData.phone || !this.phoneData.isValid
    },

    availableOptions() {
      return this.listTargetMarket?.filter(
        opt => this.productsData?.targetMarkets?.indexOf(opt) === -1,
      )
    },

    inValidContact() {
      return !(
        this.facebook
        || this.telegram
        || this.whatsApp
      )
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },
  },

  methods: {
    ...mapActions(['addNewUser']),

    // handleCheckbox() {
    //   this.isWhiteList = true
    // },

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    async submitAddNewUser() {
      const success = await this.$refs.formValidation.validate()

      const urlFb = 'https://www.facebook.com/'
      let facebookUrl = ''
      if (this.facebook?.includes('facebook.com')) {
        facebookUrl = this.facebook
      } else {
        facebookUrl = urlFb.concat(this.facebook)
      }

      if (!this.phoneData.phone) {
        this.phoneError = true
      }

      if (this.inValidContact) {
        this.isVaidContact = true
      }

      if (success && !this.inValidContact) {
        const params = {
          email: this.emailPartner,
          partnerBCId: this.partnerBCId,
          adsAccountIds: this.idAccount.split(','),
          BCId: this.BCId,
          contactInfo: {
            phone: `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}`,
            ...(this.telegram && { telegram: this.telegram }),
            ...(this.facebook && { facebook: facebookUrl }),
            ...(this.whatsApp && { whatsApp: this.whatsApp }),
          },
          whitelistProductVerify: this.isWhiteList,
          ...(this.productsData && !this.isWhiteList && { productURL: this.productsData.productURL }),
          ...(this.productsData && !this.isWhiteList && { targetMarkets: this.productsData.targetMarkets }),
        }
        await this.addNewUser(params)
        if (this.status) {
          this.toastSuccess('Add user successfully.')
          this.show = false
          this.$emit('fetch-user')
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    resetForm() {
      this.emailPartner = ''
      this.partnerId = ''
      this.idAccount = ''
      this.BCId = null
      this.phoneData.countryCode = ''
      this.phoneData.phone = ''
      this.telegram = ''
      this.facebook = ''
      this.whatsApp = ''
      this.productsData.productURL = ''
      this.productsData.targetMarkets = []
      this.$refs.formValidation.reset()
    },

    restrictChars() {
      const number = /[^0-9,]/gi
      this.idAccount = this.idAccount.replace(number, '')
      // don't move cursor to end if no change
      // if (clean !== this.idAccount) this.idAccount = clean
    },
  },
}
</script>
<style lang='scss' scoped>
  .btn-addNew{
    position: absolute;
    bottom: 22px;
    width: 372px;
    z-index: 999;
  }

  .form-user-info{
    height: 100vh;
    overflow: scroll;
  }

  .custom-control-danger{
    margin-top: 1rem;
  }
</style>
