<template>
  <div class="misc-wrapper">
    <brand-logo />

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          You are not authorized! 🔐
        </h2>
        <p class="mb-2">
          You don’t have permission to access this page!
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="routerHandler()"
        >
          {{ btnTxt() }}
        </b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import auth from '@auth'
import { BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'
import BrandLogo from '@/layouts/components/BrandLogo.vue'

export default {
  components: {
    BrandLogo,
    BImg,
    BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },

    plansStatus() {
      return store.state.auth.plansStatus
    },
  },
  methods: {

    btnTxt() {
      const loggedIn = !!auth.getToken()

      if (!loggedIn) {
        return 'Back to Login'
      }

      return 'Upgrade now'
    },

    routerHandler() {
      const loggedIn = !!auth.getToken()

      if (!loggedIn) {
        return this.$router.push('/login')
      }
      return this.$router.push('/billing')
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
