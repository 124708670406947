<template>
  <b-modal
    ref="modal-detail-user-related"
    id="detail-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <h3 class="font-medium text-primary">
        Detail users related
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="detail-container">
      <div
        v-if="loading"
        class="loading"
      >
        <b-spinner
          label="Loading..."
        />
      </div>
      <div
        v-else
        class="mt-1"
      >
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <p class="m-0 mr-1">
              Email:
            </p>
            <div>
              {{ emailUser }}
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <p class="m-0 mr-1">
              User status:
            </p>
            <div :class="styleStatusUserRelated(statusUser)">
              {{ statusUserRelated(statusUser) }}
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <p class="m-0 mr-1">
              Total spending:
            </p>
            <div>${{ localStringFormat(totalSpending) }}</div>
          </div>
          <div class="d-flex align-items-center">
            <p class="m-0 mr-1">
              Total deposit:
            </p>
            <div>${{ localStringFormat(totalDeposit) }}</div>
          </div>
        </div>

        <div>
          <div
            v-if="adsAccount.SHOW_ACCOUNT_STATUS_APPROVED"
            class="d-flex align-items-center mb-1"
          >
            <p class="m-0 mr-1">
              Total approved ads accounts:
            </p>
            <div>{{ adsAccount.SHOW_ACCOUNT_STATUS_APPROVED }}</div>
          </div>
          <div
            v-if="adsAccount.SHOW_ACCOUNT_STATUS_PUNISHED"
            class="d-flex align-items-center mb-1"
          >
            <p class="m-0 mr-1">
              Total suspended ads accounts:
            </p>
            <div>{{ adsAccount.SHOW_ACCOUNT_STATUS_PUNISHED }}</div>
          </div>
          <div
            v-if="adsAccount.CLOSED"
            class="d-flex align-items-center"
          >
            <p class="m-0 mr-1">
              Total closed ads accounts:
            </p>
            <div>{{ adsAccount.CLOSED }}</div>
          </div>
        </div>
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
import { BModal, BButton, BSpinner } from 'bootstrap-vue'
import { toastification } from '@core/mixins/toast'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { createNamespacedHelpers } from 'vuex'
import { USER_RELATED_STATUS } from '@/constants'

const { mapActions, mapGetters } = createNamespacedHelpers('user')
export default {
  components: {
    BModal,
    BButton,
    BSpinner,
  },
  mixins: [numberFormatMixin, toastification],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      // validation
      reasonBlock: '',
    }
  },

  computed: {
    ...mapGetters(['status', 'message', 'detailUserRelated', 'loading']),

    emailUser() {
      return this.userData?.email
    },

    totalDeposit() {
      return this.detailUserRelated?.user?.totalDeposit || 0
    },

    totalSpending() {
      return this.detailUserRelated?.totalSpending || 0
    },

    statusUser() {
      return this.detailUserRelated?.user?.status
    },

    adsAccount() {
      return this.detailUserRelated?.adsAccount || {}
    },

    statusUserRelated() {
      const statusUser = {
        [USER_RELATED_STATUS.ACTIVE]: 'Active',
        [USER_RELATED_STATUS.BLOCKED]: 'Blocked',
      }

      return status => statusUser[status]
    },

    styleStatusUserRelated() {
      const statusColor = {
        [USER_RELATED_STATUS.ACTIVE]: 'user-active',
        [USER_RELATED_STATUS.BLOCKED]: 'user-blocked',
      }

      return status => statusColor[status]
    },
  },

  watch: {
    userData: {
      handler(userInfo) {
        if (userInfo?.localUserId) {
          this.fetchUserRelated()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['fetchDetailUserRelated']),

    async fetchUserRelated() {
      const params = {
        id: this.userData?.localUserId,
      }

      await this.fetchDetailUserRelated(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    hideModal() {
      this.$refs['modal-detail-user-related'].hide()
    },

    showModal() {
      this.$refs['modal-detail-user-related'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-container {
  position: relative;
  padding: 20px;
  min-height: 200px;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .user-active,
  .user-blocked {
    padding: 5px 12px;
    border-radius: 100px;
    width: max-content;
  }

  .user-active {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  .user-blocked {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }
}
</style>
