<template>
  <div>
    <div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="usersRelated"
        :pagination-options="{
          enabled: true,
          perPage:pageLength,
        }"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <a
            v-if="props.column.field === 'email'"
            class="d-flex align-items-center cursor-pointer"
            target="_blank"
            :href="`/user/${props.row.localUserId}`"
          >
            <b-img
              rounded
              class="ads-avt-img"
              :src="require('@/assets/images/common/ic-user-info.svg')"
            />
            <div>
              <p class="mb-0 text-secondary font-weight-bold mb-0 underline">
                {{ props.row.fullName || '' }}
              </p>
              <span class="text-placeholder">{{ props.row.email || '' }}</span>
            </div>
          </a>

          <div v-else-if="props.column.field === 'relatedTypes'">
            <div v-if="props.row.relatedTypes">
              <span>
                {{ props.row.relatedTypes.toString() }}
              </span>
            </div>
            <span v-else>
              N/A
            </span>
          </div>

          <div v-else-if="props.column.field === 'detail'">
            <div
              v-if="(props.row.system === 'Ecomdy' && hasExclusivePartnerRole) || (props.row.system === 'Adreach' && !hasExclusivePartnerRole)"
              class="detail-content"
            >
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="text-body align-middle mr-25"
                @click.prevent="handleOpenPopupDetail(props.row)"
              />
            </div>
          </div>
          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot-scope="props"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} user(s) </span>
            </div>
            <div>
              <b-pagination
                :total-rows="props.total"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          v-if="errorGetUserInfo"
          slot="emptystate"
        >
          <p class="text-error">
            {{ errorGetUserInfo }}
          </p>
        </div>
      </vue-good-table>
    </div>

    <detail-user-related-modal
      ref="open-modal-detail-user-related"
      :user-data="userSelected"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import _get from 'lodash/get'
import {
  BPagination,
  BSpinner,
  BImg,
  BFormSelect,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'
import DetailUserRelatedModal from './DetailUserRelatedModal.vue'

export default {
  components: {
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    VueGoodTable,
    DetailUserRelatedModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, envMixin],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },

    errorGetUserInfo: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      loadingDown: false,
      columns: [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'User info',
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'relatedTypes',
          field: 'relatedTypes',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'System',
          field: 'system',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Detail',
          field: 'detail',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],

      currentPage: 1,
      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],
      userSelected: {},
    }
  },

  computed: {
    usersRelated() {
      return _get(this.userData, ['relatedUsers'], [])
    },
    hasExclusivePartnerRole() {
      return this.userData?.belongToPartner?.exclusivePartnerId || this.userData?.partnership?.exclusivePartnerId
    },
  },
  methods: {

    handleOpenPopupDetail(data) {
      this.userSelected = data
      this.$refs['open-modal-detail-user-related'].showModal()
    },
  },
}
</script>
<style lang="scss" scoped>
.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}

.detail-content{
  width: max-content;
  cursor: pointer;
}
</style>
