import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {

  // get list gift code
  GET_LIST_GIFT_CODE_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_GIFT_CODE_SUCCESS(state, data) {
    SUCCESS(state)
    state.listGiftCode = data
  },

  GET_LIST_GIFT_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // deactivate gift code
  DEACTIVATE_GIFT_CODE_REQUEST(state) {
    REQUEST(state)
  },
  DEACTIVATE_GIFT_CODE_SUCCESS(state) {
    SUCCESS(state)
  },
  DEACTIVATE_GIFT_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update user
  UPDATE_GIFT_CODE_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_GIFT_CODE_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_GIFT_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // create gift code
  CREATE_GIFT_CODE_REQUEST(state) {
    REQUEST(state)
  },

  CREATE_GIFT_CODE_SUCCESS(state) {
    SUCCESS(state)
  },

  CREATE_GIFT_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get all partners
  GET_LIST_PARTNER_NO_PAGING_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_PARTNER_NO_PAGING_SUCCESS(state, data) {
    SUCCESS(state)
    state.listPartnerNoPaging = data
  },

  GET_LIST_PARTNER_NO_PAGING_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get info user
  GET_INFO_USER_REQUEST(state) {
    REQUEST(state)
  },

  GET_INFO_USER_SUCCESS(state, data) {
    SUCCESS(state)
    if (data?.paging?.total > 0) {
      state.partnerInfo = data
    } else {
      state.partnerInfo = data
      state.listGiftCode = {}
    }
  },

  GET_INFO_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },

}
