export default [
  {
    path: '/auto-refund',
    name: 'auto-refund',
    component: () => import('@/views/auto-refund/AutoRefundManagement.vue'),
    meta: {
      pageTitle: 'Auto Refund',
      requireAuth: true,
    },
  },
]
