import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  computed: {
    ...mapGetters(['user']),
    platformName() {
      return process.env.VUE_APP_PLATFORM_NAME
    },

    isEcomdyPlatform() {
      return this.platformName === 'Ecomdy'
    },

    isEmediaPlatform() {
      return this.platformName === 'Emedia'
    },

    isTeeXcorpPlatform() {
      return this.platformName === 'TeeXcorp'
    },

    isSMBAgencyPlatform() {
      return this.platformName === 'SBH Media'
    },

    isDC3Platform() {
      return this.platformName === 'DC3'
    },

    isGyxenPlatform() {
      return this.platformName === 'Gyxen'
    },

    isGoodvibesPlatform() {
      return this.platformName === 'Goodvibes Agency'
    },

    isFinalArrowPlatform() {
      return this.platformName === 'Final Arrow'
    },

    isClickTechPlatform() {
      return this.platformName === 'ClickTech'
    },

    isTakiPlatform() {
      return this.platformName === 'DIGI HERO'
    },

    isVNWhiteLabel() {
      return this.isSMBAgencyPlatform || this.isDC3Platform
    },

    isVerifyEmailLater() {
      return process.env.VUE_APP_MODE_VERIFY_EMAIL === 'true'
    },

    hasSubscription() {
      return process.env.VUE_APP_MODE_SUBSCRIPTION === 'true'
    },

    hasPartnership() {
      return process.env.VUE_APP_MODE_PARTNERSHIP === 'true'
    },

    hasReferral() {
      return process.env.VUE_APP_MODE_REF === 'true'
    },

    hasAM() {
      return process.env.VUE_APP_AM === 'true'
    },

    hasProductLink() {
      return process.env.VUE_APP_MODE_PRODUCT_LINK === 'true'
    },

    hasInactiveAdAccount() {
      return process.env.VUE_APP_MODE_INACTIVE_AD_ACCOUNT === 'true'
    },

    hasLimitAdsAccount() {
      return process.env.VUE_APP_CHANGE_LIMIT_ADS_ACCOUNT === 'true'
    },

    hasRefundTransaction() {
      return process.env.VUE_APP_MODE_REFUND === 'true'
    },

    hasRefundAutoTransaction() {
      return process.env.VUE_APP_MODE_REFUND_AUTO === 'true'
    },

    hasCashbackTransaction() {
      return process.env.VUE_APP_MODE_CASHBACK === 'true'
    },

    hasAutoBlock() {
      return process.env.VUE_APP_MODE_AUTO_BLOCK === 'true'
    },

    hasReviewAdCreative() {
      return process.env.VUE_APP_MODE_REVIEW_AD_CREATIVE === 'true'
    },

    hasSetupCost() {
      return process.env.VUE_APP_SETUP_COST === 'true'
    },

    hasTipForAM() {
      return process.env.VUE_APP_MODE_TIP === 'true'
    },

    listStatusAutoBlock() {
      return process.env.VUE_APP_AUTO_BLOCK_FEATURES.split(',')
    },
    /* ----- End Docs ----*/
  },
}
