import { ADMIN_ROLES, NAME_TOKEN } from '@/constants'
import _some from 'lodash/some'

export default {
  getToken() {
    return localStorage.getItem(NAME_TOKEN) || ''
  },
  removeToken() {
    localStorage.removeItem(NAME_TOKEN)
  },
  getTimezone() {
    const userDataStr = localStorage.getItem('userData')
    const userData = JSON.parse(userDataStr) || {}
    if (_some(userData.roles, role => role === ADMIN_ROLES.EXCLUSIVE_PARTNER)) {
      return '+0'
    } if (process.env.VUE_APP_PLATFORM_NAME === 'Emedia') {
      return '+1'
    }
    return '+7'
  },
  setToken(token) {
    localStorage.setItem(NAME_TOKEN, token)
  },
  clearLocalStorage() {
    localStorage.removeItem(NAME_TOKEN)
    localStorage.removeItem('userData')
    window.location.reload()
  },
}
