<template>
  <b-modal
    ref="block-modal"
    id="block-child"
    class="modal-child"
    hide-footer
    title="Add reason block"
  >
    <validation-observer ref="formValidation">
      <b-form
        @submit.prevent="submit"
      >
        <!--Content-->
        <p>User: <code>{{ userData.fullName ? `${userData.fullName} - ` :'' }}{{ userData.email }}</code></p>
        <div>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="reason"
              rules="required"
            >
              <b-input-group>
                <b-form-textarea
                  v-model="blockedReason"
                  placeholder="Typing reason ..."
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>

        <!--/ Content-->

        <!--modal footer-->
        <div class="d-flex justify-content-end mt-2">
          <b-button
            variant="flat-secondary"
            @click="hideModal"
          >
            Cancel
          </b-button>
          <btn-loading
            class="btn-submit ml-1"
            variant="primary"
            type="submit"
            :loading="loading"
            @click.prevent="submit"
          >
            Submit
          </btn-loading>
        </div>
        <!--/modal footer-->
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>

import {
  BModal,
  // BRow,
  // BCol,
  // BFormCheckbox,
  BForm,
  BFormGroup,
  BButton,
  BInputGroup,
  // BInputGroupAppend,
  BFormTextarea,
} from 'bootstrap-vue'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { USER_STATUS } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
// import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toastification } from '@core/mixins/toast'
import { required } from '@validations'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BModal,
    // BRow,
    // BCol,
    BForm,
    BFormGroup,
    BButton,
    BInputGroup,
    // BInputGroupAppend,
    // BFormCheckbox,
    BFormTextarea,
    // component
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
    // flatPickr,
  },
  mixins: [toastification],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      blockedReason: '',

      // validation
      required,
    }
  },
  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  methods: {

    ...mapActions(['blockUser']),

    hideModal() {
      this.$refs['block-modal'].hide()
    },

    showModal() {
      this.$refs['block-modal'].show()
    },

    submit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line no-return-assign

          const dateBlockForever = '30-12-2521 00:00'

          const params = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.userData.localUserId,
            status: USER_STATUS.BLOCKED,
            blockedReason: this.blockedReason,
            blockedExpiredAt: this.timeStampConvert(dateBlockForever),
          }

          await this.blockUser(params)
          if (this.status) {
            this.toastSuccess('Add reason success!')
            this.$emit('update-user-selected')
            this.hideModal()
          } else {
            this.toastFailure(this.message)
          }
        }
      })
    },

    timeStampConvert(data) {
      if (data) {
        const dateTimeParts = data.split(' ')
        const timeParts = dateTimeParts[1].split(':')
        const dateParts = dateTimeParts[0].split('-')

        const date = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1])

        return date.getTime()
      }

      return ''
    },
  },
}
</script>
<style lang="scss">
</style>
