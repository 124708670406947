/* eslint-disable global-require */
import moment from 'moment'
import envMixin from '@/mixins/envMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'

export default {
  mixins: [envMixin, generalConfigsMixin],

  computed: {
    formatDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB', { ...(this.timezone && { timeZone: this.timezone }) }) : '')
    },
  },

  methods: {
    timeStampToDate(value) {
      let dateConvert = new Date(value * 1000)
      // dateConvert = dateConvert.setMonth(dateConvert.getMonth() + 1)
      dateConvert = dateConvert.toDateString()

      return dateConvert
    },

    formatCalendarDate(date) {
      if (!date) return ''
      return moment(new Date(date)).format('YYYY-MM-DD')
    },

    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },
  },
}
