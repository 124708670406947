<template>
  <b-modal
    ref="modal-reason-reject-info"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <h3 class="font-medium text-primary">
        Reason reject information
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="position-relative pl-1 pr-1 reason-reject mt-1">
      <div class="d-flex">
        <p class="mr-2"><span class="text-danger font-weight-600">Admin ID:</span>
          <span v-if="verifiedBy">
            {{ verifiedBy }}
          </span>
          <span v-else>
            N/A
          </span>
        </p>
        <p><span class="text-danger font-weight-600">Verify At:</span>
          <span v-if="verifiedAt">
            {{ formatDate(verifiedAt) }}
          </span>
        </p>
      </div>

      <b-badge
        class="reason-reject-message"
        variant="light-danger"
      >
        <div
          class="content-reason-reject-info"
          v-html="rejectedReason"
        />
      </b-badge>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>

import {
  BModal,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'

export default {
  components: {
    BModal,
    BButton,
    BBadge,
  },

  mixins: [dateTimeFormatMixin],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  computed: {
    rejectedReason() {
      return this.userData?.contactInfo?.verification?.rejectedReason
    },

    verifiedBy() {
      return this.userData?.contactInfo?.verification?.verifiedBy
    },

    verifiedAt() {
      return this.userData?.contactInfo?.verification?.at
    },
  },

  methods: {
    hideModal() {
      this.$refs['modal-reason-reject-info'].hide()
    },

    showModal() {
      this.$refs['modal-reason-reject-info'].show()
    },
  },
}
</script>

<style lang="scss">
.content-reason-reject-info{
  p{
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
.reason-reject-message {
  text-align: start;
  white-space: normal;
}

.reason-reject {
  max-width: max-content;
  width: 100%;
}
</style>
