export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      pageTitle: 'Dashboard',
      // breadcrumb: [
      //   {
      //     text: '',
      //     active: true,
      //   },
      // ],
      requireAuth: true,
      requireSiteId: false,
      requirePlan: false,
    },
  },
]
