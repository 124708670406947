import { ProductLinksService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async getListProductLinks({ commit }, { params, cancelToken }) {
    commit('GET_LIST_PRODUCT_LINKS_REQUEST')
    try {
      const { data } = await ProductLinksService.getListProductLinks(params, cancelToken)
      commit('GET_LIST_PRODUCT_LINKS_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_LIST_PRODUCT_LINKS_FAILURE', error)
    }
  },

  async handleProductVideo({ commit }, params) {
    commit('HANDLE_PRODUCT_VIDEO_REQUEST')
    try {
      await ProductLinksService.handleProductVideo(params)
      commit('HANDLE_PRODUCT_VIDEO_SUCCESS')
    } catch (error) {
      commit('HANDLE_PRODUCT_VIDEO_FAILURE', error)
    }
  },
}
