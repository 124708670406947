import axios from '@axios'

export default {

  getListUserInfo(params, cancelToken) {
    return axios.get('/api/admin/users/contact-info', { params, cancelToken })
  },

  verifyUserInfo(params) {
    const { id, status, ...realParams } = params
    return axios.post(`/api/admin/users/${id}/contact-info/${status}`, realParams)
  },
}
