<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Store Detail"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2">
      <b-card-title>Store Url</b-card-title>
      <ul>
        <li
          v-for="(item, index) in listStoreDetail"
          :key="index"
          class="mb-1"
        >
          <a
            target="_blank"
            class="text-blue store-url-detail"
            :href="item"
          >{{
            item
          }}</a>
        </li>
      </ul>
    </div>
  </b-sidebar>
</template>
<script>
import { BSidebar, BCardTitle } from 'bootstrap-vue'

export default {
  components: {
    BSidebar,
    BCardTitle,
  },

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
    storeSelected: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    listStoreDetail() {
      return this.storeSelected?.storeUrl?.split(', ')
    },
    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },
  },

  methods: {
    onHidden() {
      this.$emit('update:show-side-bar', false)
    },
  },
}
</script>

<style lang="scss">
.ql-editor {
  min-height: 200px;
}

.add-page {
  .markdown {
    border: 1px solid #ccc;
    border-radius: 0.357rem;
  }
}
</style>

<style lang="scss" scoped>
.store-url-detail {
  overflow-wrap: break-word;

  &:hover {
    color: #2667ff;
  }
}

::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}

.line-center {
  width: 100%;
  background-color: #eeeeee;
  height: 2px;
  margin: 1rem 0;
}

.product-links {
  .item-product-link {
    a {
      color: #2667ff !important;
    }
  }
}

.target-market {
  display: flex;
  .item-target-market {
    padding: 6px 8px;
    background-color: #3d405b1a;
    width: max-content;
    border-radius: 4px;
    margin-right: 1rem;
  }
}
</style>
