export default [
  {
    path: '/acl',
    name: 'acl',
    component: () => import('@/views/acl/acl.vue'),
    meta: {
      pageTitle: 'ACL management',
      requireAuth: true,
    },
  },
]
