<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Add new role"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="wrapper">
      <b-form-group
        label="Role"
        label-cols="12"
      >
        <b-form-input
          v-model.trim="roleName"
          id="add-new-role"
          placeholder="Enter new role"
        />
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
        block
        :disabled="!roleName"
        :loading="loading"
        @click.prevent="submitCreateRole"
      >
        Create
      </b-button>
    </div>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'

const { mapGetters, mapActions } = createNamespacedHelpers('acl')

export default {
  components: {
    BSidebar,
    BFormGroup,
    BFormInput,
    BButton,
  },
  mixins: [toastification],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      roleName: '',
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading', 'listAcl']),
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },
  },

  methods: {
    ...mapActions(['createRole', 'getListAcl']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    async submitCreateRole() {
      const params = {
        roles: [...this.listAcl?.roles, this.roleName],
      }
      await this.createRole(params)
      if (this.status) {
        this.toastSuccess('Create Role successfully.')
        this.resetForm()
        this.show = false
        this.$emit('fetchListAcl')
      } else {
        this.toastFailure(this.message)
      }
    },

    resetForm() {
      this.roleName = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}
</style>
