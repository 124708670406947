import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get product links list
  ADD_KYC_USER_REQUEST(state) {
    REQUEST(state)
  },
  ADD_KYC_USER_SUCCESS(state) {
    SUCCESS(state)
  },
  ADD_KYC_USER_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
