import { AuthService } from '@/services'
import auth from '@auth'
import { getResponse } from '@/plugins/store'

export default {
  async login({ commit }, { params }) {
    commit('LOGIN_REQUEST')
    try {
      const { data } = await AuthService.login(params)
      auth.setToken(getResponse(data).id_token)
      commit(
        'LOGIN_SUCCESS',
        getResponse(data.result),
        getResponse(data).id_token,
      )
    } catch (error) {
      commit('LOGIN_FAILURE', error)
    }
  },
  async sendLoginVerificationCode({ commit }, { params, config }) {
    commit('LOGIN_REQUEST')
    try {
      const { data } = await AuthService.sendLoginVerificationCode(params, config)
      auth.setToken(process.env.VUE_APP_SERVER_EAC_API ? getResponse(data).accessToken : getResponse(data).id_token)
      commit(
        'LOGIN_SUCCESS',
        getResponse(data.result),
        getResponse(data).id_token,
      )
    } catch (error) {
      commit('LOGIN_FAILURE', error)
    }
  },
  async register({ commit }, { params }) {
    commit('REGISTER_REQUEST')
    try {
      await AuthService.register(params)
      commit('REGISTER_SUCCESS')
    } catch (error) {
      commit('REGISTER_FAILURE', error)
    }
  },
  async getAccountInfo({ commit }) {
    commit('GET_ACCOUNT_INFO_REQUEST')
    try {
      const { data } = await AuthService.getAccountInfo()
      commit('GET_ACCOUNT_INFO_SUCCESS', getResponse(data) || {})
      localStorage.setItem('userData', JSON.stringify(getResponse(data)))
    } catch (error) {
      commit('GET_ACCOUNT_INFO_FAILURE', error)
    }
  },
  async resetInit({ commit }, { params }) {
    commit('RESET_INIT_REQUEST')
    try {
      await AuthService.resetInit(params)
      commit('RESET_INIT_SUCCESS')
    } catch (error) {
      commit('RESET_INIT_FAILURE', error)
    }
  },
  async resetFinish({ commit }, { params }) {
    commit('RESET_FINISH_REQUEST')
    try {
      await AuthService.resetFinish(params)
      commit('RESET_FINISH_SUCCESS')
    } catch (error) {
      commit('RESET_FINISH_FAILURE', error)
    }
  },

  async getGeneralConfig({ commit }, params) {
    commit('GET_GENERAL_CONFIG_REQUEST')
    try {
      const { data } = await AuthService.getGeneralConfig(params)
      commit('GET_GENERAL_CONFIG_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_GENERAL_CONFIG_FAILURE', error)
    }
  },

  // async updateAccount({ commit }, params) {
  //   commit('UPDATE_ACCOUNT_REQUEST')
  //   try {
  //     await AuthService.updateAccount(params)
  //     commit('UPDATE_ACCOUNT_SUCCESS')
  //   } catch (error) {
  //     commit('UPDATE_ACCOUNT_FAILURE', error)
  //   }
  // },
  async updatePassword({ commit }, params) {
    commit('UPDATE_PASSWORD_REQUEST')
    try {
      await AuthService.updatePassword(params)
      commit('UPDATE_PASSWORD_SUCCESS')
    } catch (error) {
      commit('UPDATE_PASSWORD_FAILURE', error)
    }
  },

  logout({ commit }) {
    auth.clearLocalStorage()
    commit('LOGOUT')
  },

  async getLoginVerificationCode({ commit }, { params, config }) {
    commit('LOGIN_VERIFICATION_REQUEST')
    try {
      const { data } = await AuthService.getLoginVerificationCode(params, config)
      commit('LOGIN_VERIFICATION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('LOGIN_VERIFICATION_FAILURE', error)
    }
  },
}
