import { SubscriptionService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async getSubscriptions({ commit }, { params, cancelToken }) {
    commit('GET_SUBSCRIPTIONS_REQUEST')
    try {
      const { data } = await SubscriptionService.getSubscriptions(params, cancelToken)
      commit('GET_SUBSCRIPTIONS_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_SUBSCRIPTIONS_FAILURE', error)
    }
  },

  async approveSubscription({ commit }, params) {
    commit('APROVE_SUBSCRIPTION_REQUEST')
    try {
      const { data } = await SubscriptionService.approveSubscription(params)
      commit('APROVE_SUBSCRIPTION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('APROVE_SUBSCRIPTION_FAILURE', error)
    }
  },

  async rejectSubscription({ commit }, params) {
    commit('REJECT_SUBSCRIPTION_REQUEST')
    try {
      const { data } = await SubscriptionService.rejectSubscription(params)
      commit('REJECT_SUBSCRIPTION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REJECT_SUBSCRIPTION_FAILURE', error)
    }
  },

  async deleteSubscription({ commit }, params) {
    commit('DELETE_SUBSCRIPTION_REQUEST')
    try {
      const { data } = await SubscriptionService.deleteSubscription(params)
      commit('DELETE_SUBSCRIPTION_SUCCESS', getResponse(data))
    } catch (error) {
      commit('DELETE_SUBSCRIPTION_FAILURE', error)
    }
  },
}
