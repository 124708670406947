import axios from '@axios'

export default {
  login(params) {
    return axios.post('/api/users/login', params)
  },
  sendLoginVerificationCode(params, config) {
    return axios.post(`${process.env.VUE_APP_SERVER_EAC_API ? `${process.env.VUE_APP_SERVER_EAC_API}/api/auth/admin/login` : `${process.env.VUE_APP_SERVER_API}/api/admin/login`}`, params, config)
  },
  // loginWithGoogle(token) {
  //   return axios.post('/api/users/google-account/sign-in', token)
  // },
  register(params) {
    return axios.post('/api/users/register', params)
  },
  resetInit(params) {
    return axios.post('/api/users/forgot-password', params)
  },
  resetFinish(params) {
    return axios.post('/api/users/reset-password', params)
  },
  getAccountInfo() {
    return axios.get('/api/users')
  },
  getGeneralConfig(userId) {
    return axios.get(`/api/admin/config-values?${userId ? `userId=${userId}` : ''}`)
  },
  // updateAccount(params) {
  //   return axios.post('/api/account', params)
  // },
  updatePassword(params) {
    return axios.patch(`${process.env.VUE_APP_SERVER_EAC_API ? `${process.env.VUE_APP_SERVER_EAC_API}/api/auth/change-password` : `${process.env.VUE_APP_SERVER_API}/api/users/change-password`}`, params)
  },

  getLoginVerificationCode(params, config) {
    return axios.post(
      `${process.env.VUE_APP_SERVER_EAC_API ? `${process.env.VUE_APP_SERVER_EAC_API}/api/auth/admin/login/send-verification`
        : `${process.env.VUE_APP_SERVER_API}/api/admin/login/send-verification`}`,
      params,
      config,
    )
  },
}
