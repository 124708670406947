<template>
  <b-modal
    ref="modal-reason-reject"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary">
        Reason
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="position-relative pl-1 pr-1 reason-reject mt-1">
      <!-- <div>
        <p>
          <span class="text-danger font-weight-600">Admin ID:</span>
          <span
            v-if="
              userData &&
                userData.authentication &&
                userData.authentication.updatedBy
            "
          >
            {{ userData.authentication.updatedBy }}
          </span>
          <span v-else>
            N/A
          </span>
        </p>
      </div> -->

      <div
        v-if="itemContent"
        class="reason-reject-message"
        variant="light-danger"
      >
        <div>
          <b-card-title class="font-14 mb-1">
            By admin:
          </b-card-title>
          <b-card-text>
            {{ itemContent.by }}
          </b-card-text>
          <b-card-title class="font-14 mb-1">
            Reason:
          </b-card-title>
          <div
            v-if="itemContent && itemContent.content"
            class="reason-content"
            v-html="itemContent.content"
          />
          <div v-else>
            <b-card-text>
              N/A
            </b-card-text>
          </div>
        </div>
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Cancel
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
import {
  BModal, BButton, BCardTitle, BCardText,
} from 'bootstrap-vue'

import { toastification } from '@core/mixins/toast'
import { required } from '@validations'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import _maxBy from 'lodash/maxBy'

export default {
  components: {
    BModal,
    BButton,
    BCardText,
    BCardTitle,
  },
  mixins: [numberFormatMixin, toastification],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      // validation
      required,
    }
  },

  computed: {
    itemContent() {
      const itemContent = _maxBy(
        this.userData.reviewProductResults,
        item => item.at,
      )
      return itemContent
    },
  },

  methods: {
    hideModal() {
      this.$refs['modal-reason-reject'].hide()
    },

    showModal() {
      this.$refs['modal-reason-reject'].show()
    },
  },
}
</script>

<style lang="scss">
.reason-content {
  p {
    margin: 0;
  }
}
</style>

<style lang="scss" scoped>
.reason-reject {
  max-width: max-content;
  width: 100%;
}
</style>
