<template>
  <div>
    <b-media
      no-body
    >
      <b-media-aside>
        <b-link>
          <b-img
            rounded
            :src="imageData"
            :height="80"
            :width="80"
          />
        </b-link>
      </b-media-aside>

      <b-media-body>
        <!-- upload button -->
        <btn-loading
          variant="primary"
          size="sm"
          class="mb-75 mr-75 btn-upload h-30"
          :loading="loading"
          @click="$refs.refImage.$el.click()"
        >
          {{ $t("common.btnUploadImage") }}
        </btn-loading>
        <b-form-file
          v-model="imageFile"
          ref="refImage"
          :accept="accept"
          plain
          :hidden="true"
          @input="inputImageRenderer"
        />
        <!--/ upload button -->

        <!-- reset -->
        <!--        <b-button-->
        <!--          variant="outline-secondary"-->
        <!--          size="sm"-->
        <!--          class="mb-75 mr-75 h-30"-->
        <!--          @click="resetImage"-->
        <!--        >-->
        <!--          {{ $t("common.btnReset") }}-->
        <!--        </b-button>-->
        <!--/ reset -->
        <b-card-text>{{ $t("profile.descUploadImage") }}</b-card-text>
      </b-media-body>
    </b-media>
  </div>
</template>

<script>
import {
  BFormFile,
  //  BButton,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import defaultImage from '@/assets/images/avatars/default.png'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('file')

export default {
  components: {
    // BButton,
    BImg,
    BFormFile,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    // components
    BtnLoading,
  },

  props: {
    url: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '.jpg, .png, .gif',
    },
  },

  setup() {
    const refImage = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refImage, () => {})

    return {
      refImage,
      inputImageRenderer,
    }
  },
  data() {
    return {
      imageFile: null,
    }
  },

  computed: {
    ...mapGetters(['loading']),
    imageData() {
      return this.url ? this.url : defaultImage
    },
  },

  watch: {
    imageFile(val) {
      if (val) {
        this.$emit('changeImageFile', val)
      }
    },
  },

  methods: {
    // resetImage() {
    //   this.$refs.refPreview.src = this.setting.defaultImage
    //   this.$emit('changeImageFile', null)
    // },
  },
}
</script>
