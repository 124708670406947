export default [
  {
    path: '/inactive-ad-account',
    name: 'inactive-ad-account',
    component: () => import('@/views/inactive-ad-account/InactiveAdAccount.vue'),
    meta: {
      pageTitle: 'Inactive ad account',
      requireAuth: true,
    },
  },
]
