export const AM_REVIEW_ADS_STATUS = {
  APPROVED: 'APPROVED',
  IN_REVIEW: 'IN_REVIEW',
  REJECTED: 'REJECTED',
}
export const TIKTOK_ADS_SECONDARY_STATUS = {
  AD_STATUS_NOT_DELETE: 'AD_STATUS_NOT_DELETE',
  AD_STATUS_CAMPAIGN_DELETE: 'AD_STATUS_CAMPAIGN_DELETE',
  AD_STATUS_ADGROUP_DELETE: 'AD_STATUS_ADGROUP_DELETE',
  AD_STATUS_DELETE: 'AD_STATUS_DELETE',
  AD_STATUS_ADVERTISER_AUDIT_DENY: 'AD_STATUS_ADVERTISER_AUDIT_DENY',
  AD_STATUS_ADVERTISER_AUDIT: 'AD_STATUS_ADVERTISER_AUDIT',
  ADVERTISER_CONTRACT_PENDING: 'ADVERTISER_CONTRACT_PENDING',
  ADVERTISER_ACCOUNT_PUNISH: 'ADVERTISER_ACCOUNT_PUNISH',
  AD_STATUS_BALANCE_EXCEED: 'AD_STATUS_BALANCE_EXCEED',
  AD_STATUS_CAMPAIGN_EXCEED: 'AD_STATUS_CAMPAIGN_EXCEED',
  AD_STATUS_BUDGET_EXCEED: 'AD_STATUS_BUDGET_EXCEED',
  AD_STATUS_AUDIT: 'AD_STATUS_AUDIT',
  AD_STATUS_REAUDIT: 'AD_STATUS_REAUDIT',
  AD_STATUS_AUDIT_DENY: 'AD_STATUS_AUDIT_DENY',
  AD_STATUS_ADGROUP_AUDIT_DENY: 'AD_STATUS_ADGROUP_AUDIT_DENY',
  AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY: 'AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY',
  AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK: 'AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK',
  AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY: 'AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY',
  AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING: 'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING',
  AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED: 'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED',
  AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY: 'AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY',
  AD_STATUS_MUSIC_AUTHORIZATION_MISSING: 'AD_STATUS_MUSIC_AUTHORIZATION_MISSING',
  AD_STATUS_PIXEL_UNBIND: 'AD_STATUS_PIXEL_UNBIND',
  AD_STATUS_LIVE_OFFLINE: 'AD_STATUS_LIVE_OFFLINE',
  AD_STATUS_NOT_START: 'AD_STATUS_NOT_START',
  AD_STATUS_DONE: 'AD_STATUS_DONE',
  AD_STATUS_CAMPAIGN_DISABLE: 'AD_STATUS_CAMPAIGN_DISABLE',
  AD_STATUS_ADGROUP_DISABLE: 'AD_STATUS_ADGROUP_DISABLE',
  ADGROUP_STATUS_FROZEN: 'ADGROUP_STATUS_FROZEN',
  AD_STATUS_DISABLE: 'AD_STATUS_DISABLE',
  AD_STATUS_DELIVERY_OK: 'AD_STATUS_DELIVERY_OK',
  AD_STATUS_TRANSCODING_FAIL: 'AD_STATUS_TRANSCODING_FAIL',
  AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST: 'AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST',
  AD_STATUS_ASSET_AUTHORIZATION_LOST: 'AD_STATUS_ASSET_AUTHORIZATION_LOST',
  AD_STATUS_RF_ADGROUP_CLOSED: 'AD_STATUS_RF_ADGROUP_CLOSED',
  AD_STATUS_PROCESS_AUDIO: 'AD_STATUS_PROCESS_AUDIO',
}

export const TIKTOK_ADS_PRIMARY_STATUS = {
  STATUS_NOT_DELETE: 'STATUS_NOT_DELETE',
  STATUS_DELETE: 'STATUS_DELETE',
  STATUS_NOT_DELIVERY: 'STATUS_NOT_DELIVERY',
  STATUS_DISABLE: 'STATUS_DISABLE',
  STATUS_DELIVERY_OK: 'STATUS_DELIVERY_OK',
  STATUS_TIME_DONE: 'STATUS_TIME_DONE',
  STATUS_RF_CLOSED: 'STATUS_RF_CLOSED',
  STATUS_FROZEN: 'STATUS_FROZEN',
}

export const TIKTOK_ADS_SECONDARY_STATUS_MAPPING = {
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_DELIVERY_OK]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_PARTIAL_AUDIT_DELIVERY_OK,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_DELIVERY_OK,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_DISABLE]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_CAMPAIGN_DISABLE,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_DISABLE,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_DISABLE,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_RF_CLOSED]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_RF_ADGROUP_CLOSED,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_TIME_DONE]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_DONE,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_DELETE]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_CAMPAIGN_DELETE,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_DELETE,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_DELETE,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_NOT_DELIVERY]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADVERTISER_AUDIT_DENY,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADVERTISER_AUDIT,
    TIKTOK_ADS_SECONDARY_STATUS.ADVERTISER_CONTRACT_PENDING,
    TIKTOK_ADS_SECONDARY_STATUS.ADVERTISER_ACCOUNT_PUNISH,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_BALANCE_EXCEED,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_CAMPAIGN_EXCEED,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_BUDGET_EXCEED,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_AUDIT,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_REAUDIT,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_AUDIT_DENY,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_AUDIT_DENY,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_PARTIAL_AUDIT_NO_DELIVERY,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_PARTIAL_AUDIT_NO_DELIVERY,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_MISSING,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_EXPIRED,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_INDUSTRY_QUALIFICATION_DENY,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_MUSIC_AUTHORIZATION_MISSING,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_PIXEL_UNBIND,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_LIVE_OFFLINE,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_NOT_START,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_PROCESS_AUDIO,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_TRANSCODING_FAIL,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ADGROUP_ASSET_AUTHORIZATION_LOST,
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_ASSET_AUTHORIZATION_LOST,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_FROZEN]: [
    TIKTOK_ADS_SECONDARY_STATUS.ADGROUP_STATUS_FROZEN,
  ],
  [TIKTOK_ADS_PRIMARY_STATUS.STATUS_NOT_DELETE]: [
    TIKTOK_ADS_SECONDARY_STATUS.AD_STATUS_NOT_DELETE,
  ],
}
