<template>
  <div>
    <table-acl />
  </div>
</template>

<script>
import tableAcl from './components/tableAcl.vue'

export default {
  components: {
    tableAcl,
  },
}
</script>
