/* eslint-disable global-require */
// const notifications = [
//   {
//     id: 1,
//     title: 'Congratulation Sam 🎉',
//     avatar: require('@/assets/images/avatars/6-small.png'),
//     subtitle: 'Won the monthly best seller badge',
//     type: 'light-success',
//   },
//   {
//     id: 2,
//     title: 'New message received',
//     avatar: require('@/assets/images/avatars/9-small.png'),
//     subtitle: 'You have 10 unread messages',
//     type: 'light-info',
//   },
//   {
//     id: 3,
//     title: 'Revised Order 👋',
//     avatar: 'MD',
//     subtitle: 'MD Inc. order updated',
//     type: 'light-danger',
//   },
// ]

// const systemNotifications = [
//   {
//     title: 'Server down',
//     subtitle: 'USA Server is down due to hight CPU usage',
//     type: 'light-danger',
//     icon: 'XIcon',
//   },
//   {
//     title: 'Sales report generated',
//     subtitle: 'Last month sales report generated',
//     type: 'light-success',
//     icon: 'CheckIcon',
//   },
//   {
//     title: 'High memory usage',
//     subtitle: 'BLR Server using high memory',
//     type: 'light-warning',
//     icon: 'AlertTriangleIcon',
//   },
// ]

/* eslint-disable global-require */
export default {
  namespaced: true,
  state: {
    notifications: [],
    systemNotifications: [],
  },
  getters: {
    notifications: state => state.notifications,
  },
  mutations: {
    PUSH_NOTIFICATION(state, notification) {
      state.notifications.push({
        ...notification,
        id: (Math.random().toString(36) + Date.now().toString(36)).substr(2),
      })
    },
    REMOVE_NOTIFICATION(state, notificationToRemove) {
      state.notifications = state.notifications.filter(
        notification => notification.id !== notificationToRemove.id,
      )
    },
    REMOVE_ALL_NOTIFICATION(state) {
      state.notifications = []
      state.systemNotifications = []
    },
  },
  actions: {
    async addNotification({ commit }, notification) {
      commit('PUSH_NOTIFICATION', notification)
    },
    async removeNotification({ commit }, notification) {
      commit('REMOVE_NOTIFICATION', notification)
    },
    async removeAllNotifications({ commit }) {
      commit('REMOVE_ALL_NOTIFICATION')
    },
  },
}
