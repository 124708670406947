import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // list history
  GET_PAYMENT_TICKET_REQUEST(state) {
    REQUEST(state)
    state.ticketHistoryLoading = true
  },
  GET_PAYMENT_TICKET_SUCCESS(state, data) {
    SUCCESS(state)
    state.paymentTickets = data
    state.ticketHistoryLoading = false
  },

  GET_PAYMENT_TICKET_FAILURE(state, error) {
    FAILURE(state, error)
    state.ticketHistoryLoading = false
  },

  // Approve ticket
  APPROVE_TICKET_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  APPROVE_TICKET_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  APPROVE_TICKET_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Reject ticket
  REJECT_TICKET_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  REJECT_TICKET_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  REJECT_TICKET_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // Comments ticket
  COMMENT_TICKET_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  COMMENT_TICKET_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  COMMENT_TICKET_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_TRANSACTION_REQUEST(state) {
    REQUEST(state)
    state.balanceHistoryLoading = true
  },
  GET_TRANSACTION_SUCCESS(state, data) {
    SUCCESS(state)
    state.transactions = data
    state.balanceHistoryLoading = false
  },

  GET_TRANSACTION_FAILURE(state, error) {
    FAILURE(state, error)
    state.balanceHistoryLoading = false
  },

  GET_ADSHISTORIES_REQUEST(state) {
    REQUEST(state)
    state.adsAccountHistoryLoading = true
  },
  GET_ADSHISTORIES_SUCCESS(state, data) {
    SUCCESS(state)
    state.adsAccountHistories = data
    state.adsAccountHistoryLoading = false
  },
  GET_ADSHISTORIES_FAILURE(state, error) {
    FAILURE(state, error)
    state.adsAccountHistoryLoading = false
  },

  GET_CASHBACK_TRANSACTION_REQUEST(state) {
    REQUEST(state)
  },
  GET_CASHBACK_TRANSACTION_SUCCESS(state, data) {
    SUCCESS(state)
    state.cashbackTransaction = data
  },
  GET_CASHBACK_TRANSACTION_FAILURE(state, error) {
    FAILURE(state, error)
  },

  UPDATE_CASHBACK_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_CASHBACK_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_CASHBACK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // refund history
  GET_REFUND_TRANSACTION_REQUEST(state) {
    REQUEST(state)
    state.refundTransactionLoading = true
  },
  GET_REFUND_TRANSACTION_SUCCESS(state, data) {
    SUCCESS(state)
    state.refundTransaction = data
    state.refundTransactionLoading = false
  },

  GET_REFUND_TRANSACTION_FAILURE(state, error) {
    FAILURE(state, error)
    state.refundTransactionLoading = false
  },

  // AUTO REFUND
  GET_LIST_REQUEST_AUTO_REFUND_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_REQUEST_AUTO_REFUND_SUCCESS(state, data) {
    SUCCESS(state)
    state.listRequestAutoRefund = data
  },
  GET_LIST_REQUEST_AUTO_REFUND_FAILURE(state, error) {
    FAILURE(state, error)
  },

  AM_REVIEW_REQUEST_REFUND_REQUEST(state) {
    REQUEST(state)
  },
  AM_REVIEW_REQUEST_REFUND_SUCCESS(state) {
    SUCCESS(state)
  },
  AM_REVIEW_REQUEST_REFUND_FAILURE(state, error) {
    FAILURE(state, error)
  },

  FM_REVIEW_REQUEST_REFUND_REQUEST(state) {
    REQUEST(state)
  },
  FM_REVIEW_REQUEST_REFUND_SUCCESS(state) {
    SUCCESS(state)
  },
  FM_REVIEW_REQUEST_REFUND_FAILURE(state, error) {
    FAILURE(state, error)
  },
  // END AUTO REFUND
}
