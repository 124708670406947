<template>
  <div>
    <b-card-code title="Filters">
      <!-- advance search input -->
      <b-row>
        <b-col md="6">
          <b-form-group>
            <label>Search account by field</label>
            <b-input-group class="input-filter">
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model.trim="searchTerm"
                :placeholder="
                  searchSelected === 'email'
                    ? 'Search by user email'
                    : 'Search by user ID'
                "
                :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                class="d-inline-block"
                :class="searchSelected === 'email' ? 'input-search' : ''"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
              <b-button
                v-if="searchSelected === 'email'"
                variant="outline-secondary"
                class="btn-filter-search"
                :class="isActiveBtn ? 'active-btn-search' : null"
                @click.prevent="handleSubmitSearch"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>
              {{ $t('account.status') }}
            </label>

            <b-form-select
              v-model="statusSelected"
              :options="statusOptions"
              @input="handleStatusChange"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>Requested At</label>
            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="{ mode: 'range' }"
                placeholder="Select date"
                @on-close="handleDateChange"
              />
              <b-input-group-append
                v-if="startDate && endDate"
                is-text
                class="close-icon"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearDate"
                />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="CalendarIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <approval-confirm-modal
      :show="showApprovalConfirm"
      :user-info="task"
      @close-modal="handleCloseApproval"
      @on-confirm="handleConfirmApprove"
    />

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      style-class="vgt-table custom-table"
      :rows="accounts"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalTickets > 0,
      }"
      :sort-options="{
        enabled: totalTickets > 0,
      }"
      :is-loading="loading"
      :group-options="{
        enabled: false,
      }"
      @on-sort-change="onSortChange"
      @on-row-click="onRowClick"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ads Account -->
        <a
          v-if="props.column.field === 'userId'"
          class="d-flex align-items-center"
          target="_blank"
          :href="`/user/${props.row.localUserId}`"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="userInfo(props.row.avt)"
          />
          <div class="d-flex flex-column">
            <p class="text-secondary font-weight-bold mb-0 underline">
              {{
                props.row.userId.fullName &&
                  capitalise(props.row.userId.fullName)
              }}
              <br>
              <small class="text-placeholder">
                {{ props.row.userId.email }}
              </small>
            </p>
          </div>
        </a>

        <!-- Column: Business Account -->
        <div
          v-else-if="props.column.field === 'details.businessId'"
          class="d-flex align-items-center"
        >
          <b-img
            rounded
            class="ads-avt-img"
            :src="baAdsAccountImg(props.row.avt)"
          />
          <div class="d-flex flex-column">
            <p class="text-secondary font-weight-bold mb-0">
              {{ props.row.details.name }}
              <br>
              <small class="text-placeholder">
                {{ props.row.details.businessId }}
              </small>
            </p>
          </div>
        </div>

        <div
          v-else-if="props.column.field === 'currentPlan'"
          class="d-flex align-items-center"
        >
          <div class="d-flex flex-column">
            <p class="text-secondary font-weight-bold mb-0">
              {{ getCurrentPlan(props.row) }}
            </p>
          </div>
        </div>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'details.timezone'">
          <span v-html="formatTimezone(props.row.details.timezone)" />
        </span>

        <!-- product link -->
        <span v-else-if="props.column.field === 'productLinks'">
          <div v-if="props.row.userId.products">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="text-body align-middle mr-25 cursor-pointer"
              @click.prevent="openProductLinksModal(props.row)"
            />
          </div>
        </span>

        <!-- store url -->
        <span v-else-if="props.column.field === 'details.storeUrl'">
          <div v-if="props.row.details.storeUrl">
            <feather-icon
              icon="EyeIcon"
              size="16"
              class="text-body align-middle mr-25 cursor-pointer"
              @click.prevent="openStoreDetail(props.row.details)"
            />
          </div>
        </span>

        <!-- Column: Timezone -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="adsStatusVariant(props.row.status)">
            {{ capitalise(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: createdDate -->
        <div
          v-else-if="props.column.field === 'createdAt'"
          class="d-flex flex-column"
        >
          <span>{{ formatDate(props.row.createdAt) }}</span>
        </div>

        <!-- Column: Currency -->
        <span
          v-else-if="props.column.field === 'details.currency'"
          class="font-weight-bold"
        >
          {{ capitalise(props.row.details.currency) }}
        </span>

        <!-- Column: Actions -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="props.row.status === ADS_ACCOUNT_TICKETS_STATUS.OPEN">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="approveAccountTicket(props.row)">
                <feather-icon
                  class="mr-50"
                  icon="CheckCircleIcon"
                />
                <span>
                  Approve
                </span>
              </b-dropdown-item>
              <b-dropdown-item @click="rejectAccountTicket(props.row)">
                <feather-icon
                  icon="ThumbsDownIcon"
                  class="mr-50"
                />
                <span>Reject</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <span v-else>
            <b-dropdown
              :ref="`dropdown-${props.row.originalIndex}`"
              no-caret
              variant="link"
              menu-class="abc"
              boundary="window"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <div class="position-relative pl-1 pr-1 reason-reject">
                <p class="text-capitalize font-weight-bolder">
                  {{ props.row.status }}
                </p>
                <a
                  @click="
                    $refs[`dropdown-${props.row.originalIndex}`].hide(true)
                  "
                >
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    class="position-absolute position-top-0 position-right-0 mr-1"
                  />
                </a>

                <b-dropdown-divider
                  v-if="props.row.by && props.row.by.localUserId"
                />
                <div v-if="props.row.by && props.row.by.localUserId">
                  <label>Admin ID</label>
                  <p>{{ props.row.by && props.row.by.localUserId }}</p>
                </div>

                <b-badge
                  v-if="props.row.rejectedReason"
                  variant="light-danger"
                  class="reason-reject-message"
                >
                  <div
                    class="mb-0"
                    v-html="props.row.rejectedReason"
                  />
                </b-badge>
              </div>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalTickets > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Ticket per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap ">
              Showing
              {{ pageLength * (currentPage - 1) + 1 }}
              to
              {{ pageLength * currentPage }}
              of {{ totalTickets }} ticket(s)
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalTickets"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>

    <!-- ticket handler -->
    <ticket-sidebar
      v-model="isTaskHandlerSidebarActive"
      :task="task"
      :action-type="actionType"
      :clear-task-data="clearTaskData"
      @approve-ticket="onApproveTicket"
      @reject-ticket="onRejectTicket"
    />

    <product-links-user-details
      :account-tickets="userSelected"
      :show-side-bar.sync="isProductLinksDetail"
    />

    <store-detail
      :store-selected="storeSelected"
      :show-side-bar.sync="isStoreDetail"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import moment from 'moment'
import {
  ADS_ACCOUNT_TICKETS_STATUS,
  DATE_RANGE,
  TICKET_ACTIONS,
} from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BImg,
  BDropdownDivider,
  // BLink,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BRow,
  BCol,
  BInputGroup,
  VBModal,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import TicketSidebar from './TicketSidebar.vue'
import ApprovalConfirmModal from './ApprovalConfirmModal.vue'
import productLinksUserDetails from './productLinksUserDetails.vue'
import StoreDetail from './StoreDetail.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('adsAccount')
const {
  mapGetters: mapGettersMyTask,
  mapActions: mapActionsMyTask,
} = createNamespacedHelpers('myTask')

// clearTaskData
const blankTask = {
  id: null,
  title: '',
  type: '',
  status: '',
  userId: '',
  details: {},
  description: '',
}

export default {
  components: {
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    // BLink,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
    BButton,
    flatPickr,
    BCardCode,
    TicketSidebar,
    ApprovalConfirmModal,
    productLinksUserDetails,
    StoreDetail,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, envMixin, dateTimeFormatMixin],
  data() {
    return {
      userSelected: {},
      storeSelected: {},
      isProductLinksDetail: false,
      isStoreDetail: false,

      currentPage: 1,
      searchTerm: '',
      searchAccount: '',
      ADS_ACCOUNT_TICKETS_STATUS,

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },

      // status filter
      statusSelected: '',

      statusOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: 'Approved',
          value: ADS_ACCOUNT_TICKETS_STATUS.APPROVED,
        },
        {
          text: 'Rejected',
          value: ADS_ACCOUNT_TICKETS_STATUS.REJECTED,
        },
        {
          text: 'Open',
          value: ADS_ACCOUNT_TICKETS_STATUS.OPEN,
        },
        {
          text: 'Closed',
          value: ADS_ACCOUNT_TICKETS_STATUS.CLOSED,
        },
      ],

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
      openAddFundModal: false,
      ticketComment: '',
      currentTicket: '',
      task: JSON.parse(JSON.stringify(blankTask)),
      isTaskHandlerSidebarActive: false,
      actionType: TICKET_ACTIONS.APPROVE,
      showApprovalConfirm: false,
      dataApproval: null,
    }
  },
  computed: {
    ...mapGetters(['accountTickets', 'message', 'status', 'loading']),
    ...mapGettersMyTask(['searchTask']),

    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.accountInfo'),
          field: 'userId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.businessInfo'),
          field: 'details.businessId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Product links',
          field: 'productLinks',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Store url',
          field: 'details.storeUrl',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Current plan',
          field: 'currentPlan',
          width: '150px',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasSubscription,
        },
        {
          label: this.$t('account.timeZone'),
          field: 'details.timezone',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.currency'),
          field: 'details.currency',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.status'),
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.action'),
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.requestAt'),
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    accounts() {
      return get(this.accountTickets, ['content'], [])
    },

    totalTickets() {
      return get(this.accountTickets, ['paging', 'total'], 0)
    },

    adsStatusVariant() {
      const statusColor = {
        [ADS_ACCOUNT_TICKETS_STATUS.APPROVED]: 'light-success',
        [ADS_ACCOUNT_TICKETS_STATUS.CLOSED]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.PENDING]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.REJECTED]: 'light-danger',
        [ADS_ACCOUNT_TICKETS_STATUS.OPEN]: 'light-info',
      }

      return status => statusColor[status]
    },

    adsStatusTxt() {
      const statusColor = {
        [ADS_ACCOUNT_TICKETS_STATUS.APPROVED]: 'light-success',
        [ADS_ACCOUNT_TICKETS_STATUS.CLOSED]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.PENDING]: 'light-warning',
        [ADS_ACCOUNT_TICKETS_STATUS.REJECTED]: 'light-danger',
        [ADS_ACCOUNT_TICKETS_STATUS.OPEN]: 'light-info',
      }

      return status => statusColor[status]
    },

    alertTextColor() {
      const txtColor = {
        [true]: 'text-danger',
        [false]: 'text-success',
      }

      return txt => txtColor[txt]
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    baAdsAccountImg() {
      return img => img || require('@/assets/images/common/ic-ba-info.svg')
    },
  },

  created() {
    if (this.searchTask.id && this.searchTask.status) {
      this.searchTerm = this.searchTask.id
      this.statusSelected = this.searchTask.status
      this.handleSearch(this.searchTask.id)
    }
  },

  beforeDestroy() {
    this.setSearchTask({ id: '', status: '' })
  },

  methods: {
    ...mapActions([
      'getAccountTickets',
      'approveTicket',
      'rejectTicket',
      'commentTicket',
    ]),
    ...mapActionsMyTask(['setSearchTask']),

    capitalise(value) {
      return value && value.charAt(0).toUpperCase() + value.slice(1)
    },

    formatTimezone(value) {
      const timezone = value.split(/[()]+/)
      if (timezone.length >= 2) {
        return `<span class="font-weight-bold">${timezone[1]}</span> <br /><span class="text-placeholder">${timezone[2]} </span>`
      }
      return `<span class="font-weight-bold">${timezone[0]}</span>`
    },

    async fetchAccountTickets() {
      if (
        this.searchTerm
        && !this.validateEmail(this.searchTerm)
        && this.searchSelected === 'email'
      ) {
        return
      }

      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.statusSelected && { status: [this.statusSelected] }),
      }
      await this.getAccountTickets(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchAccountTickets()
      } else if (searching.toString().length > 3 && this.searchSelected !== 'email') {
        this.debounce = setTimeout(() => {
          this.fetchAccountTickets()
        }, 600)
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchAccountTickets()
      }
    },

    getCurrentPlan(item) {
      if (item.userId && item.userId.plans && item.userId.plans.type) {
        return item.userId.plans.type
      }

      return 'N/A'
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchAccountTickets()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchAccountTickets()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchAccountTickets()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchAccountTickets()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0])).format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
        await this.fetchAccountTickets()
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchAccountTickets()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchAccountTickets()
    },

    async handlePickDateChange(date) {
      const { value } = date
      if (value !== 'ALL') {
        const start = value.start.format('YYYY-MM-DD')
        const end = value.end.format('YYYY-MM-DD')
        this.startDate = start
        this.endDate = end
        this.rangeDate = `${start} to ${end}`
        this.fetchAccountTickets()
      } else {
        this.clearDate()
      }
    },

    async rejectAccountTicket(params) {
      this.task = params
      this.isTaskHandlerSidebarActive = true
      this.actionType = TICKET_ACTIONS.REJECT
    },

    approveAccountTicket(params) {
      this.task = params
      this.isTaskHandlerSidebarActive = true
      this.actionType = TICKET_ACTIONS.APPROVE
    },

    async commentAccountTicket(id, message) {
      const params = {
        id,
        message,
      }
      await this.commentTicket(params)
      this.currentTicket = ''
      this.ticketComment = ''
      if (this.status) {
        this.toastSuccess(this.$t('account.textCommentSuccess'))
        this.fetchAccountTickets()
      } else {
        this.toastFailure(this.message)
      }
    },

    handleCommentSubmit() {
      if (this.ticketComment) {
        this.commentAccountTicket(this.currentTicket, this.ticketComment)
      }
    },

    onRowClick() {
      // this.task = params.row
      // this.isTaskHandlerSidebarActive = true
      // this.actionType = TICKET_ACTIONS.VIEW
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
    },

    async onApproveTicket(data, BCId, adsAccountId) {
      const { _id } = data
      const params = {
        id: _id,
        adsAccountId,
        BCId,
      }
      this.dataApproval = params
      this.showApprovalConfirm = true

      // await this.approveTicket(params)
      // if (this.status) {
      //   this.toastSuccess(this.$t('account.textApproveSuccess'))
      //   this.fetchAccountTickets()
      // } else {
      //   this.toastFailure(this.message)
      // }
    },

    async handleConfirmApprove() {
      if (!this.dataApproval) {
        return
      }

      await this.approveTicket(this.dataApproval)
      if (this.status) {
        this.toastSuccess(this.$t('account.textApproveSuccess'))
        this.fetchAccountTickets()
      } else {
        this.toastFailure(this.message)
      }
      this.showApprovalConfirm = false
    },

    handleCloseApproval() {
      this.showApprovalConfirm = false
      this.dataApproval = null
    },

    async onRejectTicket(data, message) {
      // TODO: write the notes some where

      const { _id } = data
      const params = {
        id: _id,
        message,
      }
      await this.rejectTicket(params)
      if (this.status) {
        this.toastSuccess(this.$t('account.textRejectSuccess'))
        this.fetchAccountTickets()
      } else {
        this.toastFailure(this.message)
      }
    },

    clearTaskData() {
      const task = JSON.parse(JSON.stringify(blankTask))
      task.value = JSON.parse(JSON.stringify(blankTask))
    },

    openProductLinksModal(data) {
      this.userSelected = data
      this.isProductLinksDetail = true
    },

    openStoreDetail(data) {
      this.storeSelected = data
      this.isStoreDetail = true
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.reason-reject {
  width: 250px;
  max-height: 200px;
  overflow: auto;

  &-message {
    display: block;
    white-space: normal;
    text-align: start;
  }
}
</style>

<style lang="scss" scoped>
::v-deep .vgt-responsive {
  min-height: 45vh;
}
</style>
