import axios from '@axios'

export default {
  getAllTask(_params) {
    const params = {
      ..._params,
    }

    return axios.get('/api/admin/tickets', { params })
  },
}
