<template>
  <b-modal
    ref="open-modal-delete-role"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium">
        Please Confirm
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <template>
      <div class="wrapper mt-1">
        Are you sure want to remove the {{ roleEdit }} role?
      </div>
    </template>

    <div class="d-flex mt-1 justify-content-end">
      <btn-loading
        class="mr-1 btn-close"
        type="submit"
        variant="outline-primary"
        block
        :loading="loading"
        @click.prevent="hideModal"
      >
        Close
      </btn-loading>
      <btn-loading
        class="button-delete"
        type="submit"
        variant="primary"
        block
        :loading="loading"
        @click.prevent="deleteRole"
      >
        Delete
      </btn-loading>
    </div>
  </b-modal>
</template>
<script>

import {
  BModal,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'

const { mapGetters, mapActions } = createNamespacedHelpers('acl')
export default {
  components: {
    BModal,
    BtnLoading,
  },
  mixins: [toastification],

  props: {
    roleEdit: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    ...mapGetters(['listAcl', 'message', 'status', 'loading']),
  },

  methods: {
    ...mapActions(['createRole', 'getListAcl']),

    async deleteRole() {
      const roleIndex = this.listAcl.roles.findIndex(role => this.roleEdit === role)
      this.listAcl.roles.splice(roleIndex, 1)
      const params = {
        roles: this.listAcl.roles,
      }
      await this.createRole(params)
      if (this.status) {
        this.toastSuccess('Delete Role successfully.')
        this.hideModal()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      } else {
        this.toastFailure(this.message)
      }
    },

    showDeleteModal() {
      this.$refs['open-modal-delete-role'].show()
    },

    hideModal() {
      this.$refs['open-modal-delete-role'].hide()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
.button-delete {
  width: max-content;
}

.btn-close {
  width: max-content;
  margin-top: 0.5rem;
}
</style>
