<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    width="420px"
    right
    no-header
    backdrop
    shadow
    @hidden="onHidden"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Note information
        </h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="onHidden"
          />
        </div>
      </div>

      <div class="mb-4">
        <div class="p-2">
          <p class="font-14 mb-0">
            User
          </p>
          <div class="d-flex content-user">
            <b-img
              rounded
              class="ads-avt-img"
              :src="require('@/assets/images/common/ic-user-info.svg')"
            />
            <div>
              <p class="mb-0 text-secondary font-weight-bold mb-0">
                {{ userData.fullName || '' }}
              </p>
              <span class="text-placeholder font-14">
                {{ userData.email || '' }}
              </span>
            </div>
          </div>
        </div>
        <p class="font-weight-bold px-2">
          Notes history
        </p>
        <div
          v-for="(item, index) in listNote"
          :key="index"
        >
          <div v-if="!item.deletedAt">
            <p class="text-date mb-2">
              {{ item.updatedAt ? formatDate(item.updatedAt) : formatDate(item.at) }}
            </p>
            <div
              class="font-14 font-weight-bold mb-1 px-2"
              v-html="item.msg"
            />
            <div class="d-flex justify-content-between px-2">
              <p class="text-placeholder font-14">
                Admin: <span class="text-dark">{{ item.by }}</span>
              </p>
              <div
                v-if="idAdmin === item.by"
                class="d-flex text-danger"
              >
                <span
                  class="cursor-pointer"
                  @click="deleteNote(item._id)"
                >Remove</span>
                <hr class="hr-custom">
                <span
                  class="cursor-pointer"
                  @click="openUpdateNoteModal(item._id, item.msg)"
                >Update</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex m-2">
        <b-button
          variant="primary"
          class="btn-add-note"
          type="add"
          @click.prevent="openAddNoteModal"
        >
          Add new note
        </b-button>
      </div>
    </template>

    <!-- Modal note -->
    <add-note-modal
      ref="open-modal-add-note"
      :user-data="userData || {}"
      :id-note="idNote"
      :description-note="descriptionNote"
      @fetchList="fetchListNote"
    />
  </b-sidebar>
</template>
<script>

import {
  BSidebar,
  BImg,
  BButton,
} from 'bootstrap-vue'

import {
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import _get from 'lodash/get'
import { createNamespacedHelpers } from 'vuex'
// import BtnLoading from '@/layouts/components/BtnLoading.vue'
import dateTimeFormatMixin from '@/mixins/dateTimeFormatMixin'
import AddNoteModal from './AddNoteModal.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('user')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('auth')
export default {
  components: {
    BSidebar,
    // BtnLoading,
    BImg,
    BButton,
    AddNoteModal,
  },
  mixins: [toastification, numberFormatMixin, dateTimeFormatMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    userData: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      show: false,
      descriptionNote: '',
      idNote: '',

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters(['listAdminNote', 'loading', 'status', 'message']),
    ...mapGettersUser(['user']),

    idAdmin() {
      return this.user?.localUserId
    },

    listNote() {
      return _get(this.listAdminNote, ['notes'], [])
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.fetchListNote()
    },
  },

  methods: {
    ...mapActions(['getListAdminNote', 'handleAddNote', 'handleDeleteNote', 'handleUpdateNote']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
    },

    async deleteNote(idNote) {
      const params = {
        id: this.userData.localUserId,
        noteIds: [idNote],
      }
      await this.handleDeleteNote(params)
      if (this.status) {
        this.toastSuccess('Delete note success.')
        this.fetchListNote()
      } else {
        this.toastFailure(this.message)
      }
    },

    fetchListNote() {
      const params = {
        id: this.userData.localUserId,
      }
      this.getListAdminNote(params)
    },

    openAddNoteModal() {
      this.$refs['open-modal-add-note'].showAddModal()
    },

    openUpdateNoteModal(idNote, description) {
      this.idNote = idNote
      this.descriptionNote = description
      this.$refs['open-modal-add-note'].showUpdateModal()
    },
  },
}
</script>

  <style lang="scss" scoped>
  .content-user {
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 12px;
    margin: 12px 0px 16px;
  }

  .text-date {
    background-color: #FAFAFA;
    font-size: 12px;
    color: #63667B;
    width: 100%;
    padding: 6px 24px;
  }

  .btn-add-note {
    position: fixed;
    bottom: 24px;
    width: calc(100% - 48px);
  }

  .hr-custom {
    margin: 5px 4px 0px 4px;
    width: 0px;
    height: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  </style>
