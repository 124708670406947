<template>
  <div class="total-analysis-container">
    <b-row>
      <b-col
        v-for="(item, index) in listAnalysisPartnerDetail"
        :key="index"
        cols="4"
        class="card-content"
      >
        <b-card
          no-body
        >
          <b-card-body class="statistics-body">
            <div class="d-flex">
              <b-img
                :src="item.icon"
                fluid
                alt="icon payment"
                class="icon"
              />
              <div>
                <div class="d-flex">
                  {{ item.value }}
                  <b-img
                    v-b-tooltip.hover.v-secondary.top="``"
                    src="@/assets/images/pages/overview/ic-detail.svg"
                    alt="logo"
                  />
                </div>
                <span class="small-text">{{ item.label }}</span>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, VBTooltip, BCard, BCardBody,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('partner')

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BCardBody,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  computed: {
    ...mapGetters(['listAnalysisPartnerDetail']),
  },
}
</script>

<style lang="scss" scoped>
.total-analysis-container{
  padding: 24px 0;
}
.card-content {
  padding: 10px;
  .card {
    margin-bottom: 0px;
    height: 100%;
  }
}
.statistics-body {
  display: flex;
  align-items: center;

  padding: 15px 10px;

  border: 1px solid #EEEEEE;
  border-radius: 10px;
}
.small-text {
  font-size: 12px;
}
.icon {
  margin-right: 12px;
  width: 35px;
}
</style>
