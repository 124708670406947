import partnerPermission from '@/navigation/vertical/partnerPermission'
import dashboard from './dashboard'
import payments from './payments'
import user from './user'
import subscriptions from './subscriptions'
import productLinks from './productLinks'
import giftCode from './giftCode'
import partner from './partner'
import marketPartner from './marketPartner'
import verifyInfo from './verifyInfo'
import inactiveAdAccount from './inactiveAdAccount'
import acl from './acl'
import adCreative from './adCreative'
import autoRefund from './autoRefund'

export default [
  ...dashboard,
  ...verifyInfo,
  ...subscriptions,
  ...productLinks,
  ...autoRefund,
  ...user,
  ...acl,
  ...inactiveAdAccount,
  ...payments,
  ...giftCode,
  ...partner,
  ...marketPartner,
  ...partnerPermission,
  ...adCreative,
]
