export default {
  methods: {
    trackingAdminApproveInfo(email) {
      const dataLayer = {
        event: 'admin_approve_user_info',
        user_email: email,
        user_id: email,
        userId: email,
      }

      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)
    },

    trackingUserAddFundSuccess(email, amount, paymentMethod) {
      const dataLayer = {
        event: 'user_add_fund_success',
        user_email: email,
        user_id: email,
        userId: email,
        add_fund_amount: amount,
        payment_method: paymentMethod,
      }

      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)
    },
  },
}
