// eslint-disable-next-line import/prefer-default-export
export const USER_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
  BLOCKED_BY_ADMIN: 'blockedByAdmin',
  BLOCKED_BY_RECHARGE_FAILED: 'blockedByRechargeFailed',
  BLOCKED_BY_IP_VIOLATION: 'blockedByIpViolation',
  BLOCKED_BY_DOMAIN_VIOLATION: 'blockedByDomainViolation',
  BLOCKED_BY_CONTACT_VIOLATION: 'blockedByContactViolation',
  BLOCKED_BY_PAYMENT_VIOLATION: 'blockedByPaymentViolation',
}

export const REASON_BLOCK = {
  BLOCKED_BY_ADMIN: 'BY_ADMIN',
  BLOCKED_BY_RECHARGE_FAILED: 'BY_RECHARGE_FAILED',
  BLOCKED_BY_IP_VIOLATION: 'IP_VIOLATION',
  BLOCKED_BY_PAYMENT_VIOLATION: 'PAYMENT_VIOLATION',
  BLOCKED_BY_CONTACT_VIOLATION: 'CONTACT_VIOLATION',
  BLOCKED_BY_DOMAIN_VIOLATION: 'DOMAIN_VIOLATION',
}

export const PROFILE_STATUS = {
  APPROVED: 'approved',
  REVIEWING: 'reviewing',
  REJECTED: 'rejected',
  NOT_FOUND: 'not_found',
}

export const BC_STATUS = {
  LIMITED: 'limited',
  ACTIVATED: 'available',
  SUSPENDED: 'suspended',
  INTERNAL_BC: 'internal_bc',
}

export const ADMIN_ROLES = {
  ADMIN: 'ADMIN',
  ROOT: 'ROOT',
  EXCLUSIVE_PARTNER: 'EXCLUSIVE_PARTNER',
}

export const USER_RELATED_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
}

export const ADS_ACCOUNT_USER_RELATED_STATUS = {
  SHOW_ACCOUNT_STATUS_APPROVED: 'SHOW_ACCOUNT_STATUS_APPROVED',
  SHOW_ACCOUNT_STATUS_PUNISHED: 'SHOW_ACCOUNT_STATUS_PUNISHED',
  CLOSED: 'CLOSED',
}

export const REGISTER_FROM_SERVICE = {
  TTM: 'TTM',
  PANCAKE: 'PANCAKE',
  TTAC: 'TTAC',
}
