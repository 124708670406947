import { GiftCodeService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getListGiftCode({ commit }, params) {
    commit('GET_LIST_GIFT_CODE_REQUEST')
    try {
      const { data } = await GiftCodeService.getListGiftCode(params)
      commit('GET_LIST_GIFT_CODE_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_LIST_GIFT_CODE_FAILURE', error)
    }
  },

  async deactivateGiftCode({ commit }, params) {
    commit('DEACTIVATE_GIFT_CODE_REQUEST')
    try {
      const { data } = await GiftCodeService.deactivateGiftCode(params)
      commit('DEACTIVATE_GIFT_CODE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_GIFT_CODE_FAILURE', error)
    }
  },

  async updateGiftCode({ commit }, params) {
    commit('UPDATE_GIFT_CODE_REQUEST')
    try {
      const { data } = await GiftCodeService.updateGiftCode(params)
      commit('UPDATE_GIFT_CODE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_GIFT_CODE_FAILURE', error)
    }
  },

  async createGiftCode({ commit }, params) {
    commit('CREATE_GIFT_CODE_REQUEST')
    try {
      const { data } = await GiftCodeService.createGiftCode(params)
      commit('CREATE_GIFT_CODE_SUCCESS', data)
    } catch (error) {
      commit('CREATE_GIFT_CODE_FAILURE', error)
    }
  },

  async getListPartnerNoPaging({ commit }, params) {
    commit('GET_LIST_PARTNER_NO_PAGING_REQUEST')
    try {
      const { data } = await GiftCodeService.getListPartnerNoPaging(params)
      commit('GET_LIST_PARTNER_NO_PAGING_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_LIST_PARTNER_NO_PAGING_FAILURE', error)
    }
  },

  async getInfoUser({ commit }, params) {
    commit('GET_INFO_USER_REQUEST')
    try {
      const { data } = await GiftCodeService.getInfoUser(params)
      commit('GET_INFO_USER_SUCCESS', getResponse(data) || {})
    } catch (error) {
      commit('GET_INFO_USER_FAILURE', error)
    }
  },
}
