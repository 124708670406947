import axios from '@axios'

export default {
  getSubscriptions(_params, cancelToken) {
    const params = {
      ..._params,
    }

    return axios.get('/api/admin/users?sort=-plans.lastCanceledAt', { params, cancelToken })
  },

  approveSubscription(_params) {
    const params = {
      ..._params,
    }

    delete params.id
    return axios.post(`/api/admin/users/${_params.id}/plans/review-cancel`, { params })
  },

  rejectSubscription(_params) {
    const params = {
      ..._params,
    }

    delete params.id
    return axios.post(`/api/admin/users/${_params.id}/plans/review-cancel?skip=true`, { params })
  },

  deleteSubscription(_params) {
    return axios.delete(`/api/admin/users/${_params.id}/ads-accounts`, { data: { adsAccountIds: _params.adsAccounts } })
  },
}
