<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 bg-white">

      <b-row class="navbar-auth-custom px-0 mx-0 col-sm-12">
        <b-col
          lg="3"
          cols="8"
        >
          <!-- Brand logo-->
          <brand-logo />
          <!-- /Brand logo-->
        </b-col>
        <b-col
          cols="4"
          class="navbar-title px-0 d-flex align-items-center justify-content-end"
        >
          <div class="d-flex justify-content-between">
            <b-link
              class="cover"
              :to="{name:'login'}"
            >
              <span class="nav-link">
                {{ $t('forgotPW.textLogin') }}
              </span>
            </b-link>
          </div>
        </b-col>
      </b-row>
      <!-- Reset password-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto max-width form-reset-password"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1 text-center"
          >
            {{ $t('resetPW.textWelcome') }}
          </b-card-title>
          <b-card-text class="mb-4 text-center">
            {{ $t('resetPW.textGuide') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label-for="reset-password-new"
                :label="$t('resetPW.labelNewPassword')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend
                      is-text
                    >
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.trim="password"
                      id="reset-password-new"
                      class="form-control-merge input-height"
                      name="reset-password-new"
                      :placeholder="$t('resetPW.placeholderNewPW')"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                class="mt-3"
                label-for="reset-password-confirm"
                :label="$t('resetPW.labelConfirmPassword')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-input-group-prepend
                      is-text
                    >
                      <feather-icon icon="LockIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model.trim="cPassword"
                      id="reset-password-confirm"
                      class="form-control-merge input-height"
                      name="reset-password-confirm"
                      :placeholder="$t('resetPW.placeholderConfirmPW')"
                      :type="password2FieldType"
                      :state="errors.length > 0 ? false:null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <btn-loading
                block
                type="submit"
                variant="primary"
                class="btn-confirm-password my-3 input-height d-flex justify-content-center align-items-center"
                :loading="loading"
              >
                {{ $t('resetPW.labelConfirmPassword') }}
              </btn-loading>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>{{ $t('forgotPW.textNoAccount') }}</span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;
                {{ $t('forgotPW.btnRegisterNow') }}
              </span>
            </b-link>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Reset password-->

      <!-- Left Text-->
      <left-image :src="imgUrl" />
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupPrepend,
  BInputGroupAppend, BLink, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'
import BrandLogo from '@/layouts/components/BrandLogo.vue'
import { toastification } from '@core/mixins/toast'
import { mapGetters } from 'vuex'
import LeftImage from '@/layouts/components/LeftImage.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

export default {
  components: {
    BrandLogo,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    LeftImage,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,

    // components
    BtnLoading,

    // validate
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification],
  data() {
    return {
      cPassword: '',
      password: '',
      imgUrl: require('@/assets/images/pages/auth/reset-password-illustrator.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    ...mapGetters({
      status: 'auth/status',
      loading: 'auth/loading',
      message: 'auth/message',
    }),
    hash() {
      return this.$route.query.hash
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    async validationForm() {
      const success = await this.$refs.simpleRules.validate()
      if (success) {
        const params = {
          hash: this.hash,
          newPassword: this.password,
        }
        await this.$store.dispatch('auth/resetFinish', { params })

        if (this.status) {
          this.toastSuccess(this.$t('resetPW.textResetPWSuccess'))
          this.$router.push({ name: 'login' })
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.max-width{
  max-width: 600px;
}
</style>
<style lang="scss" scoped>
  .label-note {
    margin-top: .75rem;
    margin-bottom: 1.5rem;
  }
  .btn-confirm-password {
    margin-bottom: 2.5rem;
  }
  .input-height {
    height: 50px;
  }
  @media (max-width: 767px) {
    .form-reset-password {
      padding-left: 0;
      padding-right: 0;
      .description {
        line-height: 24px;
        margin-bottom: 2rem !important;
      }
    }
    .navbar-auth-custom {
      width: calc(100%);
    }
    .card-title {
      padding-top: 2.5rem;
    }
    .card-text:last-child {
      margin-bottom: 2.2rem;
    }
  }
</style>
