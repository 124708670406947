var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-right","bg-variant":"white","width":"420px","right":"","backdrop":"","shadow":"","title":"Add Partner BC"},on:{"hidden":_vm.onHidden},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"wrapper"},[_c('div',[_c('validation-observer',{ref:"formValidation"},[_c('b-form',[_c('b-form-group',{staticClass:"w-100"},[_c('label',[_vm._v(" Partner BC ID ")]),_c('validation-provider',{attrs:{"id":"BC Id","name":"BC Id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-tags',{staticClass:"group-input-bc-id",attrs:{"no-outer-focus":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var tags = ref.tags;
var inputAttrs = ref.inputAttrs;
var inputHandlers = ref.inputHandlers;
var addTag = ref.addTag;
var removeTag = ref.removeTag;
return [_c('b-input-group',{attrs:{"aria-controls":"my-custom-tags-list"}},[_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"placeholder":"BC ID - Press enter to add","type":"number"}},'input',inputAttrs,false),inputHandlers)),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return addTag()}}},[_vm._v(" Add ")])],1)],1),_c('ul',{staticClass:"list-unstyled mb-0",attrs:{"id":"my-custom-tags-list","aria-live":"polite","aria-atomic":"false","aria-relevant":"additions removals"}},_vm._l((tags),function(tag){return _c('b-card',{key:tag,staticClass:"bc-id-item",attrs:{"id":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_"),"tag":"li","body-class":"body-bc-id-input"}},[_c('strong',[_vm._v(_vm._s(tag))]),_c('b-button',{staticClass:"p-0",attrs:{"variant":"link","size":"sm","aria-controls":("my-custom-tags-tag_" + (tag.replace(/\s/g, '_')) + "_")},on:{"click":function($event){return removeTag(tag)}}},[_c('feather-icon',{staticClass:"icon-delete-id",attrs:{"icon":"XIcon","size":"16"}})],1)],1)}),1)]}}],null,true),model:{value:(_vm.bcIds),callback:function ($$v) {_vm.bcIds=$$v},expression:"bcIds"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-form-group',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"id":"ads account id","name":"ads account id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Ads Account ID")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"EX: 7172040789353086977","type":"number"},model:{value:(_vm.adsAccountId),callback:function ($$v) {_vm.adsAccountId=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"adsAccountId"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-form-group',{staticClass:"w-100"},[_c('validation-provider',{attrs:{"id":"role","name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Role ")]),_c('v-select',{staticClass:"input-height",attrs:{"placeholder":"Select role","options":_vm.roleOptions},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1)],1),_c('btn-loading',{staticClass:"btn-approve",attrs:{"type":"submit","block":"","variant":"outline-primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" Approve Permissions ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }