import axios from '@axios'

const CREATE_ACCOUNT_TYPE = 'create_ads_account'

export default {
  getAccountTickets(_params) {
    const params = {
      ..._params,
      type: CREATE_ACCOUNT_TYPE,
    }
    return axios.get('/api/admin/tickets', { params })
  },

  getListAccountNoPaging() {
    const params = {
      page: 1,
      size: 20,
    }
    return axios.get('/api/admin/tickets', { params })
  },

  approveTicket(params) {
    return axios.patch(`/api/admin/tickets/approve/${params.id}`, {
      adsAccountId: params.adsAccountId,
      BCId: params.BCId,
    })
  },

  rejectTicket(params) {
    return axios.patch(`/api/admin/tickets/reject/${params.id}`, {
      message: params.message,
    })
  },

  commentTicket(params) {
    return axios.post(`/api/admin/tickets/${params.id}/comments`, {
      message: params.message,
    })
  },

  createAdsAccount(params) {
    return axios.post(`/api/admin/users/${params.id}/ads-accounts`, {
      adsAccounts: params.adsAccounts,
      skipCheckSpendingAdAccount: params.skipCheckSpendingAdAccount,
    })
  },
}
