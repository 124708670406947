import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // Comments ticket
  IMPORT_AD_CREATIVE_REQUEST(state) {
    REQUEST(state)
  },
  IMPORT_AD_CREATIVE_SUCCESS(state) {
    SUCCESS(state)
  },
  IMPORT_AD_CREATIVE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list ad creative
  GET_AD_CREATIVE_REQUEST(state) {
    REQUEST(state)
  },
  GET_AD_CREATIVE_SUCCESS(state, data) {
    SUCCESS(state)
    state.listAdCreative = data
  },
  GET_AD_CREATIVE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list ad creative
  REVIEW_AD_CREATIVE_REQUEST(state) {
    REQUEST(state)
  },
  REVIEW_AD_CREATIVE_SUCCESS(state) {
    SUCCESS(state)
  },
  REVIEW_AD_CREATIVE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list ad creative
  GET_AD_ACCOUNT_IMPORT_REQUEST(state) {
    REQUEST(state)
  },
  GET_AD_ACCOUNT_IMPORT_SUCCESS(state, data) {
    SUCCESS(state)
    state.adAccountImport = data
  },
  GET_AD_ACCOUNT_IMPORT_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
