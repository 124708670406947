<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Edit Tier"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="wrapper">
      <b-form-group class="w-100">
        <label>
          Tier
        </label>
        <v-select
          v-model="tier"
          placeholder="Select tier"
          :selectable="option => option.disabled !== true"
          :options="tierOptions"
        />
      </b-form-group>

      <btn-loading
        type="submit"
        variant="primary"
        block
        :disabled="tier.length===0"
        :loading="loading"
        @click.prevent="submitUpdateTier"
      >
        Update
      </btn-loading>
    </div>
  </b-sidebar>
</template>
<script>
import {
  BSidebar,
  BFormGroup,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import vSelect from 'vue-select'
import { LIST_TIER } from '@/constants'

const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  components: {
    BSidebar,
    BtnLoading,
    BFormGroup,
    vSelect,
  },
  mixins: [toastification],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    tierSelected: {
      type: Object,
      default: () => {},
      required: true,
    },

    userId: {
      type: Number,
      default: 0,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      tier: {},

      tierOptions: LIST_TIER,
    }
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },

    tierSelected(val) {
      this.tierOptions.map(item => {
        if (item.value < val.value) {
          // eslint-disable-next-line no-param-reassign
          item.disabled = true
        }
        return item
      })
      this.tier = val
    },
  },

  computed: {
    ...mapGetters(['loading', 'message', 'status']),
  },

  methods: {
    ...mapActions(['updateTier']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    async submitUpdateTier() {
      const params = {
        id: this.userId,
        tier: this.tier.value,
      }
      await this.updateTier(params)
      if (this.status) {
        this.toastSuccess('Update tier successfully.')
        this.resetForm()
        this.show = false
        this.$emit('fetch-user-info')
      } else {
        this.toastFailure(this.message)
      }
    },

    resetForm() {
      this.tier = this.tierSelected
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}
</style>
