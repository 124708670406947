<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    title="Edit Package"
    width="420px"
    right
    backdrop
    shadow
    @hidden="onHidden"
  >
    <div class="p-2">
      <validation-observer ref="formValidation">
        <b-form>
          <b-form-group
            label="List service fees and Tax"
            class="form-label"
          >
            <validation-provider
              v-for="payment in listTransferFee"
              :key="payment.key"
              #default="{ errors }"
              :name="payment.name + ' fee'"
              rules="required"
              class="mb-2 d-flex justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center"
              >
                <img
                  :src="getBrandIcon(payment.key)"
                  width="20px"
                  alt="payment"
                  style="margin-right: 5px"
                >
                <div class="d-flex flex-column">
                  <span class="font-14 text-capitalize">
                    {{ namePaymentConvert(keyPayment(payment.key)) }}
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid':null"
                style="max-width: 110px"
                class="text-right input-group-merge"
              >
                <b-form-input
                  v-model.number="payment.value"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <b-input-group-append
                  is-text
                >
                  %
                </b-input-group-append>
              </b-input-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="tax"
              rules="required|valTaxFee"
              class="mb-2 d-flex justify-content-between w-100"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <span class="font-14 font-weight-bold">TAX</span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </div>
              <b-input-group
                :class="errors.length > 0 ? 'is-invalid':null"
                style="max-width: 110px"
                class="text-right input-group-merge"
              >
                <b-form-input
                  v-model.number="tax"
                  name="tax"
                  class="input-height"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Tax"
                  type="number"
                />
                <b-input-group-append
                  is-text
                >
                  %
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-2">
            <div class="d-flex justify-content-between">
              <label for="landingPage">
                Min top up
              </label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="Min top up"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="minTopup"
                  name="limitAdsAccount"
                  class="input-height"
                  placeholder="Enter limit ads account"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group class="mb-2">
            <div class="d-flex justify-content-between">
              <label for="landingPage">
                Limit ads account
              </label>
            </div>
            <validation-provider
              #default="{ errors }"
              name="limitAdsAccount"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="quantity"
                  name="limitAdsAccount"
                  class="input-height"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter limit ads account"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="form-label mb-2"
          >
            <div>
              <label for="textarea-default">Description</label>
              <b-form-textarea
                v-model="description"
                id="textarea-default"
                placeholder="Enter description"
                rows="3"
              />
            </div>
          </b-form-group>

          <div class="mt-1">
            <btn-loading
              type="submit"
              variant="primary"
              block
              :loading="loading"
              @click.prevent="submit"
            >
              Update
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>

import {
  BSidebar,
  // BRow,
  // BCol,
  // BFormSelect,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormTextarea,

} from 'bootstrap-vue'

// import pick from 'lodash/pick'

import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { required, valTaxFee } from '@validations'

import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toastification } from '@core/mixins/toast'
import pick from 'lodash/pick'
import envMixin from '@/mixins/envMixin'
import { PAYMENT_METHOD } from '@/constants'

const { mapGetters, mapActions } = createNamespacedHelpers('marketPackage')

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormTextarea,

    // component
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [toastification, numberFormatMixin, paymentMixin, envMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
    detailPackage: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      show: false,
      tax: 0,
      transferFee: 0,
      quantity: 0,
      minTopup: 0,
      description: '',
      selected: false,

      // validation
      required,
      valTaxFee,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),

    keyPayment() {
      return data => (data === PAYMENT_METHOD.TRANSFERWISE ? 'Wise' : data)
    },

    listTransferFee() {
      const { plans } = this.detailPackage?.policies ? this.detailPackage.policies[0] : []

      if (plans?.transferFee) {
        return Object.entries(pick(plans.transferFee, this.listPaymentMethodGeneralPackage)).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
          name: this.defaultTransferFee.find(item => item.key === key)?.name,
          isShow: this.defaultTransferFee.find(item => item.key === key)?.isShow,
        }))
      }

      return this.defaultTransferFee.filter(item => item.isShow)
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },

    detailPackage: {
      deep: true,
      handler(value) {
        let tax = 0
        let creditCard = 0
        if (value.policies) {
          const { plans } = value.policies[0]
          if (plans?.transferFee?.tax !== undefined && plans?.transferFee?.tax !== null) {
            tax = plans.transferFee.tax
          }

          if (plans?.transferFee?.creditCard !== undefined && plans?.transferFee?.creditCard !== null) {
            creditCard = plans.transferFee.creditCard
          }

          this.quantity = plans?.adsAccountLimit
          this.minTopup = plans?.minTopup
          this.description = value?.description
        }
        this.transferFee = parseFloat((creditCard * 100).toLocaleString('en-US'))
        this.tax = parseFloat((tax * 100).toLocaleString('en-US'))
      },
    },

  },

  methods: {
    ...mapActions(['updateAPackage']),

    onHidden() {
      this.$emit('update:show-side-bar', false)
    },

    arrToObj(arr) {
      const result = {}
      for (let i = 0; i < arr.length; i += 1) {
        result[arr[i].key] = parseFloat((arr[i].value / 100).toLocaleString('en-US'))
      }

      return result
    },

    async submit() {
      await this.$refs.formValidation.validate()
      if (this.tax > 100 || this.tax < 0) {
        this.toastFailure('Tax has min 0 and max 100')
      } else {
        const taxConverted = parseFloat((this.tax / 100).toLocaleString('en-US'))

        const params = {
          // eslint-disable-next-line no-underscore-dangle
          id: this.detailPackage._id,
          name: this.detailPackage.name,
          priceId: this.detailPackage.policies[0].plans.priceId,
          transferFee: { ...this.arrToObj(this.listTransferFee), tax: taxConverted },
          ...(this.minTopup && { minTopup: this.minTopup }),
          ...(this.quantity && { adsAccountLimit: this.quantity }),
          ...(this.description && { description: this.description }),
        }

        await this.updateAPackage(params)

        if (this.status) {
          this.toastSuccess('Update package success!')
          this.$emit('fetch-list-package')
          this.onHidden()
        } else {
          this.toastFailure(this.message)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form-label{
  margin-bottom: 0;
}

::v-deep button.close {
    display: block;
    margin-left: auto;
    margin-right: 0;
    font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
    flex-direction: row-reverse;
    strong {
        flex: 1;
    }
}
</style>
