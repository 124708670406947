export default [
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/user/User.vue'),
    meta: {
      pageTitle: 'User management',
      // breadcrumb: [
      //   {
      //     text: 'Listing tickets',
      //     active: true,
      //   },
      // ],
      requireAuth: true,
    },
  },
  {
    path: '/user/:id',
    name: 'user',
    component: () => import('@/views/user/UserDetailNew.vue'),
    meta: {
      pageTitle: 'User detail',
      requireAuth: true,
    },
  },
  // {
  //   path: '/userV2/:id',
  //   name: 'user',
  //   component: () => import('@/views/user/UserDetailV2.vue'),
  //   meta: {
  //     pageTitle: 'User detail',
  //     requireAuth: true,
  //   },
  // },
]
