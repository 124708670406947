/* eslint-disable no-underscore-dangle */
import axios from '@axios'

export default {
  getListAcl() {
    return axios.get('/api/admin/roles')
  },

  createRole(params) {
    return axios.post('/api/admin/roles', params)
  },

  updateRole(params) {
    return axios.put('/api/admin/roles/acl', params)
  },

  assignRole(params) {
    return axios.put(`/api/admin/roles/users/${params.id}`, {
      roles: params.roles,
    })
  },
}
