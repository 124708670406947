<template>
  <div>
    <b-sidebar
      v-model="show"
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="onHidden"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5
            class="mb-0"
          >
            Reject reason
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form class="p-2">
            <template>
              <!-- Reason -->
              <validation-provider
                #default="{ errors }"
                name="reason"
                rules="required"
              >
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      Reason
                    </label>
                  </div>
                  <quill-editor
                    v-model.trim="description"
                    ref="QuillEditor"
                    id="quil-content"
                    size="20"
                    :options="editorOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

              </validation-provider>
            </template>
            <!-- Form Actions -->
            <div
              class="d-flex mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="description === ''"
                @click.prevent="rejectedProductVideo"
              >
                Submit
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <div
          class="p-2 text-secondary"
        >
          Example: Hi James <br>
          We found an issue that can not approve your ads account, please follow
          below instruction to recovery or create a new<br>
          1, Your business name is duplicated with another exists company.<br>
          2, Provide your Establish a business license to verify that your
          company.<br>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import {
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import Ripple from 'vue-ripple-directive'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { toastification } from '@core/mixins/toast'
import { quillEditor } from 'vue-quill-editor'
import { createNamespacedHelpers } from 'vuex'
// eslint-disable-next-line import/extensions

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

const { mapGetters, mapActions } = createNamespacedHelpers('productLinks')

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,

    // 3rd party packages
    // quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [generalConfigsMixin, toastification],
  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    productVideoSelected: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      description: '',
      show: false,

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },

      // validate
      required,
    }
  },

  computed: {
    ...mapGetters([
      'status',
      'loading',
      'message',
    ]),
  },

  watch: {
    showSideBar(val) {
      this.show = val
    },
  },

  methods: {
    ...mapActions(['handleProductVideo']),

    onHidden() {
      this.description = ''
      this.$emit('update:show-side-bar', false)
    },

    async rejectedProductVideo() {
      const params = {
        id: this.productVideoSelected.id,
        action: 'reject',
        videoURL: this.productVideoSelected.videoURL,
        productURL: this.productVideoSelected.productURL,
        rejectedReason: this.description,
      }
      await this.handleProductVideo(params)
      if (this.status) {
        this.toastSuccess('Reject product video success!')
        this.$emit('fetchProductLinks')
        this.onHidden()
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
