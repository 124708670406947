import { StatsService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getUserAnalytics({ commit }, params) {
    commit('GET_USER_ANALYTICS_REQUEST')
    try {
      const { data } = await StatsService.getUserStats(params)
      commit('GET_USER_ANALYTICS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_USER_ANALYTICS_FAILURE', error)
    }
  },

  async getDepositAnalytics({ commit }, params) {
    commit('GET_DEPOSIT_ANALYTICS_REQUEST')
    try {
      const { data } = await StatsService.getDepositStats(params)
      commit('GET_DEPOSIT_ANALYTICS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_DEPOSIT_ANALYTICS_FAILURE', error)
    }
  },

  async getSpendingAnalytics({ commit }, params) {
    commit('GET_SPENDING_ANALYTICS_REQUEST')
    try {
      const { data } = await StatsService.getSpendingStats(params)
      commit('GET_SPENDING_ANALYTICS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_SPENDING_ANALYTICS_FAILURE', error)
    }
  },

  async getUsers({ commit }, params) {
    commit('GET_USERS_REQUEST')
    try {
      const { data } = await StatsService.getUsers(params)
      commit('GET_USERS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_USERS_FAILURE', error)
    }
  },
  async getDeposits({ commit }, params) {
    commit('GET_DEPOSITS_REQUEST')
    try {
      const { data } = await StatsService.getDeposits(params)
      commit('GET_DEPOSITS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_DEPOSITS_FAILURE', error)
    }
  },
  async getAdsAccounts({ commit }, params) {
    commit('GET_ADS_ACCOUNTS_REQUEST')
    try {
      const { data } = await StatsService.getAdsAccount(params)
      commit('GET_ADS_ACCOUNTS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ADS_ACCOUNTS_FAILURE', error)
    }
  },
  async getBonus({ commit }, params) {
    commit('GET_BONUS_REQUEST')
    try {
      const { data } = await StatsService.getBonus(params)
      commit('GET_BONUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_BONUS_FAILURE', error)
    }
  },
  async getBalances({ commit }, params) {
    commit('GET_BALANCES_REQUEST')
    try {
      const { data } = await StatsService.getBalances(params)
      commit('GET_BALANCES_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_BALANCES_FAILURE', error)
    }
  },

  async updateListBC({ commit }, params) {
    commit('UPDATE_LIST_BC_REQUEST')
    try {
      const { data } = await StatsService.updateListBC(params)
      commit('UPDATE_LIST_BC_SUCCESS', getResponse(data))
    } catch (error) {
      commit('UPDATE_LIST_BC_FAILURE', error)
    }
  },
}
