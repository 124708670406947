export default [
  {
    path: '/verify-information',
    name: 'verify-information',
    component: () => import('@/views/verify-information/VerifyInformation.vue'),
    meta: {
      pageTitle: 'Verify information',
      requireAuth: true,
    },
  },
]
