export default [
  {
    path: '/gift-code',
    name: 'gift-code',
    component: () => import('@/views/gift-code/GiftCode.vue'),
    meta: {
      pageTitle: 'Gift code',
      requireAuth: true,
    },
  },
]
