// eslint-disable-next-line import/named
import { AdCreativeService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async importAdCreative({ commit }, params) {
    commit('IMPORT_AD_CREATIVE_REQUEST')
    try {
      const { data } = await AdCreativeService.importAdCreative(params)
      commit('IMPORT_AD_CREATIVE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('IMPORT_AD_CREATIVE_FAILURE', error)
    }
  },

  async getAdCreatives({ commit }, params) {
    commit('GET_AD_CREATIVE_REQUEST')
    try {
      const { data } = await AdCreativeService.getAdCreatives(params)
      commit('GET_AD_CREATIVE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_AD_CREATIVE_FAILURE', error)
    }
  },

  async reviewAdCreative({ commit }, params) {
    commit('REVIEW_AD_CREATIVE_REQUEST')
    try {
      const { data } = await AdCreativeService.reviewAdCreative(params)
      commit('REVIEW_AD_CREATIVE_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REVIEW_AD_CREATIVE_FAILURE', error)
    }
  },

  async getAdAccountImport({ commit }, params) {
    commit('GET_AD_ACCOUNT_IMPORT_REQUEST')
    try {
      const { data } = await AdCreativeService.getAdAccountImport(params)
      commit('GET_AD_ACCOUNT_IMPORT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_AD_ACCOUNT_IMPORT_FAILURE', error)
    }
  },
}
