/* eslint-disable no-underscore-dangle */
import axios from '@axios'

export default {
  // Get list member of partner
  getListMemberPartner(params) {
    return axios.get(`/api/admin/users/${params.id}/partnership/members`, { params: { ...params, id: null } })
  },

  getTotalMemberPartner(params) {
    return axios.get(`/api/admin/users/${params.id}/partnership/statistics/members`, { params: { ...params, id: null } })
  },

  createPartner(params) {
    return axios.patch(`/api/admin/users/${params.id}/partnership`, params)
  },

  // Admin reject partnership role
  rejectPartner(params) {
    return axios.delete(`/api/admin/users/${params.id}/partnership`)
  },

  getListPartner(params, cancelToken) {
    return axios.get('/api/admin/users/partnership', { params, cancelToken })
  },

  // admin update partnership role
  updateRolePartner(params) {
    return axios.patch(`/api/admin/users/${params.id}/acl`, params)
  },

  // admin delete partnership role
  deleteRolePartner(params) {
    return axios.delete(`/api/admin/users/${params.id}/acl`, { data: params })
  },

  upgradeToPartner(params) {
    return axios.patch(`/api/admin/users/${params.id}/partnership`, params)
  },

  assignToAnotherPartner(params) {
    return axios.put(`/api/admin/users/${params.id}/partner-member`, params)
  },

  getSpendingDepositPartnerDetail(params) {
    return axios.get(`/api/admin/users/${params.id}/partnership/statistics/spending-deposit`, { params })
  },

  getTotalMemberMonthPartnerDetail(params) {
    return axios.get(`/api/admin/users/${params.id}/partnership/statistics/members/all`, { params })
  },

  getTotalAdsAccountMonthPartnerDetail(params) {
    return axios.get(`/api/admin/users/${params.id}/partnership/statistics/ads-accounts`, { params })
  },

  getAdsAccountsPartner(params) {
    return axios.get(`/api/admin/users/${params.id}/partnership/statistics/ads-accounts`, { params })
  },

  removeMember(params) {
    return axios.delete(`/api/admin/users/${params.id}/partnership/members`, { data: { memberLocalUserIds: params.memberLocalUserIds } })
  },

  getListAdAccountUser(params) {
    return axios.get('/api/admin/ads-accounts/spending-today', { params })
  },

  changeStatusFormWhitelistPartner(params) {
    const { localUserId, ...realParams } = params
    return axios.patch(`/api/admin/users/${localUserId}/partnership/form-whitelist`, realParams)
  },
}
