<template>
  <section id="dashboard">
    <h5 class="mt-50">
      Welcome back, {{ fullName }}!
    </h5>
    <small
      style="color: #84818a"
    >Here's what's happening with your platform today.</small>

    <monthly-analytics />

    <!-- <b-row class="list-summary">
      <b-col
        v-for="(summaryItem, index) in listSummary"
        :key="index"
        md="3"
        sm="12"
        class="summary-detail"
      >
        <div class="detail-content">
          <div class="img-left">
            <b-img
              :src="summaryItem.icon"
              fluid
              alt="icon payment"
              class="ic-payment"
            />
          </div>
          <div class="total-right">
            <p class="m-0 font-20 font-weight-600 d-flex align-items-center">
              {{ summaryItem.total }}
              <span class="ic-detail">
                <b-img
                  v-ripple.400="'rgba(61, 64, 91, 0.3)'"
                  v-b-tooltip.hover.v-secondary.top="`${summaryItem.tooltip}`"
                  src="@/assets/images/pages/overview/ic-detail.svg"
                  alt="logo"
                />
              </span>
            </p>
            <p class="m-0">
              {{ summaryItem.sub }}
            </p>
          </div>
        </div>
      </b-col>
    </b-row> -->

    <!-- <total-user-TTM @totalUser="totalUser" /> -->
    <b-row class="my-2">
      <b-col>
        <user-chart />
      </b-col>
    </b-row>

    <b-row class="my-2">
      <b-col>
        <deposit-chart />
      </b-col>
    </b-row>
    <b-row class="my-2">
      <b-col>
        <spending-chart />
      </b-col>
    </b-row>

    <!-- <monthly-deposit-chart @depositData="depositData" /> -->

    <!-- this chart is completed -->
    <b-row>
      <b-col
        md="12"
        sm="12"
      >
        <plan-charged
          v-if="hasSubscription"
          :roles="_get(user, 'roles', [])"
        />
      </b-col>
      <b-col
        md="6"
        sm="12"
      >
        <list-bc-balance />
      </b-col>
      <b-col md="6">
        <!-- <spending-statistics-chart /> -->
        <top-payment-stats-ver2 />
      </b-col>
      <!--      <b-col md="6">-->
      <!--        <total-ticket-request-ver2 />-->
      <!--      </b-col>-->
    </b-row>
    <!-- this chart is completed -->
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import {
  BRow, BCol,
} from 'bootstrap-vue'
import icTotalUser from '@/assets/images/pages/overview/ic-totalUsers.svg'
import icMonthDeposit from '@/assets/images/pages/overview/ic-monthlyDeposit.svg'
import { StatsService } from '@/services'
import _get from 'lodash/get'
import moment from 'moment'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import icTotalBalance from '@/assets/images/pages/overview/icon-total-balance.svg'
import SpendingChart from '@/views/home/components/SpendingChart.vue'
import envMixin from '@/mixins/envMixin'
import icTotalBonus from '../../assets/images/pages/overview/icon-total-bonus.svg'
// import TotalUserTTM from './components/TotalUserTTM.vue'
// import MonthlyDepositChart from './components/MonthlyDepositChart.vue'
import UserChart from './components/UserChart.vue'
import DepositChart from './components/DepositChart.vue'
import MonthlyAnalytics from './components/MonthlyAnalytics.vue'

import TopPaymentStatsVer2 from './components/TopPaymentStatsVer2.vue'
// import TotalTicketRequestVer2 from './components/TotalTicketRequestVer2.vue'
import PlanCharged from './components/PlanCharged.vue'
// import SpendingStatisticsChart from './components/SpendingStatisticsChart.vue'
import ListBcBalance from './components/ListBcBalance.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')
const { mapActions } = createNamespacedHelpers('home')

export default {
  components: {
    SpendingChart,
    BRow,
    BCol,
    // TotalUserTTM,
    // MonthlyDepositChart,
    UserChart,
    DepositChart,
    MonthlyAnalytics,

    TopPaymentStatsVer2,
    // TotalTicketRequestVer2,
    PlanCharged,
    // SpendingStatisticsChart,
    ListBcBalance,
  },

  mixins: [numberFormatMixin, envMixin],

  data() {
    return {
      totalUserTTM: null,
      date: null,
      listSummary: [],
    }
  },
  computed: {
    ...mapGettersAuth(['user']),
    fullName() {
      return this.user?.fullName
        ? this.user.fullName
        : `${this.user?.email || ''}`
    },
  },

  created() {
    this.TotalBalanceStats()

    const m = new Date().getMonth() + 1
    const y = new Date().getFullYear()
    this.date = `${m}/${y}`
    this.totalBonusStats(y, m)

    this.createdComponent()
  },

  methods: {
    _get,
    ...mapActions(['getUsers', 'getDeposits', 'getAdsAccounts', 'getBonus', 'getBalances']),
    async createdComponent() {
      await this.getUsers({ year: new Date().getFullYear(), type: 'monthly' })
      await this.getDeposits({ year: new Date().getFullYear(), month: new Date().getMonth() + 1, type: 'daily' })
      await this.getAdsAccounts({ fromDate: moment().startOf('month').format('YYYY-MM-DD'), toDate: moment().endOf('month').format('YYYY-MM-DD') })
      await this.getBonus({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })
      await this.getBalances()
    },
    totalUser(params) {
      const totalUsers = {
        name: 'user TTM',
        total: params ? params.totalUsers : '--',
        icon: icTotalUser,
        sub: 'Total User TTM',
        tooltip: 'Total user on Ecomdy system till now',
      }
      if (
        params.totalUsers
        && !this.listSummary.find(item => item.name === totalUsers.name)
      ) {
        this.listSummary.push(totalUsers)
      }
    },

    depositData(params) {
      const monthlyDeposit = {
        name: 'deposit data',
        total: params.monthlyDeposit ? params.monthlyDeposit : '--',
        icon: icMonthDeposit,
        sub: 'Monthly Deposit 2',
        tooltip:
          'Total amount of successful charged users in this month till now',
      }

      if (
        params.monthlyDeposit
        && !this.listSummary.find(item => item.name === monthlyDeposit.name)
      ) {
        this.listSummary.push(monthlyDeposit)
      }
    },

    async TotalBalanceStats() {
      await StatsService.getTotalBalanceStats().then(response => {
        const totalBalanceAmount = _get(response, [
          'data',
          'result',
          'totalBalance',
        ])
        const totalBalance = {
          name: 'Total User’s balance currently.',
          total: totalBalanceAmount
            ? this.currencyFormat(totalBalanceAmount)
            : '__',
          icon: icTotalBalance,
          sub: 'Monthly Deposit',
          tooltip:
            'Total amount of successful charged users in this month till now',
        }

        this.listSummary.push(totalBalance)
      })
    },

    async totalBonusStats(year, month) {
      await StatsService.getBonusStats({ year, month }).then(response => {
        const totalBonusAmount = _get(response, [
          'data',
          'result',
          'totalBonus',
        ])

        const totalBonus = {
          name: 'Total User’s balance currently.',
          total: totalBonusAmount
            ? this.currencyFormat(totalBonusAmount)
            : '__',
          icon: icTotalBonus,
          sub: 'Total Bonus',
          tooltip:
            'Total amount of successful charged users in this month till now',
        }
        this.listSummary.push(totalBonus)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.list-summary {
  margin-top: 26px;

  .summary-detail {
    .detail-content {
      background-color: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      display: flex;
      padding: 20px;
      align-items: center;

      .img-left {
        padding-right: 12px;
      }

      .total-right {
        .ic-detail {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
