<template>
  <div>
    <media
      :key="toggleAvatar"
      :url="userData.imageUrl"
      @changeImageFile="changeAvatar"
    />
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            :label="$t('common.labelFirstName')"
          >
            <b-form-input
              v-model.trim="userData.firstName"
              :placeholder="$t('common.placeholderFirstName')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('common.labelLastName')"
          >
            <b-form-input
              v-model.trim="userData.lastName"
              :placeholder="$t('common.placeholderLastName')"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('common.labelEmail')"
          >
            <b-form-input
              v-model="userData.email"
              disabled
              :placeholder="$t('common.placeholderEmail')"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            :label="$t('common.labelPhone')"
          >
            <b-form-input
              v-model.trim="userData.phoneNumber"
              :placeholder="$t('common.placeholderPhone')"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <btn-loading
            variant="primary"
            class="mt-2 mr-1 btn-save"
            :loading="loading"
            @click="onUpdateAccount"
          >
            {{ $t('common.btnSaveChanges') }}
          </btn-loading>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<script>
import {
  BForm, BRow, BCol, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Media from './Media.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersFile, mapActions: mapActionFile } = createNamespacedHelpers('file')

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,

    // components
    Media,
    BtnLoading,
  },

  mixins: [toastification],

  data() {
    return {
      toggleAvatar: false,
    }
  },

  computed: {
    ...mapGetters(['user', 'status', 'loading', 'message']),
    ...mapGettersFile({
      fileUrl: 'fileUrl',
      statusFile: 'status',
      messageFile: 'message',
    }),
    userData() {
      return { ...this.user }
    },
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),
    ...mapActionFile(['uploadFile']),
    async onUpdateAccount() {
      await this.updateAccount(this.userData)
      if (this.status) {
        await this.getAccountInfo()
        this.toastSuccess(this.$t('profile.textUpdateAccountSuccess'))
      } else {
        this.toastFailure(this.message)
      }
    },

    async changeAvatar(file) {
      await this.uploadFile(file)
      if (this.statusFile) {
        this.toastSuccess(this.$t('common.textUploadImageSuccess'))
        this.userData.imageUrl = this.fileUrl
        this.toggleAvatar = !this.toggleAvatar
      } else {
        this.toastFailure(this.messageFile)
      }
    },
  },
}
</script>
