<template>
  <b-modal
    ref="modal-update-bc"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary">
        Update status BC
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <div class="wrapper">
      <b-form>
        <b-form-group class="w-100">
          <label>
            Status
          </label>
          <v-select
            v-model="statusSelect"
            placeholder="Select status"
            :options="listStatus"
          />
        </b-form-group>
        <b-form-group class="w-100">
          <label
            class="form-label"
            for="createAt"
          >
            Assign to Admin TikTok Ids
          </label>
          <b-input-group class="input-group-merge">
            <b-form-textarea
              v-model.trim="adminIds"
              id="assign-admin-ids"
              name="adminIds"
              class="input-height"
              placeholder="Enter Admin TikTok Ids"
            />
          </b-input-group>
        </b-form-group>
      </b-form>

      <!--modal footer-->
      <div class="d-flex justify-content-end mt-2">
        <b-button
          variant="flat-secondary"
          class="mr-1"
          @click="hideModal"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="updateBc()"
        >
          Update
        </b-button>
      </div>
    </div></b-modal>
</template>
<script>
import {
  BForm, BFormGroup, BInputGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { BC_STATUS } from '@/constants'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'

const { mapActions, mapGetters } = createNamespacedHelpers('home')

export default {
  components: {
    vSelect,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BButton,
  },

  mixins: [toastification],

  props: {
    bcSelected: {
      type: Object,
      required: true,
      default: () => {
      },
    },
  },

  data() {
    return {
      adminIds: '',
      statusSelect: {},
      listStatus: [
        {
          label: 'Available',
          value: BC_STATUS.ACTIVATED,
        },
        {
          label: 'Limited',
          value: BC_STATUS.LIMITED,
        },
        {
          label: 'Suspended',
          value: BC_STATUS.SUSPENDED,
        },
      ],
    }
  },

  watch: {
    bcSelected: {
      handler(val) {
        this.statusSelect = this.listStatus.find(item => item.value === val.status)
        if (val.assignToAdminIds) {
          this.adminIds = val.assignToAdminIds.join(', ')
        } else {
          this.adminIds = ''
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
  },

  methods: {
    ...mapActions(['updateListBC']),
    showUpdateModal() {
      this.$refs['modal-update-bc'].show()
    },

    hideModal() {
      this.resetModal()
      this.$refs['modal-update-bc'].hide()
    },

    resetModal() {
      this.statusSelect = this.listStatus.find(item => item.value === this.bcSelected.status)
      if (this.bcSelected.assignToAdminIds) {
        this.adminIds = this.bcSelected.assignToAdminIds.join(', ')
      } else {
        this.adminIds = ''
      }
    },

    async updateBc() {
      const params = {
        id: this.bcSelected.id,
        status: this.statusSelect.value,
        ...(this.adminIds && { assignToAdminIds: this.adminIds.split(',').map(item => item.trim()) }),
      }
      await this.updateListBC(params)
      if (this.status) {
        this.toastSuccess('Update status BC successfully')
        this.hideModal()
        this.$emit('fetchListBC')
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
