<template>
  <div
    v-if="isShowModal"
    ref="modal-play-video"
    class="video-container"
  >
    <div
      v-if="srcVideo"
      class="video"
    >
      <video
        controls="video"
        autoplay
        :src="srcVideo"
      />
      <feather-icon
        class="icon-close-modal"
        icon="XCircleIcon"
        size="32"
        @click="hideModalPlayVideo"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    srcVideo: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isShowModal: false,
    }
  },

  methods: {
    showModalPlayVideo() {
      this.isShowModal = true
    },

    hideModalPlayVideo() {
      this.isShowModal = false
    },
  },
}
</script>

<style scoped lang="scss">
  .video-container{
    position: relative;

    .video{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
      background: rgba(0,0,0,0.95);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      video{
        position: relative;
        max-width: 900px;
        outline: none;

        @media (max-width: 991px) {
          max-width: 90%;
        }
      }

      .icon-close-modal{
        position: absolute;
        top: 30px;
        right: 30px;
        color: #fff;
        cursor: pointer;
      }
    }
  }
</style>
