<template>
  <div class="card-detail">
    <b-card>
      <!-- Information -->
      <b-row class="mb-1">
        <b-col
          sm="12"
          class="d-flex align-items-center"
        >
          <feather-icon
            size="23"
            icon="ChevronLeftIcon"
            class="cursor-pointer mr-1"
            @click="isFromPartner ? $router.go(-1) : $router.push('/users')"
          />
          <div class="d-flex align-items-center">
            <b-img
              :id="`avt-user-${userDetail._id}`"
              rounded
              class="ads-avt-img"
              :src="getAvatar(userDetail.registerFromService)"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && userDetail.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${userDetail._id}`"
            >
              {{ getUserPlatform(userDetail.registerFromService) }}
            </b-tooltip>
            <div class="ml-1">
              <p
                class="name-user"
                style="margin-bottom: 6px"
              >
                {{ `${userDetail.fullName ? userDetail.fullName : userDetail.email} - ID: ${userDetail.localUserId || ''} - IP: ${userIp} - Country: ${userCountry}` }}
                <span
                  v-if="isPartner"
                >
                  <b-img
                    v-b-tooltip.hover.top="'Partner'"
                    rounded
                    class="partner-icon"
                    :src="require('@/assets/images/icons/icon-tick.svg')"
                  />
                </span>
              </p>
              <div class="d-flex">
                <small class="">User Agent: {{ userAgent }}</small>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="my-2">
        <b-col class="d-flex">
          <div v-if="hasProductLink">
            <b-button
              v-if="!userDetail.whitelistProductVerify"
              class="btn-block-user mr-1"
              variant="flat-dark"
              size="sm"
              @click="handleWhiteListUser({ whitelistProductVerify: true })"
            >
              <feather-icon
                size="20"
                icon="PlusIcon"
                class="cursor-pointer icon-btn"
              />
              Add white list user
            </b-button>
            <b-button
              v-else
              class="btn-block-user mr-1"
              variant="flat-dark"
              size="sm"
              @click="handleWhiteListUser({ whitelistProductVerify: false })"
            >
              <feather-icon
                size="20"
                icon="TrashIcon"
                class="cursor-pointer icon-btn"
              />
              Remove white list user
            </b-button>
          </div>

          <b-button
            v-if="userDetail.status === 'blocked'"
            data-testid="btn-active-user"
            class="btn-block-user"
            variant="flat-dark"
            size="sm"
            @click.prevent="activeUser"
          >
            <feather-icon
              size="20"
              icon="CheckCircleIcon"
              class="cursor-pointer icon-btn"
            />
            Active user
          </b-button>
          <b-button
            v-else
            data-testid="btn-block-user"
            class="btn-block-user"
            variant="flat-dark"
            size="sm"
            @click.prevent="openBlockModal"
          >
            <feather-icon
              size="20"
              icon="TrashIcon"
              class="cursor-pointer icon-btn"
            />
            Block user
          </b-button>
          <b-button
            v-if="!isPartner && hasPartnership"
            class="btn-block-user ml-1"
            variant="flat-dark"
            size="sm"
            @click="openUpgradeToPartner"
          >
            <feather-icon
              size="20"
              icon="ThumbsUpIcon"
              class="cursor-pointer icon-btn"
            />
            Upgrade to partner
          </b-button>

          <div
            v-if="hasAutoBlock"
            class="width-max-content"
          >
            <b-button
              v-if="!userDetail.whitelistBlockBy"
              class="btn-block-user ml-1"
              variant="flat-dark"
              size="sm"
              @click="handleWhiteListBlock({ whitelist: true })"
            >
              <feather-icon
                size="20"
                icon="PlusIcon"
                class="cursor-pointer icon-btn"
              />
              Add white list block
            </b-button>
            <b-button
              v-else
              class="btn-block-user ml-1"
              variant="flat-dark"
              size="sm"
              @click="handleWhiteListBlock({ whitelist: false })"
            >
              <feather-icon
                size="20"
                icon="TrashIcon"
                class="cursor-pointer icon-btn"
              />
              Remove white list block
            </b-button>
          </div>

          <div
            id="skip-setup-cost-btn"
            class="ml-1"
          >
            <b-button
              v-if="hasSetupCost && !isPassSetupCost"
              class="btn-block-user ml-1"
              variant="flat-dark"
              size="sm"
              :disabled="disableSkipBtn"
              @click="openSkipSetupCostModal"
            >
              <feather-icon
                size="20"
                icon="CheckIcon"
                class="cursor-pointer icon-btn"
              />
              Skip setup cost
            </b-button>
          </div>
          <b-tooltip
            v-if="disableSkipBtn"
            placement="top"
            triggers="hover"
            target="skip-setup-cost-btn"
          >
            User must have information that has been verified by admin
          </b-tooltip>

          <b-button
            v-if="hasConnectedTikTok && userDetail.registerFromService !== REGISTER_FROM_SERVICE.TTAC"
            class="btn-block-user ml-1"
            variant="flat-dark"
            size="sm"
            @click="handleDisconnectTiktok()"
          >
            <feather-icon
              size="20"
              icon="XCircleIcon"
              class="cursor-pointer icon-btn"
            />
            Disconnect tiktok
          </b-button>
        </b-col>
      </b-row>

      <!-- Tab content -->
      <b-row>
        <b-col>
          <b-tabs
            v-model="tabIndex"
            class="tabs-info"
          >
            <b-tab
              id="business-information-tab"
              title="Business Information"
            >
              <b-card class="mb-0">
                <business-information
                  @fetch-user-info="fetchUserInfo"
                />
              </b-card>
            </b-tab>

            <b-tab
              id="plan-service-fees-tab"
              :title="hasSubscription ? 'Plan & Service Fees' : 'Balance & Ads Account'"
            >

              <plan-and-method-fees />
            </b-tab>

            <b-tab
              id="list-ad-account-tab"
              title="List Ads Account"
            >

              <list-ads-account
                :user-data="userDetail"
                :error-get-list-ad-account="errorGetListAdAccount"
                @on-change="onChangeListAdsAccount"
                @fetch-user-info="fetchUserInfo"
              />
            </b-tab>
            <b-tab
              v-if="hasProductLink"
              id="product-links-tab"
              :active="isTabProductLinks"
              title="Product links"
            >
              <detail-product-links
                :user-data="userDetail"
                :error-get-user-info="errorGetUserInfo"
              />
            </b-tab>
            <b-tab
              id="user-relate-tab"
              title="Users Related"
            >
              <users-related
                :user-data="userDetail"
                :error-get-user-info="errorGetUserInfo"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <!-- block modal -->
    <block-modal
      ref="open-block-modal"
      :user-data="userDetail"
      @update-user-selected="updateUserSelected"
    />

    <upgrade-to-partner
      :show-side-bar.sync="showUpgradeToPartner"
      @fetch-user-info="fetchUserInfo"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import {
  BRow, BCol, BCard, BTabs, BTab, BImg, BButton, VBTooltip, BTooltip,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { REGISTER_FROM_SERVICE, USER_STATUS } from '@/constants'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'
import usersMixin from '@/mixins/usersMixin'
import BlockModal from './components/BlockModal.vue'
import ListAdsAccount from './components/ListAdsAccount.vue'
import BusinessInformation from './components/BusinessInformation.vue'
import PlanAndMethodFees from './components/PlanAndMethodFees.vue'
import DetailProductLinks from './components/DetailProductLinks.vue'
import UsersRelated from './components/UsersRelated.vue'
import UpgradeToPartner from './components/UpgradeToPartner.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('user')

export default {
  components: {
    BTooltip,
    BCard,
    BRow,
    BCol,
    BTabs,
    BTab,
    BImg,
    BButton,
    BlockModal,
    ListAdsAccount,
    BusinessInformation,
    PlanAndMethodFees,
    DetailProductLinks,
    UsersRelated,
    UpgradeToPartner,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [numberFormatMixin, toastification, paymentMixin, envMixin, usersMixin],

  data() {
    return {
      USER_STATUS,
      showSideBar: false,
      showBalanceSideBar: false,
      isUpdateUser: false,
      changePlanSidebar: false,
      tabIndex: 0,
      isTabProductLinks: false,
      showUpgradeToPartner: false,
      isFromPartner: false,

      errorGetUserInfo: '',
      errorGetListAdAccount: '',
      disableSkipBtn: true,
    }
  },

  metaInfo() {
    return {
      title: `${this.$route.params.id}-${this.userDetail.fullName}` || process.env.VUE_APP_OG_TITLE,
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['userDetail', 'userAdsAccounts', 'status', 'message', 'hasConnectedTikTok']),

    userInfoImg() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    userIp() {
      return this.userDetail?.signupTracking?.ip
    },

    userCountry() {
      return _get(this.userDetail, ['signupTracking', 'countryName'], '')
    },

    userAgent() {
      return this.userDetail?.signupTracking?.userAgent
    },

    isPartner() {
      return this.userDetail?.partnership?.status === 'active'
    },

    isPassSetupCost() {
      return this.userDetail?.setupCostAt
    },
  },

  watch: {
    userDetail(val) {
      this.disableSkipBtn = val?.onboardingStep < 4
    },

    isUpdateUser(value) {
      if (value) {
        const { params } = this.$route
        this.fetchUserInfo(params)
        this.isUpdateUser = false
      }
    },
    $route: {
      immediate: true,
      handler(val) {
        if (val.query.type === 'productLinks') {
          this.isTabProductLinks = true
        } else if (val?.query?.from === 'partner') {
          this.isFromPartner = true
        }
      },
    },
  },

  created() {
    const { query } = this.$route
    this.fetchUserInfo()
    this.fetchListPartnerBcs()
    if (query.tab && query.tab === 'list-ads-account') {
      this.tabIndex = 1
    }
  },

  methods: {
    ...mapActions([
      'blockUser',
      'getUser',
      'getAdsAccountsUser',
      'activeUser',
      'updateUser',
      'addWhiteListBlock',
      'getListPartnerBcs',
      'disconnectTiktok',
    ]),

    async fetchUserInfo() {
      const { params } = this.$route
      await this.getUser(params).then(() => {
        this.errorGetUserInfo = this.message
      })
      await this.getAdsAccountsUser({
        id: params.id,
        page: 1,
        size: 10,
      }).then(() => {
        this.errorGetListAdAccount = this.message
      })
    },

    openBlockModal() {
      this.$refs['open-block-modal'].showModal()
    },

    async openSkipSetupCostModal() {
      this.loadingDown = true
      this.$bvModal
        .msgBoxConfirm(<p>Are you sure Skip setup cost for user <code>{this.userDetail.email}</code> ?</p>, {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Submit',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
        })
        .then(value => {
          if (value) {
            this.handleSkipSetupCost()
          } else {
            this.loadingDown = false
          }
        })
    },

    async handleSkipSetupCost() {
      const { params } = this.$route
      const payload = {
        id: this.userDetail.localUserId,
        skipSetupCost: true,
      }
      await this.updateUser(payload)
      if (this.status) {
        this.toastSuccess('Skip setup cost success')
        await this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
      this.loadingDown = false
    },

    capitalise(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    subStatusVariant(status) {
      if (status === 'canceled') {
        return 'light-warning'
      }
      return 'light-success'
    },

    async onActiveUser(id) {
      const params = {
        status: USER_STATUS.ACTIVE,
        id,
      }
      await this.activeUser(params)
      if (this.status) {
        this.toastSuccess('Active user success')
        // eslint-disable-next-line no-underscore-dangle
        this.$emit('update-user-selected')
        this.updateUserSelected()
      } else {
        this.toastFailure(this.message)
      }
    },

    onChangePlan() {
      this.changePlanSidebar = true
    },

    openSideBar() {
      this.showSideBar = true
    },

    updateUserSelected() {
      this.isUpdateUser = true
    },

    onChangeListAdsAccount({ page = 1, size = 10, keyword = '' }) {
      const params = {
        id: this.$route.params.id,
        page,
        size,
        keyword,
      }
      this.getAdsAccountsUser(params)
    },

    showPhoneNumber(userDetail) {
      let result = ''
      const { contactInfo } = userDetail
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      }

      return result
    },

    async handleWhiteListUser(status) {
      const { params } = this.$route
      const payload = {
        id: this.userDetail.localUserId,
        whitelistProductVerify: status.whitelistProductVerify,
      }
      await this.updateUser(payload)
      if (this.status) {
        this.toastSuccess(
          status.whitelistProductVerify
            ? 'Add white list user success'
            : 'Remove white list user success',
        )
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },

    async handleWhiteListBlock(status) {
      const { params } = this.$route
      const payload = {
        id: this.userDetail.localUserId,
        whitelist: status.whitelist,
      }
      await this.addWhiteListBlock(payload)
      if (this.status) {
        this.toastSuccess(
          status.whitelist
            ? 'Add white list block success'
            : 'Remove white list block success',
        )
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },

    async handleDisconnectTiktok() {
      const params = {
        id: this.userDetail.localUserId,
      }

      await this.disconnectTiktok(params)
      if (this.status) {
        this.toastSuccess(
          'Disconnect tiktok account success',
        )
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },

    async activeUser() {
      const payload = {
        id: this.userDetail.localUserId,
        status: USER_STATUS.ACTIVE,
      }

      await this.blockUser(payload)
      if (this.status) {
        this.toastSuccess('Active user success!')
        const { params } = this.$route
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },

    openUpgradeToPartner() {
      this.showUpgradeToPartner = true
    },

    async fetchListPartnerBcs() {
      const { params } = this.$route
      await this.getListPartnerBcs(params)
    },
  },
}
</script>

<style lang="scss" scoped>
.width-max-content {
  width: max-content;
}

.card-detail {
  .card {
    border-radius: 10px;

    .card-body {
      padding: 32px 30px;
      border: 1px solid #eeeeee;
      border-radius: 10px;

      .btn-block-user {
        padding: 10px 18.4px;
        border: 1px solid #e0e0e5;
        border-radius: 6px;
        transform: rotate(0.01deg);

        .icon-btn {
          margin-right: 0.5rem;
        }
      }

      .tabs-info {
        .card-body {
          padding: 0;
          border: none;
        }
      }
    }
  }
}

.name-user {
  font-weight: 700;
}

.percent-input {
  width: 83px;
}

.change-plan-text {
  padding: 0;
  border-radius: 0;
  display: inline-block;
  color: #f02548;
  line-height: 1;
  border-bottom: 1px solid #f02548;
  cursor: pointer;
}

.partner-icon {
  width: 18px;
  margin-top: -10px;
}
</style>
