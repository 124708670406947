import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  listUser: state => state.listUser,
  userDetail: state => state.userDetail,
  userAdsAccounts: state => state.userAdsAccounts,
  listAM: state => state.listAM,

  listAdminNote: state => state.listAdminNote,

  hasConnectedTikTok: state => state.hasConnectedTikTok,
  listPartnerBC: state => state.listPartnerBC,
  detailUserRelated: state => state.detailUserRelated,
  listUserLoading: state => state.listUserLoading,
}
