import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  paymentTickets: state => state.paymentTickets,
  transactions: state => state.transactions,
  adsAccountHistories: state => state.adsAccountHistories,
  cashbackTransaction: state => state.cashbackTransaction,
  refundTransaction: state => state.refundTransaction,
  ticketHistoryLoading: state => state.ticketHistoryLoading,
  balanceHistoryLoading: state => state.balanceHistoryLoading,
  adsAccountHistoryLoading: state => state.adsAccountHistoryLoading,
  refundTransactionLoading: state => state.refundTransactionLoading,
  listRequestAutoRefund: state => state.listRequestAutoRefund,
}
