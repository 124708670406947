<template>
  <b-sidebar
    v-model="show"
    id="sidebar-right"
    bg-variant="white"
    width="420px"
    right
    backdrop
    shadow
    title="Upgrade Partner"
    @hidden="onHidden"
  >
    <div class="p-2">
      <validation-observer
        ref="formValidation"
        v-slot="{ invalid }"
      >
        <!-- v-slot="{ invalid }" -->
        <b-form>
          <!-- Landing page -->
          <b-form-group
            label="Landing page"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="landingPage"
              rules="required|url"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model.number="landingPage"
                  placeholder="Ex: partner.ecomdymedia.com"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Community group -->
          <b-form-group
            label="Community group"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Community group"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  v-model="communityGroup"
                  placeholder="Enter Community group"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="isEcomdyPlatform"
            label="Regional"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="regional"
              rules="required"
            >
              <v-select
                v-model="regionalSelected"
                class="input-height"
                placeholder="Select regional"
                :options="regionalOptions"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Package for member"
            class="form-label mb-2"
          >
            <validation-provider
              #default="{ errors }"
              name="Package for member"
              rules="required"
            >
              <v-select
                v-model="packageSelected"
                class="input-height"
                placeholder="Select package for member"
                :options="optionsPackage"
              >
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <feather-icon
                      icon="ChevronDownIcon"
                      size="20"
                    />
                  </span>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <div>
            <b-form-checkbox
              v-model="selected"
              checked="true"
              name="check-button"
              class="mb-1 custom-control-danger"
            >
              Allow handle product link
              <b-img
                id="tooltip-verification"
                variant="outline-primary"
                src="@/assets/images/pages/overview/ic-detail.svg"
                alt="logo"
              />
              <b-tooltip
                target="tooltip-verification"
                placement="top"
                triggers="hover"
              >
                <div class="content-tooltip">
                  <li>Verify member product link</li>
                  <li>Add member product link</li>
                  <li>Verify member contact information</li>
                </div>
              </b-tooltip>
            </b-form-checkbox>
          </div>

          <div class="mt-2">
            <!-- edit buttons -->
            <btn-loading
              type="submit"
              variant="primary"
              block
              :disabled="invalid"
              :loading="loading"
              @click.prevent="submitAssignToPartner"
            >
              Upgrade
            </btn-loading>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>
<script>
/* eslint-disable no-underscore-dangle */

import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
  BImg,
  BTooltip,
  VBTooltip,
} from 'bootstrap-vue'

import {
  LIST_REGIONALS,
} from '@/constants'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, url } from '@validations'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import Ripple from 'vue-ripple-directive'

const { mapGetters, mapActions } = createNamespacedHelpers('partner')
const { mapActions: mapActionsMarketPackage, mapGetters: mapGettersMarketPackage } = createNamespacedHelpers('marketPackage')

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BtnLoading,
    BFormCheckbox,
    BImg,
    BTooltip,

    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [toastification, numberFormatMixin, envMixin],

  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      landingPage: '',
      communityGroup: '',
      packageSelected: null,

      selected: false,

      pageLength: 100,
      currentPage: 1,
      regionalSelected: null,
      regionalOptions: [
        { label: 'METAP', value: LIST_REGIONALS.METAP },
        { label: 'UCE', value: LIST_REGIONALS.UCE },
        { label: 'AUNZ', value: LIST_REGIONALS.AUNZ },
        { label: 'SEA', value: LIST_REGIONALS.SEA },
        { label: 'Other', value: LIST_REGIONALS.OTHER },
      ],

      // validate
      required,
      url,
    }
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
    ...mapGettersMarketPackage(['listMarketPackage']),

    optionsPackage() {
      return this.listMarketPackage?.content.map(item => {
        let itemPackage = {}
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < item.policies.length; i++) {
          itemPackage = {
            label: item?.policies[i].plans?.type,
            value: item._id,
          }
          return itemPackage
        }
        return itemPackage
      })
    },
  },

  watch: {
    showSideBar(val) {
      this.show = val
      this.fetchListPackage()
    },
  },

  methods: {
    ...mapActions(['createPartner', 'upgradeToPartner']),
    ...mapActionsMarketPackage(['getListPackage']),

    async fetchListPackage() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
      }
      await this.getListPackage(params)
    },

    async submitAssignToPartner() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        const { params } = this.$route
        const data = {
          id: params.id,
          landingPage: this.landingPage,
          communityGroup: this.communityGroup,
          packageIdForMember: this.packageSelected.value,
          allowVerifyUserInfo: this.selected,
          ...(this.regionalSelected && { region: this.regionalSelected.value }),
        }
        await this.upgradeToPartner(data)
        if (this.status) {
          this.toastSuccess('Assign this partner success.')
          this.onHidden()
          this.$emit('fetch-user-info')
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    onHidden() {
      this.$emit('update:show-side-bar', false)
      this.resetForm()
    },

    resetForm() {
      this.landingPage = ''
      this.communityGroup = ''
      this.packageSelected = null
      this.regionalSelected = null
      this.$refs.formValidation.reset()
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep button.close {
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 2.5rem !important;
}

::v-deep .b-sidebar > .b-sidebar-header {
  flex-direction: row-reverse;
  strong {
    flex: 1;
  }
}

.content-tooltip {
  text-align: left !important;
}
</style>
