<template>
  <b-modal
    ref="modal-add-note"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium text-primary">
        {{ !id? 'Add Note' : 'Update Note' }}
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <template>
      <validation-provider
        #default="{ errors }"
        name="reason"
        rules="required"
      >
        <b-form-group>
          <quill-editor
            v-model.trim="description"
            ref="QuillEditor"
            id="quil-content"
            size="20"
            :options="editorOption"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
    </template>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        class="mr-1"
        @click="hideModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        @click="!id ? addNote() : updateNote(id)"
      >
        {{ !id ? 'Add' : 'Update' }}
      </b-button>
    </div>
  </b-modal>
</template>
<script>

import {
  BButton,
  BModal,
  BFormGroup,
} from 'bootstrap-vue'

import {
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider } from 'vee-validate'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import { createNamespacedHelpers } from 'vuex'
// import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('user')
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    quillEditor,
    ValidationProvider,
  },
  mixins: [toastification, numberFormatMixin],

  props: {
    userData: {
      type: Object,
      required: true,
      default: () => {
      },
    },

    idNote: {
      type: String,
      default: '',
    },

    descriptionNote: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      show: false,
      description: '',
      id: '',

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },
    }
  },

  watch: {
    idNote(val) {
      this.id = val
    },

    descriptionNote(val) {
      this.description = val
    },
  },

  computed: {
    ...mapGetters(['loading', 'status', 'message']),
  },

  methods: {
    ...mapActions(['handleAddNote', 'handleUpdateNote']),

    async addNote() {
      const params = {
        id: this.userData.localUserId,
        msg: this.description,
      }
      await this.handleAddNote(params)
      if (this.status) {
        this.toastSuccess('Add note success.')
        this.$emit('fetchList')
        this.hideModal()
      } else {
        this.toastFailure(this.message)
      }
    },

    async updateNote(idNote) {
      const params = {
        id: this.userData.localUserId,
        noteId: idNote,
        msg: this.description,
      }
      await this.handleUpdateNote(params)
      if (this.status) {
        this.toastSuccess('Update note success.')
        this.$emit('fetchList')
        this.hideModal()
      } else {
        this.toastFailure(this.message)
      }
    },

    showAddModal() {
      this.id = ''
      this.description = ''
      this.$refs['modal-add-note'].show()
    },

    showUpdateModal() {
      this.id = this.idNote
      this.description = this.descriptionNote
      this.$refs['modal-add-note'].show()
    },

    hideModal() {
      this.id = this.idNote || ''
      this.description = this.descriptionNote || ''
      this.$refs['modal-add-note'].hide()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
