<template>
  <div class="analysis-container">
    <div class="total-analysis">
      <total-analysis />
    </div>
    <div class="monthly-analysis">
      <monthly-analysis />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MonthlyAnalysis from './monthlyAnalysis.vue'
import TotalAnalysis from './TotalAnalysis.vue'

const {
  // mapActions,
  mapGetters,
} = createNamespacedHelpers('partner')
const { mapGetters: mapGettersUser } = createNamespacedHelpers('user')

export default {
  components: { TotalAnalysis, MonthlyAnalysis },
  data() {
    return {}
  },

  computed: {
    ...mapGetters(['totalSpendingAndDeposit', 'listAnalysisPartnerDetail']),
    ...mapGettersUser(['userAdsAccounts']),
  },
}
</script>

<style lang="scss" scoped>
</style>
