import axios from '@axios'

export default {
  getUserStats(params) {
    return axios.get('/api/admin/statistics/users', { params })
  },

  getDepositStats(params) {
    return axios.get('/api/admin/statistics/deposit', { params })
  },

  getSpendingStats(params) {
    return axios.get('/api/admin/statistics/spending', { params })
  },

  getAdsAccountStats(params) {
    return axios.get('/api/admin/statistics/ads-account', { params })
  },

  getBonusStats(params) {
    return axios.get('/api/admin/statistics/bonus', { params })
  },

  getTopPaymentStats(params) {
    return axios.get('/api/admin/statistics/payment-method', { params })
  },

  getTotalBalanceStats(params) {
    return axios.get('/api/admin/statistics/balances', { params })
  },

  getPlanCharged(params) {
    return axios.get('/api/admin/statistics/plan-charged', { params })
  },

  getUsers(params) {
    return axios.get('/api/admin/statistics/users', { params })
  },

  getDeposits(params) {
    return axios.get('/api/admin/statistics/deposit', { params })
  },

  getAdsAccount(params) {
    return axios.get('/api/admin/statistics/ads-account', { params })
  },

  getBonus(params) {
    return axios.get('/api/admin/statistics/bonus', { params })
  },

  getBalances(params) {
    return axios.get('/api/admin/statistics/balances', { params })
  },

  getListBC() {
    return axios.get('/api/admin/bcs/balance')
  },

  updateListBC(params) {
    const { id, ...currentParams } = params
    return axios.put(`/api/admin-bcs/${id}`, currentParams)
  },
}
