import { KYCService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async addKYCUser({ commit }, params) {
    commit('ADD_KYC_USER_REQUEST')
    try {
      const { data } = await KYCService.createKYCUser(params)
      commit('ADD_KYC_USER_SUCCESS', getResponse(data))
    } catch (error) {
      commit('ADD_KYC_USER_FAILURE', error)
    }
  },
}
