<template>
  <div class="d-flex justify-content-end align-items-center">
    <b-button-group
      size="sm"
      class="mr-1 group-button"
    >
      <b-button
        :variant="type === 'daily' ? 'danger' : 'light'"
        size="sm"
        @click="handleChangeType('daily')"
      >
        Monthly
      </b-button>
      <b-button
        :variant="type === 'monthly' ? 'danger' : 'light'"
        size="sm"
        @click="handleChangeType('monthly')"
      >
        Yearly
      </b-button>
    </b-button-group>

    <div>
      <b-form-select
        v-if="type === 'daily'"
        v-model="monthSelected"
        :options="monthOptions"
        class="month-selection mr-1"
        size="sm"
      />
      <b-form-select
        v-model="yearSelected"
        :options="yearOptions"
        class="year-selection"
        size="sm"
      />
    </div>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BButtonGroup,
    BFormSelect,
  },
  props: {
    selection: {
      type: Object,
      required: true,
      default() {
        return {
          type: 'daily',
          monthSelected: new Date().getMonth() + 1,
          yearSelected: new Date().getFullYear(),
        }
      },
    },
  },

  data() {
    return {
      type: this.selection.type || 'daily',
      monthSelected: this.selection.monthSelected || new Date().getMonth() + 1,
      yearSelected: this.selection.yearSelected || new Date().getFullYear(),
    }
  },

  computed: {
    monthOptions() {
      const data = []
      let i = 1
      while (i <= 12) {
        data.push({
          value: i,
          text: i,
          disabled: i > new Date().getMonth() + 1 && this.yearSelected >= new Date().getFullYear(),
        })

        i += 1
      }

      return data
    },

    yearOptions() {
      const data = []
      let i = 2020
      while (i <= new Date().getFullYear()) {
        data.push({
          value: i,
          text: i,
        })
        i += 1
      }

      return data
    },
  },

  watch: {
    monthSelected() {
      this.change()
    },
    yearSelected(value) {
      if (value >= new Date().getFullYear()) {
        this.monthSelected = new Date().getMonth() + 1
      }
      this.change()
    },
    type() {
      this.monthSelected = new Date().getMonth() + 1
      this.yearSelected = new Date().getFullYear()
    },
  },

  methods: {
    handleChangeType(type) {
      this.type = type
      this.change()
    },

    change() {
      if (this.type === 'daily') {
        this.$emit('change', {
          type: this.type,
          month: this.monthSelected,
          year: this.yearSelected,
        })

        return
      }
      this.$emit('change', {
        type: this.type,
        year: this.yearSelected,
      })
    },
  },
}
</script>

<style>
.month-selection {
  width: 70px;
}
.year-selection {
  width: 100px;
}
.group-button {
  height: 35px;
}
</style>
