export default [
  {
    path: '/ad-creative',
    name: 'ad-creative',
    component: () => import('@/views/ad-creative/AdCreative.vue'),
    meta: {
      pageTitle: 'Ad Creative Management',
      requireAuth: true,
    },
  },
]
