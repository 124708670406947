<template>
  <b-modal
    ref="monthly-spending-modal"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal mb-2">
      <h4 class="">
        Monthly spending
      </h4>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div>
      <ul
        v-for="(item, index) in amountData"
        :key="index"
        class="list-spending"
      >
        <li>{{ `${item.month}: ${currencyFormat(item.value)}` }}</li>
      </ul>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Close
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
import {
  BModal, BButton,
  // BCardTitle, BCardText,
} from 'bootstrap-vue'

import numberFormatMixin from '@/mixins/numberFormatMixin'

export default {
  components: {
    BModal,
    BButton,
    // BCardText,
    // BCardTitle,
  },
  mixins: [numberFormatMixin],

  props: {
    amountData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      // validation
    }
  },

  methods: {
    hideModal() {
      this.$refs['monthly-spending-modal'].hide()
    },

    showModal() {
      this.$refs['monthly-spending-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
