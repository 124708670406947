<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="val => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5
            v-if="actionType == TICKET_ACTIONS.REJECT"
            class="mb-0"
          >
            Reject reason
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            Ticket information
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <template v-if="actionType != TICKET_ACTIONS.REJECT">
              <!-- Agency Business ID -->
              <validation-provider
                #default="{ errors }"
                name="Agency Business"
                rules="required"
              >
                <b-form-group
                  label="Agency Business ID"
                  label-for="Agency Business"
                >
                  <b-form-select
                    v-model="BCId"
                    :options="listBussinessCenter"
                    placeholder="Select business ID"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Select an option --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>

              <!-- User Business ID -->
              <b-form-group
                label="User Business ID"
                label-for="user-business-id"
              >
                <b-form-input
                  v-model="taskLocal.details.businessId"
                  id="user-business-id"
                  autofocus
                  trim
                  placeholder="Business Id"
                  disabled
                />
              </b-form-group>

              <!-- Assignee -->
              <b-form-group
                label="Timezone"
                label-for="timezone"
              >
                <v-select
                  v-model="taskLocal.details.timezone"
                  label="fullName"
                  class="assignee-selector"
                  input-id="timezone"
                  disabled
                />
              </b-form-group>

              <!-- currency -->
              <b-form-group
                label="Currency"
                label-for="currency"
              >
                <v-select
                  v-model="taskLocal.details.currency"
                  label="fullName"
                  class="assignee-selector"
                  input-id="currency"
                  disabled
                />
              </b-form-group>

              <!-- Account name -->
              <b-form-group
                label="Account name"
                label-for="name"
              >
                <b-form-input
                  v-model="taskLocal.details.name"
                  id="name"
                  autofocus
                  trim
                  placeholder="Account name"
                  disabled
                />
              </b-form-group>
            </template>
            <template v-else>
              <!-- Reason -->
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      Reason
                    </label>
                  </div>
                  <quill-editor
                    v-model="description"
                    ref="QuillEditor"
                    id="quil-content"
                    size="20"
                    :options="editorOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

                <!-- <b-form-group
                  label="Description"
                  label-for="task-description"
                >
                  <quill-editor
                    v-model="description"
                    id="quil-content"
                    :options="editorOption"
                    class="border-bottom-0"
                  />
                  <div
                    id="quill-toolbar"
                    class="d-flex justify-content-end border-top-0"
                  >
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                    <button class="ql-align" />
                    <button class="ql-link" />
                  </div>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group> -->
              </validation-provider>
            </template>
            <!-- Form Actions -->
            <div
              v-if="taskLocal.status === ADS_ACCOUNT_TICKETS_STATUS.OPEN"
              class="d-flex mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                Submit
              </b-button>
              <!--              <b-button-->
              <!--                v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
              <!--                type="reset"-->
              <!--                variant="outline-secondary"-->
              <!--              >-->
              <!--                Reset-->
              <!--              </b-button>-->
            </div>
          </b-form>
        </validation-observer>

        <div
          v-if="actionType == TICKET_ACTIONS.REJECT"
          class="p-2 text-secondary"
        >
          Example: Hi James <br>
          We found an issue that can not approve your ads account, please follow
          below instruction to recovery or create a new<br>
          1, Your business name is duplicated with another exists company.<br>
          2, Provide your Establish a business license to verify that your
          company.<br>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from 'bootstrap-vue'
import {
  ADS_ACCOUNT_TICKETS_STATUS,
  TICKET_ACTIONS,
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, ref } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'
// eslint-disable-next-line import/extensions
import useTaskHandler from './useTaskHandler.js'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    // 3rd party packages
    vSelect,
    // quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [generalConfigsMixin],
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
    actionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      TICKET_ACTIONS,
      ADS_ACCOUNT_TICKETS_STATUS,
      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },
    }
  },

  watch: {
    isTaskHandlerSidebarActive(value) {
      if (!value) {
        this.BCId = null
      }
    },
  },
  setup(props, { emit }) {
    const BCId = ref(null)
    const adsAccountId = ref('')
    const description = ref('')
    const {
      taskLocal,
      resetTaskLocal,
      // UI
      onSubmit,
    } = useTaskHandler(toRefs(props), emit, BCId, adsAccountId, description)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetTaskLocal, props.clearTaskData)

    // const editorOption = {
    //   modules: {
    //     toolbar: '#quill-toolbar',
    //   },
    //   placeholder: 'Write your reason description',
    // }

    return {
      // Add New
      taskLocal,
      BCId,
      adsAccountId,
      description,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // UI
      // editorOption,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
