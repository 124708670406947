// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_GETTERS = {
  status: state => state.status,
  loading: state => state.loading,
  error: state => state.error,
  message: state => state.error?.response?.data?.message || state.error?.message,
}

export const DEFAULT_STATE = {
  status: false,
  error: null,
  message: '',
  loading: false,
}
