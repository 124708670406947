<template>
  <b-modal
    ref="open-modal-reactive-ad-account"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h3 class="font-medium">
        Please Confirm
      </h3>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>
    <template>
      <div class="wrapper mt-1">
        Are you sure  want to reactive ads account have ID: {{ adAccountId }} ?
      </div>
    </template>

    <div class="d-flex mt-1 justify-content-end">
      <btn-loading
        class="mr-1 btn-close"
        type="submit"
        variant="outline-primary"
        block
        :loading="loading"
        @click.prevent="hideModal"
      >
        Close
      </btn-loading>
      <btn-loading
        class="button-delete"
        type="submit"
        variant="primary"
        block
        :loading="loading"
        @click.prevent="ReactiveAdAccount"
      >
        Reactive
      </btn-loading>
    </div>
  </b-modal>
</template>
<script>

import {
  BModal,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'

const { mapActions, mapGetters } = createNamespacedHelpers('inactiveAdAccount')
export default {
  components: {
    BModal,
    BtnLoading,
  },
  mixins: [toastification],

  props: {
    adAccountId: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      show: false,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'loading']),
  },

  methods: {
    ...mapActions(['reactiveAdAccounts']),

    async ReactiveAdAccount() {
      const params = {
        adsAccountIds: [this.adAccountId],
      }
      await this.reactiveAdAccounts(params)
      if (this.status) {
        this.toastSuccess('Reactive ad account successfully.')
        this.hideModal()
        this.$emit('fetchInactiveAdAccount')
      } else {
        this.toastFailure(this.message)
      }
    },

    showConfirmModal() {
      this.$refs['open-modal-reactive-ad-account'].show()
    },

    hideModal() {
      this.$refs['open-modal-reactive-ad-account'].hide()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
.button-delete {
  width: max-content;
}

.btn-close {
  width: max-content;
  margin-top: 0.5rem;
}
</style>
