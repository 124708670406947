import { InactiveAdAccountService } from '@/services'
import { getResponse } from '@/plugins/store'
import axios from 'axios'

export default {
  async getListInactiveAdAccount({ commit }, { params, cancelToken }) {
    commit('GET_LIST_INACTIVE_AD_ACCOUNT_REQUEST')
    try {
      const { data } = await InactiveAdAccountService.listInactiveAdAccount(params, cancelToken)
      commit('GET_LIST_INACTIVE_AD_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      if (axios.isCancel(error)) return

      commit('GET_LIST_INACTIVE_AD_ACCOUNT_FAILURE', error)
    }
  },

  async deleteAdsAccounts({ commit }, params) {
    commit('DISABLE_MULTI_INACTIVE_AD_ACCOUNT_REQUEST')
    try {
      const { data } = await InactiveAdAccountService.deleteAdsAccounts(params)
      commit('DISABLE_MULTI_INACTIVE_AD_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('DISABLE_MULTI_INACTIVE_AD_ACCOUNT_FAILURE', error)
    }
  },

  async reactiveAdAccounts({ commit }, params) {
    commit('REACTIVE_AD_ACCOUNT_REQUEST')
    try {
      const { data } = await InactiveAdAccountService.reactiveAdAccounts(params)
      commit('REACTIVE_AD_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('REACTIVE_AD_ACCOUNT_FAILURE', error)
    }
  },
}
